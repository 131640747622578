import axios from "axios";

export const getAddresses = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/addressManagement/getAddresses",
      body
    );
    if (res?.status === 200) return res.data.result;
    console.log(res);
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createAddress = async ({ input }) => {
  const { status, data } = await axios.post(
    "/api/v1/addressManagement/createAddress",
    { input }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return {};
};

export const updateAddress = async ({ id, input }) => {
  const { status, data } = await axios.post(
    "/api/v1/addressManagement/updateAddress",
    { id, input }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return {};
};

export const deleteAddress = async ({ id }) => {
  const { status, data } = await axios.post(
    "/api/v1/addressManagement/deleteAddress",
    { id }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return {};
};
