import React, { useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import { Body } from "../../UI/Text";
import Table from "../../UI/Table";
import Button from "../../UI/Button";
import * as quoteReqs from "../../../requests/quoteManagementRequests";
import formatter from "../../../modules/formatter";
import guid from "../../../modules/guid";
import { useQuery } from "../../Hooks";
import { getQuotes } from "../../../requests/quoteManagementRequests";
import UserContext from "../../../context/user/UserContext";
import { getStatusTypes } from "../../../requests/statusTypesMgmtReqs";

const ButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-out;
`;

const { copyQuote, deleteQuote } = quoteReqs;

const QuoteTable = (props) => {
  const theme = useTheme();
  const { activeUser, queries } = useContext(UserContext);
  const { username, roleLevel } = activeUser || {};

  const {
    onRowClick,
    allQuotes = false,
    isLoading,
    createLink,
    createButtonText,
    isQuoteRequest = false,
  } = props || {};

  const queryID1 = allQuotes ? "allQuotes" : "quotes";
  const queryID2 = "quoteRequests";

  const { [queryID1]: _quotes, [queryID2]: quoteRequests } = queries || {};

  // WM MOD modified attributes
  const quotes = useQuery({
    queryID: queryID1,
    request: getQuotes,
    queryVariables: {
      username: username,
      type: 1,
    },
    skip: isQuoteRequest || !username || isLoading,
    attributes: [
      "id",
      "sfQuoteNumber",
      "statusTypeId",
      "createdByEmail",
      "createdByUserId",
      "createdDate",
      "totalCost",
      "name",
      "sfUserId",
      "sfUserAlias",
      "quoteGenConfigId",
      "soWriteUpConfigId",
      "poNumber",
      "isLocked",
      "_id",
      "type",
      "dateOrderPlaced",
      "rmaCreated",
      "contactId",
      "erpId",
      "erpOrderNo",
      "createdAt",
      "updatedAt",
      "salesforceQuoteId",
      "sfRepCode",
      "sfDivisionCode",
      "sfTerritoryCode",
      "sfRegionCode",
      "csiCustomerNumber",
      "sfCustomer",
      "notes",
      "quoteGenHeaderId",
      "soWriteUpDocLink",
      "currentPrintedDocument",
      "notes",
      "salesforceOpportunityId",
      "sameAsBilling",
      "shippingAddressId",
      "companyId",

      "pid",
    ],
  });
  const {
    data: qData,
    refetchQuery: qRefetch,
    setData: qSetData,
    isLoading: qLoading,
  } = quotes || _quotes || {};

  const requests = useQuery({
    queryID: queryID2,
    request: getQuotes,
    queryVariables: { type: 3, username: username },
    skip:
      !isQuoteRequest ||
      allQuotes ||
      isLoading ||
      (!isQuoteRequest && (qLoading || !qData)),
    attributes: [
      "id",
      "sfQuoteNumber",
      "statusTypeId",
      "createdByEmail",
      "createdDate",
      "totalCost",
      "name",
    ],
  });

  const {
    data: rData,
    refetchQuery: rRefetch,
    setData: rSetData,
    isLoading: rLoading,
  } = requests || quoteRequests || {};

  const { rows: qRows = [], count: qCount } = qData || {};
  const { rows: rRows = [], count: rCount } = rData || {};

  const { data: stData } = useQuery({
    queryID: "statusTypes",
    request: getStatusTypes,
    forcedFilters: {},
    attributes: ["id", "name"],
    skip: qLoading || rLoading,
  });

  const { rows: statusTypes } = stData || {};

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onDeleteQuote = useCallback(
    async (event, quoteId) => {
      stopPropagation(event);
      const inQuotes = !!_.find(qRows, (quote) => quote.id === quoteId);
      const rows = inQuotes ? qRows : rRows;
      const setData = inQuotes ? qSetData : rSetData;

      const newQuotes = [...rows];
      _.remove(newQuotes, (quote) => quote.id === quoteId);

      setData({
        count: (isQuoteRequest ? rCount : qCount) - 1,
        rows: newQuotes,
      });

      await deleteQuote({ id: quoteId });
    },
    [qRows, rRows, qSetData, rSetData, isQuoteRequest, rCount, qCount]
  );

  const onCopyQuote = useCallback(
    async (event, quoteId) => {
      stopPropagation(event);

      const inQuotes = !!_.find(qRows, (quote) => quote.id === quoteId);
      const rows = inQuotes ? qRows : rRows;
      const setData = inQuotes ? qSetData : rSetData;

      let newQuoteMock = { ..._.find(rows, (quote) => quote.id === quoteId) };
      const newId = guid.GenerateGUID();
      const { id: newMockId } = rows[0];
      newQuoteMock._id = newId;
      newQuoteMock.id = newMockId + 1;

      const newQuotes = [...rows, newQuoteMock];
      setData({ count: newQuotes.length, rows: newQuotes });
      await copyQuote({ id: quoteId, _id: newId });
    },
    [qRows, qSetData, rRows, rSetData]
  );

  const columns = useMemo(
    () => [
      {
        id: "sfQuoteNumber",
        data: (row) => (
          <Body center ellipsis>
            {row.sfQuoteNumber}
          </Body>
        ),
        header: "#",
        size: { md: 2, sm: 2, xs: -1 },
      },

      // WM MOD ADDED StatusTypeID - This needs to be converted to the actual status value
      {
        id: "statusTypeId",
        data: (row) => (
          <Body ellipsis center>
            {_.find(statusTypes, (st) => row?.statusTypeId === st.id)?.name ||
              "—"}
          </Body>
        ),
        header: "Status",
        size: { md: 2, sm: 2, xs: -1 },
      },

      // WM MOD removed
      {
        id: "createdByEmail",
        data: (row) => {
          const { lastName, firstName, createdByEmail } = row;

          return (
            <Body center ellipsis>
              {firstName || lastName
                ? `${firstName || ""}${lastName && firstName ? " " : ""}${
                    lastName || " "
                  }`
                : createdByEmail}
            </Body>
          );
        },
        header: "Customer",
        size: { md: 3, sm: 3, xs: -1 },
      },

      {
        id: "name",
        data: (row) => <Body center>{row?.name}</Body>,
        header: "Project",
        size: { md: 4, sm: 4, xs: -1 },
      },
      {
        id: "createdDate",
        data: (row) => (
          <Body center>{`${moment(row.createdDate).format(
            "MM/DD/YYYY"
          )}`}</Body>
        ),
        header: "Created On",
        size: { md: 3, sm: 3, xs: 2 },
      },

      {
        id: "totalCost",
        data: (row) => (
          <Body right>{`$${formatter.currency(row.totalCost)}`}</Body>
        ),
        // WM MOD changed label
        header: "Quote Value",
        size: { md: 2, sm: 2, xs: 3 },
      },
      {
        display: (props) => {
          let quote = props.cell.row.original;

          return (
            <ButtonsWrapper id="actions">
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faPencil"
                onClick={(e) => onRowClick(props.cell.row, e)}
              />
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faCopy"
                onClick={(e) => onCopyQuote(e, quote.id)}
              />
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faTrashCan"
                onClick={(e) => onDeleteQuote(e, quote.id)}
              />
            </ButtonsWrapper>
          );
        },
        header: "Actions",
        sort: false,
        size: { md: 4, sm: 0, xs: 0 },
      },
    ],
    [onCopyQuote, onDeleteQuote, onRowClick, statusTypes, theme.secondary]
  );

  return !allQuotes ? (
    <Table
      title={isQuoteRequest ? "Quote Requests" : "Quotes"}
      hideCreate={isQuoteRequest}
      hideBack
      createLink={createLink}
      createButtonText={createButtonText}
      hideToggle
      refetchData={isQuoteRequest ? rRefetch : qRefetch}
      hideRefetch={false}
      data={isQuoteRequest ? rRows : qRows}
      columns={columns}
      showAddNew
      isLoading={isQuoteRequest ? rLoading : qLoading}
      totalCount={isQuoteRequest ? rCount : qCount}
      onRowClick={onRowClick}
    />
  ) : (
    <>
      <Table
        title="Quotes"
        createLink={createLink}
        createButtonIcon="faFileCirclePlus"
        createButtonText={createButtonText}
        hideToggle
        hideBack
        refetchData={qRefetch}
        hideRefetch={false}
        data={qRows}
        columns={columns}
        showAddNew
        isLoading={qLoading}
        totalCount={qCount}
        onRowClick={onRowClick}
      />
      {/* {activeUser?.roleLevel >= 5 && (
        <Table
          title={"Quote Requests"}
          hideCreate
          hideBack
          hideToggle
          refetchData={rRefetch}
          hideRefetch={false}
          data={rRows}
          columns={columns}
          showAddNew
          isLoading={rLoading}
          totalCount={rCount}
          onRowClick={onRowClick}
        />
      )} */}
    </>
  );
};

export default (props) => <QuoteTable {...props} />;
