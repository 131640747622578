import React, { useMemo } from "react";
import { useState, useContext, useCallback } from "react";
import { PageWrapper } from "../UI/Grid";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { getUsers, deleteUsers } from "../../requests/userManagementRequests";
import Table, { useTableSelections } from "../UI/Table";
import Card from "../UI/Card";
import { Body } from "../UI/Text";
import styled, { useTheme } from "styled-components";
import Button from "../UI/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../UI/Modal";
import { useQuery } from "../Hooks";
import UserContext from "../../context/user/UserContext";
import { Flex } from "reflexbox";

const LoggedInIcon = ({ username, isLoggedIn }) => {
  const theme = useTheme();

  const { loggedInUsers } = useContext(UserContext);

  const backgroundColor = useMemo(() => {
    if (loggedInUsers[username] === false) return theme.danger;
    if (isLoggedIn === true && !loggedInUsers[username]) return theme.success;
    if (loggedInUsers[username] === true) return theme.success;

    return theme.danger;
  }, [isLoggedIn, loggedInUsers, theme.danger, theme.success, username]);

  return (
    <Flex key={username} width={1} justifyContent="center">
      <div
        style={{
          width: "1rem",
          height: "1rem",
          borderRadius: "50%",
          border: `1px solid ${theme.borderColor}`,
          backgroundColor,
        }}
      />
    </Flex>
  );
};

const Users = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userContext = useContext(UserContext);

  const { data, isLoading, refetchQuery } = useQuery({
    queryID: "users",
    request: getUsers,
    skip: !userContext.loggedInUsers,
  });

  const { rows = [], count, roles = [] } = data || {};

  const gridData = rows.map((user) => ({
    id: user.id,
    title: `${user.firstName} ${user.lastName}`,
    subtitle: user.username,
    original: { username: user.username },
  }));

  const getRoleName = useCallback(
    (roleId) => {
      const roleName = _.find(roles, (role) => role.id === roleId)?.name || "—";
      return roleName;
    },
    [roles]
  );

  const columns = useMemo(
    () => [
      {
        id: "username",
        data: ({ username }) => <Body ellipsis>{username || "—"}</Body>,
        header: "Username",
        size: { md: 4, sm: 3, xs: 5 },
      },
      {
        id: "firstName",
        data: ({ firstName }) => (
          <Body center ellipsis>
            {firstName || "—"}
          </Body>
        ),
        header: "First Name",
        size: { md: 3, sm: -1, xs: -1 },
      },
      {
        id: "lastName",
        data: ({ lastName }) => (
          <Body center ellipsis>
            {lastName || "—"}
          </Body>
        ),
        header: "Last Name",
        size: { md: 3, sm: -1, xs: -1 },
      },
      {
        id: "isLoggedIn",
        data: ({ username, isLoggedIn, id }) => {
          return <LoggedInIcon username={username} isLoggedIn={isLoggedIn} />;
        },
        header: "Online",
        size: { lg: 2, md: 2, sm: 2, xs: 3 },
      },
      {
        id: "roleId",
        data: ({ roleId }) => <Body center>{getRoleName(roleId)}</Body>,
        header: "Role Level",
        size: { md: 2, sm: 3, xs: 5 },
      },

      {
        id: "id",
        data: "id",
        header: "Id",
        size: -1,
      },
    ],
    [getRoleName]
  );

  const onRowClick = (row) => {
    const { username } = row?.original || {};
    if (username) navigate(`/Users/edit/${username}`);
  };

  const selections = useTableSelections("id");

  const [deleting, setDeleting] = useState(false);

  const onConfirmDelete = async () => {
    await deleteUsers({
      users: selections.rows
        .map((row) => ({ id: row.id, username: row.username }))
        .filter((row) => row.id !== userContext.activeUser?.id),
      self: userContext.activeUser?.username,
    });
    selections.actions.clear();
    setDeleting(false);

    refetchQuery("users");
  };

  return (
    <PageWrapper>
      {/* {selections.rows.length > 0 ? (
        <StyledRow style={{ padding: "1rem 0px 0px 0px !important" }}>
          <Flex md={6}>
            <StyledCard interactive={false}>
              <Body>
                {selections.rows.length} user
                {selections.rows.length !== 1 ? "s" : ""} selected
              </Body>
              <div>
                <Flex onClick={(_) => setDeleting(true)} size={24}>
                  <FontAwesomeIcon icon={faTrash} />
                </Flex>
              </div>
            </StyledCard>
          </Flex>
        </StyledRow>
      ) : null} */}
      <Table
        createButtonText="Create User"
        createButtonIcon="faUserPlus"
        createLink="/Users/create"
        title="Users"
        data={rows}
        columns={columns}
        onRowClick={onRowClick}
        onCardClick={onRowClick}
        gridData={gridData}
        hideRefetch={false}
        refetchData={refetchQuery}
        backButtonLink="/Administration"
        // selections={selections}
        isLoading={isLoading}
        totalCount={count}
      />
      <Modal show={deleting} setShow={setDeleting}>
        <ModalContainer>
          <Card
            disableHover
            style={{ display: "block", borderStyle: "none", padding: "1rem" }}
          >
            <ModalTitle>
              Are you sure you want to delete the following users?
            </ModalTitle>
            <DeleteList>
              <div>
                {selections.rows.map((row) => (
                  <Body key={row.id} xs={6}>
                    {row.username}
                  </Body>
                ))}
              </div>
            </DeleteList>
            <span
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              <Button color={theme.success} onClick={onConfirmDelete}>
                Yes
              </Button>
              <Button color={theme.danger} onClick={() => setDeleting(false)}>
                No
              </Button>
            </span>
          </Card>
        </ModalContainer>
      </Modal>
    </PageWrapper>
  );
};

// const StyledRow = styled(Flex)`
//   margin: 0px !important;
//   padding: 1rem 0px 0px 0px !important;
//   align-items: flex-end;
// `;

// const StyledCard = styled(Card)`
//   padding: 0.5rem 1rem;
//   margin-right: 1rem;
//   flex-direction: row;
//   justify-content: space-between;
// `;

const DeleteList = styled.div`
  overflow-y: auto;
  height: fit-content;
  max-height: 12rem;
  margin: 1rem;
  padding: 0.25rem;
`;

const ModalTitle = styled(Body)`
  font-size: 24px;
  text-align: center;
  margin: 1rem;
`;

const ModalContainer = styled(Flex)`
  padding: 1rem !important;
  margin: auto;
  display: flex;
  width: 80%;
  height: 60%;
  background-color: ${({ theme }) => theme?.backgroundColor};
  border: 1px solid ${({ theme }) => theme?.borderColor || "gray"};
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};
`;

export default Users;
