import React from "react";
import PropTypes from "prop-types";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../Link";
import { Body } from "../Text";
import { StyledButton } from "./styles";

const Button = React.memo((props) => {
  const {
    color = null,
    reverse = false,
    disabled = false,
    to = null,
    type = "button",
    htmlElement = "button",
    onClick,
    children,
    theme,
    iconName,
    textOnly = false,
    iconPosition = "right",
    size = "normal",
    ...rest
  } = props;
  const as = to && !disabled ? Link : htmlElement;
  return (
    <StyledButton
      as={as}
      onClick={disabled ? null : onClick}
      color={color}
      reverse={reverse}
      disabled={disabled}
      to={to}
      type={to && !disabled ? undefined : type}
      theme={theme}
      textOnly={textOnly}
      size={size}
      icon={!children ? "true" : undefined}
      {...rest}
    >
      {iconPosition === "left" && iconName && (
        <FontAwesomeIcon icon={icons[iconName]} />
      )}
      {typeof children === "string" ? <Body>{children}</Body> : children}
      {iconPosition === "right" && iconName && (
        <FontAwesomeIcon icon={icons[iconName]} />
      )}
    </StyledButton>
  );
});

Button.propTypes = {
  color: PropTypes.string,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  borderColor: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  htmlElement: PropTypes.string,
  onClick: PropTypes.func,
  iconPosition: PropTypes.string,
  iconName: PropTypes.string,
  textOnly: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
