import React, { useContext, useMemo, useState, useCallback } from "react";
import _ from "lodash";
import styled, { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import { useQuery } from "../Hooks";
import { PageWrapper } from "../UI/Grid";
import { Body } from "../UI/Text";
import Table from "../UI/Table";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import AlertContext from "../../context/alert/AlertContext";
import ContactForm from "./CustomerForm";
import { getPlatforms } from "../../requests/platformManagementRequests";
import {
  getCustomers,
  deleteCustomer,
} from "../../requests/customerManagementRequests";

const ButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-out;
`;

const CustomersPage = () => {
  const theme = useTheme();
  const { showSuccessAlert } = useContext(AlertContext);

  const [show, setShow] = useState(false);
  const [contactId, setContactId] = useState("");

  const { data, isLoading, refetchQuery, setData } = useQuery({
    queryID: "contactsPage",
    request: getCustomers,
    queryVariables: { all: true },
  });

  const { data: platformData, isLoading: platformsLoading } = useQuery({
    queryID: "platforms",
    request: getPlatforms,
    forcedFilters: {},
    skip: isLoading,
  });

  const { rows: platforms = [], count: platformCount } = platformData || {};

  const { count = 0, rows: contacts = [] } = data || {};

  const currentContact = useMemo(
    () => _.find(contacts, (__) => __.id === contactId),
    [contacts, contactId]
  );
  const onDelete = useCallback(
    async (e, id) => {
      e.stopPropagation();
      const { rows } = data;
      const newRows = [...rows];
      _.remove(newRows, (row) => row.id === id);
      setData({ rows: newRows, count: count - 1 });
      setShow(false);
      await deleteCustomer({ id });

      showSuccessAlert(`Successfully deleted Contact with the ID of ${id}`);
    },
    [count, data, setData, showSuccessAlert]
  );

  const onRowClick = (row) => {
    setContactId(row.original.id);
    setShow(true);
  };

  const columns = useMemo(() => {
    return [
      {
        id: "contactId",
        data: (d) => (
          <Body center ellipsis>
            {d?.id || "—"}
          </Body>
        ),
        header: "ID",
        size: { md: 1.15, sm: 1.15, xs: 1.15 },
      },
      {
        id: "lastName",
        data: (d) => (
          <Body center ellipsis>
            {d?.firstName || d?.lastName
              ? `${d.firstName || ""}${d?.lastName && d?.firstName ? " " : ""}${
                  d?.lastName || " "
                }`
              : "—"}
          </Body>
        ),
        header: "Name",
        size: { md: 2, sm: 2, xs: 2 },
      },

      {
        id: "phone",
        data: (d) => (
          <Body center ellipsis>
            {d?.phone || "—"}
          </Body>
        ),
        header: "Phone",
        size: { lg: 3, md: 0, sm: 0, xs: 0 },
      },
      {
        id: "email",
        data: (d) => (
          <Body center ellipsis>
            {d?.email || "—"}
          </Body>
        ),
        header: "Email",
        size: { lg: 3, md: 2, sm: 1, xs: 2 },
      },

      {
        id: "pid",
        data: (d) => (
          <Body center>
            {_.find(platforms, (p) => p.id === d?.pid)?.name || "—"}
          </Body>
        ),
        header: "Platform",
        size: { md: 3, sm: 0, xs: 0 },
      },
      {
        display: (props) => {
          let contact = props.cell.row.original;

          return (
            <ButtonsWrapper id="actions">
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faPencil"
                onClick={(e) => onRowClick(props.cell.row, e)}
              />
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faTrashCan"
                onClick={(e) => onDelete(e, contact.id)}
              />
            </ButtonsWrapper>
          );
        },
        header: "Actions",
        sort: false,
        size: { md: 4, sm: 0, xs: 0 },
      },
    ];
  }, [onDelete, platforms, theme.secondary]);

  return (
    <PageWrapper>
      <Modal show={show} setShow={setShow}>
        {platformsLoading || platformCount === 0 ? null : (
          <ContactForm
            data={data}
            count={count}
            contactId={contactId}
            setContactId={setContactId}
            currentContact={currentContact}
            platforms={platforms}
            platformCount={platformCount}
            setShow={setShow}
            refetchCustomers={refetchQuery}
            setData={setData}
          />
        )}
      </Modal>
      <Table
        title="Customers"
        data={contacts}
        createButtonText="Create Customer"
        createLink={() => {
          setContactId("create");
          setShow(true);
        }}
        createButtonIcon="faAddressCard"
        hideToggle
        hideRefetch={false}
        refetchData={refetchQuery}
        onRowClick={onRowClick}
        columns={columns}
        isLoading={isLoading || platformsLoading}
        totalCount={count}
        backButtonLink="/Administration"
      />
    </PageWrapper>
  );
};

export default CustomersPage;
