import React, { useContext } from "react";
import { useRef, useState, useMemo, useCallback } from "react";
import { flexRender } from "@tanstack/react-table";
import _ from "lodash";
import { StyledRow } from "./styles-table.jsx";
import { RowDisplayContext } from "./TableContext";
import styled, { useTheme } from "styled-components";
import { PagerContext } from "../Pager/PagerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { ColumnDisplayContext } from "./TableContext";

const StyledHeading = styled.span`
  flex: 1 1 ${({ relativeSize }) => relativeSize}%;
  max-width: ${({ relativeSize }) => relativeSize}%;
  padding: 1rem;
  position: relative;
  white-space: nowrap;
  color: ${({ theme }) => theme.textDark}};
  &:last-of-type {
    padding-right: ${({ hasDropdown }) => (hasDropdown ? "3rem" : "1rem")};
  }
  &:first-child .widthDrag {
    display: none;
  }
`;

const TableHeader = React.memo(
  ({ sort, header, id, index, last, hasDropdown, ...props }) => {
    const theme = useTheme();
    const {
      relativeSizes,
      arbitrarySizes,
      arbitrarySizeReducer,
      columnOrder,
      setColumnOrder,
      getVisibleIndices,
    } = useContext(ColumnDisplayContext);
    const relativeSize = relativeSizes[id],
      arbitrarySize = arbitrarySizes[id],
      lastArbitrarySize = last !== null ? arbitrarySizes[last[1]] : 0;

    const { order } = useContext(PagerContext);

    const headingRef = useRef(null);
    const throttle = useRef(null);
    const setWidthModifier = useCallback(
      (newSize, newLastSize) => {
        clearTimeout(throttle.current);
        throttle.current = setTimeout(() => {
          arbitrarySizeReducer([id, newSize]);
          arbitrarySizeReducer([last[1], newLastSize]);
          throttle.current = null;
        }, 1);
      },
      [id, last, arbitrarySizeReducer]
    );
    const [hover, setHover] = useState(0);
    const start = useRef(0);
    const [dragging, , onMouseDown] = useCustomDrag(
      () => {
        start.current = headingRef.current.getBoundingClientRect().left;
      },
      (e, cursor) => {
        setHover(cursor.x - start.current);
      },
      (e, cursor) => {
        setHover(0);
        let diff =
          (arbitrarySize * (cursor.x - start.current)) /
          headingRef.current.clientWidth;
        if (diff >= 0 && diff <= arbitrarySize) return;
        const visibleIndices = getVisibleIndices();
        let cutIndex = visibleIndices.indexOf(index);
        let addIndex = cutIndex;
        const sizes = Object.values(arbitrarySizes);
        const dir = diff > arbitrarySize ? 1 : -1;
        if (diff > arbitrarySize) diff -= arbitrarySize;
        diff = Math.abs(diff);
        while (addIndex + dir >= 0 && addIndex + dir < columnOrder.length) {
          addIndex += dir;
          let size = sizes[addIndex];
          if (diff < size) {
            if (diff < size / 2) addIndex -= dir;
            break;
          }
          diff -= size;
        }
        cutIndex = columnOrder.indexOf(visibleIndices[cutIndex]);
        addIndex = columnOrder.indexOf(visibleIndices[addIndex]);
        if (cutIndex === addIndex) return;
        setColumnOrder((order) => {
          const newOrder = order.slice();
          newOrder.splice(cutIndex, 1);
          newOrder.splice(addIndex, 0, index);
          return newOrder;
        });
      },
      20
    );

    const context = header.getContext();
    const renderContent = useMemo(
      () =>
        header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, context),
      [header.isPlaceholder, header.column.columnDef.header, context]
    );

    const sortIcon = () => {
      const { 0: orderName, 1: orderDirection } = order || {};
      if (orderName !== header.column.id) return null;
      if (orderDirection === "ASC")
        return (
          <span style={{ marginLeft: "0.5rem", color: theme.secondary }}>
            <FontAwesomeIcon icon={faArrowUp} size="sm" />
          </span>
        );
      else if (orderDirection === "DESC")
        return (
          <span style={{ marginLeft: "0.5rem", color: theme.secondary }}>
            <FontAwesomeIcon icon={faArrowDown} size="sm" />
          </span>
        );
      else return null;
    };

    return (
      <StyledHeading
        hasDropdown={hasDropdown}
        ref={headingRef}
        relativeSize={relativeSize}
        onClick={sort ? header.column.getToggleSortingHandler() : undefined}
        {...props}
      >
        <div
          style={{ transform: `translateX(${hover}px)` }}
          onMouseDown={onMouseDown}
        >
          <div
            style={{
              transform: `translateY(${
                dragging ? "calc(-100% - 0.75rem)" : "0px"
              })`,
              transition: "100ms transform linear",
              textAlign: "center",
              cursor: !!sort ? "pointer" : "",
            }}
          >
            {renderContent}
            {sortIcon()}
          </div>
        </div>
        <WidthDrag
          headingRef={headingRef}
          size={arbitrarySize}
          prevSize={lastArbitrarySize}
          setWidthModifier={setWidthModifier}
        />
      </StyledHeading>
    );
  }
);

const MIN_PIXEL_SIZE = 0;
const WidthDrag = React.memo(
  ({ headingRef, size, prevSize, setWidthModifier }) => {
    //const [diff, setDiff] = useState(0);
    const start = useRef([size, prevSize]);
    const [dragging, , onMouseDown] = useCustomDrag(
      () => {
        start.current = [
          size,
          prevSize,
          headingRef.current.getBoundingClientRect().left,
          headingRef.current.clientWidth,
        ];
      },
      (_, cursor) => {
        let [size, prevSize, left, width] = start.current;
        const pixelToSize = size / width;
        const diff = Math.max(
          Math.min(
            pixelToSize * (left - cursor.x),
            prevSize - pixelToSize * MIN_PIXEL_SIZE
          ),
          pixelToSize * (MIN_PIXEL_SIZE - width)
        );
        setWidthModifier(size + diff, prevSize - diff);
      },
      (_, cursor) => {},
      5
    );
    const onClick = (e) => {
      e.stopPropagation();
    };

    return (
      <StyledDrag
        className={`widthDrag ${dragging ? "active" : ""}`}
        onMouseDown={onMouseDown}
        onClick={onClick}
      >
        <HoverBar diff={0 /*diff*/} />
      </StyledDrag>
    );
  }
);

const StyledDrag = styled("div")`
  position: absolute;
  left: -0.5rem;
  top: 0px;
  bottom: 0px;
  width: 1rem;
  background: inherit;
  z-index: 1;
  cursor: col-resize;
  draggable: true;
  &.active {
    padding-right: 100px;
  }
  &.active > div {
    background-color: ${({ theme }) =>
      theme?.activeStageColor || "blue"} !important;
  }
`;

const HoverBar = styled("div").attrs(({ diff }) => ({
  style: {
    left: `calc(0.5rem - ${diff}px)`,
  },
}))`
  position: absolute;
  top: 0.5rem;
  bottom: 0.5rem;
  width: 1px;
  background-color: ${({ theme }) => theme?.borderColor || "gray"};
`;

const useCustomDrag = (
  onpickup = (_) => _,
  ondrag = (_) => _,
  ondrop = (_) => _,
  sensitivity = 5
) => {
  const dragState = useRef(0);
  const [dragging, setDragging] = useState(false);
  const startDrag = useRef({ x: 0, y: 0 });
  const drag = useCallback(
    (e) => {
      e.preventDefault();
      if (dragState.current === 1) {
        const diff = {
          x: e.pageX - startDrag.current.x,
          y: e.pageY - startDrag.current.y,
        };
        if (Math.abs(diff.x) + Math.abs(diff.y) < sensitivity) return;
        onpickup(e, { x: e.pageX, y: e.pageY }, startDrag.current);
        setDragging(true);
        dragState.current = 2;
      }
      if (dragState.current === 2) {
        ondrag(e, { x: e.pageX, y: e.pageY }, startDrag.current);
      }
    },
    [ondrag, onpickup, sensitivity]
  );
  const drop = useCallback(
    (e) => {
      e.preventDefault();
      if (dragState.current === 2) {
        ondrop(e, { x: e.pageX, y: e.pageY }, startDrag.current);
        setDragging(false);
      }
      dragState.current = 0;
      document.removeEventListener("mousemove", drag);
      document.removeEventListener("mouseup", drop);
    },
    [drag, ondrop]
  );
  /*useEffect(() => () => {
    document.removeEventListener("mousemove", drag);
    document.removeEventListener("mouseup", drop);
  }, [drag,drop])*/
  return [
    dragging,
    startDrag,
    useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (dragState.current === 0) {
          startDrag.current = { x: e.pageX, y: e.pageY };
          dragState.current = 1;
          document.addEventListener("mousemove", drag);
          document.addEventListener("mouseup", drop);
        }
      },
      [drag, drop]
    ),
  ];
};

const TableHeaderGroup = React.memo(({ headers, columns }) => {
  // headers = headerGroup.headers
  const { dropdown, visibleIndices } = useContext(RowDisplayContext);
  let last = null;
  return (
    <StyledRow className="header" heading dropdown={dropdown}>
      {visibleIndices.map((index) => {
        const header = headers[index],
          id = header.column.columnDef.id;
        let temp = last;
        last = [index, id];
        const { sort } = _.find(columns, (col) => col.id === id);
        return (
          <TableHeader
            sort={sort}
            key={id}
            id={id}
            index={index}
            header={header}
            last={temp}
            hasDropdown={dropdown}
          />
        );
      })}
    </StyledRow>
  );
});

export default TableHeaderGroup;
