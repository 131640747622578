import React, { forwardRef } from "react";
import { FastField, useField } from "formik";
import { useTheme } from "styled-components";
import { Body } from "../../Text";
import Label from "../Label";
import * as styles from "./styles";
const { RadioWrap, Radio, RadioContainer } = styles;

const RadioComponent = forwardRef(
  (
    {
      name,
      labelText,
      error,
      required,
      description,
      disabled,
      options,
      onChange,
      id,
      autoFocus,
      optionWidth: _optionWidth,
    },
    ref
  ) => {
    let optionWidth = _optionWidth;

    if (_optionWidth > 20) optionWidth = 20;
    if (_optionWidth < 100 / 6) optionWidth = 100 / 6;

    const theme = useTheme();
    const [field, meta] = useField(name);

    const overriddenProps = {};
    if (onChange) overriddenProps.onChange = onChange;

    return (
      <RadioContainer>
        {labelText ? (
          <Label disabled={disabled} htmlFor={id || name} required={required}>
            {labelText}
          </Label>
        ) : null}
        {description ? <Body>{description}</Body> : null}
        <Radio flexWrap="wrap" disabled={disabled}>
          <FastField
            name={name}
            id={id || name}
            options={options}
            disabled={disabled}
            innerRef={ref ? ref : undefined}
            error={error}
            autoFocus={autoFocus}
            required={required}
            {...field}
          />
          {options.map(
            (
              {
                value,
                label: optionLabel,
                disabled: optionDisabled,
                appearChecked,
              },
              i
            ) => {
              return (
                <RadioWrap
                  aF={autoFocus}
                  className={`animated-radio ${appearChecked ? "checked" : ""}`}
                  itemNumber={i + 1}
                  id={`${value}`}
                  numItems={options.length}
                  disabled={optionDisabled}
                  width={
                    optionWidth
                      ? [
                          "calc(33.3333333% - 0.75rem)",
                          `calc(${optionWidth}% - 0.5rem)`,
                        ]
                      : undefined
                  }
                  key={value || i}
                >
                  {optionLabel}
                </RadioWrap>
              );
            }
          )}
        </Radio>
        {meta.error && meta.touched && (
          <Body my="0.5rem" color={theme.danger}>
            {meta.error}
          </Body>
        )}
      </RadioContainer>
    );
  }
);

export default RadioComponent;
