import React, { useState, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Flex, Box } from "reflexbox/styled-components";
import Button from "../UI/Button";
import Card from "../UI/Card";
import Loading from "../UI/Loading";
import { payment } from "../../requests/paymentRequests";
import { updateQuote } from "../../requests/quoteManagementRequests";
import UserContext from "../../context/user/UserContext";

const StripeWrapper = styled(Box)`
  iframe {
    height: 1rem;
  }
`;

const PaymentForm = ({ quote }) => {
  const theme = useTheme();
  const { totalCost } = quote || {};
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setLoading] = useState(false);

  const { activeUser, platform } = useContext(UserContext);
  const { integration } = platform || {};
  const { checkoutType } = activeUser || {};

  if (checkoutType !== "stripe") return null;

  const cardOptions = {
    iconStyle: "solid",
    style: {
      base: {
        backgroundColor: "transparent",
        iconColor: theme.primary,
        color: theme.primary,
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: theme.secondary },
        "::placeholder": { color: theme.secondary },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      setLoading(true);
      try {
        const { id } = paymentMethod;
        await payment({ amount: totalCost * 100, id });

        await updateQuote({
          id: quote?.id,
          _id: quote?._id,
          input: { type: 2, integration },
          type: "order",
        });
        /* Using http request here to unload the stripe library since they like
         * to track users for the remainder of the session. */
        window.location.replace("/ThankYou");
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      console.error(error);
    }
  };

  return (
    <>
      <Card>
        <form onSubmit={onSubmit}>
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <fieldset className="FormGroup">
              <StripeWrapper width="16rem" className="FormRow">
                <CardElement options={cardOptions} />
              </StripeWrapper>
            </fieldset>
            <Button
              disabled={isLoading}
              mt="1rem"
              type="submit"
              iconName="faCashRegister"
            >
              {isLoading ? (
                <Loading
                  inline
                  color1={theme.secondary}
                  color2={theme.success}
                />
              ) : (
                "Checkout"
              )}
            </Button>
          </Flex>
        </form>
      </Card>
    </>
  );
};

export default PaymentForm;
