import { createContext } from "react";

const AlertContext = createContext({
  alerts: [],
  setAlerts: () => {},
  showFailureAlert: () => {},
  showSuccessAlert: () => {},
  showWarningAlert: () => {},
});

export default AlertContext;
