import React, { useContext, useMemo } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useTheme } from "styled-components";
import { Flex, Box } from "reflexbox";
import { Body } from "../UI/Text";
import Button from "../UI/Button";
// import Modal from "../UI/Modal";
import Field from "../UI/forms/Field";
import {
  createCommunication,
  // updateCommunication,
  // deleteCommunication,
} from "../../requests/quoteManagementRequests";
import UserContext from "../../context/user/UserContext";
import { useParams } from "react-router-dom";
import moment from "moment";

const Communications = ({
  readonly,
  communications = [],
  setData = () => {},
}) => {
  const { quoteId } = useParams();
  const theme = useTheme();
  const { activeUser } = useContext(UserContext);

  // const [editComId, setEditComId] = useState("");
  // const [show, setShow] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    setData([
      ...communications,
      {
        comment: values.comment,
        username: activeUser?.username,
        quoteId,
        date: moment().format(),
      },
    ]);
    const { result } = await createCommunication({
      input: {
        comment: values.comment,
        username: activeUser?.username,
        quoteId,
      },
    });

    setData([...communications, result]);
    window.sessionStorage.setItem(`comment|${quoteId}`, "");
    resetForm();
  };

  // const onDelete = async (id) => {
  //   const newData = [...communications];
  //   _.remove(newData, (com) => com.id === id);
  //   setData(newData);
  //   await deleteCommunication({ id });
  //   return;
  // };

  const comms = useMemo(() => {
    return _.isArray(communications) ? (
      communications.map((item, i) => {
        const { id, comment, date, username } = item || {};
        return (
          <Flex
            key={id}
            width={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Body ellipsis>
              {`${moment(date).format("MM/DD/YYYY")}`}
              {" – "}
              {comment}
              {i === 0 ? " by " : " – "}
              {username}
            </Body>
          </Flex>
        );
      })
    ) : (
      <></>
    );
  }, [communications]);

  return (
    <Flex width={1} flexDirection="column">
      {/* <Modal show={show} setShow={setShow}>
        <Card>
          <EditModal
            communications={communications}
            setData={setData}
            refetchQuery={refetchQuery}
            editId={editComId}
            setShow={setShow}
          />
        </Card>
      </Modal> */}

      <Box
        width={1}
        maxHeight={400}
        id="columnScrollable"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Flex id="columnScrollable" width={1} flexDirection="column">
          {comms}
        </Flex>
      </Box>
      {!readonly && (
        <Formik
          onSubmit={onSubmit}
          validationSchema={yup.object().shape({ comment: yup.string() })}
          initialValues={{
            comment: window.sessionStorage.getItem(`comment|${quoteId}`) || "",
          }}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              <Flex
                mt={2}
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Field
                  name="comment"
                  noLabel
                  onBlur={(e) => {
                    window.sessionStorage.setItem(
                      `comment|${quoteId}`,
                      e.target.value
                    );
                  }}
                  type="text"
                />
                <Button
                  ml="1rem"
                  mb="1rem"
                  type="submit"
                  size="small"
                  color={theme.success}
                  iconName="faPaperPlane"
                />
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Flex>
  );
};

export default Communications;

// Disabled but kept in case we decide to edit communications

// const EditModal = ({
//   editId,
//   communications,
//   setData,
//   refetchQuery,
//   setShow,
// }) => {
//   console.log({ editId, communications, setData, refetchQuery });
//   const theme = useTheme();
//   const item = _.find(communications, ({ id: comId }) => comId === editId);
//   const { id, comment, date, username, quoteId } = item || {};

//   const onUpdate = async (values, { resetForm }) => {
//     const coms = [...communications];
//     const index = _.findIndex(coms, (com) => com.id === editId);
//     _.remove(coms, (com) => com.id === editId);
//     coms.splice(index, 0, {
//       ...item,
//       comment: values.comment,
//     });
//     setData([...coms]);
//     await updateCommunication({ id, input: { comment: values.comment } });

//     window.sessionStorage.setItem(`comment|${quoteId}`, "");
//     resetForm();
//     setShow(false);
//     await refetchQuery();
//   };

//   return (
//     <Formik
//       onSubmit={onUpdate}
//       validationSchema={yup.object().shape({ comment: yup.string() })}
//       initialValues={{ comment }}
//       enableReinitialize
//     >
//       {(formProps) => (
//         <Form>
//           <Flex
//             width={1}
//             justifyContent="space-between"
//             alignItems="center"
//             mb="1rem"
//           >
//             <Flex justifyContent="space-between" alignItems="center" width={1}>
//               <Helper mb="0.5rem" color={theme.primary} bold>
//                 {username}
//               </Helper>
//               <Helper bold>{`${moment(date).format("MM/DD/YYYY")}`}</Helper>
//             </Flex>
//             <Card width={1}>
//               <Flex
//                 width={1}
//                 justifyContent="space-between"
//                 alignItems="center"
//               >
//                 <Field noLabel name="comment" />
//                 <Button
//                   ml="1rem"
//                   type="submit"
//                   color={theme.success}
//                   iconName="faPaperPlane"
//                 />
//               </Flex>
//             </Card>
//           </Flex>
//         </Form>
//       )}
//     </Formik>
//   );
// };
