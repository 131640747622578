import React, { forwardRef, useCallback } from "react";
import { useRichTextFieldPlainText } from "./useRichTextField";
import RichTextEditor from "./RichTextEditor";
import styled from "styled-components";
import Label from "../forms/Label";
import { Flex } from "reflexbox";

const Container = styled(Flex)`
  width: 100%;
  position: relative;
  margin: auto auto 1rem auto;
  flex-direction: column;
`;

const RichText = forwardRef(
  (
    {
      name,
      disabled,
      onChange: _onChange,
      onBlur: _onBlur,
      readOnly,
      id,
      required,
      labelText,
      value,
      runSubmit,
      ...rest
    },
    ref
  ) => {
    const content = useRichTextFieldPlainText(value || "", "\n");
    const { exportString } = content || {};
    const onChange = useCallback(
      (s) => _onChange({ target: { value: s } }),
      [_onChange]
    );
    const onBlur = useCallback(() => {
      if ((value || "") !== exportString.current) {
        _onBlur({ target: { value: exportString.current } });
      }
    }, [_onBlur, value, exportString]);

    return (
      <Container>
        {labelText && (
          <Label
            required={required}
            mb={"0.5rem"}
            htmlFor={name}
            style={{ display: "flex" }}
          >
            {labelText}
          </Label>
        )}
        <RichTextEditor
          noStyles
          noBlockTypes
          disabled={disabled}
          content={content}
          {...rest}
          onChange={_onChange && onChange}
          onBlur={_onBlur && onBlur}
          ref={ref}
          runSubmit={runSubmit}
        />
      </Container>
    );
  }
);

export default RichText;
