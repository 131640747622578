import React, { useContext, useMemo } from "react";
import { flexRender } from "@tanstack/react-table";
import { StyledDescriptor } from "./styles-table";
import { ColumnDisplayContext } from "./TableContext";

const TableDescriptor = React.memo(({ cell, selected }) => {
  const { relativeSizes } = useContext(ColumnDisplayContext);
  const columnDef = cell.column.columnDef;
  const size = relativeSizes[columnDef.id];

  const context = cell.getContext();
  const renderContent = useMemo(
    () => flexRender(columnDef.cell, context),
    [columnDef.cell, context]
  );

  return (
    <StyledDescriptor expanded={selected} size={size}>
      {renderContent}
    </StyledDescriptor>
  );
});

export default TableDescriptor;
