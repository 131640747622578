import React, { useContext, useState, useCallback } from "react";
import { useTheme } from "styled-components";
import Loading from "../../UI/Loading";
import { useNavigate } from "react-router-dom";
import { createQuote } from "../../../requests/quoteManagementRequests";
import { PageWrapper } from "../../UI/Grid";
import QuoteTable from "./QuoteTable";
import guid from "../../../modules/guid";
import ConfiguratorContext from "../../../context/configurator/ConfiguratorContext";
import UserContext from "../../../context/user/UserContext";
import { useQuery } from "../../Hooks";
import { getProducts } from "../../../requests/productManagementRequests";
import PagerContextProvider from "../../UI/Pager/PagerContext";

const Quotes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setQuote } = useContext(ConfiguratorContext);
  const { activeUser } = useContext(UserContext);

  const _quickConfig = useQuery({
    queryID: "quickConfig",
    request: getProducts,
    forcedFilters: {},
    skip: !activeUser?.id,
    queryVariables: { isQuickConfig: true, isNonConfigurable: false },
    attributes: ["ID", "ImageUrl"],
  });
  const { data, isLoading } = _quickConfig || {};

  // const { rows: products = [] } = data || {};

  const [isCreating, setIsCreating] = useState(false);

  // const productGridData = products.map(({ ID, ImageUrl }) => ({
  //   id: ID,
  //   image: ImageUrl,
  // }));

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setQuote({ ...row?.original });
    navigate(`/Quote/${row.original.id}`);
  };

  // const onProductClick = async (product) => {
  //   setIsReconfigure(false);
  //   const { id: productId } = product || {};
  //   const configurationId = guid.GenerateGUID();

  //   setActiveProduct(productId);

  //   navigate(`/Configurator/${productId}/new/${configurationId}`);
  // };

  const onCreateClick = useCallback(async () => {
    setIsCreating(true);
    const newId = guid.GenerateGUID();
    const { result: newQuote } = await createQuote({
      input: { username: activeUser?.username, _id: newId, type: 1 },
    });
    console.log(newQuote);

    setIsCreating(false);

    if (newQuote) navigate(`/Quote/${newQuote}`);
  }, [activeUser, navigate]);
  return (
    <PageWrapper>
      {/* WM MOD Removed Quick Configure */}
      {/* <Table
        hideBack
        title="Quick Configure"
        createButtonText={
          isCreating ? (
            <Loading inline color1={theme.secondary} color2={theme.success} />
          ) : (
            "New Quote"
          )
        }
        createButtonIcon="faFileCirclePlus"
        createLink={() => onCreateClick()}
        gridData={productGridData}
        hidePager
        gridSize="small"
        forceGrid
        hideProperties
        hideSearch
        hideToggle
        onCardClick={onProductClick}
        isLoading={isLoading}
        relativeLoading
      /> */}
      <PagerContextProvider defaultOrder={["createdDate", "DESC"]}>
        {!isLoading && data && (
          <QuoteTable
            allQuotes
            createButtonText={
              isCreating ? (
                <Loading
                  inline
                  color1={theme.secondary}
                  color2={theme.success}
                />
              ) : (
                "New Quote"
              )
            }
            createButtonIcon="faFileCirclePlus"
            createLink={() => onCreateClick()}
            onRowClick={onRowClick}
          />
        )}
      </PagerContextProvider>

      {/* WM MOD Removed Quote Requests */}
      {/* <PagerContextProvider defaultOrder={["createdDate", "DESC"]}>
        {!isLoading && data && (
          <QuoteTable isQuoteRequest onRowClick={onRowClick} />
        )}
      </PagerContextProvider> */}
    </PageWrapper>
  );
};

export default Quotes;
