import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import { layout, space } from "styled-system";

export const WarningBlock = styled(Box)`
  background-color: ${({ theme }) => theme.danger};
  color: white;
  font-weight: bold;
  border-radius: ${({ theme }) => theme.borderRadius};
  flex: 1;
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
  ${space};
`;

export const ClusterWrapper = styled(Flex)`
  padding: 1rem 0;
  margin: 1rem auto;
  border-top: 1px solid ${({ theme }) => theme?.borderColor || "gray"};
  ${layout};
  ${space};
  > div {
    justify-content: inherit;
    display: flex;
    ${space};
  }
`;

export const DangerWrapper = styled(Flex)`
  ${layout};
`;

export const ButtonWrapper = styled(Box)`
  ${space};
`;
