export const gcd = (a, b) => {
  // 1, 5
  return b ? gcd(b, a % b) : a;
};

export const decimalToFraction = (decimal) => {
  // 1.5
  const stringify = `${decimal}`;
  const { 0: integer, 1: float } = stringify.split(".");
  let returnString = float ? "" : integer;

  if (float) {
    let numerator = stringify.includes(".")
      ? stringify.replace(/\d+[.]/, "")
      : 0;
    let denominator = Math.pow(
      10,
      numerator.toString().replace("-", "").length
    ); // 10
    if (decimal >= 1) {
      numerator = +numerator + Math.floor(decimal) * denominator;
    } else if (decimal <= -1) {
      numerator = +numerator + Math.ceil(decimal) * denominator;
    }

    let x = Math.abs(gcd(numerator, denominator));
    const displayTop = numerator / x - (denominator / x) * +integer;
    if (+integer > 0)
      returnString = `${integer} ${displayTop}/${denominator / x}`;
    else returnString = `${numerator / x}/${denominator / x}`;
  }

  return returnString;
};
