import React, { useContext, useMemo, useCallback, useState } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { Flex, Box } from "reflexbox";
import formatter from "../../modules/formatter";
import {
  createUser,
  emailNewUserPassword,
} from "../../requests/userManagementRequests";
import { login } from "../../requests/authRequests";
import { Heading, Body } from "../UI/Text";
import Button from "../UI/Button";
import Card from "../UI/Card";
import Modal from "../UI/Modal";
import Field from "../UI/forms/Field";
import UserContext from "../../context/user/UserContext";
import Table from "../UI/Table";
import { useEffect } from "react";
import guid from "../../modules/guid";
import Loading from "../UI/Loading";

const validationSchema = yup.object().shape({
  username: yup.string().email(),
});

export const CreateUserModal = ({
  show,
  setShow,
  finishConfiguration,
  sessionId,
}) => {
  const { buildUserObject } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();

  const onSubmit = useCallback(
    async ({ username }) => {
      if (!username) return;
      setLoading(true);
      const newPassword = guid.GenerateGUID();
      await createUser({
        input: {
          username,
          password: newPassword,
          roleId: "public",
          roleLevel: 0,
          checkoutType: "stripe",
        },
      });
      emailNewUserPassword({ username, password: newPassword });
      await login({ username, password: newPassword, buildUserObject });
      await finishConfiguration({ sessionId, username, publicUser: true });
    },
    [buildUserObject, finishConfiguration, sessionId]
  );

  useEffect(() => {
    if (show) {
      if (email) {
        setShow(false);
        onSubmit({ username: email });
      }
    }
  }, [email, onSubmit, setShow, show]);

  return (
    <Modal show={show} setShow={setShow}>
      <Card maxWidth="400px">
        <Heading center mb="2rem">
          Please enter your email to save your configuration.
        </Heading>
        <Formik
          {...{ validationSchema, onSubmit, initialValues: { username: "" } }}
        >
          {() => (
            <Form>
              <Flex alignItems="center" mb="1.5rem">
                <Field style={{ width: "200px" }} noLabel name="username" />
                <Button
                  ml="1rem"
                  size="large"
                  type="submit"
                  iconName={isLoading ? undefined : "faFloppyDisk"}
                >
                  {isLoading ? (
                    <Loading
                      inline
                      color1={theme.secondary}
                      color2={theme.success}
                    />
                  ) : null}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </Modal>
  );
};

export const CancelModal = ({ show, setShow }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { quoteId } = useParams();

  const redirect = useCallback(() => {
    if (quoteId) navigate(`/Quote/${quoteId}`);
    else navigate("/");
    return;
  }, [navigate, quoteId]);

  return (
    <Modal show={show} setShow={setShow}>
      <Card maxWidth="300px">
        <Heading center mb="2rem">
          Are you sure you want to cancel?
        </Heading>
        <Flex mb="1.5rem">
          <Button onClick={redirect} iconName="faCheck" />
          <Button
            color={theme.gray}
            onClick={() => setShow(false)}
            iconName="faXmark"
          />
        </Flex>
      </Card>
    </Modal>
  );
};

export const SummaryModal = ({ show, setShow, Details, ConfiguredPrice }) => {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        id: "Caption",
        data: (row) => (
          <Body bold flex={1}>
            {row.Caption}
          </Body>
        ),
        header: "Part",
        sort: false,
        size: { md: 7, sm: 7, xs: 7 },
      },
      {
        id: "value",
        data: (row) => (
          <Body right color={theme.secondary} flex={1}>
            {row.value}
          </Body>
        ),
        header: "Selection",
        sort: false,
        size: { md: 3, sm: 3, xs: 3 },
      },
    ],
    [theme.secondary]
  );

  const gridData = [];
  Details.forEach((ss) => {
    const { Value, Type, Caption } = ss || {};
    if (!Value) return;
    const value = Type >= 5 ? `$${formatter.currency(Value)}` : Value;
    gridData.push({ Caption, value });
  });

  return (
    <Modal show={show} setShow={setShow}>
      <Card>
        <Flex
          style={{ gap: "1rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            iconName="faXmark"
            style={{ opacity: 0, pointerEvents: "none" }}
          />
          <Heading my={0} center>
            Configuration Details
          </Heading>
          <Button iconName="faXmark" onClick={() => setShow(false)} />
        </Flex>
        <Body mt="1rem" color={theme.secondary} bold center>
          Total Price: ${formatter.currency(ConfiguredPrice)}
        </Body>
        <Box
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          maxHeight="300px"
          maxWidth="500px"
          width={1}
          flexDirection="column"
          flexWrap="wrap"
          id="columnScrollable"
        >
          <Table
            hideBack
            hideCreate
            columns={columns}
            totalCount={gridData.length}
            data={gridData}
            hidePager
            hideProperties
            hideSearch
            hideToggle
          />
        </Box>
      </Card>
    </Modal>
  );
};
