import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import { PageWrapper } from "../UI/Grid";
import Table from "../UI/Table";

const Administration = () => {
  const navigate = useNavigate();
  const { activeUser } = useContext(UserContext);
  const { pid, roleLevel } = activeUser || {};

  let gridData = [
    {
      title: "Users",
      path: "/Users",
      iconName: "faUsers",
    },
    {
      title: "Products",
      path: "/Products",
      iconName: "faBoxesStacked",
    },
    {
      title: "Customers",
      path: "/Customers",
      iconName: "faIdCard",
    },
    {
      title: "Addresses",
      path: "/Addresses",
      iconName: "faAddressBook",
    },
  ];

  if (roleLevel >= 5)
    gridData = [
      ...gridData,
      {
        title: "Status Types",
        path: "/StatusTypes",
        iconName: "faTimeline",
      },
      {
        title: "Platform Settings",
        path: `/Platforms/edit/${pid}`,
        iconName: "faGears",
      },
    ];
  if (roleLevel === 9)
    gridData = [
      ...gridData,
      {
        title: "Role Management",
        path: `/Role/${pid}`,
        iconName: "faIdCardClip",
      },
      {
        title: "Platforms",
        path: `/Platforms`,
        iconName: "faPeopleRoof",
      },
    ];

  return (
    <PageWrapper>
      <Table
        title="Administration"
        hideCreate
        hideBack
        gridData={gridData}
        onCardClick={(item) => navigate(item.path)}
        hidePager
        hideSearch
        forceGrid
        hideToggle
        hideProperties
      />
    </PageWrapper>
  );
};

export default Administration;
