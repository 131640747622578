import React, { useContext, useState, useEffect } from "react";
import { useMemo } from "react";
import Link from "../UI/Link";
import { Heading } from "../UI/Text";
import { Flex, Box } from "reflexbox";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import * as yup from "yup";
import { Formik, Form } from "formik";
import FormField from "./FormField";
import styled from "styled-components";

const Separator = styled(Box)`
  border-top: 2px solid ${({ theme }) => theme.borderColor};
  margin-bottom: 1rem;
  width: 100%;
`;

const AnimatedField = styled(Flex)`
  flex-direction: column;
  width: calc(50% - 0.5rem);
  opacity: 1;
  position: relative;
  @keyframes slide-up {
    0% {
      opacity: 0;
      top: 10rem;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  }

  opacity: 1;
  position: relative;

  ${({
    apiCurrentPage,
    optionIndex,
    numItems,
    optionToFocus,
    id,
    hasValue,
    value,
  }) => {
    if (!apiCurrentPage) return "";
    if (optionIndex + 1 < numItems && optionToFocus !== id) return "";
    if (hasValue && value !== "default" && value !== "" && optionToFocus !== id)
      return ".animated-radio {animation-name: unset !important;}";
    return `animation-name: slide-up; animation-duration: 200ms;`;
  }}
  ${({ hasValue, value }) => {
    if (hasValue && value !== "default" && value !== "")
      return `
        .animated-radio {
          animation-name: unset !important;
        }
      `;
  }}
`;

const FormComponent = ({
  queryID,
  isLoading,
  disableFinish,
  setDisableFinish,
}) => {
  const { api, currentPage, optionToFocus } = useContext(ConfiguratorContext);

  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setSchema] = useState(yup.object().shape({}));

  const apiCurrentPage = useMemo(() => {
    return api?.d?.Pages?.[currentPage];
  }, [api, currentPage]);

  const screens = useMemo(() => {
    if (!apiCurrentPage) return [];
    const options = [];
    const { Screens = [] } = apiCurrentPage || {};

    Screens.forEach((Screen) => {
      const {
        ScreenOptions = [],
        Caption,
        ShowCaption,
        ShowSeparator,
      } = Screen || {};
      if (ShowCaption || ShowSeparator) {
        if (ShowCaption) options.push(<Heading mb="0">{Caption}</Heading>);
        if (ShowSeparator) options.push(<Separator />);
      }
      ScreenOptions.forEach((ScreenOption, optionIndex) => {
        const { ID, InformationLink, IsVisible, Value } = ScreenOption;
        if (IsVisible)
          options.push(
            <AnimatedField
              optionToFocus={optionToFocus}
              numItems={ScreenOptions.length}
              optionIndex={optionIndex}
              apiCurrentPage={apiCurrentPage}
              key={ID}
              hasValue={!!Value}
              value={Value}
            >
              <FormField
                disableFinish={disableFinish}
                setDisableFinish={setDisableFinish}
                ScreenOption={ScreenOption}
                animateIndex={optionIndex}
                lastOptionIndex={ScreenOptions.length - 1}
              />

              {InformationLink && (
                <Link to={InformationLink} target="_blank">
                  see more info
                </Link>
              )}
            </AnimatedField>
          );
      });
    });

    return options;
  }, [apiCurrentPage, optionToFocus, disableFinish, setDisableFinish]);

  // const getFields = useCallback(() => {});

  useEffect(() => {
    let vals = {};
    let validation = {};
    const obj = {
      numericcontrol: "number",
      numerictextbox: "number",
      dropdown: "string",
      radiobuttonhorizontal: "string",
      imageandtexthorizontal: "string",
      typeabledropdown: "string",
      imagedropdown: "string",
      textbox: "string",
      checkbox: "boolean",
      textarea: "string",
    };
    if (!api?.d?.Pages?.[currentPage]) return;

    const { Screens = [] } = api?.d?.Pages?.[currentPage] || {};
    Screens.forEach((Screen) => {
      const { ScreenOptions = [] } = Screen || {};
      ScreenOptions.forEach((ScreenOption) => {
        const {
          IsRequired,
          Name: name,
          Value,
          Ranges,
          DisplayType,
        } = ScreenOption || {};
        const Name = name.replace("[", "").replace("]", "") || [name];

        const { DisplayFormat } = ScreenOption || {};
        const { Max, Min } = Ranges?.[0] || {};
        const type = DisplayType.toLowerCase();
        let validationObj = yup?.[obj?.[type]]?.();
        if (!DisplayFormat || DisplayFormat !== "Fraction") {
          if (Min)
            validationObj = validationObj.min(
              Min,
              `This field can be no smaller than ${Min}`
            );
          if (Max)
            validationObj = validationObj.max(
              Max,
              `This field can be no larger than ${Max}`
            );

          if (type === "numerictextbox") {
            if (Min >= 0)
              validationObj = validationObj.positive(
                "Must be a positive number."
              );
          }
        }
        if (IsRequired && validationObj?.required)
          validationObj = validationObj.required("This field is required.");
        // if (Increment) validationObj = validationObj.step(Increment);
        if (type === "checkbox") {
          vals[Name] = Value === "True";
        } else if (obj[type] === "number") {
          const val =
            typeof Value === "string" ? Value.replace(",", "") : Value;
          vals[Name] = val;
        } else {
          vals[Name] = Value;
        }

        validation[Name] = validationObj;
      });
    });

    setInitialValues(vals);
    setSchema(yup.object().shape(validation));
  }, [api, currentPage]);

  const ret = useMemo(
    () => (
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
      >
        <Form style={{ paddingBottom: "10rem" }}>
          <Flex style={{ gap: "1rem" }} flexWrap="wrap">
            {screens}
          </Flex>
        </Form>
      </Formik>
    ),
    [initialValues, screens, validationSchema]
  );
  if (isLoading) return null;
  return ret;
};

export default FormComponent;
