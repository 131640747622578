import React, { useContext } from "react";
import { PageWrapper } from "../UI/Grid";
import { createSearchParams, useNavigate } from "react-router-dom";
import Table from "../UI/Table";
import UserContext from "../../context/user/UserContext";
import { useQuery } from "../Hooks";
import { Body } from "../UI/Text";
import { getLines } from "../../requests/quoteManagementRequests";
import formatter from "../../modules/formatter";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";

const MyConfigurationsList = () => {
  const navigate = useNavigate();
  const { activeUser } = useContext(UserContext);
  // const { requireHttps } = platform || {};
  const config = useContext(ConfiguratorContext);
  const { data } =
    useQuery({
      queryID: "myConfigurations",
      request: getLines,
      queryVariables: { username: activeUser?.username },
      skip: !activeUser?.username,
    }) || {};
  const { rows: lines = [], count } = data || {};

  const onCardClick = (row) => {
    const { configurationId, headerId, productId, quoteId, id } = row || {};
    config.setIsReconfigure(true);

    navigate({
      pathname: `/Configurator/${productId}/${quoteId}/${configurationId}`,
      search: createSearchParams({
        headerId,
        configurationId,
        LineId: id,
        lineNumber: 0,
      }).toString(),
    });
  };

  const gridData = lines.map((line) => {
    const { id, imgUrl, price, description, partNumber } = line;
    return {
      id,
      iconName: "",
      title: partNumber,
      subtitle: description,
      image: `${imgUrl}`,
      ...line,
      elements: [
        <Body key={id} center bold>
          ${formatter.currency(price)}
        </Body>,
      ],
    };
  });

  return (
    <PageWrapper>
      <Table
        title="My Configurations"
        hideCreate
        gridData={gridData}
        totalCount={count}
        hideProperties
        hideSearch
        hidePager
        hideToggle
        forceGrid
        onCardClick={onCardClick}
      />
    </PageWrapper>
  );
};

export default MyConfigurationsList;
