import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import Button from "../Button";
import { Heading, Body } from "../Text";

const HeadingSection = ({
  hideBack = false,
  hideCreate = false,
  backButtonIcon = "faArrowLeft",
  backButtonLink: _backButtonLink,
  additionalElements = [],
  title,
  subtitle,
  createButtonText,
  createButtonIcon,
  createLink: _createLink,
  ...rest
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const createLink = () => {
    let key = "to";
    if (typeof _createLink === "function") key = "onClick";
    if (!_createLink) return { onClick: () => navigate(-1) };
    return { [key]: _createLink };
  };

  const backButtonLink = () => {
    let key = "to";
    if (typeof _backButtonLink === "function") key = "onClick";
    if (!_backButtonLink) return { onClick: () => navigate(-1) };
    return { [key]: _backButtonLink };
  };

  return (
    <Flex {...rest} style={{ gap: "1rem" }} flexDirection="column" width={1}>
      <Flex
        width={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          width={1}
          flexDirection={["column", "column", "row"]}
          justifyContent="space-between"
          alignItems={["flex-start", "flex-start", "center"]}
        >
          {(title || !hideBack) && (
            <Flex alignItems="center" flexGrow={0.65}>
              {!hideBack && (
                <Button
                  size="tiny"
                  mr="0.6rem"
                  reverse
                  color={theme.gray}
                  iconName={backButtonIcon}
                  {...backButtonLink()}
                />
              )}

              {title && <Heading m={0}>{title}</Heading>}
            </Flex>
          )}
          {(additionalElements || !hideCreate) && (
            <Flex
              alignItems={["flex-start", "flex-start", "center"]}
              justifyContent="space-between"
              flexDirection={["column", "column", "row"]}
              flex={additionalElements ? 2 : 1}
            >
              {additionalElements && (
                <Flex
                  flexGrow={0}
                  m={["1rem -0.5rem", "1rem -0.5rem", "0 1rem"]}
                >
                  {additionalElements}
                </Flex>
              )}
              {!hideCreate && (
                <Button
                  iconName={createButtonIcon}
                  color={theme.callToActionColor}
                  {...createLink()}
                >
                  {createButtonText && <Body>{createButtonText}</Body>}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {subtitle && <Body m={0}>{subtitle}</Body>}
    </Flex>
  );
};

export default HeadingSection;
