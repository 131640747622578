import React from "react";
import styled, { useTheme } from "styled-components";
import { Flex, Box } from "reflexbox";
import { Helper } from "../UI/Text";
import Link from "../UI/Link";

const StyledFooterLogo = styled(Box)`
  width: 160px;
  svg {
    width: 100%;
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const Footer = () => {
  const theme = useTheme();

  return (
    <Flex className="no-print" maxWidth="1200px" width={1} m="0 auto">
      <Flex p="1rem" width={1} justifyContent="center" alignItems="center">
        <Flex alignItems="center">
          <Helper
            color={theme.primary}
            mt="0.5rem"
            mr="1rem"
            className="poweredBy"
          >
            Powered By
          </Helper>

          <Link
            color={theme.primary}
            target="_blank"
            to="https://renaissancetech.com/"
          >
            <StyledFooterLogo alt="RenaissanceTech">
              <svg
                height="43"
                viewBox="0 0 220 43"
                width="220"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="a">
                  <path
                    d="m0 39.2864818h219.979196v-39.22131954h-219.979196z"
                    fillRule="evenodd"
                  />
                </mask>
                <g fillRule="evenodd" transform="translate(0 .466667)">
                  <path d="m1 3.53333333 18.1499686 8.09585047 2.3369754 26.8729582.0052918.0311913v-.0624696l2.3577956-26.8471536 18.1499686-8.09037677-.2290217.0678564-20.2840343 4.25870578-20.42977533-4.31092306z" />
                  <path d="m0 18.5333333 1-14.49127562-.97368586-.50872435z" />
                  <path d="m0 2.96493695 1.3651142.56839638 11.6348858-3z" />
                  <path d="m42.6050827 3.20821005 1.3949173 14.32512325-.0367958-14.81711075.0210636-.01090397-12.9842678-2.17198525z" />
                  <path d="m21.9998329 40.5333333h.0001671v-1.8924884l-11-4.1075116z" />
                  <path d="m22 38.6507021v1.8826312l11-6z" />

                  <path d="m61.4699764 11.4798625h-.7177305v5.250634h.9101654c1.2170213 0 2.0491726-.1027295 2.4964539-.3095638.4576833-.2062325.8113476-.5157962 1.055792-.9281753.2548463-.4231249.38487-.8973136.38487-1.4285837 0-.5265421-.1404255-.9959167-.4212766-1.4183538-.2808511-.4285407-.6762096-.7273587-1.1858156-.9026435-.5044917-.1759724-1.3470449-.2633139-2.5224586-.2633139zm-3.1205674 14.607522v-16.62970128h4.2022774c1.6955949 0 3.0426398.41298087 4.0307328 1.24848488 1.0038692.8355039 1.50316 1.9597679 1.50316 3.3835375 0 .964453-.2444444 1.8052869-.7333333 2.5118418s-1.1858156 1.2330969-2.1011821 1.5836664c.5356974.3452397 1.0609929.825446 1.5758866 1.4284978.5096926.6038255 1.2326241 1.6562218 2.1583924 3.151859.5825059.9489792 1.050591 1.6555341 1.4042553 2.1350527l.8893617 1.1867612h-2.8553191l-.7333334-1.0934021c-.0260047-.0416076-.0728132-.1087471-.1456264-.2015044l-.4680852-.6548034-.7437352-1.217623-.8061466-1.2948206c-.4940898-.6857511-.951773-1.2270793-1.3678487-1.6293144-.4056737-.4077369-.7801418-.6964969-1.1182033-.8717816-.3276595-.1807007-.8841607-.2733721-1.6643026-.2733721h-.6241134v7.2366216z" />
                  <path d="m74.5087384 19.5056007h5.5598109c-.0572105-.8663658-.3172577-1.5319149-.7801419-2.0013755-.4680851-.4694605-1.0921986-.701225-1.8723404-.701225-.7853428 0-1.4302601.2317645-1.9347518.701225-.4992908.4694606-.8217494 1.1350097-.9725768 2.0013755zm7.7182033 1.2995487h-7.7650118c.0520094 1.0470664.4056737 1.8778422 1.0557919 2.4963679.6553192.6192134 1.4978724.9288631 2.5328605.9288631 1.440662 0 2.7669031-.4439286 3.9839244-1.3310982v2.1202665c-.670922.4432409-1.341844.7582205-2.0023641.9489791-.6553191.1906727-1.43026.28876-2.3196217.28876-1.2170213 0-2.2-.2524823-2.9541371-.7528906-.7489362-.5003224-1.3522459-1.1712874-1.8099291-2.0114335-.4472813-.8462498-.670922-1.8315066-.670922-2.9402966 0-1.6662798.4732861-3.0223082 1.4250591-4.0592306.9517731-1.0470664 2.1895981-1.5736084 3.7134752-1.5736084 1.4614657 0 2.6316785.5111541 3.5054373 1.5272727.868558 1.0161186 1.3054374 2.3774769 1.3054374 4.0800344z" />
                  <path d="m86.7673586 15.1367806v1.3929078c.9725768-1.0732001 2.0856658-1.6098002 3.3234042-1.6098002.6865248 0 1.3314421.1806147 1.9192356.5366001.5928211.3505695 1.0401891.835504 1.3470449 1.4547174.3119701.6084676.4679985 1.5836664.4679985 2.9140769v6.2621105h-2.1843972v-6.2358908c0-1.1196218-.1716312-1.9188481-.5148936-2.3936386-.3484634-.4849344-.9205674-.7273586-1.7319149-.7273586-1.0297872 0-1.9087471.5104663-2.6264775 1.5373307v7.8195573h-2.2364067v-10.9506125z" />
                  <path d="m101.662858 23.9207651v-3.2492585l-1.461379.5621319c-.7437351.2941758-1.2743182.5876639-1.586375.8918977-.3016548.2941758-.4575965.6602192-.4575965 1.1041479 0 .4539867.1455398.82536.4315918 1.10879.2964539.2841178.6761229.4285407 1.144208.4285407.6969267 0 1.3366427-.2787019 1.9295507-.8462497m2.147991-4.41513v4.6522243c0 .3767892.130023.562132.384956.562132.265162 0 .676123-.1954868 1.237739-.582334v1.3204385c-.49409.3197077-.894476.5311841-1.201332.6447453-.296454.1182033-.608597.1752847-.936257.1752847-.93617 0-1.487384-.3613152-1.653814-1.0934021-.930969.7118848-1.9192356 1.0725984-2.964539 1.0725984-.7697399 0-1.4095429-.2525683-1.9244365-.7528907-.5096927-.5057382-.76974-1.1397378-.76974-1.9033741 0-.691081.2496454-1.3048786.7489362-1.8468945.5044917-.5466581 1.211907-.9799269 2.1324744-1.2942188l2.7980219-.954309v-.5830217c0-1.3203525-.66052-1.9751558-1.9918753-1.9751558-1.1911033 0-2.3509141.6084676-3.4795193 1.8308188v-2.3674189c.8477541-.9954008 2.070063-1.490995 3.6614657-1.490995 1.1910169 0 2.1479909.3095637 2.8658079.9288631.244358.2007307.457596.4640447.650118.7991403.192348.3251236.31197.6554911.358779.9852568.05721.3304535.083215.9489792.083215 1.8725124" />
                  <path d="m107.441135 26.0874275h2.184397v-10.9506125h-2.184397zm1.076595-15.5619171c.364067 0 .670922.1235332.925769.3713733.254846.2478401.38487.5467441.38487.9026435 0 .3505695-.130024.6548033-.38487.9080593-.254847.2524823-.561702.3814313-.925769.3814313-.33286 0-.629314-.128949-.88416-.3814313-.260048-.263314-.38487-.562132-.38487-.9080593 0-.3297658.124822-.6239416.38487-.8771116.254846-.2626262.5513-.3969052.88416-.3969052z" />
                  <path d="m112.231171 25.3029357v-2.3311412c.613712.4277671 1.243113.7736944 1.882829 1.0469805.650118.263314 1.19093.392263 1.633097.392263.457683 0 .847754-.1134751 1.175327-.3357833.32766-.2216205.49409-.4842467.49409-.7938964 0-.3196218-.109133-.5829358-.32766-.7890823-.207951-.2115625-.66052-.5212122-1.367762-.9133893-1.404255-.7784225-2.33011-1.4439716-2.772191-1.9907156-.431591-.5574039-.650118-1.1605416-.650118-1.8106168 0-.8408339.332861-1.526585.988267-2.0578552.66052-.5311842 1.513475-.799828 2.553664-.799828 1.08171 0 2.189511.3049215 3.328518.9079733v2.1404685c-1.294948-.7784225-2.356028-1.1652697-3.182978-1.1652697-.421277 0-.76974.0926714-1.029787.273372-.260048.1752848-.38487.4069633-.38487.7011391 0 .25317.11442.4949924.34855.7273587.239156.2323662.655232.5057382 1.24814.8355039l.780142.4385128c1.841134 1.0315925 2.761702 2.1766602 2.761702 3.4299592 0 .8979153-.358779 1.635332-1.071395 2.2129379-.702128.57219-1.612293.8563077-2.725295.8563077-.65532 0-1.237826-.0671394-1.752632-.2015044-.50978-.1444229-1.154697-.4023211-1.929638-.7736944" />
                  <path d="m121.691743 25.3029357v-2.3311412c.613712.4277671 1.243026.7736944 1.882742 1.0469805.650119.263314 1.191017.392263 1.633097.392263.457684 0 .847754-.1134751 1.175414-.3357833.327573-.2216205.494003-.4842467.494003-.7938964 0-.3196218-.109133-.5829358-.327573-.7890823-.208038-.2115625-.66052-.5212122-1.367848-.9133893-1.409457-.7784225-2.330024-1.4439716-2.772191-1.9907156-.431592-.5574039-.650118-1.1605416-.650118-1.8106168 0-.8408339.332947-1.526585.988266-2.0578552.66052-.5311842 1.513475-.799828 2.553664-.799828 1.081797 0 2.189512.3049215 3.328606.9079733v2.1404685c-1.300237-.7784225-2.356115-1.1652697-3.183066-1.1652697-.42119 0-.76974.0926714-1.029787.273372-.260047.1752848-.384783.4069633-.384783.7011391 0 .25317.114334.4949924.348463.7273587.239157.2323662.655319.5057382 1.248227.8355039l.780142.4385128c1.841135 1.0315925 2.761615 2.1766602 2.761615 3.4299592 0 .8979153-.358778 1.635332-1.071308 2.2129379-.702214.57219-1.61238.8563077-2.725382.8563077-.655233 0-1.237738-.0671394-1.752632-.2015044-.509693-.1444229-1.154697-.4023211-1.929551-.7736944" />
                  <path d="m136.47804 23.9207651v-3.2492585l-1.461466.5621319c-.743735.2941758-1.274318.5876639-1.586288.8918977-.301741.2941758-.457683.6602192-.457683 1.1041479 0 .4539867.14554.82536.436879 1.10879.291253.2841178.676037.4285407 1.138921.4285407.696926 0 1.34193-.2787019 1.929637-.8462497m2.153192-4.41513v4.6522243c0 .3767892.124736.562132.379669.562132.265161 0 .681324-.1954868 1.237825-.582334v1.3204385c-.49409.3197077-.894563.5311841-1.201419.6447453-.296454.1182033-.60851.1752847-.93617.1752847-.93617 0-1.48747-.3613152-1.653901-1.0934021-.930969.7118848-1.919149 1.0725984-2.964539 1.0725984-.76974 0-1.409456-.2525683-1.924436-.7528907-.509693-.5057382-.764452-1.1397378-.764452-1.9033741 0-.691081.244357-1.3048786.743735-1.8468945.504405-.5466581 1.216934-.9799269 2.137502-1.2942188l2.792994-.954309v-.5830217c0-1.3203525-.66052-1.9751558-1.991962-1.9751558-1.191103 0-2.350914.6084676-3.479433 1.8308188v-2.3674189c.847668-.9954008 2.069977-1.490995 3.661466-1.490995 1.19093 0 2.147904.3095637 2.870835.9288631.239331.2007307.452569.4640447.645004.7991403.192349.3251236.31197.6554911.358779.9852568.05721.3304535.088503.9489792.088503 1.8725124" />
                  <path d="m144.393827 15.1367806v1.3929078c.972664-1.0732001 2.080379-1.6098002 3.323405-1.6098002.686611 0 1.326327.1806147 1.919149.5366001.592907.3505695 1.040189.835504 1.347131 1.4547174.31197.6084676.467999 1.5836664.467999 2.9140769v6.2621105h-2.184311v-6.2358908c0-1.1196218-.171718-1.9188481-.51498-2.3936386-.348377-.4849344-.920568-.7273586-1.731828-.7273586-1.029874 0-1.908834.5104663-2.626565 1.5373307v7.8195573h-2.236406v-10.9506125z" />
                  <path d="m162.78438 23.528545v2.1457984c-1.0974.4023211-2.168794.6037396-3.219386.6037396-1.726713 0-3.110165-.5056523-4.139866-1.5218569-1.024673-1.0161186-1.539566-2.3774769-1.539566-4.0846766 0-1.7226735.504492-3.1102514 1.503073-4.1680636.998582-1.0517086 2.319622-1.5836664 3.947518-1.5836664.572104 0 1.076682.0570814 1.529078.1706426.452482.1034171 1.014271.2988179 1.679905.5929937v2.3110252c-1.107801-.7018268-2.132301-1.0470664-3.073759-1.0470664-.982978 0-1.794326.3452396-2.42364 1.0315925-.634516.6857511-.951773 1.5574468-.951773 2.6199871 0 1.1195358.343262 2.0114335 1.019472 2.6669246.691639.6602192 1.617407.9899849 2.777305.9899849.837265 0 1.804641-.2424242 2.891639-.7273587" />
                  <path d="m166.950389 19.5056007h5.559811c-.057297-.8663658-.317344-1.5319149-.780142-2.0013755-.468171-.4694605-1.092198-.701225-1.87234-.701225-.78543 0-1.43026.2317645-1.934752.701225-.49409.4694606-.821836 1.1350097-.972577 2.0013755zm7.718204 1.2995487h-7.765012c.052009 1.0470664.405674 1.8778422 1.055792 2.4963679.655232.6192134 1.497872.9288631 2.532774.9288631 1.440662 0 2.766903-.4439286 3.983924-1.3310982v2.1202665c-.670835.4432409-1.341757.7582205-2.002277.9489791-.655406.1906727-1.430261.28876-2.319622.28876-1.217021 0-2.2-.2524823-2.954137-.7528906-.749023-.5003224-1.352333-1.1712874-1.809929-2.0114335-.447282-.8462498-.670922-1.8315066-.670922-2.9402966 0-1.6662798.473286-3.0223082 1.424972-4.0592306.95186-1.0470664 2.189685-1.5736084 3.713562-1.5736084 1.461379 0 2.631592.5111541 3.505351 1.5272727.868644 1.0161186 1.305524 2.3774769 1.305524 4.0800344z" />
                  <path d="m175.895946 9.43212551h14.006147v2.11957879h-5.845776v14.5356544h-2.413239v-14.5356544h-5.747132z" />
                  <path d="m189.886516 19.5056007h5.559811c-.057297-.8663658-.317344-1.5319149-.780142-2.0013755-.468085-.4694605-1.092198-.701225-1.87234-.701225-.785343 0-1.43026.2317645-1.934752.701225-.49409.4694606-.821749 1.1350097-.972577 2.0013755zm7.718203 1.2995487h-7.765011c.05721 1.0470664.405673 1.8778422 1.055792 2.4963679.655319.6192134 1.497872.9288631 2.53286.9288631 1.440575 0 2.766903-.4439286 3.983924-1.3310982v2.1202665c-.670922.4432409-1.336643.7582205-2.002364.9489791-.655319.1906727-1.43026.28876-2.319621.28876-1.217022 0-2.2-.2524823-2.954137-.7528906-.748937-.5003224-1.352246-1.1712874-1.80993-2.0114335-.447281-.8462498-.670922-1.8315066-.670922-2.9402966 0-1.6662798.473287-3.0223082 1.42506-4.0592306.951773-1.0470664 2.189598-1.5736084 3.713475-1.5736084 1.461465 0 2.631678.5111541 3.50535 1.5272727.868645 1.0161186 1.305524 2.3774769 1.305524 4.0800344z" />
                  <path d="m208.261405 23.528545v2.1457984c-1.097313.4023211-2.168794.6037396-3.219385.6037396-1.726714 0-3.110166-.5056523-4.139866-1.5218569-1.024673-1.0161186-1.53948-2.3774769-1.53948-4.0846766 0-1.7226735.504405-3.1102514 1.503073-4.1680636.998495-1.0517086 2.314421-1.5836664 3.947518-1.5836664.572017 0 1.076596.0570814 1.528991.1706426.452482.1034171 1.014271.2988179 1.679906.5929937v2.3110252c-1.107715-.7018268-2.132301-1.0470664-3.073759-1.0470664-.988093 0-1.794327.3452396-2.428755 1.0315925-.629401.6857511-.946572 1.5574468-.946572 2.6199871 0 1.1195358.343262 2.0114335 1.019385 2.6669246.691639.6602192 1.612206.9899849 2.777305.9899849.837352 0 1.804728-.2424242 2.891639-.7273587" />
                  <path d="m210.685132 9.45764883h2.184311v7.11841827c.915453-1.1041479 2.043971-1.6562218 3.401418-1.6562218.733334 0 1.39394.1860305 1.97636.5473458.587793.3660434 1.019472.8710939 1.300236 1.5158392.286052.6400172.431765 1.5991403.431765 2.868429v6.2358908h-2.184397v-6.7724909c0-.799914-.202837-1.4446593-.60331-1.9288201-.390071-.4903503-.910165-.7381044-1.560283-.7381044-.478574 0-.931056.1242209-1.357534.3666451-.421276.2471524-.889275.6548034-1.404255 1.2270793v7.845691h-2.184311z" />
                </g>
              </svg>
            </StyledFooterLogo>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
