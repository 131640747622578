import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import { useTheme } from "styled-components";
import { Flex } from "reflexbox/styled-components";
import Button from "../Button";
import { Body, Helper } from "../Text";
import { PagerContext } from "./PagerContext";
import { Wrapper } from "./styles";

const Pager = ({ limitOptions: _limitOptions, totalCount }) => {
  const theme = useTheme();
  const { limit, offset, setLimit, setOffset } = useContext(PagerContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [range, setRange] = useState([1, 2, 3, 4]);

  const limitOptions = _limitOptions || [12, 48, 96];

  const getNumPages = useCallback(() => {
    let a = +totalCount / +limit;
    if (a < 1) return 1;
    return Math.ceil(a);
  }, [totalCount, limit]);

  useEffect(() => {
    const newPages = getNumPages();

    if (newPages > 4) {
      let _pageNum = pageNum > 3 ? pageNum : 3;
      if (_pageNum > newPages - 2) _pageNum = newPages - 1;
      setRange(_.range(_pageNum - 2, _pageNum + 2));
    } else {
      setRange(_.range(1, newPages + 1));
    }
    if (newPages !== numPages) setNumPages(newPages);
  }, [getNumPages, limit, numPages, pageNum, totalCount]);

  const handleLimitClick = (option) => {
    setLimit(option);
    setPageNum(1);
    setOffset(0);
    setDropdownOpen(false);
  };

  const handleNumberClick = (_pageNum) => {
    const pageN = _pageNum - 1;
    let newOffset = pageN * +limit;
    if (newOffset < 0) newOffset = 0;
    setPageNum(newOffset < 0 ? 1 : _pageNum);
    setOffset(newOffset);
  };

  const handleArrowClick = (direction) => {
    let newOffset = offset;

    switch (direction) {
      case "right":
        if (pageNum < numPages) setPageNum(pageNum + 1);
        newOffset = +offset + +limit;
        break;
      case "left":
        if (pageNum > 1) setPageNum(pageNum - 1);
        newOffset = +offset - +limit;
        break;
      case "beginning":
        setPageNum(1);
        newOffset = 0;
        break;
      case "end":
        setPageNum(numPages);
        newOffset = numPages * limit - limit;
        break;
      default:
        break;
    }

    if (direction === "right") {
    } else {
    }
    if (newOffset < 0) return (newOffset = 0);
    if (newOffset / limit >= getNumPages(totalCount, limit))
      return (newOffset = offset);
    setOffset(newOffset);
  };

  return limitOptions[0] >= totalCount ? null : (
    <Wrapper
      justifyContent="space-around"
      alignItems="center"
      m="0 auto 1rem auto"
    >
      <Flex flexDirection="column" alignItems="center">
        <Flex
          className="pagerOptions"
          mb="1rem"
          alignItems="center"
          justifyContent="center"
        >
          {numPages > 4 && (
            <Button
              size="small"
              color={theme.secondary}
              reverse
              iconName="faAnglesLeft"
              onClick={() => handleArrowClick("beginning")}
              style={{
                opacity: pageNum > 2 ? undefined : 0,
                pointerEvents: pageNum > 2 ? undefined : "none",
              }}
            />
          )}
          <Button
            size="small"
            color={theme.secondary}
            reverse
            iconName="faAngleLeft"
            onClick={() => handleArrowClick("left")}
            style={{
              opacity: pageNum > 1 ? undefined : 0,
              pointerEvents: pageNum > 1 ? undefined : "none",
            }}
          />

          {range.map((i) => (
            <Button
              size="small"
              className="pagerOption"
              color={theme.secondary}
              onClick={() => handleNumberClick(i)}
              key={`pageNumber${i}`}
              disabled={pageNum === i}
            >
              <Body bold={pageNum === i}>{i}</Body>
            </Button>
          ))}

          <Button
            size="small"
            color={theme.secondary}
            reverse
            iconName="faAngleRight"
            onClick={() => handleArrowClick("right")}
            style={{
              opacity: pageNum <= numPages - 1 ? undefined : 0,
              pointerEvents: pageNum <= numPages - 1 ? undefined : "none",
            }}
          />
          {numPages > 4 && (
            <Button
              size="small"
              color={theme.secondary}
              reverse
              iconName="faAnglesRight"
              onClick={() => handleArrowClick("end")}
              style={{
                opacity: pageNum <= numPages - 2 ? undefined : 0,
                pointerEvents: pageNum <= numPages - 2 ? undefined : "none",
              }}
            />
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            size="small"
            reverse
            color={theme.secondary}
            iconName={dropdownOpen ? "faAngleUp" : "faAngleDown"}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <Body bold>{limit} per page</Body>
          </Button>
          {dropdownOpen && (
            <Flex flexDirection="column" mt="1rem" alignItems="center">
              {limitOptions.map((option) => (
                <Button
                  key={option}
                  size="small"
                  color={theme.secondary}
                  reverse
                  m="0 0 0.5rem 0"
                  onClick={() => handleLimitClick(option)}
                >
                  <Helper bold>{option}</Helper>
                </Button>
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Pager;
