import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const getParsedUrl = (url) => {
  const parser = document.createElement("a");
  parser.href = url;
  return parser;
};
const getRelativePath = (path) => {
  const urlIsAbsolute = /^https?:\/\//.test(path);
  if (!urlIsAbsolute) return path;
  const { pathname, search, hash } = getParsedUrl(path) || {};
  return `${pathname}${search}${hash}`;
};

const externalUrl = (url) => {
  if (/^https?:\/\//.test(url) === false) return false;
  if (/^http?:\/\//.test(url) === false) return false;
  const { host } = getParsedUrl(url);
  return window.location.host !== host;
};

const Link = React.forwardRef(function RefLink(props, ref) {
  const { target, to, children, textOnly, reverse, ...rest } = props;
  if (typeof window !== "undefined" && externalUrl(to))
    return (
      <a ref={ref} href={to} target={target} {...rest}>
        {children}
      </a>
    );
  return (
    <RouterLink
      target={target}
      ref={ref}
      to={getRelativePath(to) || ""}
      {...rest}
    >
      {children}
    </RouterLink>
  );
});

Link.propTypes = {
  /** This will direct the user to a new URL */
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  borderless: PropTypes.bool,
  type: PropTypes.string,
  htmlElement: PropTypes.string,
  onClick: PropTypes.func,
  textOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Link;
