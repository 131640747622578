import React from "react";
import PropTypes from "prop-types";
import { Flex } from "reflexbox/styled-components";
import styled, { css } from "styled-components";
import { color } from "styled-system";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledCard = styled(Flex)`
  background-color: ${({ theme }) => theme?.backgroundColor};
  border: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  color: ${({ theme }) => theme?.backgroundOpposite};
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  transform-origin: center;
  transition: all ease-in 200ms;
  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
  div {
    transform-origin: center;
    transition: all ease-in 200ms;
  }
  > svg {
    transition: all ease-in 200ms;
    width: 75px;
    height: 75px;
  }
  img {
    transition: all ease-in 200ms;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
    ${({ fullImage }) => {
      if (!fullImage) return css``;
    }};
  }
  ${color};

  &:hover {
    > svg {
      transition: 50ms all ease-out;
      color: ${({ theme, interactive, active, isRadio }) => {
        if (isRadio) return theme.secondary;
        return active || interactive ? theme?.secondary : "";
      }};
    }
    > div {
      transition: 50ms all ease-out;
      color: ${({ theme, interactive, active, isRadio }) => {
        if (isRadio) return theme.secondary;
        return active || interactive ? theme?.secondary : "";
      }};
    }
    ${({ interactive, theme, active, isRadio }) => {
      if (!interactive && !active) return;
      return {
        transform: "scale(1.05)",
        cursor: "pointer",
        transition: "50ms all ease-out",
        boxShadow: "0 10px 10px 5px " + theme.boxShadowColor,
        zIndex: 2,
        position: "relative",
        transformOrigin: "center",
        borderColor: isRadio ? theme.secondary : theme?.secondary,
        borderWidth: "2px",
      };
    }}
  }
  ${({ theme, active, isRadio }) => {
    if (!active) return;
    return {
      transform: "none",
      borderColor: isRadio ? theme.secondary : theme?.secondary,
      borderWidth: "2px",
      color: isRadio ? theme.secondary : theme?.secondary,
    };
  }}

  &:active {
    ${({ interactive }) => {
      if (!interactive) return;
      return {
        transform: "scale(1)",
        boxShadow: "none",
      };
    }}
  }
`;

const Card = (props) => {
  const {
    fullImage = false,
    isRadio = false,
    isLoading,
    iconName,
    children,
    ...rest
  } = props || {};
  return (
    <StyledCard
      isRadio={isRadio}
      fullImage={fullImage}
      isLoading={isLoading}
      {...rest}
    >
      {iconName && <FontAwesomeIcon icon={icons[iconName]} />}
      {children}
    </StyledCard>
  );
};

Card.propTypes = {
  interactive: PropTypes.bool,
  iconName: PropTypes.string,
  isLoading: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Card;
