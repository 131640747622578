module.exports = {
  currency: function (n, c, d, t) {
    // eslint-disable-next-line
    var c = isNaN((c = Math.abs(c))) ? 2 : c,
      // eslint-disable-next-line
      d = d == undefined ? "." : d,
      // eslint-disable-next-line
      t = t == undefined ? "," : t,
      // eslint-disable-next-line
      s = n < 0 ? "-" : "",
      // eslint-disable-next-line
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      // eslint-disable-next-line
      j = (j = i.length) > 3 ? j % 3 : 0;

    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  },

  // function to format phone number
  formatStringAsPhone: function (phone) {
    var str = phone.toString();
    return (
      "(" +
      str.substring(0, 3) +
      ") " +
      str.substring(3, 6) +
      "-" +
      str.substring(6, 10)
    );
  },
};
