import React, { useContext, useState, useCallback, useMemo } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import formatter from "../../modules/formatter";
import UserContext from "../../context/user/UserContext";
import { Flex, Box } from "reflexbox";
import { Body } from "../UI/Text";
import LineTable from "./PrintQuote/LineTable";
import Card from "../UI/Card";
import PrintQuote, { CustomerInfo } from "./PrintQuote";
import Checkout from "./Checkout";
import { updateQuote } from "../../requests/quoteManagementRequests";
import { getStatusTypes } from "../../requests/statusTypesMgmtReqs";
import { useQuery } from "../Hooks";
import _ from "lodash";
import PagerContextProvider from "../UI/Pager/PagerContext";

const Summary = ({
  lineCount,
  quote,
  printRef,
  preview,
  setPreview,
  communications,
  refetchQuote,
  lineLoading,
}) => {
  // const navigate = useNavigate();
  const { activeUser } = useContext(UserContext);
  // const { integration } = platform || {};

  const { id, _id, totalCost, poNumber, name, lines } = quote || {};

  const [isLoading] = useState(false);

  const isCheckout = activeUser?.checkoutType === "stripe";

  const { data: stData } = useQuery({
    queryID: "statusTypes",
    request: getStatusTypes,
    forcedFilters: {},
    attributes: ["id", "name"],
    skip: isLoading,
  });

  const { rows: statusTypes } = stData || {};

  const onSubmit = useCallback(
    async ({ poNumber: _poNumber }) => {
      await updateQuote({
        input: { poNumber: _poNumber },
        _id: _id,
        id,
      });
      refetchQuote();
    },
    [_id, id, refetchQuote]
  );

  // const onPlaceOrder = useCallback(
  //   async (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     await updateQuote({
  //       type: "order",
  //       input: { type: 2, integration, lines },
  //       _id: _id || undefined,
  //       id,
  //     });
  //     refetchQuote();

  //     navigate("/ThankYou");
  //   },
  //   [_id, id, integration, lines, navigate, refetchQuote]
  // );

  const quoteStatus = useMemo(() => {
    const { statusTypeId } = quote || {};
    console.log(quote);
    return _.find(statusTypes, (st) => +statusTypeId === +st.id)?.name || "—";
  }, [quote, statusTypes]);
  return (
    <>
      <PagerContextProvider>
        <PrintQuote
          ref={printRef}
          lines={lines}
          lineCount={lineCount}
          preview={preview}
          setPreview={setPreview}
          communications={communications}
          lineLoading={lineLoading}
        />
      </PagerContextProvider>
      <Box mb="1rem" mt="2rem">
        <CustomerInfo poNumber={quote?.poNumber} />

        <Flex
          alignItems={["center", "center", "center", "flex-start"]}
          flexDirection={["column", "column", "column", "row"]}
          style={{ gap: "1rem" }}
        >
          <Box flexDirection="column" mt="-2rem" flex={2} width={1}>
            <PagerContextProvider>
              <LineTable
                open={false}
                lineLoading={lineLoading}
                lines={lines}
                lineCount={lineCount}
                quoteId={id}
                quote={quote}
              />
            </PagerContextProvider>
            {/* <CommunicationList communications={communications} /> */}
          </Box>
          {quote?.type !== 1 ? null : (
            <Flex mt={["1rem", "1rem", "1rem", 0]} flex={1}>
              {/**
               * Add PO number and account number fields instead of checkout
               */}
              {isCheckout ? (
                <Checkout lines={lines} quote={quote} activeUser={activeUser} />
              ) : (
                <Card width={1}>
                  <Flex
                    width={1}
                    flexDirection="column"
                    alignItems="center"
                    mx="1rem"
                  >
                    {/* WM MOD ADDED JOB NAME AND STATUS TO QUOTE SUMMARY */}
                    <Flex width={1} mb=".25rem" justifyContent="space-between">
                      <Body bold>Total: </Body>
                      <Body right>{`$${formatter.currency(totalCost)}`}</Body>
                    </Flex>
                    <Flex width={1} mb=".25rem" justifyContent="space-between">
                      <Body bold>Status: </Body>
                      <Body right>{quoteStatus}</Body>
                    </Flex>
                    <Flex width={1} mb="1rem" justifyContent="space-between">
                      <Body bold>Job Name: </Body>
                      <Body right>{name}</Body>
                    </Flex>

                    <Formik
                      onSubmit={onSubmit}
                      enableReinitialize
                      initialValues={{ poNumber: poNumber || "" }}
                      validationSchema={yup
                        .object()
                        .shape({ poNumber: yup.string() })}
                    >
                      {/* {({ handleSubmit, values }) => {
                        return (
                          <Form style={{ width: "100%" }}>
                            <Field
                              disabled={quote?.type === 2}
                              labelText="PO Number: "
                              onBlur={() => handleSubmit(values)}
                              name="poNumber"
                            />
                          </Form>
                        );
                      }} */}
                    </Formik>

                    {/* <Button
                      mt="1.5rem"
                      iconName="faFileInvoiceDollar"
                      onClick={onPlaceOrder}
                    >
                      {isLoading ? (
                        <Loading
                          inline
                          color1={theme.secondary}
                          color2={theme.success}
                        />
                      ) : (
                        "Create Order"
                      )}
                    </Button> */}
                  </Flex>
                </Card>
              )}
            </Flex>
          )}
          {/* This will be for place order when user is not assigned to self checkout  */}
        </Flex>
      </Box>
    </>
  );
};

export default Summary;
