import React, { Component, useContext } from "react";
import GlobalStyle from "../../modules/globalStyles";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Header from "../Header";
import Configurator from "../Configurator";
import Alerts from "../Alerts";
import MyConfigurations from "../MyConfigurations";
import Loading from "../UI/Loading";
import Quote from "../Quote";
import QuoteDashboard from "../Quote/QuoteDashboard";
import Users from "../Administration/Users";
import UserDetails from "../Administration/UserDetails";
import Login from "../Login";
import ResetPassword from "../Administration/ResetPassword";
import { ThemeProvider } from "styled-components";
import * as theme from "../../modules/theme";
import * as constants from "../../modules/constants";
import ProductForm from "../ProductManagement/ProductForm";
import ProductPage from "../ProductManagement";
import UserContext from "../../context/user/UserContext";
import PagerContextProvider from "../UI/Pager/PagerContext";
import ConfiguratorContextProvider from "../../context/configurator/ConfiguratorContextProvider";
import UserContextProvider from "../../context/user/UserContextProvider";
import SocketContextProvider from "../../context/socket/SocketContextProvider";
import AlertContextProvider from "../../context/alert/AlertContextProvider";
import Administration from "../Administration";
import PlatformSettings from "../Administration/PlatformSettings";
import PlatformsPage from "../Administration/PlatformsPage";
import StatusTypesPage from "../Administration/StatusTypesPage";
import CustomersPage from "../Administration/CustomersPage";
import AddressesPage from "../Administration/AddressesPage";
import RolesPage from "../Administration/RolesPage";
import OrdersPage from "../OrdersPage";
import AllQuotes from "../Administration/AllQuotes";
import ThankYouPage from "../ThankYouPage";
import { PageWrapper } from "../UI/Grid";
import { Heading, Body } from "../UI/Text";
import { Box, Flex } from "reflexbox";
import Footer from "../Footer";

const { DEFAULT_HOMEPAGE } = constants;

const RequireAuth = ({ children, loginPage, publicRoute }) => {
  const { activeUser, loggedIn, isLoading, platform } = useContext(UserContext);
  const { roleLevel } = activeUser || {};
  // const homePage = DEFAULT_HOMEPAGE[roleLevel] || "/";
  if (loggedIn) {
    // if (loginPage) return <Navigate to={homePage} />;
    if (isLoading || !activeUser || !platform) return <Loading />;
    if (roleLevel > (publicRoute ? -1 : 0)) return children;
    // else return <Navigate to={homePage} />;
    return children;
  } else {
    if (publicRoute) return children;
    if (loginPage) return children;
    else return <Navigate to="/Login" />;
  }
};

const RequireAdmin = ({ children }) => {
  const { activeUser, loggedIn, isLoading, platform } = useContext(UserContext);
  const { roleLevel } = activeUser || {};

  const homePage = DEFAULT_HOMEPAGE[roleLevel] || "/";

  if (loggedIn) {
    if (isLoading || !activeUser || !platform) return <Loading />;
    if ([9, 5].includes(roleLevel)) return children;
    else return <Navigate to={homePage} />;
  } else {
    return <Navigate to="/Login" />;
  }
};

class App extends Component {
  static contextType = UserContext;
  constructor(props, context) {
    super(props);

    this.state = {
      theme: context.darkMode ? theme.darkModeTheme : theme.rentechTheme,
      hasError: false,
      configuratorMode: constants.CONFIGURATOR_MODES.Default,
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.error(error);
    console.log(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider
          theme={
            this.context.darkMode ? theme.darkModeTheme : theme.rentechTheme
          }
        >
          <Box
            bg={this.state.theme.backgroundColor}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <PageWrapper>
              <Flex
                height="calc(100vh - 2rem)"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Heading
                  style={{ fontFamily: "sans-serif" }}
                  color={this.state.theme.backgroundOpposite}
                  center
                >
                  Oops! Something went wrong...
                </Heading>
                <Body
                  color={this.state.theme.backgroundOpposite}
                  style={{ flex: "unset", fontFamily: "sans-serif" }}
                  center
                >
                  Try refreshing the page, if that doesn't work please come back
                  and try again later.
                </Body>
              </Flex>
            </PageWrapper>
          </Box>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider
        theme={this.context.darkMode ? theme.darkModeTheme : theme.rentechTheme}
      >
        <GlobalStyle />
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          className="App"
        >
          <Alerts />

          <Flex flexDirection="column" justifyContent="flex-start">
            <Header />

            <Routes>
              <Route
                path="/Login"
                element={
                  <RequireAuth loginPage>
                    <Login />
                  </RequireAuth>
                }
              />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              <Route
                path="/"
                element={
                  this.context.loggedIn ? (
                    <RequireAuth>
                      <QuoteDashboard />
                    </RequireAuth>
                  ) : (
                    <RequireAuth loginPage>
                      <Login />
                    </RequireAuth>
                  )
                }
              />
              {/* <Route
                path="/SUN"
                element={
                  this.context.loggedIn ? (
                    <RequireAuth>
                      <QuoteDashboard />
                    </RequireAuth>
                  ) : (
                    <RequireAuth loginPage>
                      <Login SUN />
                    </RequireAuth>
                  )
                }
              /> */}

              <Route path="Configurator">
                <Route
                  index
                  element={
                    <RequireAuth publicRoute>
                      <Configurator />
                    </RequireAuth>
                  }
                />
                <Route
                  path=":productId/:quoteId/:configurationId"
                  element={
                    <RequireAuth publicRoute>
                      <Configurator />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path="/MyConfigurations"
                element={
                  <RequireAuth publicRoute>
                    <PagerContextProvider
                      defaultOrder={["createdDate", "DESC"]}
                    >
                      <MyConfigurations />
                    </PagerContextProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/Quote/:quoteId"
                element={
                  <RequireAuth>
                    <Quote />
                  </RequireAuth>
                }
              />

              <Route
                path="/MyProfile/:username"
                element={
                  <RequireAuth publicRoute>
                    <UserDetails />
                  </RequireAuth>
                }
              />

              <Route
                path="/Orders/:username"
                element={
                  <RequireAuth>
                    <PagerContextProvider
                      defaultOrder={["dateOrderPlaced", "DESC"]}
                    >
                      <OrdersPage />
                    </PagerContextProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/Orders/:pid"
                element={
                  <RequireAdmin>
                    <PagerContextProvider
                      defaultOrder={["dateOrderPlaced", "DESC"]}
                    >
                      <OrdersPage />
                    </PagerContextProvider>
                  </RequireAdmin>
                }
              />
              <Route
                path="/Quotes/:pid"
                element={
                  <RequireAdmin>
                    <PagerContextProvider
                      defaultOrder={["createdDate", "DESC"]}
                    >
                      <AllQuotes />
                    </PagerContextProvider>
                  </RequireAdmin>
                }
              />
              <Route
                path="/ThankYou"
                element={
                  <RequireAuth>
                    <ThankYouPage />
                  </RequireAuth>
                }
              />

              <Route
                path="/QuoteDashboard"
                element={
                  <RequireAuth>
                    <QuoteDashboard />
                  </RequireAuth>
                }
              />
              <Route path="/Users">
                <Route
                  index
                  element={
                    <RequireAdmin>
                      <PagerContextProvider
                        defaultOrder={["isLoggedIn", "DESC"]}
                      >
                        <Users />
                      </PagerContextProvider>
                    </RequireAdmin>
                  }
                />
                <Route path="/Users/:action">
                  <Route
                    index
                    path="/Users/:action"
                    element={
                      <RequireAdmin>
                        <UserDetails />
                      </RequireAdmin>
                    }
                  />
                  <Route
                    path="/Users/:action/:username"
                    element={
                      <RequireAdmin>
                        <UserDetails />
                      </RequireAdmin>
                    }
                  />
                </Route>
              </Route>

              <Route path="/Products">
                <Route
                  index
                  element={
                    <RequireAdmin>
                      <PagerContextProvider
                        defaultOrder={["ProductNumber", "ASC"]}
                      >
                        <ProductPage />
                      </PagerContextProvider>
                    </RequireAdmin>
                  }
                />
                <Route path="/Products/:action">
                  <Route
                    index
                    path="/Products/:action"
                    element={
                      <RequireAdmin>
                        <ProductForm />
                      </RequireAdmin>
                    }
                  />
                  <Route
                    path="/Products/:action/:id"
                    element={
                      <RequireAdmin>
                        <ProductForm />
                      </RequireAdmin>
                    }
                  />
                </Route>
              </Route>

              <Route
                path="/Administration"
                element={
                  <RequireAdmin>
                    <Administration />
                  </RequireAdmin>
                }
              />

              <Route path="/Platforms">
                <Route
                  path="/Platforms"
                  element={
                    <RequireAdmin superUser>
                      <PlatformsPage />
                    </RequireAdmin>
                  }
                />
                <Route path="/Platforms/:action">
                  <Route
                    index
                    path="/Platforms/:action"
                    element={
                      <RequireAdmin>
                        <PlatformSettings />
                      </RequireAdmin>
                    }
                  />
                  <Route
                    path="/Platforms/:action/:pid"
                    element={
                      <RequireAdmin>
                        <PlatformSettings />
                      </RequireAdmin>
                    }
                  />
                </Route>
              </Route>

              <Route
                path="/StatusTypes"
                element={
                  <RequireAdmin>
                    <PagerContextProvider
                      defaultOrder={["displayOrder", "ASC"]}
                    >
                      <StatusTypesPage />
                    </PagerContextProvider>
                  </RequireAdmin>
                }
              />
              <Route
                path="/Customers"
                element={
                  <RequireAdmin>
                    <PagerContextProvider defaultOrder={["quoteId", "ASC"]}>
                      <CustomersPage />
                    </PagerContextProvider>
                  </RequireAdmin>
                }
              />
              <Route
                path="/Addresses"
                element={
                  <RequireAdmin>
                    <PagerContextProvider defaultOrder={["Address", "ASC"]}>
                      <AddressesPage />
                    </PagerContextProvider>
                  </RequireAdmin>
                }
              />

              <Route
                index
                path="/Role/:pid"
                element={
                  <RequireAdmin superUser>
                    <RolesPage />
                  </RequireAdmin>
                }
              />
            </Routes>
          </Flex>
          <Footer />
        </Flex>
      </ThemeProvider>
    );
  }
}

const AppHOC = (props) => (
  <BrowserRouter>
    <React.StrictMode>
      <SocketContextProvider>
        <AlertContextProvider>
          <ConfiguratorContextProvider>
            <UserContextProvider {...props}>
              <App />
            </UserContextProvider>
          </ConfiguratorContextProvider>
        </AlertContextProvider>
      </SocketContextProvider>
    </React.StrictMode>
  </BrowserRouter>
);

export default AppHOC;
