import React, { useState, useCallback } from "react";
import AlertContext from "./AlertContext";
import guid from "../../modules/guid";

const AlertContextProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const showAlert = useCallback(
    (alertText, alertColor) => {
      const newAlert = { alertText, alertColor, id: guid.GenerateGUID() };
      let newAlertsArray = [...alerts];
      newAlertsArray.splice(0, 0, newAlert);
      setAlerts(newAlertsArray);
    },
    [alerts]
  );

  const showFailureAlert = useCallback(
    (alertText) => showAlert(alertText, "danger"),
    [showAlert]
  );
  const showSuccessAlert = useCallback(
    (alertText) => showAlert(alertText, "success"),
    [showAlert]
  );
  const showWarningAlert = useCallback(
    (alertText) => showAlert(alertText, "warning"),
    [showAlert]
  );

  return (
    <AlertContext.Provider
      value={{
        alerts,
        setAlerts,
        showFailureAlert,
        showSuccessAlert,
        showWarningAlert,
      }}
    >
      {children || []}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
