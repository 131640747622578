import React, { forwardRef } from "react";
import { Flex } from "reflexbox/styled-components";
import styled, { useTheme } from "styled-components";
import { useField } from "formik";
import Label from "../Label";
import Loading from "../../Loading";
import { Helper, Body } from "../../Text";

const CheckboxWrapper = styled(Flex)`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 0.75rem;
  width: 100%;

  .label {
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    label,
    div {
      margin-bottom: 0;
    }
  }
`;

const StyledCheckbox = styled.input`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 28px;
  width: 28px;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  appearance: none;
  transition: all ease-in-out 200ms;

  border-color: ${({ theme }) => theme?.borderColor};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme?.secondary};
  }
  &:focus {
    border-color: ${({ theme }) => theme?.secondary};
  }

  &::after {
    pointer-events: none;
    content: "";
    display: none;
    width: 8px;
    height: 14px;
    margin-top: -4px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  &:checked {
    background-color: ${({ theme }) => theme?.secondary};
    &::after {
      display: block;
    }
  }

  &:indeterminate {
    background-color: ${({ theme }) => theme?.warning};
    &::after {
      display: block;
      width: 8px;
      height: 3px;
      margin-top: -2px;
      border: solid white;
      border-width: 0 0 3px 0;
      transform: rotate(0);
    }
  }
  &:disabled {
    background-color: ${({ theme }) => theme?.gray};
    cursor: not-allowed;
  }
`;

const Checkbox = forwardRef(
  ({ labelText, name, required, description, isLoading, ...rest }, ref) => {
    const [field, meta] = useField(name);

    const theme = useTheme();
    return (
      <>
        <CheckboxWrapper>
          {isLoading ? (
            <Loading width="auto !important" inline />
          ) : (
            <StyledCheckbox
              noLabel
              type="checkbox"
              name={name}
              ref={ref}
              description=""
              checked={["true", "True", true].includes(field.value)}
              {...rest}
              {...field}
            />
          )}

          {labelText && (
            <Flex alignItems="center" className="label" flexDirection="column">
              <Label required={required} htmlFor={name}>
                {labelText}
              </Label>
              {description && (
                <Helper mt={labelText ? "0.5rem" : 0}>{description}</Helper>
              )}
            </Flex>
          )}
        </CheckboxWrapper>
        {meta.error && meta.touched && (
          <Body my="0.5rem" color={theme.danger}>
            {meta.error}
          </Body>
        )}
      </>
    );
  }
);

export default Checkbox;
