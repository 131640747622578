import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Flex } from "reflexbox";
import styled, { useTheme, css } from "styled-components";
import { useQuery } from "../Hooks";
import {
  createAttachment,
  deleteAttachment,
  getAttachments,
} from "../../requests/quoteManagementRequests";
import { Body, Helper } from "../UI/Text";
import Card from "../UI/Card";
import Button from "../UI/Button";
import File from "../UI/forms/File";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AttachmentWrap = styled(Flex)`
  ${({ key }) => {
    return css`
      #${key} {
        opacity: 0;
        transition: 200ms ease-in all;
      }
      &:hover {
        #${key} {
          opacity: 1;
          transition: 50ms ease-out all;
        }
      }
    `;
  }}
`;

const Attachments = ({ name = "file", readonly }) => {
  const theme = useTheme();
  const { quoteId } = useParams();

  const {
    data: attachments = [],
    refetchQuery,
    setData,
  } = useQuery({
    queryID: `attachments|${quoteId}`,
    request: getAttachments,
    queryVariables: { quoteId },
    forcedFilters: {},
  });

  const checkFileType = useCallback((filetype) => {
    if (filetype === "video") {
      return "video/mp4,video/x-m4v,video/*";
    }
    if (filetype === "officedoc") {
      return `application/msword,  application/vnd.ms-excel,
        application/vnd.ms-powerpoint,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/vnd.openxmlformats-officedocument.presentationml.presentation,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    }
    if (filetype === "pdf") {
      return "application/pdf";
    }
    return "image/*";
  }, []);

  const getIcon = useCallback((encoding, mimetype) => {
    if (!mimetype) return icons["faFileCircleQuestion"];
    if (mimetype.startsWith("image")) return icons["faFileImage"];
    if (mimetype.startsWith("video")) return icons["faFileVideo"];
    if (mimetype.startsWith("office")) return icons["faFile"];
    if (mimetype === "pdf") return icons["faFilePdf"];
    // console.log(icons);
  }, []);

  const onSubmit = useCallback(
    async (file) => {
      const input = {
        fieldname: name,
        originalName: file.name,
        encoding: checkFileType(file.type),
        mimetype: file.type,
        filename: file.name,
        path: file.path,
        size: file.size,
        quoteId,
      };
      const oldAttachments = [...attachments];
      setData([...oldAttachments, { id: "new", ...input }]);
      const newAttachment = await createAttachment({ input });
      setData([...oldAttachments, newAttachment.dataValues]);
      await refetchQuery(`attachments|${quoteId}`);
    },
    [attachments, checkFileType, name, quoteId, refetchQuery, setData]
  );

  const onDelete = useCallback(
    async (id) => {
      const oldAttachments = [...attachments];
      _.remove(oldAttachments, (a) => a.id === id);
      setData(oldAttachments);
      await deleteAttachment({ id });
    },
    [attachments, setData]
  );

  const _attachments = useMemo(() => {
    const exts = { GB: 1000000000, MB: 1000000, KB: 1000 };
    return _.isArray(attachments)
      ? attachments.map((attachment) => {
          const { id, filename, path, size, mimetype, encoding } =
            attachment || {};

          const _size = () => {
            if (size > exts.GB) return `${Math.round(size / exts.GB)} GB`;
            if (size > exts.MB) return `${Math.round(size / exts.MB)} MB`;
            if (size > exts.KB) return `${Math.round(size / exts.KB)} KB`;
            else return `${size} B`;
          };

          return (
            <AttachmentWrap
              key={id}
              p="0.5rem"
              width={0.3333}
              flexDirection="column"
            >
              <Card>
                <FontAwesomeIcon icon={getIcon(encoding, mimetype)} />
                <Helper center mt="1rem">
                  Size: {_size()}
                </Helper>
                <Body center ellipsis>
                  {filename}
                </Body>
              </Card>
              <Flex
                id={id}
                my="1rem"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  mr="0.25rem"
                  color={theme.secondary}
                  to={`${path}`}
                  target="_blank"
                  iconName="faDownload"
                />
                <Button
                  ml="0.25rem"
                  color={theme.danger}
                  onClick={() => onDelete(id)}
                  iconName="faTrash"
                />
              </Flex>
            </AttachmentWrap>
          );
        })
      : [];
  }, [attachments, getIcon, onDelete, theme.danger, theme.secondary]);

  return (
    <Flex width={1} mt="-1rem" flexDirection="column">
      {!readonly && <File multiple onSubmit={onSubmit} name={name} />}

      <Flex m="-0.5rem" width="calc(100% + 1rem)" flexWrap="wrap">
        {_attachments}
      </Flex>
    </Flex>
  );
};

export default Attachments;
