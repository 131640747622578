import React, { useState, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox/styled-components";
import * as style from "../styles";
import Button from "../../Button";
import Loading from "../../Loading";

const ButtonCluster = (props) => {
  const {
    recordAction = "",
    onDelete,
    onCancel,
    deleteMessage,
    extraButtons,
    hideDelete,
  } = props;
  const theme = useTheme();

  const { dirty, submitCount, isSubmitting, values, handleReset } =
    useFormikContext();

  const [removing, setRemoving] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  useEffect(() => {
    if (submitCount > 1 && dirty) {
      handleReset({ values });
    }
  }, [dirty, handleReset, submitCount, values]);

  const leftCluster = useMemo(() => {
    if (removing || cancelling)
      return (
        <style.WarningBlock mr={[0, 0, "1rem"]} mb={["1rem", "1rem", 0]}>
          <Box>
            {removing
              ? deleteMessage
              : "Changes have been made are you sure you want to cancel?"}
          </Box>
        </style.WarningBlock>
      );
    if (isSubmitting) {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Button color={theme.success}>
            <Loading inline color1={theme.secondary} color2={theme.danger} />
          </Button>
          <Button
            iconName="faBan"
            ml="1rem"
            color={theme.gray}
            onClick={() => setCancelling(true)}
          >
            Cancel
          </Button>
        </Flex>
      );
    }
    if (submitCount > 0) {
      return (
        <Box>
          <Button reverse type="button" onClick={onCancel}>
            Return
          </Button>
        </Box>
      );
    }
    if (dirty) {
      return (
        <div>
          <Button
            type="submit"
            disabled={!dirty}
            color={theme.success}
            mr="1rem"
            iconName="faFile"
          >
            Save
          </Button>
          <Button
            iconName="faBan"
            color={theme.gray}
            onClick={() => setCancelling(true)}
            type="button"
          >
            Cancel
          </Button>
        </div>
      );
    }

    if (submitCount === 0 && !dirty) {
      return (
        <Box>
          <Button iconName="faXmark" color={theme.gray} onClick={onCancel}>
            Close Editor
          </Button>
        </Box>
      );
    }
    return null;
  }, [
    removing,
    cancelling,
    deleteMessage,
    isSubmitting,
    submitCount,
    dirty,
    theme.success,
    theme.secondary,
    theme.danger,
    theme.gray,
    onCancel,
  ]);
  const rightCluster = useMemo(() => {
    if ((isSubmitting || recordAction === "create") && !cancelling && !removing)
      return null;
    if (removing || cancelling) {
      return (
        <style.DangerWrapper
          flexDirection={["column", "column", "row"]}
          alignItems="center"
        >
          <Flex
            justifyContent={["center", "center", "flex-start"]}
            width="auto"
            alignItems="center"
          >
            <Button
              color={theme.danger}
              type="button"
              onClick={
                removing
                  ? onDelete
                  : () => {
                      onCancel();
                      setCancelling(false);
                    }
              }
            >
              {removing ? "Yes, Delete" : "Yes"}
            </Button>

            <Button
              color={theme.gray}
              type="button"
              onClick={() =>
                removing ? setRemoving(false) : setCancelling(false)
              }
            >
              {removing ? "No, Cancel" : "No"}
            </Button>
          </Flex>
        </style.DangerWrapper>
      );
    }
    return (
      !hideDelete && (
        <div>
          {extraButtons}
          <Button
            iconName="faTrash"
            color={theme.danger}
            onClick={() => setRemoving(true)}
          >
            Delete
          </Button>
        </div>
      )
    );
  }, [
    isSubmitting,
    recordAction,
    cancelling,
    removing,
    hideDelete,
    extraButtons,
    theme.danger,
    theme.gray,
    onDelete,
    onCancel,
  ]);
  return (
    <style.ClusterWrapper
      width={1}
      flexDirection={["column", "row"]}
      justifyContent={["center", "space-between"]}
    >
      {leftCluster}
      <style.ButtonWrapper mt={["1rem", "0"]}>
        {rightCluster}
      </style.ButtonWrapper>
    </style.ClusterWrapper>
  );
};

ButtonCluster.propTypes = {
  recordAction: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  deleteMessage: PropTypes.string,
  extraButtons: PropTypes.arrayOf(PropTypes.element),
  hideDelete: PropTypes.bool,
};

export default ButtonCluster;
