import React, { useState, useContext, useMemo } from "react";
import _ from "lodash";
import UserContext from "../../context/user/UserContext";
import { useQuery } from "../Hooks";
import { getRoles } from "../../requests/roleManagementRequests";
import { PageWrapper } from "../UI/Grid";
import Modal from "../UI/Modal";
import Table from "../UI/Table";
import RoleSettingsForm from "./RoleSettingsForm";

const RolesPage = () => {
  const { activeUser } = useContext(UserContext);
  const { roleLevel } = activeUser;

  const [show, setShow] = useState(false);
  const [roleId, setRoleId] = useState("");

  const { data, isLoading, refetchQuery } =
    useQuery({
      queryID: "roles",
      request: getRoles,
      queryVariables: { roleLevel },
      forcedFilters: {},
      skip: !roleLevel,
    }) || {};
  const { rows: _roles = [] } = data || {};

  const roles = useMemo(() => _.sortBy(_roles, ["roleLevel"]), [_roles]);

  const role = useMemo(
    () => _.find(roles, (role) => role.id === roleId),
    [roleId, roles]
  );

  const onCardClick = (id) => {
    setShow(true);
    setRoleId(id);
  };

  const getIcon = (roleLevel) => {
    switch (roleLevel) {
      case 9:
        return "faUserAstronaut";
      case 5:
        return "faUserTie";
      case 1:
        return "faUserCheck";
      default:
        return "faPeopleGroup";
    }
  };

  return (
    <>
      <PageWrapper>
        <Table
          createLink={() => {
            setShow(true);
            setRoleId("create");
          }}
          createButtonText="Create Role"
          createButtonIcon="faIdBadge"
          title="Role Management"
          backButtonLink="/Administration"
          gridData={roles.map(({ id, name, description, roleLevel }) => ({
            id,
            title: name,
            subtitle: description,
            iconName: getIcon(roleLevel),
          }))}
          hideProperties
          hidePager
          hideSearch
          isLoading={isLoading || !roleLevel}
          hideToggle
          forceGrid
          onCardClick={(role) => onCardClick(role.id)}
        />
      </PageWrapper>
      <Modal show={show} setShow={setShow}>
        {show && (
          <RoleSettingsForm
            role={role}
            roleId={roleId}
            setRoleId={setRoleId}
            show={show}
            setShow={setShow}
            refetchQuery={refetchQuery}
          />
        )}
      </Modal>
    </>
  );
};

export default RolesPage;
