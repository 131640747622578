import React, { useContext, useMemo } from "react";
import { Body } from "../Text";
import { flexRender } from "@tanstack/react-table";
import {
  StyledRow,
  StyledCollapsible,
  DetailRow,
  StyledCheckbox,
  RowWrap,
} from "./styles-table.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { RowDisplayContext } from "./TableContext";
import { Checkbox } from "../forms";
import { Flex } from "reflexbox";
import TableDescriptor from "./TableDescriptor";

const TableRow = React.memo(
  ({ row, headersRef, count, index, open = false }) => {
    const {
      dropdown,
      visibleIndices,
      dropdownIndices,
      dropdownRow,
      onRowClick,
      clickable,
      selections,
      popout,
    } = useContext(RowDisplayContext);
    const dropdownSelected = open || dropdownRow === row.id;
    const [checkbox, checked, onCheckboxClick] = useMemo(() => {
      const _checkbox = selections !== null;
      const _checked =
        _checkbox && selections.actions.getIndex(row.original) !== -1;
      const _onCheckboxClick = (e) => {
        e.stopPropagation();
        if (_checked) {
          selections.actions.deselect(row.original);
        } else {
          selections.actions.select(row.original);
        }
      };
      return [_checkbox, _checked, _onCheckboxClick];
    }, [selections, row]);

    const cells = row.getAllCells();

    return (
      <RowWrap index={index} count={count}>
        <StyledRow
          expanded={dropdownSelected}
          onClick={(e) => onRowClick(row, e)}
          dropdown={dropdown}
          rowClickable={clickable}
          checkbox={checkbox}
          popout={popout}
        >
          <div style={{ position: "relative" }}>
            {checkbox && (
              <StyledCheckbox>
                <Checkbox
                  noLabel
                  type="checkbox"
                  onClick={onCheckboxClick}
                  onChange={(_) => {}}
                  as="input"
                  checked={checked}
                />
              </StyledCheckbox>
            )}
            {visibleIndices.map((index) => (
              <TableDescriptor
                key={cells[index].column.columnDef.id}
                cell={cells[index]}
                selected={dropdownSelected}
              />
            ))}
            {dropdown ? (
              <StyledCollapsible>
                {dropdownSelected ? (
                  <FontAwesomeIcon icon={faChevronUp} size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} size="sm" />
                )}
              </StyledCollapsible>
            ) : null}
          </div>
        </StyledRow>
        {dropdownSelected && dropdownIndices && (
          <DetailRow
            expanded={open || dropdownSelected}
            count={dropdownIndices}
          >
            {dropdownIndices.map((index, i) => {
              let header = headersRef.current[index],
                headerContent = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    );
              let cell = cells[index],
                id = cell.column.columnDef.id,
                cellContent = flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                );

              if (headerContent === null)
                return (
                  <Flex key={id + "container"}>
                    {typeof cellContent === "string" ? (
                      <Body>{cellContent}</Body>
                    ) : (
                      cellContent
                    )}
                  </Flex>
                );
              return (
                <Flex key={id + "container"}>
                  {typeof headerContent === "string" ? (
                    <Body bold>{headerContent}</Body>
                  ) : (
                    headerContent
                  )}
                  {typeof cellContent === "string" ? (
                    <Body>{cellContent}</Body>
                  ) : (
                    <Body key={id + "cell"}>{cellContent}</Body>
                  )}
                </Flex>
              );
            })}
          </DetailRow>
        )}
      </RowWrap>
    );
  }
);

export default TableRow;
