import { createContext } from "react";

const ConfiguratorContext = createContext({
  activeProduct: null,
  setActiveProduct: () => {},
  quote: null,
  setQuote: () => {},
  isReconfigure: false,
  setIsReconfigure: () => {},
  api: null,
  setApi: () => {},
  sessionId: false,
  setSessionId: () => {},
  headerId: false,
  setHeaderId: () => {},
  autoContinueMode: null,
  setACMode: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  continueClicked: false,
  setContinueClicked: () => {},
});

export default ConfiguratorContext;
