import axios from "axios";

export const login = async ({
  username,
  password,
  stayLoggedIn,
  fromSalesforce,
}) => {
  try {
    const { status, data } = await axios.post("/api/v1/auth/login", {
      username,
      password,
      stayLoggedIn,
      fromSalesforce,
    });
    if (status !== 200) {
      console.error(data);
      return data;
    }
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const authorize = async ({ token, username, secret }) => {
  try {
    const { data } = await axios.post("/api/v1/auth/authorize", {
      token,
      username,
      secret,
    });

    return data.result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getSecret = async () => {
  try {
    const { data, status } = await axios.post("/api/v1/auth/getSecret", {});
    const { result: secret } = data || {};
    if (status !== 200) throw new Error("Cannot generate secret.");
    return secret;
  } catch (error) {
    console.error(error);
    return error;
  }
};
