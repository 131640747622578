import React from "react";
import { StyledRow, StyledDescriptor, RowWrap } from "./styles-table.jsx";
import Shimmer from "../Shimmer";
import { useContext, useMemo } from "react";
import { RowDisplayContext, ColumnDisplayContext } from "./TableContext.jsx";

/**
 * See the README.md for detailed documentation
 */

const Table = ({ instance }) => {
  const { visibleIndices } = useContext(RowDisplayContext);
  const { relativeSizes } = useContext(ColumnDisplayContext);
  const columns = instance.getAllColumns();
  const rowContent = useMemo(
    () => (
      <StyledRow>
        {visibleIndices.map((index) => {
          let id = columns[index].columnDef.id;
          let size = relativeSizes[id];
          return (
            <StyledDescriptor key={id} size={size}>
              <Shimmer style={{ margin: "0px" }}>&nbsp;</Shimmer>
            </StyledDescriptor>
          );
        })}
      </StyledRow>
    ),
    [visibleIndices, relativeSizes, columns]
  );
  return (
    <>
      {[...Array(12).keys()].map((i) => (
        <RowWrap key={i}>{rowContent}</RowWrap>
      ))}
    </>
  );
};
export default Table;
