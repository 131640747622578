import React, { useCallback, useContext } from "react";
import _ from "lodash";
import * as yup from "yup";
import HeadingSection from "../UI/HeadingSection";
import { Form, Formik } from "formik";
import { Flex, Box } from "reflexbox";
import { Field, FormButtonCluster } from "../UI/forms";
import * as r from "../../requests/customerManagementRequests";
import Card from "../UI/Card";
import AlertContext from "../../context/alert/AlertContext";
import AddressForm from "./AddressForm";
import {
  createAddress,
  updateAddress,
} from "../../requests/addressManagementRequests";

const validationSchema = yup.object().shape({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  email: yup.string().email().nullable(),
  phone: yup.string().nullable(),
  pid: yup.string().nullable(),
  erpId: yup.string().required("ERP ID is a required field."),
  companyName: yup.string().nullable(),
});

const CustomerForm = ({
  data,
  count,
  contactId,
  currentContact,
  platforms = [],
  platformCount = 0,
  setShow,
  setData,
  refetchCustomers,
}) => {
  const { showSuccessAlert } = useContext(AlertContext);

  const {
    firstName,
    lastName,
    email,
    phone,
    pid,
    erpId,
    billingAddress,
    companyName,
  } = currentContact || {};

  const { Address, Address2, City, State, Zip } = billingAddress || {};

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    phone: phone || "",
    pid: pid || "default",
    erpId: erpId || "",
    Address: Address || "",
    Address2: Address2 || "",
    City: City || "",
    State: State || "",
    Zip: Zip || "",
    companyName: companyName || null,
  };

  const onSubmit = useCallback(
    async (_values, { resetForm }) => {
      try {
        const {
          pid: _pid,
          Address,
          Address2,
          City,
          State,
          Zip,
          companyName,
          ...rest
        } = _values;
        const values = {
          ...rest,
          pid: _pid === "default" ? null : _pid,
          companyName,
        };

        const { rows } = data;

        if (Address || Address2 || City || State || Zip) {
          if (!billingAddress?.id) {
            await createAddress({
              input: {
                Address,
                Address2,
                City,
                State,
                Zip,
                customerId: contactId,
              },
            });
          } else {
            await updateAddress({
              id: billingAddress.id,
              input: {
                Address,
                Address2,
                City,
                State,
                Zip,
                customerId: contactId,
              },
            });
          }
        }

        if (contactId === "create") {
          const result = await r.createCustomer({ input: values });
          const newObj = {
            ...result,
            visible: _values.visible,
          };

          const newRows = _.sortBy([...rows, newObj], ["displayOrder"]);

          const newData = { rows: newRows, count: count + 1 };

          showSuccessAlert(
            `Successfully created Customer: ${values.name || contactId}`
          );
          setData(newData);
          setShow(false);
        } else {
          const updatedRecord = await r.updateCustomer({
            id: contactId,
            input: values,
          });

          showSuccessAlert(
            `Successfully updated Customer: ${values.name || contactId}`
          );

          const newRows = _.sortBy(
            rows.map((row) => {
              const { id: rowId } = row;
              if (rowId === contactId) {
                return updatedRecord;
              }
              return row;
            }),
            ["displayOrder"]
          );

          const newData = { ...data, rows: newRows };
          setData(newData);
          resetForm();
          setShow(false);
          refetchCustomers();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      data,
      contactId,
      billingAddress,
      count,
      showSuccessAlert,
      setData,
      setShow,
      refetchCustomers,
    ]
  );

  const onDelete = useCallback(() => {
    r.deleteCustomer({ id: contactId });
    const { rows } = data;
    const newRows = [...rows];
    _.remove(newRows, (row) => row.id === contactId);
    setData({ rows: newRows, count: count - 1 });
    setShow(false);
  }, [contactId, count, data, setData, setShow]);

  return (
    <Card>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formProps) => {
          const { values, setFieldValue } = formProps;
          return (
            <>
              <HeadingSection
                my="1rem"
                hideCreate
                hideBack
                backButtonLink={() => setShow(false)}
                title={`${contactId === "create" ? "Create" : "Edit"} Customer`}
              />
              <Form>
                <Flex
                  style={{ gap: "1rem" }}
                  flexDirection={["column", "column", "row"]}
                >
                  <Box flex={1}>
                    <Field name="companyName" labelText="Company Name" />

                    <Field
                      autoFocus={contactId === "create"}
                      labelText="First Name"
                      name="firstName"
                    />
                    <Field labelText="Last Name" name="lastName" />
                    <Field
                      value={values.phone}
                      onChange={(value) => setFieldValue("phone", value)}
                      phone
                      name="phone"
                      labelText="Phone Number"
                    />
                    <Field name="email" labelText="Email Address" />
                    <Field required name="erpId" labelText="ERP ID" />

                    <Field
                      name="pid"
                      as="select"
                      labelText="Assigned Platform"
                      description="Setting this value will assign this Customer to a platform, allowing only users assigned to that platform to view it."
                      isLoading={platformCount === 0}
                    >
                      <option
                        key="default"
                        value="default"
                        label="Select a Value"
                      />
                      {platforms.map((p) => (
                        <option key={p.id} label={p.name} value={p.id} />
                      ))}
                    </Field>
                  </Box>
                  <Box flex={1}>
                    <AddressForm
                      count={count}
                      addressId={billingAddress?.id}
                      // setAddressId={setAddressId}
                      setShow={setShow}
                      setData={setData}
                      hasForm={false}
                    />
                  </Box>
                </Flex>

                <FormButtonCluster
                  onDelete={onDelete}
                  onCancel={() => setShow(false)}
                  hideDelete={contactId === "create"}
                  recordAction={contactId === "create" ? "create" : "edit"}
                  deleteMessage={`Are you sure you want to delete ${values.name}?`}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CustomerForm;
