import React, { useContext } from "react";
import { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import LineTable from "./LineTable";
import { Heading, Body } from "../../UI/Text";
import formatter from "../../../modules/formatter";
import moment from "moment";
import _ from "lodash";
import ConfiguratorContext from "../../../context/configurator/ConfiguratorContext";

export const CustomerInfo = ({ poNumber }) => {
  const { quote } = useContext(ConfiguratorContext);
  const { shippingAddress, billingAddress, contact } = quote || {};
  const addresses = [billingAddress, shippingAddress];
  const { sameAsShipping } = billingAddress || {};
  const { firstName, lastName, email, phone } = contact || {};
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "";
  }

  return (
    <Flex width={1} alignItems="flex-start" flexWrap="wrap">
      {contact ? (
        <Flex
          className="print-col"
          width={[1, 1, 0.5, sameAsShipping ? 0.5 : 0.33]}
          pr="0.5rem"
          flexDirection="column"
        >
          <Heading>Customer</Heading>
          {firstName && (
            <Flex width={1} alignItems="center" justifyContent="space-between">
              <Body width="33.3333%" bold>
                First Name:
              </Body>
              <Body width="66.6666%">{firstName}</Body>
            </Flex>
          )}
          {lastName && (
            <Flex width={1} alignItems="center" justifyContent="space-between">
              <Body width="33.3333%" bold>
                Last Name:
              </Body>
              <Body width="66.6666%">{lastName}</Body>
            </Flex>
          )}
          {email && (
            <Flex width={1} alignItems="center" justifyContent="space-between">
              <Body width="33.3333%" bold>
                Email Address:
              </Body>
              <Body width="66.6666%">{email}</Body>
            </Flex>
          )}
          {phone && (
            <Flex width={1} alignItems="center" justifyContent="space-between">
              <Body width="33.3333%" bold>
                Phone Number:
              </Body>
              <Body width="66.6666%">{formatPhoneNumber(phone)}</Body>
            </Flex>
          )}
          {/* WM MOD Removed PO Number */}
          {/* {poNumber && (
            <Flex width={1} alignItems="center" justifyContent="flex-start">
              <Body width="33.3333%" bold>
                PO Number:
              </Body>
              <Body width="66.6666%">{poNumber}</Body>
            </Flex>
          )} */}
        </Flex>
      ) : null}

      {addresses.map((address, i) => {
        const { Address, Address2, City, State, Zip, id } = address || {};

        if (i === 1 && sameAsShipping) return null;
        const headers = ["Billing Address", "Shipping Address"];

        return (
          <Flex
            className="print-col"
            key={id + "|address" + i}
            width={[1, 1, 0.5, sameAsShipping ? 0.5 : 0.33]}
            pl="0.5rem"
            flexDirection="column"
          >
            <Heading>{headers[i]}</Heading>
            {Address && (
              <Flex
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Body width="33.3333%" bold>
                  Address Line 1:
                </Body>
                <Body width="66.6666%">{Address}</Body>
              </Flex>
            )}
            {Address2 && (
              <Flex
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Body width="33.3333%" bold>
                  Address Line 2:
                </Body>
                <Body width="66.6666%">{Address2}</Body>
              </Flex>
            )}
            {City && (
              <Flex
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Body width="33.3333%" bold>
                  City:
                </Body>
                <Body width="66.6666%">{City}</Body>
              </Flex>
            )}
            {State && (
              <Flex
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Body width="33.3333%" bold>
                  State:
                </Body>
                <Body width="66.6666%">{State}</Body>
              </Flex>
            )}
            {Zip && (
              <Flex
                width={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Body width="33.3333%" bold>
                  Zip:
                </Body>
                <Body width="66.6666%">{Zip}</Body>
              </Flex>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export const CommunicationList = ({ communications }) => {
  if (!communications || !_.isArray(communications)) return null;

  return (
    <Flex width={1} flexDirection="column">
      {communications.map((item, i) => {
        const { id, comment, date, username } = item || {};
        return (
          <Flex
            key={id}
            width={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Body ellipsis>
              {`${moment(date).format("MM/DD/YYYY")}`}
              {" – "}
              {comment}
              {i === 0 ? " by " : " – "}
              {username}
            </Body>
          </Flex>
        );
      })}
    </Flex>
  );
};

const PrintQuote = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    lines = [],
    lineCount: count,
    communications,
    lineLoading,
  } = props || {};

  const { quote } = useContext(ConfiguratorContext);

  const {
    id: quoteId,
    totalCost,
    poNumber,
    type,
    billingAddress,
  } = quote || {};

  const { sameAsShipping } = billingAddress || {};

  return (
    <>
      <style type="text/css" media="print">
        {`
          .no-print { 
            display: none; 
          }
          
          @media print {
            .print-col {
              margin-bottom: -3rem;
              width: ${sameAsShipping ? "50%" : "33%"} !important;
            }
            .printquote {
              display: block;
            }
            html,
            body {
              padding: 1rem;
              height: 99vh;
            }
          }
        `}
      </style>
      <style type="text/css">
        {`
          .no-summary {
            display: none;
          }
          .print-col {
            margin-bottom: 1rem;
          }
          @media print {
            .printquote {
              display: block;
            }
            .no-summary {
              display: block;
            }
            html,
            body {
              height: 99vh;
            }
          }
        `}
      </style>

      {/* remove total cost and quote header on summary page */}
      <div
        className="printquote no-summary"
        ref={ref}
        style={{ width: "100%", height: "auto" }}
      >
        <Flex width={1} flexDirection="column" alignItems="flex-start">
          <Flex width={1} justifyContent="space-between" alignItems="center">
            <img
              width="150px"
              className="no-summary"
              src={theme.companyLogo}
              alt="SwiftSell"
            />
            <Flex flexDirection="column">
              <Heading right className="no-summary">
                {`${["Quote", "Order", "Quote Request"][type - 1]} #${quoteId}`}
              </Heading>

              <Body right bold>
                Total: ${formatter.currency(totalCost)}
              </Body>
            </Flex>
          </Flex>

          <CustomerInfo poNumber={poNumber} />

          <LineTable
            lineLoading={lineLoading}
            quote={quote}
            lines={lines}
            open
            lineCount={count}
            quoteId={quoteId}
            printQuote
          />

          <CommunicationList communications={communications} />
        </Flex>
      </div>
    </>
  );
});

export default PrintQuote;
