import { Box, Flex } from "reflexbox/styled-components";
import styled from "styled-components";
import { Body } from "../Text";

export const LeftArrow = styled(Box)`
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
  border-left: 3px solid ${({ theme }) => theme?.primary || "gray"};
  border-bottom: 3px solid ${({ theme }) => theme?.primary || "gray"};
  transition: all ease-in-out 300ms;
  &:hover {
    transform: scale(1.2) rotate(45deg);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.75) rotate(45deg);
  }
`;

export const Wrapper = styled(Flex)`
  width: 100%;

  @media (max-width: 600px) {
    button {
      &:last-of-type {
        display: none;
      }
      &:first-of-type {
        display: none;
      }
    }
  }
`;

export const RightArrow = styled(LeftArrow)`
  border-left: none;
  border-bottom: none;
  border-top: 3px solid ${({ theme }) => theme?.callToActionColor || "gray"};
  border-right: 3px solid ${({ theme }) => theme?.callToActionColor || "gray"};
`;

export const PageNumber = styled(Body)`
  padding: 0.5rem;
  margin: 0 0.5rem;
  transition: all ease-in-out 200ms;
  border-radius: ${({ theme }) => theme?.borderRadius};
  &:hover {
    background-color: ${({ theme }) => theme?.primary};
    color: white;
    cursor: pointer;
  }
  ${({ theme, selected }) =>
    !selected
      ? {
          color: theme?.primary,
          backgroundColor: "transparent",
        }
      : {
          color: "white",
          backgroundColor: theme?.primary,
        }};
`;

export const PagerWrapper = styled(Flex)`
  justify-content: space-around;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
`;

export const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
  min-width: 120px;
  border: 2px solid ${({ theme }) => theme?.primary || "gray"};
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};
  overflow: hidden;
`;

export const DropdownItem = styled(Box)`
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  border-bottom: ${({ theme }) => theme?.primary || "gray"};
  color: ${({ theme }) => theme?.primary || "gray"};
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    ${({ theme }) => ({
      backgroundColor: theme?.primary,
      color: "white",
      cursor: "pointer",
    })};
  }
`;
