import React, { useState, useRef, useCallback } from "react";
import { useMemo, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useQuery } from "../Hooks";
import styled, { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import { Heading, Body } from "../UI/Text";
import { PageWrapper } from "../UI/Grid";
import Button from "../UI/Button";
import Lines from "./Lines";
import Details from "./Details";
import Customer from "./Customer";
import Attachments from "./Attachments";
import Communications from "./Communications";
import Summary from "./Summary";
import formatter from "../../modules/formatter";
import * as constants from "../../modules/constants";
import AlertContext from "../../context/alert/AlertContext";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import { GenerateGUID } from "../../modules/guid";
import * as qRs from "../../requests/quoteManagementRequests";
import { getCustomer } from "../../requests/customerManagementRequests";

const { getQuote, getCommunications } = qRs;

const Stages = styled(Flex)`
  @media (max-width: 600px) {
    .hide {
      display: none;
    }
  }
`;

const quoteTypes = ["Quote", "Order", "Quote Request"];

const Quote = () => {
  const { quoteId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const { setQuote, setConfiguratorMode } = useContext(ConfiguratorContext);
  const { showFailureAlert } = useContext(AlertContext);

  const [preview, setPreview] = useState(false);

  // Get Quote
  const quoteResponse = useQuery({
    queryID: `quote|${quoteId}`,
    skip: !quoteId,
    request: getQuote,
    forcedFilters: {},
    queryVariables: { id: quoteId },
  });
  const {
    data: quote,
    isLoading,
    refetchQuery: refetchQuote,
  } = quoteResponse || {};

  // Get Communications
  const commsResponse = useQuery({
    queryID: `comms|${quoteId}`,
    skip: isLoading || !quote || !quoteId,
    request: getCommunications,
    queryVariables: { quoteId },
    forcedFilters: {},
  });
  const {
    data: communications = [],
    setData: setCommunications,
    isLoading: commsLoading,
  } = commsResponse || {};

  // Get Customer
  const customerResponse = useQuery({
    queryID: `contact|${quoteId}`,
    skip: isLoading || commsLoading || !communications || !quote || !quoteId,
    request: getCustomer,
    forcedFilters: {},
    queryVariables: { quoteId: +quoteId },
  });
  const {
    data: customerData,
    setData: setCustomerData,
    isLoading: customerLoading,
    refetchQuery: refetchCustomer,
  } = customerResponse || {};
  const { customer, shippingAddresses, billingAddresses } = customerData || {};

  const loading = useMemo(() => {
    if (!isLoading && !commsLoading && !customerLoading) return false;
    else return true;
  }, [commsLoading, customerLoading, isLoading]);

  const { lines } = quote || {};
  const lineCount = useMemo(() => quote?.lines?.length || 0, [quote]);

  const printRef = useRef();

  const [stage, setStage] = useState(
    +window.sessionStorage.getItem(`quoteId:${quoteId}`) || 0
  );

  useEffect(() => {
    refetchQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!quote) return;
    else {
      if (quote.type === 2) setStage(0);
    }
  }, [quote, quoteId]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const onPrintClick = () => {
    handlePrint();
  };

  useEffect(() => {
    if (!isLoading && quote)
      setQuote({
        ...quote,
        billingAddresses,
        shippingAddresses,
        lineCount,
      });
  }, [
    setQuote,
    billingAddresses,
    shippingAddresses,
    lineCount,
    isLoading,
    refetchQuote,
    quote,
  ]);

  const commonProps = useMemo(
    () => ({
      quote,
      setQuote,
      isLoading: loading,
      quoteId: quoteId || quote?.id,
      lines,
      lineCount,
      refetchQuote,
      quoteType: quote?.type,
    }),
    [lineCount, lines, loading, quote, refetchQuote, setQuote, quoteId]
  );

  const stages = useMemo(
    () =>
      quote?.type !== 2
        ? [
            {
              id: "Lines",
              component: <Lines quoteLoading={isLoading} {...commonProps} />,
            },
            {
              id: "Details",
              component: <Details {...commonProps} />,
            },
            {
              id: "Customer",
              component: (
                <Customer
                  {...commonProps}
                  customer={customer}
                  billingAddresses={billingAddresses}
                  shippingAddresses={shippingAddresses}
                  setCustomerData={setCustomerData}
                  customerLoading={customerLoading}
                  refetchCustomer={refetchCustomer}
                />
              ),
            },

            {
              id: "Summary",
              component: (
                <Summary
                  {...commonProps}
                  lineLoading={isLoading}
                  printRef={printRef}
                  contact={customer || {}}
                  preview={preview}
                  setPreview={setPreview}
                  communications={communications}
                />
              ),
            },
          ]
        : [
            {
              id: "Summary",
              component: (
                <Summary
                  {...commonProps}
                  lineLoading={isLoading}
                  printRef={printRef}
                  contact={customer || {}}
                  preview={preview}
                  setPreview={setPreview}
                  communications={communications}
                />
              ),
            },
          ],
    [
      quote,
      isLoading,
      commonProps,
      customer,
      billingAddresses,
      shippingAddresses,
      setCustomerData,
      customerLoading,
      refetchCustomer,
      preview,
      communications,
    ]
  );

  const onSetStage = useCallback(
    (_stage) => {
      setStage(_stage);
      window.sessionStorage.setItem(`quoteId:${quoteId}`, _stage);
    },
    [quoteId]
  );

  const onViewClick = useCallback(() => {
    var docPath = quote?.currentPrintedDocument;

    if (!docPath) return;
    // eslint-disable-next-line no-useless-escape
    var filename = docPath.replace(/^.*[\\\/]/, "");

    qRs.downloadFile(docPath, filename, "application/pdf", filename);
  }, [quote]);

  // Run a ruleset that generates a document, find the link to this ruleset = pricing doc

  const onCreateQuoteClick = useCallback(() => {
    const { contact } = quote || {};

    if (!quote?.contact?.firstName || !quote?.contact?.lastName) {
      setStage(1);
      return showFailureAlert("Please add a contact first and last name.");
    }

    if (!lines || lines?.length === 0)
      return showFailureAlert("Please add a line.");
    if (quote?.isLocked)
      return showFailureAlert("Quote is locked, no action can be taken.");

    const reviewProduct = { ID: 31 };
    const { billingAddress, shippingAddress } = quote || {};
    setConfiguratorMode(constants.CONFIGURATOR_MODES.Review);
    // add setIsReconfigure

    setQuote(
      {
        ...quote,
        lines,
        billingAddress,
        shippingAddress,
        contact,
        lineCount,
      },
      [quote]
    );

    refetchQuote();

    var configGuid = quote?.quoteGenConfigId
      ? quote?.quoteGenConfigId
      : GenerateGUID();

    navigate(`/Configurator/${reviewProduct.ID}/${quote?.id}/${configGuid}`);
  }, [
    lineCount,
    lines,
    navigate,
    quote,
    refetchQuote,
    setConfiguratorMode,
    setQuote,
    showFailureAlert,
  ]);

  const onCreateOrderClick = useCallback(async () => {
    if (!lines || lines?.length === 0)
      return showFailureAlert("Please add a line.");
    if (quote?.isLocked)
      return showFailureAlert("Quote is locked, no action can be taken.");
    // await qRs.updateQuote({
    //   id: quote.id,
    //   input: { type: 2, isLocked: "1" },
    // });

    const createOrderRuleset = { ID: 33 };
    setConfiguratorMode(constants.CONFIGURATOR_MODES.SOWriteup);

    var configGuid = quote?.soWriteUpConfigId
      ? quote?.soWriteUpConfigId
      : GenerateGUID();

    navigate(
      `/Configurator/${createOrderRuleset.ID}/${quote?.id}/${configGuid}`
    );
  }, [lines, navigate, quote, setConfiguratorMode, showFailureAlert]);

  return (
    <PageWrapper className="no-print">
      <Heading mb="0rem !important" center>
        {quoteTypes[quote?.type - 1]}
        {` #${quote?.sfQuoteNumber}`}
        {quote?.type === 2 ? ` — ${quote?.csiStatus || "Ordered"}` : ""}
      </Heading>

      <Flex
        width={[0.5, 0.5, 0.33, 0.2]}
        m="0 auto"
        alignItems="center"
        justifyContent="center"
      >
        <Body center>Total: ${formatter.currency(quote?.totalCost)}</Body>
      </Flex>
      {quote?.type === 2 ? (
        <Flex
          alignItems="center"
          style={{ zIndex: 1, position: "relative" }}
          mt="-3rem"
          justifyContent="space-between"
          width={1}
        >
          <Button
            color={theme.gray}
            size="tiny"
            reverse
            mr="1rem"
            iconName="faArrowLeft"
            onClick={() => navigate(-1)}
          />
          <Flex
            alignItems="flex-end"
            style={{ gap: "1rem" }}
            flexDirection={["column", "column", "column", "row"]}
          >
            <Button
              m="0"
              color={theme.gray}
              iconName="faPrint"
              reverse
              onClick={() => onPrintClick()}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex
          flex={1}
          alignItems="center"
          flexDirection={["column", "column", "column", "row"]}
          justifyContent="space-between"
        >
          <Flex flex={[0, 0, 0, 1]} />
          <Flex my="1rem" justifyContent="center" alignItems="center">
            <Button
              color={theme.secondary}
              reverse
              mr="1rem"
              style={{
                opacity: !stage ? 0 : 1,
                cursor: !stage ? "auto" : "pointer",
                pointerEvents: !stage ? "none" : "",
              }}
              iconName="faAngleLeft"
              onClick={() => {
                if (stage - 1 === -1) return null;
                return onSetStage(stage - 1);
              }}
            />

            <Stages>
              {stages.map(({ id }, i) => (
                <Button
                  className={stage !== i ? "hide" : undefined}
                  color={theme.secondary}
                  reverse={stage !== i}
                  key={id}
                  onClick={() => onSetStage(i)}
                >
                  {id}
                </Button>
              ))}
            </Stages>

            <Button
              color={theme.secondary}
              reverse
              ml="1rem"
              style={{
                opacity:
                  (stage === stages.length - 1 || !stages.length) &&
                  stages.length !== 1
                    ? 0
                    : 1,
                cursor:
                  (stage === stages.length - 1 || !stages.length) &&
                  stages.length !== 1
                    ? "auto"
                    : "pointer",
                pointerEvents:
                  (stage === stages.length - 1 || !stages.length) &&
                  stages.length !== 1
                    ? "none"
                    : "",
              }}
              iconName="faAngleRight"
              onClick={() => {
                if (stage + 1 === stages.length) return null;
                return onSetStage(stage + 1);
              }}
            />
          </Flex>

          <Flex
            width={1}
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
          >
            <Button
              color={theme.gray}
              style={{
                opacity: +(stages?.[stage]?.id === "Summary"),
                cursor: stages?.[stage]?.id === "Summary" ? "pointer" : "auto",
                pointerEvents: stages?.[stage]?.id === "Summary" ? "" : "none",
              }}
              iconName="faPrint"
              reverse
              onClick={() => onPrintClick()}
            />
            {(quote?.currentPrintedDocument || quote?.type === 2) && (
              <>
                <Button
                  iconName="faEye"
                  iconPosition="right"
                  onClick={() => onViewClick()}
                >
                  View
                </Button>
              </>
            )}

            {quote?.type === 1 &&
              !(
                quote?.currentPrintedDocument && stages[stage].id === "Summary"
              ) && (
                <Button
                  iconName="faFileInvoice"
                  iconPosition="right"
                  disabled={quote?.isLocked}
                  onClick={() => onCreateQuoteClick()}
                >
                  {quote?.currentPrintedDocument
                    ? "Update Quote"
                    : "Create Quote"}
                </Button>
              )}
            {quote?.currentPrintedDocument &&
              quote?.type === 1 &&
              stages[stage].id === "Summary" && (
                <Button
                  iconName="faFileSignature"
                  iconPosition="right"
                  disabled={quote?.isLocked}
                  onClick={() => onCreateOrderClick()}
                >
                  {quote?.soWriteUpConfigId ? "Update Order" : "Create Order"}
                </Button>
              )}
          </Flex>
        </Flex>
      )}

      {stages?.[stage]?.component}

      {stage === 0 && (
        <Flex
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
          alignItems="flex-start"
          width={1}
          style={{ gap: "1rem" }}
        >
          <Communications
            communications={communications}
            setData={setCommunications}
          />
          {/* <Attachments
            readonly={quote?.type === 2}
            attachmentType={quote?.type === 2 ? 2 : 1}
          /> */}
        </Flex>
      )}
    </PageWrapper>
  );
};

export default Quote;
