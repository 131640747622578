import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";

export const CheckboxWrapper = styled.div`
  align-items: center;
  input {
    margin-bottom: -0.25rem;
    margin-right: 0.5rem;
    transform: translateY(-15%);
    &:hover {
      border-color: ${({ theme }) => theme?.activeStageColor};
    }
    &.one::after {
      content: "";
      display: block;
      width: 6px;
      height: 11px;
      margin-top: -4px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
    &.two::after {
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: translateY(-2px) rotate(45deg);
    }

    &:checked {
      &::after {
        display: block;
      }
    }
    &.one:checked {
      background-color: ${({ theme }) => theme?.activeStageColor};
    }
    &.two:checked {
      background-color: ${({ theme }) => theme?.accentColor};
    }
    &:indeterminate {
      background-color: ${({ theme }) => theme?.activeStageColor};
      &::after {
        display: block;
        width: 8px;
        height: 3px;
        margin-top: -2px;
        border: solid white;
        border-width: 0 0 3px 0;
        transform: rotate(0);
      }
    }
    &:disabled {
      background-color: #dedede;
      cursor: not-allowed;
    }
  }
`;

export const CheckboxContainer = styled(Flex)`
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  border: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};

  .table-properties {
    padding: 1rem;
    border-top: 2px solid ${({ theme }) => theme.borderColor};
    transition: 200ms all ease-in-out;
    gap: 1rem;
    height: 100%;
    ${({ open }) => {
      if (!open) return "height: 0px; opacity: 0; padding: 0; margin: 0;";
    }}
    svg {
      margin-top: 0.25rem;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 200ms all ease-in-out;
      transform-origin: center;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TableWrapper = styled(Box)`
  border: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};
  width: 100%;
  position: relative;
`;

export const StyledTable = styled(Flex)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowWrap = styled(Flex)`
  flex-direction: column;
  position: relative;
  border-top: 2px solid ${({ theme }) => theme.borderColor};

  &:last-child {
    > div:last-child {
      border-radius: 0 0 15px 15px;
    }
  }
  &:nth-child(even) {
    > div {
      background-color: ${({ theme }) => theme.gridRowBackgroundColor};
    }
  }
  &:nth-child(odd) {
    > div {
      background-color: ${({ theme }) => theme.lightBackground};
    }
  }
`;

export const StyledRow = styled(Box)`
  width: 100%;
  > div:last-child {
    border-radius: 0 0 15px 15px;
    overflow: hidden;
  }

  background-color: inherit;
   &.header {
    font-weight: bold;
    border-bottom: none;
     border-radius: ${({ theme }) =>
       `${theme.borderRadius} ${theme.borderRadius}`} 0 0;
   }
  
  
  cursor: ${({ rowClickable }) => (rowClickable ? "pointer" : "auto")};
  color: ${({ theme }) => theme.textDark}};

  
  display: inline-flex;
  position: relative;
  transition: 200ms all ease-in;
  

  > div {
    ${({ dropdown }) => {
      if (dropdown) return "padding-right: 2rem;";
    }}
    width: 100%;

    display: inline-flex;
    

    align-items: center;
    justify-content: flex-start;
    div {
      button {
        margin: 0 0.5rem !important;
      }

      word-break: inherit;
    }
  }
  #actions {
    opacity: 0;
    flex-wrap: wrap;
    margin: 0 auto;
    button {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  }
  img {
    width: 100%;
  }
  &:hover {

    z-index: 100;
    ${({ expanded, heading, popout, theme }) => {
      if (expanded || heading || !popout) return "";
      return {
        position: "relative",
        zIndex: 2,
        transition: "50ms all ease-out",
        transform: "scale(1.015)",
        transformOrigin: "center",
        boxShadow: "0px 10px 10px 5px " + theme.boxShadowColor,
      };
    }};

    #actions {
      opacity: 1;
    }
  }
  @media (max-width: 800px) {
    #actions {
      opacity: 1 !important;
      z-index:1;
      align-items: center;
      justify-content: center;
      button {
        margin: 0.5rem 0;
      }
    }
  }
  &:active {
    transform: ${({ rowClickable }) => (rowClickable ? "scale(1)" : "")};
    box-shadow: ${({ rowClickable }) => (rowClickable ? "none" : "")};
  }
    
`;

export const StyledDescriptor = styled.div`
  flex: ${({ size }) => `1 1 ${size}%`};
  max-width: ${({ size }) => `${size}%`};
  overflow-x: hidden;
  padding: 0.75rem 1rem;
  color: ${({ theme }) => theme.textDark};
`;

export const StyledCollapsible = styled.div`
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 0.75rem;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const StyledCheckbox = styled.div`
  top: 0px;
  bottom: 0px;
  left: 0px;
  padding: 0.75rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const TableResetContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 100;
  top: 0.5rem;
  height: 48px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms all ease-in-out;
  transform-origin: center;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const AddNewRow = ({ rowProps, ...props }) => (
  <StyledRow {...props} {...rowProps} />
);

export const DetailRow = styled(Box)`
  &:last-of-type {
    border-radius: 0px 0px 15px 15px;
  }
  @keyframes open {
    0% {
      opacity: 0;
      height: 0px;
    }
    99% {
      opacity: 1;
      height: ${({ count }) => (count - 1) * 2}rem;
    }
    100% {
      height: auto;
    }
  }
  @keyframes close {
    0% {
      opacity: 1;
      height: auto;
    }
    1% {
      opacity: 0;
      height: ${({ count }) => (count - 1) * 2}rem;
    }
    100% {
      height: 0px;
    }
  }

  > div > div {
    padding: 0 1rem 0.5rem 1rem;
  }
`;

// > div {
//   ${({ expanded }) => {
//     if (!expanded)
//       return `
//     height: 0px; overflow: hidden; opacity: 0; padding: 0;`;
//     else return "height: auto;";
//   }}
//   animation-name: ${({ expanded }) => (!expanded ? "close" : "open")};
//   animation-duration: 200ms;
//   padding: 0;
// }
