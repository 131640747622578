import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

const type = ["unstyled", "blockquote", "unordered-list-item", "ordered-list-item", "header-one", "header-two", "header-three", "header-four"];
const style = ["BOLD", "ITALIC", "UNDERLINE", "STRIKETHROUGH"];

const keyChars = "0123456789abcdefghijklmnopqrstuvwxyz"
const generateKey = (i, length) => {
  let out = "";
  for (let n = 0; n < length; n++) {
    out += keyChars[i % keyChars.length];
    i = Math.floor(i / keyChars.length); 
  }
  return out;
}

export const plainTextToEditorState = (s, lineDelimiter) => {
  const lines = s.split(lineDelimiter);
  let i = 0;
  const output = {blocks: lines.map(text => ({
    key: generateKey(i++, 5),
    text,
    type: 0, depth: 0, inlineStyleRanges: [], entityRanges: [], data: {}
  })), entityMap:{}}
  return EditorState.createWithContent(convertFromRaw(output));
}

export const editorStateToPlainText = (es, lineDelimiter) => {
  const lines = [];
  convertToRaw(es.getCurrentContent())["blocks"].forEach(entry => {
    lines.push(entry.text);
  });
  return lines.join(lineDelimiter);
}

export const JSONToEditorState = (s) => {
  const output = {blocks: [], entityMap:{}};
  let i = 0;
  JSON.parse(s).forEach(row => {
    const [text, typeIndex, inlineStyleRanges] = row;
    output.blocks.push({key: generateKey(i++, 5), text, type: type[typeIndex], depth: row.length >= 4 ? row[3] : 0, inlineStyleRanges: inlineStyleRanges.map(range => ({offset: range[0], length: range[1], style: style[range[2]]})), entityRanges: [], data: {}});
  });
  return EditorState.createWithContent(convertFromRaw(output));
};

export const editorStateToJSON = (es) => {
  return JSON.stringify(convertToRaw(es.getCurrentContent())["blocks"].map(entry => {
    const line = [entry.text, type.indexOf(entry.type), entry.inlineStyleRanges.map(styleRange => [styleRange.offset, styleRange.length, style.indexOf(styleRange.style)])]
    if (entry.depth !== 0) {
      line.push(entry.depth);
    }
    return line;
  }));
};


const styleTag = ["b", "i", "u", "s"];
const generateLineHTML = (line, styling, lineOffset=0, stylingOffset=0) => {
  if (line.length === 0) {
    return "";
  }
  for (let i = stylingOffset; i < styling.length; i++) {
    const {offset: o, length: l, style: s} = styling[i];
    const offset = Math.max(o-lineOffset,0);
    if (offset >= line.length) {
      break;
    }
    const length =  Math.min(l+Math.min(0,o-lineOffset), line.length - offset);
    if (length <= 0) {
      continue;
    }
    const tag = styleTag[style.indexOf(s)];
    return `${line.substring(0, offset)}<${tag}>${generateLineHTML(line.substr(offset, length), styling, lineOffset + offset,i+1)}</${tag}>${generateLineHTML(line.substring(offset+length), styling, lineOffset + offset + length,i+1)}`
  }
  return line;
}

const olDepth = ["1", "a", "i"];
const typeTag = ["p", "blockquote", "ul", "ol", "h1", "h2", "h3", "h4"];
export const editorStateToHTML = (es) => {
  let output = "";
  const listDepth = [];
  convertToRaw(es.getCurrentContent())["blocks"].forEach(entry => {
    const blockIndex = type.indexOf(entry.type);
    const tag = typeTag[blockIndex];
    entry.inlineStyleRanges.sort((a, b) => a.offset - b.offset);
    if (tag==="ul" || tag==="ol") {
      while (listDepth.length > entry.depth + 1) {
        output += `</${listDepth.pop()}>`;
      }
      if (listDepth.length === entry.depth + 1 && listDepth[listDepth.length - 1] !== tag) {
        output += `</${listDepth.pop()}>`;
      }
      while (listDepth.length < entry.depth + 1) {
        if (tag==="ol") {
          output += `<${tag} type="${olDepth[listDepth.length % olDepth.length]}">`;
        } else {
          output += `<${tag}>`;
        }
        listDepth.push(tag);
      }
      output += `<li>${generateLineHTML(entry.text, entry.inlineStyleRanges)}</li>`;
    } else {
      while (listDepth.length > 0) {
        output += `</${listDepth.pop()}>`;
      }
      output += `<${tag}>${generateLineHTML(entry.text, entry.inlineStyleRanges)}</${tag}>`;
    }
  });
  return output;
};

export const HTMLToEditorState = (html) => {
  const output = {blocks: [], entityMap:{}};
  let blockCount = 0;
  let currentBlock = {}
  let inlineStyleRanges = [];
  let styleStartStack = [];
  let tagStack = [];
  for (let i = 0; i < html.length; i++) {
    const c = html[i];
    if (c === "<") {
      i++;
      const c2 = html[i];
      if (c2 === "/") {
        i++
        const tag = tagStack.pop();
        while (i < html.length && html[i] !== ">") {i++}
        if (tag !== "ol" && tag !== "ul") {
          if (tagStack.length === 0 || tag === "li") {
            let formattedRange = [];
            inlineStyleRanges.forEach((rows, styleIndex) => {
              let s = style[styleIndex];
              for (let j = 0; j < rows.length; j+=2) {
                let left = j;
                while (j+2 < rows.length && rows[j+1]+1===rows[j+2]) {
                  j+=2;
                }
                formattedRange.push({offset: rows[left], length: rows[j+1] - rows[left], style: s});
              }
            });
            currentBlock.inlineStyleRanges = formattedRange;
            output.blocks.push(currentBlock);
          } else {
            const styleIndex = styleTag.indexOf(tag);
            inlineStyleRanges[styleIndex].push(styleStartStack.pop());
            inlineStyleRanges[styleIndex].push(currentBlock.text.length);
          }
        }
      } else {
        let tag = "";
        for (; i < html.length && html[i] !== ">" && html[i] !== " "; i++) {
          tag += html[i];
        }
        while (i < html.length && html[i] !== ">") {i++}
        tagStack.push(tag);
        if (tag !== "ol" && tag !== "ul") {
          if (tagStack.length === 1 || tag === "li") {
            let blockType, depth;
            if (tag === "li") {
              console.log(tagStack[tagStack.length - 2]);
              console.log(typeTag.indexOf(tagStack[tagStack.length - 2]));
              blockType = type[typeTag.indexOf(tagStack[tagStack.length - 2])];
              depth = tagStack.length - 2;
            } else {
              blockType = type[typeTag.indexOf(tag)];
              depth = 0;
            }
            console.log(blockType);
            currentBlock = {key: generateKey(blockCount++, 5), text: "", type: blockType, depth, entityRanges: [], data: {}};
            inlineStyleRanges = style.map(() => []);
          } else {
            styleStartStack.push(currentBlock.text.length);
          }
        }
      }
    } else {
      currentBlock.text += c;
    }
  }
  return EditorState.createWithContent(convertFromRaw(output));
}