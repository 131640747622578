import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../Hooks";
import { getPlatforms } from "../../requests/platformManagementRequests";
import { PageWrapper } from "../UI/Grid";
import Table from "../UI/Table";
import { Body } from "../UI/Text";

const PlatformsPage = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryID: "platforms",
    request: getPlatforms,
    forcedFilters: {},
  });
  const { count = 0, rows = [] } = data || {};

  const onRowClick = (row) => {
    const { id } = row?.original || {};
    navigate(`/Platforms/edit/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        id: "id",
        data: "id",
        header: "Platform ID",
        size: { md: 3, sm: 3, xs: 3 },
      },
      {
        id: "name",
        data: "name",
        header: "Name",
        size: { md: 3, sm: 3, xs: 3 },
      },
      {
        id: "emailAddresses",
        data: (row) => <Body>{row?.emailAddresses}</Body>,
        header: "Email Addresses",
        size: { md: 6, sm: 0, xs: 0 },
      },
    ],
    []
  );

  return (
    <PageWrapper>
      <Table
        createLink="/Platforms/create"
        backButtonLink="/Administration"
        createButtonIcon="faPersonShelter"
        createButtonText="Create Platform"
        title="Platforms"
        subtitle="These settings control application-level preferences for specific clients."
        hidePager
        hideSearch
        hideProperties
        hideToggle
        columns={columns}
        data={rows}
        isLoading={isLoading}
        totalCount={count}
        onRowClick={onRowClick}
      />
    </PageWrapper>
  );
};

export default PlatformsPage;
