import React, { useContext, useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import { Flex } from "reflexbox";
import styled, { useTheme } from "styled-components";
import { color } from "styled-system";
import { Body } from "../UI/Text";
import Card from "../UI/Card";
import AlertContext from "../../context/alert/AlertContext";

const AlertContainer = styled(Flex)`
  z-index: 10000000;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  top: 1rem;
  gap: 1rem;
  pointer-events: none;
  ${color};
`;

const AlertCard = styled(Card)`
  position: relative;
  align-items: flex-start;
  opacity: 0;
  width: 33vw;
  @keyframes slide-in {
    0% {
      opacity: 1;
      right: -36vw;
      height: 100%;
    }
    5% {
      right: 0;
    }
    95% {
      right: 0;
    }
    99% {
      opacity: 1;
      height: 100%;
    }
    100% {
      height: 0px;
      right: -36vw;
      opacity: 0;
    }
  }

  animation-name: slide-in;
  animation-duration: 6s;
`;

const Alerts = () => {
  const theme = useTheme();
  const { alerts = [] } = useContext(AlertContext);

  const alertRef = useRef();

  const removeAlert = useCallback(
    (alertId) => {
      if (alerts?.length > 0) {
        const alertTimer = setTimeout(() => {
          if (alertRef.current.children?.lenght > 0) {
            return alertRef.current.removeChild(
              _.find(alertRef.current.children, (child) => child.id === alertId)
            );
          }
          return;
        }, 6000);
        return () => clearTimeout(alertTimer);
      }
    },
    [alerts]
  );

  useEffect(() => {
    removeAlert();
  }, [alerts.length, removeAlert]);

  return (
    <AlertContainer ref={alertRef}>
      {alerts.map((alert) => (
        <AlertCard key={alert.id} backgroundColor={theme[alert.alertColor]}>
          <Body color="white">{alert?.alertText || ""}</Body>
          <div style={{ display: "none" }}>
            {setTimeout(() => {
              removeAlert(alert.id);
            }, 5999)}
          </div>
        </AlertCard>
      ))}
    </AlertContainer>
  );
};

export default Alerts;
