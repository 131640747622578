import axios from "axios";

export const getPlatform = async ({ signal, id, defaultPlatform }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/platformManagement/getPlatform",
      { signal, id, defaultPlatform }
    );

    if (status === 200 && data.success === true) return data.result;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getPlatforms = async ({ signal }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/platformManagement/getPlatforms",
      { signal }
    );

    if (status === 200 && data.success === true) return data.result;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const createPlatform = async ({ signal, input }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/platformManagement/createPlatform",
      { signal, input }
    );

    if (status === 200 && data.success === true) return data.result;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const updatePlatform = async ({ signal, id, input }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/platformManagement/updatePlatform",
      { signal, id, input }
    );

    if (status === 200 && data.success === true) return data.result;
  } catch (error) {
    console.error(error);
  }
  return null;
};
