import React from "react";
import _ from "lodash";
import { Formik, Form } from "formik";
import Field from "../UI/forms/Field";
import ButtonCluster from "../UI/forms/ButtonCluster";
import { Heading } from "../UI/Text";
import Card from "../UI/Card";
import * as yup from "yup";
import {
  createRole,
  updateRole,
  deleteRole,
} from "../../requests/roleManagementRequests";

const validationSchema = yup.object().shape({
  name: yup.string(),
  description: yup.string(),
  roleLevel: yup.number(),
});

const accessLevels = [
  { label: "Public User", value: 0 },
  { label: "Registered User", value: 1 },
  { label: "System Admin", value: 5 },
  { label: "Super Admin", value: 9 },
];

const RoleSettingsForm = ({
  role,
  setShow,
  roleId,
  setRoleId,
  refetchQuery,
}) => {
  const {
    name: _name,
    description: _description,
    roleLevel: _roleLevel,
  } = role || {};

  const { value } =
    _.find(accessLevels, (level) => level.value === _roleLevel) || {};

  const initialValues = {
    name: _name || "",
    description: _description || "",
    roleLevel: value || 0,
  };

  const onSubmit = async (values, { resetForm }) => {
    const { name, description, roleLevel: level } = values;
    if (roleId === "create")
      await createRole({ input: { name, description, roleLevel: +level } });
    else
      await updateRole({
        id: roleId,
        input: { name, description, roleLevel: +level },
      });
    setShow(false);
    await refetchQuery("roles");
    resetForm();
  };

  const onDelete = async () => {
    await deleteRole({ id: roleId });
    setShow(false);
    refetchQuery("roles");
  };

  const deleteMessage =
    "Warning! Deleting this role will cause the application to break for any users assigned to this role. Verify that no users are assigned to this role before deleting.";

  const onCancel = () => {
    setRoleId(-1);
    setShow(false);
  };

  return (
    <Card>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formProps) => {
          const {
            dirty,
            isSubmitting,
            isValidating,
            values,
            errors,
            submitForm,
          } = formProps;
          return (
            <>
              <Heading>Editing Role: {values.name}</Heading>

              <Form>
                <Field errors={errors} labelText="Role Name" name="name" />
                <Field
                  errors={errors}
                  labelText="Description"
                  name="description"
                />
                <Field
                  component="select"
                  errors={errors}
                  description="This field will set the access level of this role. This will grant the specified level of access to any users assigned to this role."
                  labelText="Role Access Level"
                  name="roleLevel"
                >
                  {accessLevels.map(({ value, label }) => (
                    <option key={value} value={value} label={label} />
                  ))}
                </Field>
                <ButtonCluster
                  dirty={dirty}
                  onCancel={onCancel}
                  onDelete={onDelete}
                  saving={isSubmitting || isValidating}
                  deleteMessage={deleteMessage}
                  values={values}
                  onSubmit={submitForm}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default RoleSettingsForm;
