import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: auto;
  height: auto;
  max-height: 600px;
  max-width: 100%;
  margin: 0 auto auto;
  position: sticky;
  top: 142px;
`;

const ProductModel = ({ image, altText, onError }) => {
  return (
    <StyledImage onError={onError} src={image} alt={altText || "product"} />
  );
};

export default ProductModel;
