import React, { useContext, useMemo, useState, useCallback } from "react";
import { useTheme } from "styled-components";
import _ from "lodash";
import { Flex } from "reflexbox";
import { useQuery } from "../Hooks";
import { PageWrapper } from "../UI/Grid";
import { Body } from "../UI/Text";
import Table from "../UI/Table";
import Modal from "../UI/Modal";
import { getStatusTypes } from "../../requests/statusTypesMgmtReqs";
import UserContext from "../../context/user/UserContext";
import { getRoles } from "../../requests/roleManagementRequests";
import StatusTypeForm from "./StatusTypeForm";
import { GenerateGUID } from "../../modules/guid";

const TF = ({ value }) => {
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    if (["True", "true", true].includes(value)) return theme.success;
    return theme.danger;
  }, [theme.danger, theme.success, value]);

  return (
    <Flex key={GenerateGUID()} width={1} justifyContent="center">
      <div
        style={{
          width: "1rem",
          height: "1rem",
          borderRadius: "50%",
          border: `1px solid ${theme.borderColor}`,
          backgroundColor,
        }}
      />
    </Flex>
  );
};

const StatusTypesPage = () => {
  const { activeUser } = useContext(UserContext);
  const { roleLevel } = activeUser || {};

  const [show, setShow] = useState(false);
  const [typeId, setTypeId] = useState("");

  const { data, isLoading, refetchQuery, setData } = useQuery({
    queryID: "statusTypes",
    request: getStatusTypes,
    queryVariables: { roleLevel },
    skip: !roleLevel,
  });

  const { data: roleData, isLoading: rolesLoading } = useQuery({
    request: getRoles,
    forcedFilters: {},
    queryVariables: { roleLevel: activeUser?.roleLevel },
    skip: isLoading,
  });

  const { rows: _roles = [], count: roleCount } = roleData || {};

  const roles = useMemo(() => {
    return _.sortBy(_roles, ["roleLevel"]);
  }, [_roles]);

  const getIcon = useCallback((_roleLevel) => {
    switch (_roleLevel) {
      case 9:
        return "faUserAstronaut";
      case 5:
        return "faUserTie";
      case 1:
        return "faUserCheck";
      default:
        return "faPeopleGroup";
    }
  }, []);

  const { count = 0, rows: statusTypes = [] } = data || {};

  const currentType = useMemo(
    () => _.find(statusTypes, (__) => __.id === typeId),
    [statusTypes, typeId]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "id",
        data: (d) => (
          <Body center ellipsis>
            {d?.id || "—"}
          </Body>
        ),
        header: "ID",
        size: { md: 1, sm: 0, xs: 0 },
      },
      {
        id: "name",
        data: (d) => (
          <Body center ellipsis>
            {d?.name || "—"}
          </Body>
        ),
        header: "Name",
        size: { md: 3, sm: 2, xs: 2 },
      },
      {
        id: "visible",
        data: (d) => <TF value={!!d?.visible} />,
        header: "Visible",
        size: { md: 1.15, sm: 1.15, xs: 1.15 },
      },
      {
        id: "roleLevel",
        data: (d) => (
          <Body center>
            {_.find(roles, (role) => role.roleLevel === d?.roleLevel)?.name}
          </Body>
        ),
        header: "Role Level",
        size: { md: 3, sm: 3, xs: 3 },
      },
      {
        id: "displayOrder",
        data: (d) => <Body center>{d?.displayOrder}</Body>,
        header: "Display Order",
        size: { md: 3, sm: 3, xs: 3 },
      },
    ];
  }, [roles]);

  const onRowClick = (row) => {
    setTypeId(row.original.id);
    setShow(true);
  };

  return (
    <PageWrapper>
      <Modal show={show} setShow={setShow}>
        <StatusTypeForm
          roles={roles}
          roleCount={roleCount}
          count={count}
          refetchQuery={refetchQuery}
          isLoading={rolesLoading}
          currentType={currentType}
          typeId={typeId}
          data={data}
          setShow={setShow}
          getIcon={getIcon}
          setData={setData}
        />
      </Modal>
      <Table
        title="Status Types"
        data={statusTypes}
        createButtonText="Create Status Type"
        createLink={() => {
          setTypeId("create");
          setShow(true);
        }}
        hideToggle
        hideRefetch={false}
        refetchData={refetchQuery}
        columns={columns}
        onRowClick={onRowClick}
        isLoading={isLoading || rolesLoading}
        totalCount={count}
        backButtonLink="/Administration"
      />
    </PageWrapper>
  );
};

export default StatusTypesPage;
