import React, { useState, useEffect, useContext } from "react";
import { useCallback, useMemo, useRef } from "react";
import { useQuery } from "../Hooks";
import * as quoteReqs from "../../requests/quoteManagementRequests";
import * as constants from "../../modules/constants";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import UserContext from "../../context/user/UserContext";
import { Box, Flex } from "reflexbox";
import { PageWrapper } from "../UI/Grid";
import Loading from "../UI/Loading";
import Form from "./Form";
import { start, finish } from "../../requests/apiRequests";
import ProductModel from "./ProductModel";
import TopBar from "./TopBar";
import guid, { GenerateGUID } from "../../modules/guid";
import { SummaryModal, CreateUserModal, CancelModal } from "./Modals";
import { getProduct } from "../../requests/productManagementRequests";
import { CONFIGURATOR_MODES } from "../../modules/constants";
import _ from "lodash";
import AlertContext from "../../context/alert/AlertContext";

const { SOWriteup, Review, Default } = constants.CONFIGURATOR_MODES;

const Configurator = () => {
  const navigate = useNavigate();
  const {
    createLine,
    createQuote,
    updateLine,
    getCurrentPrintedDocument,
    updateSolidfitLinePricing,
  } = quoteReqs;

  const {
    productId: paramProductId,
    quoteId: paramQuoteId,
    configurationId: paramConfigId,
  } = useParams();

  const [disableFinish, setDisableFinish] = useState(false);

  const configuratorContext = useContext(ConfiguratorContext);
  const {
    activeUser,
    platform,
    loggedIn,
    isLoading: userLoading,
  } = useContext(UserContext);
  const { showFailureAlert, showWarningAlert, showSuccessAlert } =
    useContext(AlertContext);
  const { requireHttps } = platform || {};

  const {
    isReconfigure,
    setIsReconfigure,
    api,
    setApi,
    setCurrentPage,
    sessionId,
    setSessionId,
    setACMode,
    optionToFocus,
    setOptionToFocus,
    setActiveProduct,
    activeProduct,
    configuratorMode,
    setConfiguratorMode,
    // currentPage,
    // autoContinueMode,
  } = configuratorContext;

  const { ConfiguredPrice, ImageUrl, Details = [] } = api?.d || {};

  const [hideImage, setHideImage] = useState(false);

  const [searchParams] = useSearchParams();
  const sourceConfigurationId = searchParams.get("configurationId") || "";
  const sourceHeaderId = searchParams.get("headerId") || "";
  const lineNumber = searchParams.get("lineNumber") || "";
  const LineId = searchParams.get("LineId") || "";

  const productId = useMemo(() => +paramProductId, [paramProductId]);

  const PRODUCT = useQuery({
    queryID: productId,
    request: getProduct,
    queryVariables: { id: productId },
    forcedFilters: {},
    skip:
      !activeUser?.id ||
      !loggedIn ||
      !productId ||
      activeProduct?.ID === productId,
  });

  const {
    data: productData,
    isLoading: productLoading,
    refetchQuery,
  } = PRODUCT || {};
  const { result: product } = productData || {};

  useEffect(() => {
    if (product && !productLoading) setActiveProduct(product);
  }, [setActiveProduct, productLoading, product]);

  useEffect(() => {
    if (product?.Ruleset === "Quote_Gen") {
      console.log("Quote_gen");
      setConfiguratorMode(Review);
    } else if (product?.Ruleset === "SO_WriteUP") {
      setConfiguratorMode(SOWriteup);
    } else {
      setConfiguratorMode(Default);
    }
  }, [product, setConfiguratorMode]);

  const quoteId = useMemo(
    () => (!activeUser ? "new" : paramQuoteId),
    [activeUser, paramQuoteId]
  );
  const configurationId = !activeUser ? GenerateGUID() : paramConfigId;

  const [showCancel, setShowCancel] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const { data: quote, isLoading: quoteLoading } = useQuery({
    request: quoteReqs.getQuoteById,
    queryVariables: { id: paramQuoteId },
    forcedFilters: {},
    skip:
      quoteId === "new" ||
      api?.d?.Pages?.length > 0 ||
      !platform ||
      !activeProduct?.ID ||
      activeProduct?.ID !== productId ||
      (!activeUser?.id && loggedIn) ||
      productLoading,
  });

  useEffect(() => {
    //setApi(null);
    if (sourceConfigurationId === configurationId) {
      setIsReconfigure(true);
      console.log("is reconfig 1");
      return;
    }

    if (
      (configuratorMode === Review && quote?.quoteGenConfigId) ||
      (configuratorMode === SOWriteup && quote?.soWriteUpConfigId)
    ) {
      console.log("is reconfig 2");
      setIsReconfigure(true);
    }
  }, [
    configuratorMode,
    quote,
    setApi,
    setIsReconfigure,
    sourceConfigurationId,
    configurationId,
  ]);

  const intParam = useRef([]);

  const getIntegrationParams = useCallback(() => {
    console.log("start int params");
    if (!quote) setTimeout(() => getIntegrationParams(), 1000);
    let quoteDisplayNumber = `${quote?.id}`;
    let tempStorage = [
      {
        IsNull: true,
        Name: "Fax",
        SimpleValue: null,
        Type: 0,
      },
    ];

    if (configuratorMode !== CONFIGURATOR_MODES.SOWriteup) {
      console.log("int params");
      if (quote?.sfQuoteNumber && quote?.sfQuoteNumber !== "") {
        quoteDisplayNumber = quote?.sfQuoteNumber;
      }
      if (lineNumber) {
        tempStorage.push({
          IsNull: false,
          Name: "LineNumber",
          SimpleValue: +lineNumber,
          Type: 1,
        });
      }
      if (quoteDisplayNumber) {
        tempStorage.push({
          IsNull: !quoteDisplayNumber,
          Name: "QuoteId",
          SimpleValue: quoteDisplayNumber,
          Type: 0,
        });
      }

      tempStorage.push({
        IsNull: false,
        Name: "IsOrder",
        SimpleValue: false,
        Type: 2,
      });
      if (activeUser?.username) {
        tempStorage.push({
          IsNull: false,
          Name: "User_Login",
          SimpleValue: activeUser?.username,
          Type: 0,
        });
      }
      tempStorage.push({
        IsNull: false,
        Name: "co_type",
        SimpleValue: "q",
        Type: 0,
      });
      if (quote?.name) {
        tempStorage.push({
          IsNull: !quote?.name,
          Name: "projectname",
          SimpleValue: quote?.name,
          Type: 0,
        });
      }
      if (quote?.sfCustomer) {
        tempStorage.push({
          IsNull: false,
          Name: "account_name",
          SimpleValue: quote?.sfCustomer,
          Type: 0,
        });
      }
      //if (quote?.shippingAddress?.Address) {
      tempStorage.push({
        IsNull: false,
        Name: "address",
        SimpleValue: quote?.shippingAddress?.Address || "",
        Type: 0,
      });
      //}
      //if (quote?.shippingAddress?.Address2) {
      tempStorage.push({
        IsNull: !quote?.shippingAddress?.Address2,
        Name: "address2",
        SimpleValue: quote?.shippingAddress?.Address2 || "",
        Type: 0,
      });
      //}
      //if (quote?.shippingAddress?.City) {
      tempStorage.push({
        IsNull: false,
        Name: "city",
        SimpleValue: quote?.shippingAddress?.City || "",
        Type: 0,
      });
      //}
      //if (quote?.shippingAddress?.State) {
      tempStorage.push({
        IsNull: false,
        Name: "state",
        SimpleValue: quote?.shippingAddress?.State || "",
        Type: 0,
      });
      //}

      //if (quote?.shippingAddress?.Zip) {
      tempStorage.push({
        IsNull: false,
        Name: "zipcode",
        SimpleValue: quote?.shippingAddress?.Zip || "",
        Type: 0,
      });
      //   }
      tempStorage.push({
        IsNull: false,
        Name: "email",
        SimpleValue: quote?.contact?.email || "N/A",
        Type: 0,
      });
      if (quote?.contact?.firstName) {
        tempStorage.push({
          IsNull: false,
          Name: "first_name",
          SimpleValue: quote?.contact?.firstName || null,
          Type: 0,
        });
      }
      if (quote?.contact?.lastName) {
        tempStorage.push({
          IsNull: false,
          Name: "last_name",
          SimpleValue: quote?.contact?.lastName || null,
          Type: 0,
        });
      }
      tempStorage.push({
        IsNull: false,
        Name: "phone",
        SimpleValue: quote?.contact?.phone
          ? quote?.contact?.phone
          : // ?.replace("(", "")
            // .replace(")", "")
            // .replace(" ", "")
            // .replace(" ", "")
            // .replace("-", "")
            // .replace("-", "")
            "N/A",
        Type: 0,
      });

      if (quote?.contact?.altPhone) {
        tempStorage.push({
          IsNull: false,
          Name: "alt_phone",
          SimpleValue: quote?.contact?.altPhone,
          Type: 0,
        });
      }
      //if (quote?.shippingAddress?.Country) {
      tempStorage.push({
        IsNull: false,
        Name: "Country",
        SimpleValue: quote?.shippingAddress?.Country || "",
        Type: 0,
      });
      //}
      if (quote?.shippingAddress?.CountryCode) {
        tempStorage.push({
          IsNull: false,
          Name: "CountryCode",
          SimpleValue: quote?.shippingAddress?.CountryCode,
          Type: 0,
        });
      }

      console.log(configuratorMode);
      if (
        ![CONFIGURATOR_MODES.Review, CONFIGURATOR_MODES.SOWriteup].includes(
          configuratorMode
        )
      )
        console.log("set int param");
      intParam.current = tempStorage;
      console.log(intParam);
    }

    if (configuratorMode === CONFIGURATOR_MODES.Review) {
      let quoteLineQty = "";
      let quoteLineDesc = "";
      let quoteLinePrice = "";
      let quoteLineType = "";
      let quoteLineDiv = "";
      let quoteLineID = "";
      let quoteLineName = "";
      quote.lines.forEach((currentLine) => {
        quoteLineQty += currentLine.quantity
          ? currentLine.quantity + "|"
          : "0|";
        var itemNaming = currentLine.productName || "";
        if (
          currentLine.CfgPartNumber != null &&
          currentLine.CfgPartNumber !== ""
        ) {
          itemNaming = currentLine.CfgPartNumber;
        }

        quoteLineDesc += currentLine.LineDescription
          ? currentLine.LineDescription + "|"
          : (currentLine.productName || "") + "|";
        quoteLinePrice += currentLine.price ? currentLine.price + "|" : "0|";
        quoteLineType += "Configured|"; //currentLine.quantity ? (currentLine.quantity)+ "|": "";
        quoteLineDiv += "I|"; //currentLine.quantity ? (currentLine.quantity)+ "|": "";
        quoteLineID += currentLine.configurationId
          ? currentLine.configurationId + "|"
          : "0|";
        quoteLineName += itemNaming ? itemNaming + "|" : "NO NAME ERROR|";
      });

      quoteLineQty = quoteLineQty.endsWith("|")
        ? quoteLineQty.substring(0, quoteLineQty.length - 1)
        : quoteLineQty;
      quoteLineDesc = quoteLineDesc.endsWith("|")
        ? quoteLineDesc.substring(0, quoteLineDesc.length - 1)
        : quoteLineDesc;
      quoteLinePrice = quoteLinePrice.endsWith("|")
        ? quoteLinePrice.substring(0, quoteLinePrice.length - 1)
        : quoteLinePrice;
      quoteLineType = quoteLineType.endsWith("|")
        ? quoteLineType.substring(0, quoteLineType.length - 1)
        : quoteLineType;
      quoteLineDiv = quoteLineDiv.endsWith("|")
        ? quoteLineDiv.substring(0, quoteLineDiv.length - 1)
        : quoteLineDiv;
      quoteLineID = quoteLineID.endsWith("|")
        ? quoteLineID.substring(0, quoteLineID.length - 1)
        : quoteLineID;
      quoteLineName = quoteLineName.endsWith("|")
        ? quoteLineName.substring(0, quoteLineName.length - 1)
        : quoteLineName;

      if (quote?.sfQuoteNumber && quote?.sfQuoteNumber !== "") {
        quoteDisplayNumber = quote?.sfQuoteNumber;
      }

      if (quote?.sfQuoteNumber && quote?.sfQuoteNumber !== "") {
        quoteDisplayNumber = quote?.sfQuoteNumber;
      }

      if (quote?.lineCount) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Count",
          SimpleValue: +quote?.lineCount,
          Type: 1,
        });
      }
      if (quoteLineName) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Item",
          SimpleValue: quoteLineName,
          Type: 0,
        });
      }
      if (quoteLineQty) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Qty",
          SimpleValue: quoteLineQty,
          Type: 0,
        });
      }
      if (quoteLineDesc) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Desc",
          SimpleValue: quoteLineDesc,
          Type: 0,
        });
      }
      if (quoteLinePrice) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Price",
          SimpleValue: quoteLinePrice,
          Type: 0,
        });
      }
      if (quoteLineType) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Type",
          SimpleValue: quoteLineType,
          Type: 0,
        });
      }
      if (quoteLineDiv) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_Div",
          SimpleValue: quoteLineDiv,
          Type: 0,
        });
      }
      if (quoteLineID || paramConfigId) {
        tempStorage.push({
          IsNull: false,
          Name: "Line_ConfigurationID",
          SimpleValue: quoteLineID || paramConfigId,
          Type: 0,
        });
      }
    }

    if (configuratorMode === CONFIGURATOR_MODES.SOWriteup) {
      if (activeUser?.username) {
        tempStorage.push({
          IsNull: false,
          Name: "User_Login",
          SimpleValue: activeUser?.username || "",
          Type: 0,
        });
      }

      if (quote?.sfRepCode) {
        tempStorage.push({
          IsNull: false,
          Name: "sfRepCode",
          SimpleValue: quote?.sfRepCode,
          Type: 0,
        });
      }
      if (quote?.sfTerritoryCode) {
        tempStorage.push({
          IsNull: false,
          Name: "sfTerritoryCode",
          SimpleValue: quote?.sfTerritoryCode,
          Type: 0,
        });
      }
      if (quote?.sfCustomer) {
        tempStorage.push({
          IsNull: false,
          Name: "account_name",
          SimpleValue: quote?.sfCustomer,
          Type: 0,
        });
      }
      if (quote?.quoteGenConfigId) {
        tempStorage.push({
          IsNull: false,
          Name: "ConfigurationID",
          SimpleValue: quote?.quoteGenConfigId,
          Type: 0,
        });
      }
    }
    intParam.current = tempStorage;
  }, [activeUser, configuratorMode, lineNumber, paramConfigId, quote]);

  useEffect(() => {
    if (quote) getIntegrationParams();
  }, [getIntegrationParams, quote]);

  // var newGuid = guid.GenerateGUID();
  // const queryData = useQuery({
  //   queryID: paramConfigId,
  //   request: start,
  //   queryVariables: {
  //     activeProduct,
  //     configurationId: paramConfigId,
  //     isReconfigure,
  //     headerId: activeProduct?.HeaderId || "ICE",
  //     integrationParams: intParam.current,
  //     requireHttps: platform?.requireHttps,
  //     showFailureAlert,
  //   },
  //   forcedFilters: {},
  //   skip:
  //     !activeUser?.id ||
  //     !platform ||
  //     !activeProduct ||
  //     !activeProduct?.ID ||
  //     (!activeUser && loggedIn) ||
  //     productLoading ||
  //     quoteLoading ||
  //     !paramConfigId ||
  //     (!quote && quoteId !== "new"),
  // });

  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setApi(null);
  }, [LineId, setApi, sourceConfigurationId, quoteId, configurationId]);

  const startConfigurator = useCallback(
    async (signal) => {
      if (isLoading) return;
      if (data?.ui) return;

      console.log("startConfigurator");
      console.log(intParam.current);
      setLoading(true);

      var srcConfig = isReconfigure
        ? paramConfigId
        : sourceConfigurationId || "";

      if (product?.Ruleset === "Quote_Gen" && quote?.quoteGenConfigId) {
        console.log("Quote_gen");
        srcConfig = quote?.quoteGenConfigId;
      } else if (
        product?.Ruleset === "SO_WriteUP" &&
        quote?.soWriteUpConfigId
      ) {
        srcConfig = quote?.soWriteUpConfigId;
      } else {
        srcConfig = paramConfigId;
      }

      // console.log({ srcConfig });
      const { prepare, initialize, ui } = await start({
        signal,
        productId,
        configurationId: srcConfig,
        isReconfigure:
          isReconfigure || quote?.quoteGenConfigId || quote?.soWriteUpConfigId,
        headerId: product?.HeaderId,
        integrationParams: intParam.current,
        sourceHeaderId: product?.HeaderId,
        sourceConfigurationId: srcConfig,
        requireHttps: platform?.requireHttps,
        showFailureAlert,
      });

      setData({ prepare, initialize, ui });
      setLoading(false);
      return;
    },
    [
      isLoading,
      data,
      isReconfigure,
      paramConfigId,
      sourceConfigurationId,
      product,
      quote,
      productId,
      platform,
      showFailureAlert,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    if ((!data && isLoading) || userLoading || data || !quote) return;

    startConfigurator(controller.signal);

    return () => controller.abort();
  }, [data, isLoading, startConfigurator, userLoading, quote]);

  //const { data, isLoading } = queryData || {};
  useEffect(() => {
    if (api?.d?.Pages?.length > 0 && product && product.ID !== productId) {
      setApi({});
    }
  }, [api, product, productId, setApi]);
  const { data: ui, config } = data?.ui || {};
  const { d: sessionID } = data?.initialize || {};
  const { headerId: _headerId } = JSON.parse(config?.data.headers || "{}");

  useEffect(() => {
    if (api?.d?.Pages?.length === 0) return;
    const lastValue = api?.d?.Pages?.length - 1;
    const maxScreenOptions = (api?.d?.Pages?.[lastValue]?.Screens || [])
      .slice()
      .reverse()
      .find((s) => s.ScreenOptions.length > 0);

    console.log({ maxScreenOptions });

    const { ScreenOptions } = maxScreenOptions || {};
    const { length } = ScreenOptions || [];
    const { [length - 1]: lastScreenOption } = ScreenOptions || {};
    const acMode = lastScreenOption?.AutoContinueMode;
    if (optionToFocus === lastScreenOption?.ID && !lastScreenOption.Value)
      setCurrentPage(lastValue);
    setACMode(acMode);
  }, [api, optionToFocus, setACMode, setCurrentPage]);

  useEffect(() => {
    if (api?.d?.Pages?.length === 0) return;
    const pages = [...(api?.d?.Pages || [])];
    const lastValue = pages.length - 1;
    const lastPage = pages.pop();
    const { Screens = [] } = lastPage || {};

    const maxScreenOptions = Screens.slice()
      .reverse()
      .find((s) => s.ScreenOptions.length > 0);

    const { ScreenOptions } = maxScreenOptions || {};
    const { length } = ScreenOptions || [];
    const { [length - 1]: lastScreenOption } = ScreenOptions || {};
    const acMode = lastScreenOption?.AutoContinueMode;
    setACMode(acMode);
    if (optionToFocus === lastScreenOption?.ID && !lastScreenOption?.Value)
      setCurrentPage(lastValue);
  }, [api, optionToFocus, setACMode, setCurrentPage]);

  useEffect(() => {
    if (_.isArray(api?.d?.Messages)) {
      api.d.Messages.forEach((message) => {
        const { Critical, Warning, Information } = constants.MESSAGE_RULE_TYPES;
        switch (message.Type) {
          case Critical:
            showFailureAlert(message.Value);
            break;
          case Warning:
            showWarningAlert(message.Value);
            break;
          case Information:
            showSuccessAlert(message.Value);
            break;
          default:
            showSuccessAlert(message.Value);
            break;
        }
        return;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    setSessionId(sessionID);
    setCurrentPage(0);
  }, [sessionID, setApi, setCurrentPage, setSessionId]);

  useEffect(() => {
    if (ui) {
      setApi(ui);
      setOptionToFocus(ui.d?.OptionToFocus);
    }
  }, [ui, setApi, setOptionToFocus]);

  const finishConfiguration = useCallback(
    async ({ sessionId, username, publicUser = false }) => {
      await finish({ sessionId, requireHttps, Server: activeProduct?.Server });

      let _quoteId = quoteId;
      console.log(api);

      const { Value: imagePath } =
        _.find(api?.d?.Details || [], (detailItem) => {
          const { Caption } = detailItem || {};
          if (Caption === "RAL Image Path") return true;
          return false;
        }) || {};

      const { Value: imageName } =
        _.find(api?.d?.Details || [], (detailItem) => {
          const { Caption } = detailItem || {};
          if (Caption === "RAL Image") return true;
          return false;
        }) || {};
      const { Value: imageLink } =
        _.find(api?.d?.Details || [], (detailItem) => {
          const { Caption } = detailItem || {};
          if (Caption === "Image Link") return true;
          return false;
        }) || {};

      const line = {
        configurationId,
        headerId: _headerId || activeProduct?.HeaderId,
        imgUrl: imageLink
          ? imageLink
          : imagePath && imageName
          ? `${imagePath}${imageName}`
          : ImageUrl,
        price: ConfiguredPrice,
        quantity: 1,
        productId,
        quoteId: quoteId === "new" ? null : +_quoteId,
        description: activeProduct?.Description,
        partNumber: activeProduct?.ProductNumber,
        username: username || quote?.contact?.email,
        quoteCreated: publicUser ? "0" : "1",
        Details: JSON.stringify(api?.d?.Details),
      };

      const newId = GenerateGUID();

      const currentUser = activeUser?.username;

      switch (configuratorMode) {
        case SOWriteup:
          const docLink = await quoteReqs.getSOWriteUpDocumentLink({
            configId: configurationId,
            productId,
            componentAttributeName: "SO_WriteUP_DocLink",
            quoteId: +_quoteId,
            currentUser,
          }).result;

          await quoteReqs.updateQuote({
            input: {
              type: 2,
              soWriteUpConfigId: configurationId,
              lines: quote?.lines,
              soWriteUpDocLink: docLink,
            },
            _id: quote?._id || undefined,
            id: +_quoteId,
          });

          refetchQuery(`quote|${_quoteId}`);
          navigate(`/Quote/${_quoteId}`);
          break;

        case Review:
          await getCurrentPrintedDocument(
            productId,
            configurationId,
            "Document_Link",
            _quoteId
          );

          await updateSolidfitLinePricing(
            configurationId,
            JSON.stringify(quote?.lines),
            +_quoteId
          );

          refetchQuery(`quote|${_quoteId}`);

          navigate(`/Quote/${_quoteId}`);
          break;

        default:
          if (_quoteId === "new" || publicUser || !quoteId) {
            const newQuote = await createQuote({
              input: {
                username:
                  username || quote?.contact?.email || activeUser?.username,
                line,
                type: publicUser ? 3 : 1,
                _id: newId,
              },
            });

            refetchQuery(`quote|${_quoteId}`);

            if (publicUser) refetchQuery("quoteRequests");
            const { result } = newQuote || {};
            _quoteId = result;
          } else if (LineId) {
            await updateLine({
              id: LineId,
              input: {
                Details: JSON.stringify(api?.d?.Details),
                price: ConfiguredPrice,
                imgUrl: ImageUrl,
              },
            });
          } else {
            const newUUID = GenerateGUID();
            await createLine({
              input: {
                ...line,
                quoteId: quoteId === "new" ? undefined : +quoteId,
                Details: JSON.stringify(api?.d?.Details),
                _id: newUUID,
                companyId: 1,
              },
            });

            refetchQuery(`quote|${_quoteId}`);
          }

          navigate(`/Quote/${_quoteId}`);
          break;
      }
    },
    [
      requireHttps,
      activeProduct,
      quoteId,
      configurationId,
      _headerId,
      ImageUrl,
      ConfiguredPrice,
      productId,
      quote,
      api,
      configuratorMode,
      navigate,
      getCurrentPrintedDocument,
      updateSolidfitLinePricing,
      LineId,
      refetchQuery,
      createQuote,
      activeUser,
      updateLine,
      createLine,
    ]
  );

  return (
    <>
      <CreateUserModal
        show={showCreateUser}
        setShow={setShowCreateUser}
        finishConfiguration={finishConfiguration}
        sessionId={sessionId || sessionID}
      />
      <SummaryModal
        show={showDetails}
        setShow={setShowDetails}
        Details={Details}
        ConfiguredPrice={ConfiguredPrice}
      />
      <CancelModal show={showCancel} setShow={setShowCancel} />

      {ui && !isLoading ? (
        <PageWrapper style={{ minHeight: "calc(100vh - 155px)" }}>
          <TopBar
            disableFinish={disableFinish}
            setDisableFinish={setDisableFinish}
            sessionId={sessionID || sessionId}
            setShowCancel={setShowCancel}
            Pages={api?.d?.Pages}
            setShowDetails={setShowDetails}
            finishConfiguration={finishConfiguration}
            setShowCreateUser={setShowCreateUser}
            showCreateUser={showCreateUser}
          />

          <Flex
            pt="1rem"
            flexDirection={["column-reverse", "column-reverse", "row"]}
            style={{ gap: "1rem" }}
          >
            <Flex flexDirection="column" flex={2}>
              <Form
                disableFinish={disableFinish}
                setDisableFinish={setDisableFinish}
                isLoading={isLoading}
                queryID={paramConfigId}
              />
            </Flex>
            {![SOWriteup, Review].includes(configuratorMode) && !hideImage && (
              <Box
                textAlign="center"
                flex={1}
                m="0 auto 2rem auto"
                width={["70%", "70%", 1]}
              >
                <ProductModel
                  onError={() => setHideImage(true)}
                  image={ImageUrl}
                  altText="image"
                />
              </Box>
            )}
          </Flex>
        </PageWrapper>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Configurator;
