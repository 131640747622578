import axios from "axios";
import ErrorHandler from "../modules/errorHandler";

export const copyLineConfiguration = async (input) => {
  const {
    instanceName,
    applicationName,
    headerId,
    detailId,
    sourceHeaderId,
    sourceDetailId,
  } = input || {};
  try {
    const response = axios.post(
      "https://20.232.123.81/RenTechHostServicesAPI/api/CopyConfiguration",
      {
        instanceName,
        applicationName,
        serviceUrl:
          "https://20.232.123.81/CPQ_DEV/ConfiguratorService/v2/ProductConfigurator.svc",
        headerId,
        detailId,
        sourceHeaderId,
        sourceDetailId,
      }
    );
    return response.data;
  } catch (error) {
    ErrorHandler.logError(error);
    return null;
  }
};

export const getQuote = async ({ id }) => {
  const { data, status } = await axios.post(
    "/api/v1/quoteManagement/getQuote",
    { id }
  );
  if (status === 200 && data.success === true) {
    return data.result;
  }
  return null;
};
export const getQuoteById = async ({ id }) => {
  const { data, status } = await axios.post(
    "/api/v1/quoteManagement/getQuoteById",
    { id }
  );
  if (status === 200 && data.success === true) {
    return data.result;
  }
  return null;
};

export const getQuotes = async ({
  signal,
  limit,
  offset,
  order,
  search,
  username,
  type,
  attributes,
}) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/getQuotes",
    {
      limit,
      offset,
      order,
      search,
      username,
      signal,
      type,
      attributes,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};

export const getLines = async ({
  signal,
  limit,
  offset,
  order,
  search,
  quoteId,
  username,
}) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/getLines",
    {
      limit,
      offset,
      order,
      search,
      signal,
      quoteId,
      username,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};

export const addContactToQuote = async (quoteId, contactId) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/addContactToQuote",
      {
        quoteId,
        contactId,
      }
    );
    if (status === 200 && data.success === true) {
      return data.result;
    }
    return {};
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const removeContactFromQuote = async (quoteId) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/removeContactFromQuote",
      { quoteId }
    );
    if (status === 200 && data.success === true) {
      return data.result;
    }
    return {};
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getAddress = async (vars) => {
  const { signal, quoteId, customerId } = vars || {};
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/getAddress",
    {
      signal,
      quoteId,
      customerId,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return {};
};

export const addAddressToQuote = async ({ id, input }) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/addAddressToQuote",
    {
      id,
      input,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return {};
};

export const createLine = async ({ input }) => {
  try {
    const res = await axios.post("/api/v1/quoteManagement/createLine", {
      input,
    });

    return res;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return {};
  }
};
export const updateLine = async ({ input, id, _id }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/updateLine",
      { input, id, _id }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return {};
  }
};
export const updateLines = async ({ input, quoteId }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/updateLines",
      { input, quoteId }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return {};
  }
};
export const deleteLine = async ({ _id, id, quoteId }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/deleteLine",
      { _id, id, quoteId }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return {};
  }
};

export const createQuote = async ({ input }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/createQuote",
      { input }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const updateQuote = async ({ id, _id, input, type }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/updateQuote",
      { id, _id, input, type }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const deleteQuote = async ({ id, _id }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/deleteQuote",
      { id, _id }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const copyQuote = async ({ id, _id }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/copyQuote",
      { id, _id }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const createCommunication = async ({ input }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/createCommunication",
      { input }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const updateCommunication = async ({ id, _id, input, type }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/updateCommunication",
      { id, _id, input, type }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const deleteCommunication = async ({ id, _id }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/deleteCommunication",
      { id, _id }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const createAttachment = async ({ input }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/createAttachment",
      { input }
    );

    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const updateAttachment = async ({ id, _id, input, type }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/updateAttachment",
      { id, _id, input, type }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const deleteAttachment = async ({ id, _id }) => {
  try {
    const { status, data } = await axios.post(
      "/api/v1/quoteManagement/deleteAttachment",
      { id, _id }
    );
    if (status !== 200) ErrorHandler.logError(data);
    return data;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
  }
};

export const getCommunications = async ({ signal, quoteId }) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/getCommunications",
    {
      quoteId,
      signal,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};
export const getAttachments = async ({ signal, quoteId }) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/getAttachments",
    {
      quoteId,
      signal,
    }
  );

  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};

export const createRMAs = async ({ data: _data }) => {
  const { status, data } = await axios.post(
    "/api/v1/quoteManagement/createRMAs",
    { data: _data }
  );
  if (status === 200 && data.success === true) {
    return data.result;
  } else {
    console.error(data);
    return null;
  }
};

export const sendOrderStatusEmails = async ({ emails, subject, htmlBody }) => {
  const res = await axios.post(
    "/api/v1/quoteManagement/sendOrderStatusEmails",
    {
      emails,
      subject,
      htmlBody,
    }
  );

  if (res.status === 200) return res.data;

  return null;
};

export const createOrderFromQuote = async (body) => {
  const res = await axios.post("/api/v1/quoteManagement/createOrderFromQuote", {
    ...body,
  });

  if (res.status === 200) return res.data;

  return null;
};

export const createQuoteFromRequest = async ({ body }) => {
  const res = await axios.post(
    "/api/v1/quoteManagement/createQuoteFromRequest",
    { ...body }
  );

  if (res.status === 200) return res.data;

  return null;
};

function dataURItoBlob(dataURI, callback) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);
  // separate out the mime component
  // var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab]);
  return bb;
}

export const downloadFile = async (path, originalName, mimetype) => {
  try {
    const res = await axios.post("/api/v1/quoteManagement/downloadFile", {
      path,
      originalName,
      mimetype,
    });
    if (res.status !== 200) return ErrorHandler.logError(res);
    // dispatch(alerts.showFailureAlert("File failed to download."));
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      let linkSource = dataURItoBlob(res.data);

      navigator.msSaveOrOpenBlob(linkSource, originalName);
    } else {
      let linkSource = res.data;
      const downloadLink = document.createElement("a");
      const fileName = originalName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
      return downloadLink;
    }
  } catch (error) {
    return ErrorHandler.logError(error);
  }
};

/**
 * START
 * SF Integration
 */

export const salesforceCreateQuote = async (opportunityId, opportunityName) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceCreateQuote",
      {
        opportunityId,
        opportunityName,
      }
    );

    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceUpdateQuote = async (
  salesforceQuoteId,
  swiftSellQuoteId
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceUpdateQuote",
      {
        salesforceQuoteId,
        swiftSellQuoteId,
      }
    );

    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const updateSalesforceQuoteNumber = async (sfQuoteNumber, quoteId) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/updateSalesforceQuoteNumber",
      {
        sfQuoteNumber,
        quoteId,
      }
    );

    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const createQuoteInitiatedFromSalesforce = async (
  salesPortalQuoteDTO
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/createQuoteInitiatedFromSalesforce",
      {
        salesPortalQuoteDTO,
      }
    );

    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceGetCustomers = async () => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceGetCustomers"
    );

    if (res.status !== 200) {
      return ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceCreateQuoteLineItem = async (
  quantity,
  sfQuoteId,
  unitPrice,
  product2Id,
  pricebookEntryId,
  partNumber,
  lineDescription,
  newLineId
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceCreateQuoteLineItem",
      {
        quantity: quantity,
        sfQuoteId: sfQuoteId,
        unitPrice: unitPrice,
        product2Id: product2Id,
        pricebookEntryId: pricebookEntryId,
        partNumber: partNumber,
        lineDescription: lineDescription,
      }
    );

    console.log("sfquotelineitemid", { res });
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    } else {
      await axios.post(
        "/api/v1/quoteManagement/updateLineSalesforceQuoteLineItemId",
        {
          LineId: newLineId,
          salesforceQuoteLineItemId: res || res?.id,
        }
      );
      return res.data;
      // Whenever this runs we have to send a success alert with the line id I guess
      // dispatch(updateLineSalesforceQuoteLineItemId(res.data.result, newLineId));
    }
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const updateSolidfitLinePricing = async (
  quoteGenConfigId,
  lines,
  quoteId
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/updateSolidfitLinePricing",
      {
        quoteGenConfigId,
        lines,
        quoteId,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const updateLineSalesforceQuoteLineItemId = async (
  salesforceQuoteLineItemId,
  LineId
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/updateLineSalesforceQuoteLineItemId",
      {
        salesforceQuoteLineItemId: salesforceQuoteLineItemId,
        LineId: LineId,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceGetQuoteNumber = async (quoteId) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceGetQuoteNumber",
      {
        quoteId: quoteId,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data.result;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};
export const salesforceDeleteQuoteLine = async (values) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceDeleteQuoteLine",
      {
        ...values,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data.result;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};
export const saveSOWriteupDocLinkToSolidfit = async (values) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/saveSOWriteupDocLinkToSolidfit",
      {
        ...values,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data.result;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};
export const getSOWriteUpDocumentLink = async (values) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/getSOWriteUpDocumentLink",
      {
        ...values,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceCreateProduct = async (
  name,
  productCode,
  description
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceCreateProduct",
      {
        name: name,
        productCode: productCode,
        description: description,
      }
    );
    console.log({ res });
    if (res.status !== 200) {
      return ErrorHandler.logError(res);
    }
    return res.data;
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const salesforceCreatePriceBookEntry = async (
  pricebook2Id,
  product2Id,
  unitPrice
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/salesforceCreatePriceBookEntry",
      {
        pricebook2Id: pricebook2Id,
        product2Id: product2Id,
        unitPrice: unitPrice,
      }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

export const saveCurrentPrintedDocumentToSolidfit = async (
  quoteId,
  documentPath
) => {
  try {
    const result = await axios.post(
      "/api/v1/quoteManagement/saveCurrentPrintedDocumentToSolidfit",
      { quoteId, currentPrintedDocument: documentPath }
    );

    return result;
  } catch (error) {
    ErrorHandler.logError(error);
    return error;
  }
};

export const getCurrentPrintedDocument = async (
  productId,
  configId,
  componentAttributeName,
  quoteId
) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/getCurrentPrintedDocument",
      {
        productId: `${productId}`,
        configId: configId,
        componentAttributeName: componentAttributeName,
        quoteId,
      }
    );
    console.log({ res });
    if (res.status !== 200) {
      return ErrorHandler.logError(res);
    }

    return res;
  } catch (error) {
    ErrorHandler.logError(error);
    return error;
  }
};
export const getPricingDoc = async (
  quoteGenConfigId,
  configId,
  componentAttributeName,
  lineId
) => {
  try {
    const res = await axios.post("/api/v1/quoteManagement/getPricingDoc", {
      quoteGenConfigId,
      configId: configId,
      componentAttributeName: componentAttributeName,
      lineId,
    });
    console.log(res.data.result);
    if (res.status !== 200) {
      return ErrorHandler.logError(res);
    }

    return res?.data?.result;
  } catch (error) {
    ErrorHandler.logError(error);
    return error;
  }
};

export const updateSOWriteUpConfigId = async (quoteId, soWriteUpConfigId) => {
  try {
    const res = await axios.post(
      "/api/v1/quoteManagement/updateSOWriteUpConfigId",
      { quoteId, soWriteUpConfigId }
    );
    if (res.status !== 200) {
      ErrorHandler.logError(res);
    }
  } catch (error) {
    ErrorHandler.logError(error);
  }
};

/**
 * END
 * SF INTEGRATION
 */
