import {
  createContext,
  useMemo,
  useReducer,
  useCallback,
  useState,
} from "react";

export const RowDisplayContext = createContext({});

export const ColumnDisplayContext = createContext({});

export const useTableContext = (
  processedColumns,
  device,
  includeDropdown,
  _onRowClick,
  selections,
  popout
) => {
  const [columnOrder, _setColumnOrder] = useState(() => [
    ...processedColumns.keys(),
  ]);
  const setColumnOrder = useCallback(_setColumnOrder, [_setColumnOrder]);

  const initialVisibility = useMemo(() => {
    let o = {};
    processedColumns.forEach((entry) => {
      let size = getSize(entry.size, device);
      o[entry.id] = [
        entry.header || entry.data || entry.id,
        size > 0 ? 1 : size < 0 ? 0 : 2,
      ];
    });
    return o;
  }, [processedColumns, device]);

  const [_refresh, forceVisRefresh] = useReducer((a, _) => a + 1, 0);
  const [configuredVisibility, setConfiguredVisibility] = useReducer(
    (state, [propertyName, visibility]) => {
      forceVisRefresh();
      if (visibility === -1) {
        delete state[propertyName];
        return state;
      } else if (visibility === -2) {
        return {};
      }
      return { ...state, [propertyName]: visibility };
    },
    {}
  );

  const [visibleIndices, dropdownIndices, hasDropdown] = useMemo(
    () =>
      getVisibility(
        columnOrder,
        processedColumns,
        initialVisibility,
        configuredVisibility,
        _refresh
      ),
    [
      columnOrder,
      processedColumns,
      initialVisibility,
      configuredVisibility,
      _refresh,
    ]
  );
  const dropdown = hasDropdown && includeDropdown;
  const getVisibleIndices = useCallback(() => visibleIndices, [visibleIndices]);

  const [configuredSizing, sizingReducer] = useReducer(
    (state, [propertyName, size]) => {
      if (size === -1) {
        delete state[propertyName];
        return state;
      } else if (size === -2) {
        return {};
      }
      return { ...state, [propertyName]: size };
    },
    {}
  );
  const arbitrarySizeReducer = useCallback(
    (change) => sizingReducer(change),
    [sizingReducer]
  );
  const [relativeSizes, arbitrarySizes, totalSizes] = useMemo(
    () => getSizes(processedColumns, device, visibleIndices, configuredSizing),
    [processedColumns, device, visibleIndices, configuredSizing]
  );

  const [dropdownRow, setDropdownRow] = useState("");
  const onRowClick = useCallback(
    (row, e) => {
      if (dropdown) {
        const { id } = row;
        if (id !== dropdownRow) setDropdownRow(id);
        else setDropdownRow("");
        return;
      }
      if (_onRowClick) return _onRowClick(row, e);
    },
    [dropdownRow, setDropdownRow, _onRowClick, dropdown]
  );
  const rowContext = useMemo(
    () => ({
      dropdown,
      visibleIndices,
      dropdownIndices,
      dropdownRow,
      onRowClick,
      clickable: !!_onRowClick || dropdown,
      selections,
      popout,
    }),
    [
      dropdown,
      visibleIndices,
      dropdownIndices,
      dropdownRow,
      onRowClick,
      _onRowClick,
      selections,
      popout,
    ]
  );
  const columnContext = useMemo(
    () => ({
      relativeSizes,
      arbitrarySizes,
      totalSizes,
      arbitrarySizeReducer,
      columnOrder,
      setColumnOrder,
      getVisibleIndices,
    }),
    [
      relativeSizes,
      arbitrarySizes,
      totalSizes,
      arbitrarySizeReducer,
      columnOrder,
      setColumnOrder,
      getVisibleIndices,
    ]
  );
  return [
    rowContext,
    columnContext,
    {
      initialVisibility,
      configuredVisibility,
      setConfiguredVisibility,
      configuredSizing,
    },
  ];
};
const deviceSizes = ["xl", "lg", "md", "sm", "xs"];
const getSize = (size, device) => {
  if (typeof size === "number") {
    return size;
  }
  let colSize;
  deviceSizes.every((deviceSize) => {
    if (device[deviceSize] && (colSize = size[deviceSize]) !== undefined) {
      return false;
    }
    return true;
  });
  return colSize;
};

function getVisibility(
  columnOrder,
  processedColumns,
  initialVisibility,
  configuredVisibility
) {
  let visibleIndices = [],
    dropdownIndices = [];
  for (const index of columnOrder) {
    let column = processedColumns[index],
      id = column.id;
    let columnVisibility =
      id in configuredVisibility
        ? configuredVisibility[id][1]
        : initialVisibility[id][1];
    if (columnVisibility === 1) {
      visibleIndices.push(index);
    } else if (columnVisibility === 2) {
      dropdownIndices.push(index);
    }
  }
  return [visibleIndices, dropdownIndices, dropdownIndices.length > 0];
}

function getSizes(processedColumns, device, visibleIndices, configuredSizing) {
  let totalSizes = 0;
  const arbitrarySizes = {};
  visibleIndices.forEach((index) => {
    let { id, size } = processedColumns[index];
    let colSize;
    if (id in configuredSizing) {
      colSize = configuredSizing[id];
    } else {
      colSize = getSize(size, device);
      if (colSize <= 0) colSize = 1;
    }
    totalSizes += colSize;
    arbitrarySizes[id] = colSize;
  });
  let relativeSizes = {};
  Object.entries(arbitrarySizes).forEach(([id, size]) => {
    relativeSizes[id] = (100 * size) / totalSizes;
  });
  return [relativeSizes, arbitrarySizes, totalSizes];
}
