import React, { useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import Field from "../../UI/forms/Field";
import Checkbox from "../../UI/forms/Checkbox";
import { Heading } from "../../UI/Text";
// import _ from "lodash";
import states from "../../../modules/states";
// import * as AMR from "../../../requests/addressManagementRequests";
// import ConfiguratorContext from "../../../context/configurator/ConfiguratorContext";
// import { updateQuote } from "../../../requests/quoteManagementRequests";
import countries from "../../../modules/countries";
// import { useParams } from "react-router-dom";

// const { createAddress, updateAddress } = AMR;

const AddressWrapper = styled(Flex)`
  > div {
    margin-top: 0;
  }
`;

const AddressForm = ({
  isLoading,
  shippingAddresses,
  disableForm,
  onSubmit,
  refetchQuote,
  setRefetch,
}) => {
  const { values } = useFormikContext();
  // const { quoteId } = useParams();

  // const { quote } = useContext(ConfiguratorContext);

  // const { shippingAddress } = quote || {};
  // const { id: savedShippingId } = shippingAddress || {};

  // const onAutofillShipping = useCallback(
  //   async (addressToFillId) => {
  //     setFieldValue("shippingAddressId", +addressToFillId);

  //     if (addressToFillId === "default") {
  //       await updateAddress({
  //         id: +savedShippingId,
  //         input: {
  //           Address: "",
  //           Address2: "",
  //           City: "",
  //           State: "",
  //           Zip: null,
  //         },
  //       });
  //       await updateQuote({
  //         id: quoteId,
  //         input: { shippingAddressId: null },
  //       });
  //       await refetchQuote();
  //       return;
  //     }

  //     const addressToFill = _.find(
  //       shippingAddresses,
  //       (SA) => +SA.id === +addressToFillId
  //     );
  //     const { Address, Address2, City, State, Zip } = addressToFill;

  //     const input = { Address, Address2, City, State, Zip };

  //     await updateQuote({
  //       id: quoteId,
  //       input: { shippingAddressId: +addressToFillId },
  //     });

  //     if (savedShippingId) {
  //       await updateAddress({ id: +savedShippingId, input });
  //     } else {
  //       await createAddress({ input, QuoteId: quoteId });
  //     }
  //     await refetchQuote();
  //   },
  //   [setFieldValue, shippingAddresses, refetchQuote, quoteId, savedShippingId]
  // );

  const stateOptions = useMemo(
    () =>
      states.getStates().map(({ name, abbreviation: a }) => (
        <option value={a} key={a}>
          {name}
        </option>
      )),
    []
  );
  const countryOptions = useMemo(
    () =>
      countries.getCountries().map(({ name, abbreviation: a }) => (
        <option value={a} key={a}>
          {name}
        </option>
      )),
    []
  );

  // const shippingOptions = useMemo(() => {
  //   if (!_.isArray(shippingAddresses)) return [];
  //   return shippingAddresses.map((shipAddress) => {
  //     const { Address, Address2, City, State, Zip, companyName } = shipAddress;
  //     return (
  //       <option value={shipAddress.id} key={shipAddress.id}>
  //         {`${companyName || ""} ${Address || ""}${
  //           Address2 ? " " + Address2 : ""
  //         }, ${City || ""} ${State || ""}, ${Zip || ""}`}
  //       </option>
  //     );
  //   });
  // }, [shippingAddresses]);

  const onBlur = useCallback(
    async (e) => {
      await onSubmit({ [e.target.name]: e.target.value });
      setRefetch(1);
    },
    [onSubmit, setRefetch]
  );

  const fieldProps = useMemo(
    () => ({
      isLoading,
      onBlur,
    }),
    [isLoading, onBlur]
  );

  return (
    <Box flex={values.sameAsBilling ? 1 : [1, 1, 2]}>
      <Flex flexDirection={["column", "column", "row"]}>
        <Flex
          ml={["0", "0", "0.5rem"]}
          mr={!values.sameAsBilling ? ["0", "0", "0.5rem"] : ""}
          flexDirection="column"
          alignItems="flex-end"
        >
          <Heading mt="0">Billing Address</Heading>
          <Box mt="0.55rem" mb="1rem">
            <Checkbox
              isLoading={isLoading}
              labelText="Shipping Address is same as Billing?"
              name="sameAsBilling"
              onClick={onBlur}
            />
          </Box>
          <Field
            {...fieldProps}
            disabled={disableForm}
            name="billing.Address"
            labelText="Address Line 1"
          />
          <Field
            {...fieldProps}
            disabled={disableForm}
            name="billing.Address2"
            labelText="Address Line 2"
          />
          <Field
            {...fieldProps}
            disabled={disableForm}
            name="billing.City"
            labelText="City"
          />
          <Field
            {...fieldProps}
            disabled={disableForm}
            as="select"
            onChange={onBlur}
            name="billing.State"
            labelText="State"
          >
            <option value="" key="default">
              Select a State
            </option>
            {stateOptions}
          </Field>
          <Field
            {...fieldProps}
            disabled={disableForm}
            name="billing.Zip"
            labelText="Zip"
          />
          <Field
            {...fieldProps}
            disabled={disableForm}
            as="select"
            onChange={onBlur}
            name="billing.Country"
            labelText="Country"
          >
            <option value="" key="default">
              Select a Country
            </option>
            {countryOptions}
          </Field>
        </Flex>
        {!values.sameAsBilling ? (
          <AddressWrapper ml={["0", "0", "0.5rem"]} flexDirection="column">
            <Heading mt="0">Shipping Address</Heading>

            {/* <Field
              isLoading={isLoading}
              onChange={(e) => {
                onAutofillShipping(e.target.value);
              }}
              name="shippingAddressId"
              as="select"
              value={values["shippingAddressId"] || "default"}
            >
              <option value="default" key="default">
                Select an Existing Address
              </option>
              {shippingOptions}
            </Field> */}

            <Field
              {...fieldProps}
              name="shipping.Address"
              labelText="Address Line 1"
            />
            <Field
              {...fieldProps}
              name="shipping.Address2"
              labelText="Address Line 2"
            />
            <Field {...fieldProps} name="shipping.City" labelText="City" />
            <Field
              as="select"
              onChange={onBlur}
              name="shipping.State"
              labelText="State"
            >
              <option value="" key="default">
                Select a State
              </option>
              {stateOptions}
            </Field>

            <Field {...fieldProps} name="shipping.Zip" labelText="Zip" />
            <Field
              {...fieldProps}
              disabled={disableForm}
              as="select"
              onChange={onBlur}
              name="shipping.Country"
              labelText="Country"
            >
              <option value="" key="default">
                Select a Country
              </option>
              {countryOptions}
            </Field>
          </AddressWrapper>
        ) : null}
      </Flex>
    </Box>
  );
};

export default AddressForm;
