import { EditorState } from "draft-js";
import { useEffect, useState, useRef, useCallback } from "react";
import { JSONToEditorState, editorStateToJSON, editorStateToHTML, HTMLToEditorState, editorStateToPlainText, plainTextToEditorState } from "./editorContextConverter";

const attemptConvert = (initialState, exportString, setEditorState, converter) => {
  if (initialState && initialState !== exportString.current) {
    try {
      setEditorState(converter(initialState));
    } catch {}
  }
}

const useRichTextFieldJSON = (initialState) => {
  const [editorState, _setEditorState] = useState(() => EditorState.createEmpty());
  const exportString = useRef("");
  const setEditorState = useCallback((es) => {
    _setEditorState(es);
    exportString.current = editorStateToJSON(es);
  }, [_setEditorState, exportString]);
  useEffect(() => attemptConvert(initialState, exportString, setEditorState, JSONToEditorState), [initialState, setEditorState]);
  return {editorState, setEditorState, exportString};
}

const useRichTextFieldHTML = (initialState) => {
  const [editorState, _setEditorState] = useState(() => EditorState.createEmpty());
  const exportString = useRef("");
  const setEditorState = useCallback((es) => {
    _setEditorState(es);
    exportString.current = editorStateToHTML(es);
  }, [_setEditorState, exportString]);
  useEffect(() => attemptConvert(initialState, exportString, setEditorState, HTMLToEditorState), [initialState, setEditorState]);
  return {editorState, setEditorState, exportString};
}

const useRichTextFieldPlainText = (initialState, lineDelimiter) => {
  const [editorState, _setEditorState] = useState(() => EditorState.createEmpty());
  const exportString = useRef("");
  const setEditorState = useCallback((es) => {
    _setEditorState(es);
    exportString.current = editorStateToPlainText(es, lineDelimiter);
  }, [_setEditorState, lineDelimiter, exportString]);
  useEffect(() => attemptConvert(initialState, exportString, setEditorState, (a) => plainTextToEditorState(a, lineDelimiter)), [initialState, setEditorState, lineDelimiter]);
  return {editorState, setEditorState, exportString};
}

export { useRichTextFieldJSON, useRichTextFieldHTML, useRichTextFieldPlainText};