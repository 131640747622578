import React from "react";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../UI/Grid";
import { Body, Heading } from "../UI/Text";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { Flex } from "reflexbox";
import Card from "../UI/Card";
import Button from "../UI/Button";

const Wrapper = styled(Flex)`
  margin: 0 auto !important;
  svg {
    width: 50px;
    height: 50px;
  }
`;

const ThankYouPage = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Heading mt="2rem !important" center mb="1rem">
        Thank you!
      </Heading>
      <Body mb="3rem" bold center>
        Your order was completed successfully.
      </Body>
      <Card m="0 auto" width={["300px", "300px", "500px"]}>
        <Wrapper>
          <FontAwesomeIcon icon={fa.faPaperPlane} />
          <Body center ml="2rem">
            An email confirmation including the details of your order has been
            sent to the email address provided.
          </Body>
        </Wrapper>
      </Card>
      <Flex mt="2rem" justifyContent="center">
        <Button
          onClick={() => navigate("/QuoteDashboard")}
          iconPosition="left"
          iconName="faArrowLeft"
        >
          Back to Quotes
        </Button>
      </Flex>
    </PageWrapper>
  );
};

export default ThankYouPage;
