import React from "react";
import styled, { css } from "styled-components";
import { Box } from "reflexbox";
import { withTheme } from "styled-components";

export const Wrapper = styled(Box)`
  ${({ relative, inline }) => {
    if (relative) {
      return css`
        position: relative;
        height: 100%;
        width: 100%;
        .loader {
          width: 64px;
          height: 64px;
          border: 8px solid ${({ theme }) => theme.light};
          border-top: 8px solid ${({ theme }) => theme.primary};
          border-bottom: 8px solid ${({ theme }) => theme.secondary};
        }
      `;
    } else if (inline) {
      return css`
        position: relative;
        height: 100%;
        width: 100%;
        .loader {
          width: 16px;
          height: 16px;
          border: 2px solid ${({ theme }) => theme.light};
          border-top: 2px solid
            ${({ theme, color1, color }) => color1 || color || theme.secondary};
          border-bottom: 2px solid
            ${({ theme, color2, color1, color }) =>
              color2 || color || theme.secondary};
        }
      `;
    } else {
      return css`
        .loader {
          position: fixed;
          left: calc(50vw - 68px);
          top: calc(50vh - 34px);
          transform: translate(-50%, -50%);
          overflow: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 100;
          width: 100px;
          height: 100px;
          border: 16px solid ${({ theme }) => theme.light};
          border-top: 16px solid ${({ theme }) => theme.primary};
          border-bottom: 16px solid ${({ theme }) => theme.secondary};
        }
      `;
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = React.memo((props) => {
  return (
    <Wrapper {...props}>
      <div className="loader" />
    </Wrapper>
  );
});

export default withTheme(Loading);
