import React, { forwardRef, useMemo } from "react";
import _ from "lodash";
import { FastField, useFormikContext } from "formik";
import { Flex } from "reflexbox/styled-components";
import { Body, Helper } from "../../Text";
import Label from "../Label";
import styled, { useTheme, css } from "styled-components";
import { ShimmerField } from "../../Shimmer";
import { decimalToFraction } from "../../../../modules/utilities";

const thumbStyles = css`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.secondary};
  cursor: grab;
  transition: all ease-in-out 200ms;
  &:focus,
  &:hover {
    border: 5px solid ${({ theme }) => theme.secondary};
  }
  &:active {
    cursor: grabbing;
  }

  margin-top: -12px;

  opacity: ${({ disabled }) => disabled && "0.5"};
`;

const trackStyles = css`
  width: 100%;
  height: 1px;
  padding: 2px 0;
  cursor: pointer;
  background: ${({ theme }) => theme.gray};
`;

export const StyledRangeInput = styled(FastField)`
  appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  margin: 0.375rem 0 0;
  padding: 0 1rem;
  height: 1.5rem;
  position: relative;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-slider-container {
  }

  &::-webkit-slider-thumb {
    appearance: none;
  }

  &&:focus {
    outline: none; /* removes the input highlight border */
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    ${thumbStyles};
  }
  &::-moz-range-thumb {
    ${thumbStyles};
  }
  &::-ms-thumb {
    ${thumbStyles};
  }

  &::-webkit-slider-runnable-track {
    ${trackStyles};
  }
  &::-moz-range-track {
    ${trackStyles};
  }
  &::-ms-track {
    ${trackStyles};
  }
`;

export const ValueDisplay = styled.span`
  font-weight: 600;
  float: right;
`;

const Container = styled(Flex)`
  width: 100%;
  margin: 0.5rem auto 1rem auto;
  flex-direction: column;
`;

const FlexContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  position: relative;

  transition: all ease-in-out 200ms;

  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

  &:last-of-type {
    margin-bottom: 0;
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    padding: 1.25rem 0;
    border: none;
    border-radius: 0.25rem;
    transform-origin: center;
    ${({ theme }) => `
      background-color: ${theme?.backgroundColor};
      color: ${theme?.backgroundOpposite};
    `}
  }
`;

const Range = forwardRef((props, ref) => {
  const {
    noLabel = false,
    name,
    labelText,
    description,
    unit = "",
    errors = {},
    isLoading = false,
    cta = false,
    required,
    disabled,
    max = 1,
    min = 0,
    isFraction = false,
    step = 0.1,
    ...rest
  } = props;

  const { 1: fieldIndex } = name.split(",") || {};
  const theme = useTheme();
  const { values } = useFormikContext();

  const error =
    _.isArray(errors) && fieldIndex && errors[fieldIndex]
      ? errors[fieldIndex]
      : errors[name];

  const value = useMemo(() => {
    if (!values[name]) return min;
    if (+values[name]) return +values[name];
    if (isFraction) {
      if (typeof values[name] === "string") {
        const { 0: integer, 1: fraction } = values[name].split(" ") || {};

        if (fraction) {
          const { 0: numerator, 1: denominator } = fraction.split("/");
          const float = +numerator / +denominator;
          return +integer + float;
        } else return +values[name];
      } else return values[name];
    } else return values[name];
  }, [isFraction, min, name, values]);

  return (
    <Container cta={cta}>
      <Flex width={1} alignItems="center" justifyContent="space-between">
        {!noLabel && (
          <Label
            required={required}
            mb={!description ? "0.5rem" : "0"}
            htmlFor={name}
          >
            {labelText}
          </Label>
        )}
        <ValueDisplay>
          {isFraction
            ? `${decimalToFraction(values[name] || min)}${unit}`
            : values[name] || min}
        </ValueDisplay>
      </Flex>
      {description && <Helper mb="0.5rem">{description}</Helper>}
      {isLoading ? (
        <ShimmerField />
      ) : (
        <FlexContainer disabled={disabled} cta={cta}>
          <StyledRangeInput
            required={required}
            id={name}
            key={name}
            innerRef={ref}
            name={name}
            disabled={disabled}
            type="range"
            max={max}
            min={min}
            step={step}
            {...rest}
            value={value}
          />
        </FlexContainer>
      )}
      {error && (
        <Body my="0.5rem" color={theme.danger}>
          {error}
        </Body>
      )}
    </Container>
  );
});

export default Range;
