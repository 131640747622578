import React, { useMemo, useCallback, useState, useContext } from "react";
import _ from "lodash";
import * as yup from "yup";
import styled, { useTheme } from "styled-components";
import { Formik, Form } from "formik";
import formatter from "../../../modules/formatter";
import { Heading } from "../../UI/Text";
import { Flex } from "reflexbox";
import { Body } from "../../UI/Text";
import Card from "../../UI/Card";
import Loading from "../../UI/Loading";
import { Checkbox, Field } from "../../UI/forms";
import ButtonCluster from "../../UI/forms/ButtonCluster";
import Modal from "../../UI/Modal";
import Table from "../../UI/Table";
import Button from "../../UI/Button";
import {
  createRMAs,
  updateLine,
  updateQuote,
  downloadFile,
} from "../../../requests/quoteManagementRequests";
import AlertContext from "../../../context/alert/AlertContext";

const LineImg = styled.img`
  max-width: 75px;
  max-height: 75px;
  width: auto !important;
  height: auto;
`;
const ButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-out;
  height: 100%;
`;
const LineTable = ({
  lines = [],
  lineCount,
  quoteId,
  quote,
  lineLoading,
  open,
  printQuote = false,
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [line] = useState({});
  const [loading, setLoading] = useState(false);

  const { showSuccessAlert, showFailureAlert } = useContext(AlertContext);

  // const onRMAClick = useCallback((_line) => {
  //   setShow(true);
  //   setLine(_line);
  // }, []);

  const runCreateDoc = useCallback(
    async (row) => {
      const { PricingDocLink } = row?.original || row || {};

      console.log(PricingDocLink);
      var docPath = PricingDocLink;
      if (!docPath) return showFailureAlert("No Document Available.");
      var filename = docPath.replace(/^.*[\\/]/, "");

      downloadFile(
        PricingDocLink.replace(
          "C:/inetpub/wwwroot/",
          "https://configure.wmwmeyer.com/"
        ),
        filename,
        "application/pdf"
      );
    },
    [showFailureAlert]
  );

  /// Make a multi line text input
  const runCreateRMA = useCallback(
    async (values) => {
      setLoading(true);
      const { enableRmaDispositions, reason, quantity } = values || {};
      if (!quote.rmaCreated)
        await updateQuote({
          _id: quote?._id,
          id: quote?.id,
          input: { rmaCreated: true },
        });
      await updateLine({ id: line.id, input: { rmaCreated: true } });

      console.log("runCreateRMA", { line, lines });
      const lineNum = _.findIndex(lines, ({ id }) => id === line.id) + 1;

      const rmaData = [
        {
          Action: 1,
          ItemId: "PBT=SLRmas",
          Properties: [
            {
              IsNull: false,
              Modified: true,
              Name: "RmaNum",
              Value: `RMA0000${quoteId}`,
            },
            { IsNull: false, Modified: true, Name: "CustSeq", Value: "1" },
            {
              IsNull: false,
              Modified: true,
              Name: "CustNum",
              Value: "      5",
            },
            { IsNull: false, Modified: true, Name: "Whse", Value: "MAIN" },
            { IsNull: false, Modified: true, Name: "CurrCode", Value: "USD" },
            { IsNull: false, Modified: true, Name: "TaxCode1", Value: "CA" },
          ],
        },
      ];

      const lineData = [
        {
          Action: 1,
          ItemId: "PBT=SLRmas",
          Properties: [
            {
              IsNull: false,
              Modified: true,
              Name: "RmaNum",
              Value: `RMA0000${quoteId}`,
            },
            {
              IsNull: false,
              Modified: true,
              Name: "QtyToReturn",
              Value: `${quantity}`,
            },
            {
              IsNull: false,
              Modified: true,
              Name: "QtyToReturnConv",
              Value: `${quantity}.00000000`,
            },
            {
              IsNull: false,
              Modified: true,
              Name: "RmaLine",
              Value: `${lineNum}`,
            },
            {
              IsNull: false,
              Modified: true,
              Name: "ReasonText",
              Value: reason,
            },
            { IsNull: false, Modified: true, Name: "Item", Value: "BB10" },
            { IsNull: false, Modified: true, Name: "UM", Value: "EA" },
            {
              IsNull: false,
              Modified: true,
              Name: "EnableRmaDispositions",
              Value: enableRmaDispositions ? "1" : "0",
            },
          ],
        },
      ];
      createRMAs({
        data: { rmaData: quote.rmaCreated ? undefined : rmaData, lineData },
      });

      setShow(false);
      showSuccessAlert("RMA Created!");
      setLoading(false);
    },
    [line, lines, quote, quoteId, showSuccessAlert]
  );

  const columns = useMemo(() => {
    const cols = [
      {
        id: "imgUrl",
        data: (row) => (
          <Flex
            width={1}
            alignItems="center"
            height="100%"
            key={row?.id + "|imgUrl"}
            justifyContent="center"
          >
            {row?.imgUrl?.startsWith("http") ||
            row?.imgUrl?.startsWith("/") ||
            row?.imgUrl?.startsWith("C:") ? (
              <LineImg
                src={(row?.imgUrl || "")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace("\\", "/")
                  .replace(String.fromCharCode(92), "/")
                  .replace(String.fromCharCode(92, 92), "//")
                  .replace(
                    "C:/inetpub/wwwroot/",
                    "https://configure.wmwmeyer.com/"
                  )}
                alt={row?.id || "imgUrl"}
              />
            ) : null}
          </Flex>
        ),
        header: "Preview",
        size: { md: 2, sm: -1, xs: -1 },
        sort: false,
      },
      {
        id: "partNumber",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="center"
            alignItems="center"
            key={row?.id + "|partNumber"}
          >
            <Body color={row.id === "new" ? theme.secondary : undefined} center>
              {row.partNumber}
            </Body>
          </Flex>
        ),
        header: "Part #",
        size: { md: 2, sm: 2, xs: 2 },
        sort: false,
      },

      {
        id: "description",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            key={row?.id + "|description"}
          >
            <Body>{row.description}</Body>
          </Flex>
        ),
        header: "Description",
        size: { md: 4, sm: 3, xs: 0 },
        sort: false,
      },
      {
        id: "quantity",
        data: (row) => {
          return row.id === "new" ? null : <Body center>{row?.quantity}</Body>;
        },
        header: "Quantity",
        size: { md: 2, sm: 2, xs: 2 },
        sort: false,
      },
      {
        id: "unitPrice",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
            key={row?.id + "|price"}
          >
            <Body right>${formatter.currency(row.price)}</Body>
          </Flex>
        ),
        header: "Unit Price",
        size: { md: 2, sm: -1, xs: -1 },
        sort: false,
      },
      {
        id: "totalPrice",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
            key={row?.id + "|price"}
          >
            <Body right>${formatter.currency(row.price * row.quantity)}</Body>
          </Flex>
        ),
        header: "Total",
        size: { md: 2, sm: 2, xs: 2 },
        sort: false,
      },
      {
        id: "Details",
        header: "",
        data: (row) => {
          return (
            <Flex width={1} flexDirection="column">
              {(row?.Details ? JSON.parse(row?.Details) : []).map((Details) => {
                const { Caption, Value } = Details || {};
                if (!Value) return null;
                return (
                  <Flex key={Caption + Value} width={1}>
                    <Body flex={7} bold>
                      {Caption}
                    </Body>
                    <Body right>${formatter.currency(Value)}</Body>
                  </Flex>
                );
              })}
            </Flex>
          );
        },
        sort: false,
        size: { xs: 0 },
      },
    ];
    if (quote?.type === 2 && !printQuote)
      cols.push({
        id: "actions",
        header: "Actions",
        data: (row) => {
          return (
            <ButtonsWrapper
              id="actions"
              m="0 auto"
              height="100%"
              key={row?.id + "|actions"}
            >
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faFileCirclePlus"
                onClick={() => runCreateDoc(row)}
              />
            </ButtonsWrapper>
          );
        },
        size: { md: 2, sm: 2, xs: 2 },
        sort: null,
      });
    return cols;
  }, [printQuote, quote, runCreateDoc, theme.secondary]);

  const initialValues = useMemo(() => {
    return {
      reason: "",
      quantity: 1,
      enableRmaDispositions: false,
    };
  }, []);

  const schema = useMemo(
    () =>
      yup.object().shape({
        reason: yup.string().required("Please state a reason for this RMA"),
        quantity: yup
          .number()
          .max(
            line.quantity,
            `Quantity can be no greater than ${line.quantity}`
          )
          .required(),
        enableRmaDispositions: yup.boolean(),
      }),
    [line]
  );

  return (
    <>
      <Modal show={show} setShow={setShow}>
        <Card width={["300px", "350px", "400px", "500px"]}>
          <Heading>Create RMA</Heading>

          <Formik
            onSubmit={runCreateRMA}
            initialValues={initialValues}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {() => {
              return (
                <Form style={{ width: "100%" }}>
                  <Field labelText="Reason for RMA" name="reason" />
                  <Field
                    labelText="Quantity to Return"
                    name="quantity"
                    type="number"
                  />

                  <Checkbox
                    labelText="Enable RMA Dispositions"
                    name="enableRmaDispositions"
                  />
                  {loading ? (
                    <Loading relative />
                  ) : (
                    <ButtonCluster hideDelete onCancel={() => setShow(false)} />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Modal>
      <Table
        hideBack
        hideCreate
        hideSearch
        open={open}
        hideProperties
        hidePager
        isLoading={lineLoading}
        data={lines}
        columns={columns}
        totalCount={lineCount}
      />
    </>
  );
};

export default LineTable;
