import axios from "axios";
import ErrorHandler from "../modules/errorHandler";

export const start = async ({ integrationParams = [], ...rest }) => {
  try {
    console.log("start");
    const response = await axios.post("/api/v1/configurator/start", {
      integrationParams,
      ...rest,
    });

    const { prepare, initialize, ui } = response?.data?.result || {};

    return { prepare, initialize, ui };
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return null;
  }
};

export const configure = async (body) => {
  try {
    const res = await axios.post("/api/v1/configurator/configure", body);

    if (res.status !== 200) throw Error(res.statusText);
    return res;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return null;
  }
};

export const finish = async (body) => {
  try {
    const response = await axios.post("/api/v1/configurator/finish", body);
    return response;
  } catch (error) {
    console.error(error);
    ErrorHandler.logError(error);
    return null;
  }
};
