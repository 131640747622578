import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Field, FastField, useField, useFormikContext } from "formik";
import { Box, Flex } from "reflexbox/styled-components";
import { Body, Helper } from "../../Text";
import Label from "../Label";
import Card from "../../Card";
import styled, { useTheme } from "styled-components";
import { ShimmerField } from "../../Shimmer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-number-input";

const ToolTip = styled(Box)`
  position: absolute;
  top: 0;
  right: 0.5rem;
  cursor: pointer;

  z-index: 20;
  > div {
    right: 0;
    position: absolute;
    width: 10rem;
    z-index: 20;
    display: ${({ show }) => (show ? "flex" : "none")};
  }
`;

const Container = styled(Flex)`
  width: 100%;
  position: relative;
  margin: auto auto 1rem auto;
  flex-direction: column;
  input {
    ${({ readOnly }) => (readOnly ? "display: none;" : "")}
    margin: 0;
  }

  .PhoneInput {
    display: flex;
    width: 100%;
    > input {
      width: 100%;
    }
  }
  .PhoneInputCountry {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    select {
      width: 100%;
      padding-right: 0;
    }
    svg {
      max-width: 1.5rem;
      flex: 1;
    }
    > div {
      width: 2rem;
    }
  }
`;
const Input = styled.input``;

const FlexContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  position: relative;

  transition: all ease-in-out 200ms;

  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};

  svg {
    position: absolute;
    top: 0.75rem;
    z-index: 0;
    transition: all ease-in 200ms;
    ${({ theme, cta }) => `
      color: ${cta ? "white" : theme?.backgroundOpposite};
      right: ${cta ? "1rem" : "0.5rem"};
    `}
  }

  select {
    ${({ theme }) => `
      background-color: ${theme?.backgroundColor};
      color: ${theme?.backgroundOpposite};
      option {
        color: ${theme.backgroundOpposite} !important;
        background-color: ${theme.backgroundColor} !important;
      }
    `}
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  input {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  }
  select {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  select,
  input {
    -webkit-appearance: none;
    appearance: none;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    transform-origin: center;
    transition: all ease-in-out 200ms;

    border: 2px solid ${({ theme }) => theme?.borderColor};
    ${({ theme }) => `
      background-color: ${theme?.backgroundColor};
      color: ${theme?.backgroundOpposite};
      &:focus, &:hover {
        border-color: ${theme.secondary};
      }
    `}
  }

  select {
    z-index: 1;
    background-color: transparent;
    padding-right: 2rem;
  }
`;

const StyledFormikField = forwardRef((props, ref) => {
  const {
    isInput = false,
    noLabel = false,
    name,
    labelText,
    description,
    type,
    isLoading = false,
    cta = false,
    required,
    fastField,
    disabled,
    phone = false,
    readOnly,
    options,
    as,
    tooltip,
    component,
    ...rest
  } = props;

  const theme = useTheme();
  let Component = isInput ? Input : Field;
  if (fastField) Component = FastField;
  const [field, meta] = useField(name);

  const { setFieldValue, values } = useFormikContext();

  const { error } = meta || {};

  const [show, setShow] = useState(false);

  const _ref = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (_ref.current && !_ref.current.contains(e.target) && show) {
        setShow(false);
      }
    },
    [_ref, show]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [handleClickOutside, show]);

  return (
    <Container readOnly={readOnly} cta={cta}>
      {(!noLabel || tooltip) && (
        <Label
          required={required}
          mb={!description ? "0.5rem" : "0.5rem"}
          htmlFor={name}
          style={{ display: "flex" }}
        >
          {labelText}
          {tooltip && (
            <>
              <ToolTip ref={_ref} onClick={() => setShow(!show)} show={show}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <Card>
                  <Body>{tooltip}</Body>
                </Card>
              </ToolTip>
            </>
          )}
        </Label>
      )}

      {description && <Helper mb="0.5rem">{description}</Helper>}
      {isLoading ? (
        <ShimmerField />
      ) : (
        <FlexContainer disabled={disabled} cta={cta}>
          {phone ? (
            <PhoneInput
              readOnly={readOnly}
              required={required}
              id={name}
              key={name}
              name={name}
              disabled={disabled}
              type={type}
              ref={ref}
              defaultCountry="US"
              as={as}
              value={values[name] || ""}
              onChange={(e) => {
                console.log(e);
                setFieldValue(name, e?.target?.value || "");
              }}
              component={component}
              {...rest}
            />
          ) : (
            <Component
              readOnly={readOnly}
              required={required}
              id={name}
              key={name}
              innerRef={ref}
              name={name}
              disabled={disabled}
              type={type}
              options={options}
              as={as}
              component={component}
              {...field}
              {...rest}
            />
          )}
          {[as, component].includes("select") && (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </FlexContainer>
      )}
      {error && (
        <Body my="0.5rem" color={theme.danger}>
          {error}
        </Body>
      )}
    </Container>
  );
});

export default StyledFormikField;
