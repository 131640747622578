import axios from "axios";

export const getUser = async ({ username, signal }) => {
  const { status, data } = await axios.post("/api/v1/userManagement/getUser", {
    username,
    signal,
  });

  if (status === 200 && data.success === true) return data;
  return null;
};

export const getUsers = async ({
  limit,
  offset,
  order,
  search,
  signal,
  attributes,
}) => {
  const { status, data } = await axios.post("/api/v1/userManagement/getUsers", {
    limit,
    offset,
    order,
    search,
    signal,
    attributes,
  });
  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};

export const createUser = async ({ input }) => {
  const res = await axios.post("/api/v1/userManagement/createUser", {
    input,
  });
  if (res?.status === 200) return res?.data;

  return null;
};
export const updateUser = async ({ id, input }) => {
  const res = await axios.post("/api/v1/userManagement/updateUser", {
    id,
    input,
  });
  if (res?.status === 200) return res?.data;

  return null;
};
export const deleteUser = async ({ id }) => {
  const res = await axios.post("/api/v1/userManagement/deleteUser", { id });
  if (res?.status === 200) return res?.data;

  return null;
};
export const deleteUsers = async ({ users, self }) => {
  const res = await axios.post("/api/v1/userManagement/deleteUsers", {
    users,
    self,
  });
  if (res?.status === 200) return res?.data;

  return null;
};

export const forgotPassword = async ({ username }) => {
  const res = await axios.post("/api/v1/auth/forgotPassword", {
    username: username,
  });

  if (res?.status === 200) return res?.data;

  return null;
};

export const finishDemoEmail = async ({ email, password }) => {
  const res = await axios.post("/api/v1/auth/finishDemoEmail", {
    email,
    password,
  });
  if (res.status === 200) return res.data;

  return null;
};

export const getPasswordResetRequest = async ({ passwordResetRequestId }) => {
  const res = await axios.post(
    "/api/v1/userManagement/getPasswordResetRequest",
    {
      passwordResetRequestId,
    }
  );

  if (res?.status === 200) return res?.data;

  return null;
};

export const emailNewUserPassword = async ({ username, password }) => {
  const res = await axios.post("/api/v1/administration/emailNewUserPassword", {
    username: username,
    password: password,
  });

  if (res.status === 200) return res.data;

  return null;
};
