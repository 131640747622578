import { createContext } from "react";

const UserContext = createContext({
  activeUser: {},
  darkMode: false,
  setDarkMode: () => {},
  setActiveUser: () => {},
  isLoading: true,
});

export default UserContext;
