import axios from "axios";

/**
 * Have a create, update and delete request to the API
 */

// Use async await to halt the program until this request completes

export const getProducts = async (values) => {
  const { status, data } = await axios.post(
    "/api/v1/productManagement/getProducts",
    {
      ...values,
    }
  );
  if (status === 200 && data.success === true) {
    return data.result;
  }
  return [];
};

export const getProduct = async ({ id }) => {
  const result = await axios.post("/api/v1/productManagement/getProduct", {
    id,
  });

  if (result) return result.data;
  return null;
};

export const createProduct = async ({ input }) => {
  const result = await axios.post("/api/v1/productManagement/createProduct", {
    input,
  });

  if (result) return result.data;
  return null;
};

export const updateProduct = async ({ id, input }) => {
  const result = await axios.post("/api/v1/productManagement/updateProduct", {
    id,
    input,
  });

  if (result) return result.data;
  return null;
};

export const deleteProduct = async (id) => {
  const result = await axios.post("/api/v1/productManagement/deleteProduct", {
    id,
  });
  if (result) return result.data;
  return null;
};

// export const createProduct = async ({ input }) => {
//   // Target the createProduct service
//   const res = await axios.post("/api/v1/productManagement/createProduct", {
//     input,
//   });
//   // If the request is successful giving a status of 200 return the data
//   if (res?.status === 200) return res?.data;
//   // Otherwise, we handle the error and return null
//   ErrorHandler.logError(res);
//   alerts.showFailureAlert(res);
//   return null;
// };
