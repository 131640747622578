import React, { createContext, useState } from "react";

export const PagerContext = createContext({
  limit: 12,
  offset: 0,
  order: ["id", "ASC"],
  search: "",
});

const PagerContextProvider = ({ children, defaultOrder }) => {
  const [limit, setLimit] = useState(12);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState(defaultOrder || ["id", "ASC"]);
  const [search, setSearch] = useState("");

  return (
    <PagerContext.Provider
      value={{
        limit,
        setLimit,
        offset,
        setOffset,
        order,
        setOrder,
        search,
        setSearch,
      }}
    >
      {children}
    </PagerContext.Provider>
  );
};

export default PagerContextProvider;
