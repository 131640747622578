import React from "react";
import styled, { keyframes } from "styled-components";
import { Flex } from "reflexbox";

const ShimmerAnimation = keyframes`
0% {
    background-position: 100% 0;
}
100% {
    background-position: -100% 0;
}
`;

const Shimmer = styled("span")`
  width: ${({ width = "100%" }) => width};
  height: ${({ height = "16px" }) => height};
  animation: ${ShimmerAnimation} 2s infinite linear;
  background: linear-gradient(
    to right,
    #a9a9a9 15%,
    #d3d3d3 25%,
    #d3d3d3 30%,
    #a9a9a9 40%
  );
  background-size: 200% 100%;
  opacity: 0.5;
  padding: 0.25rem 0;
  display: block;
  border-radius: 15px;
  ${({ delay }) => delay && `animation-delay: ${delay}s;`}
`;

const ShimmerFieldWrapper = styled(Flex)`
  height: 38px;
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  align-items: center;
  padding: 0.5rem;
  margin: 0;
  justify-content: flex-start;
`;

export const ShimmerField = () => (
  <ShimmerFieldWrapper>
    <Shimmer />
  </ShimmerFieldWrapper>
);

export default Shimmer;
