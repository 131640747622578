import _ from "lodash";
import { useState, useEffect, useCallback } from "react";
import { useContext, useRef, useMemo } from "react";
import { PagerContext } from "../UI/Pager/PagerContext";
import UserContext from "../../context/user/UserContext";

const useQuery = ({
  queryID,
  request,
  queryVariables,
  skip: _skip = false,
  forcedFilters = null,
  attributes = null,
  queries: _queries,
  setQueries: _setQueries,
}) => {
  const pagerContext = useContext(PagerContext);
  const {
    queries: __queries,
    setQueries: __setQueries,
    activeUser,
    platform,
    loggedIn,
  } = useContext(UserContext);

  const { queries, setQueries } = useMemo(
    () =>
      !!_queries && !!_setQueries
        ? { queries: { ..._queries }, setQueries: _setQueries }
        : { queries: { ...__queries }, setQueries: __setQueries },
    [__queries, __setQueries, _queries, _setQueries]
  );

  const cache = useRef({ ...queries?.[queryID] });
  const skip = useMemo(() => {
    if (!activeUser && loggedIn) return true;
    if (!platform && queryID !== "platform") return true;
    if (_skip) return true;
  }, [_skip, activeUser, loggedIn, platform, queryID]);

  const { limit, offset, order, search } = forcedFilters
    ? forcedFilters
    : pagerContext;

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const refetchQuery = useCallback(
    (id) => {
      const q = { ...queries, [id]: undefined };
      setQueries(q);
      setShouldRefetch(true);
    },
    [queries, setQueries]
  );

  const setData = useCallback(
    (newData) => {
      cache.current.data = newData;
      setQueries({
        ...queries,
        [queryID]: { ...(queries[queryID] || {}), data: newData },
      });
    },
    [queries, queryID, setQueries]
  );

  const initialized = useMemo(() => !!queries[queryID], [queries, queryID]);

  const setInitialState = useCallback(() => {
    setQueries({
      ...queries,
      [queryID]: { isLoading: true, data: {}, setData, refetchQuery },
    });
  }, [queries, queryID, refetchQuery, setData, setQueries]);

  useEffect(() => {
    if (!initialized) setInitialState();
  }, [initialized, setInitialState]);

  const fetchData = useCallback(
    async ({ signal }) => {
      if (skip) return;
      if (isLoading) return;

      if (cache.current.data?.refetched) {
        cache.current = {
          data: queries[queryID]?.data,
          refetched: false,
          refetchQuery,
          setData,
          isLoading,
          pagerVariables: { limit, offset, order, search },
          queryVariables,
        };

        setShouldRefetch(false);
        return;
      }

      setShouldRefetch(false);
      setLoading(true);

      const result = await request({
        signal,
        limit,
        offset,
        order,
        search,
        ...queryVariables,
        attributes,
      });

      cache.current = {
        data: result,
        refetched: true,
        refetchQuery,
        setData,
        isLoading,
        pagerVariables: { limit, offset, order, search },
        queryVariables,
      };
      if (setQueries)
        setQueries({
          ...queries,
          [queryID]: {
            data: result,
            refetchQuery,
            setData,
            isLoading,
            refetched: true,
            pagerVariables: { limit, offset, order, search },
            queryVariables,
          },
        });

      setLoading(false);

      return result;
    },
    [
      attributes,
      isLoading,
      limit,
      offset,
      order,
      queries,
      queryID,
      queryVariables,
      refetchQuery,
      request,
      search,
      setData,
      setQueries,
      skip,
    ]
  );

  useEffect(() => {
    if (!cache.current.data) {
      setShouldRefetch(true);
    }
  }, []);

  useEffect(() => {
    if (
      cache.current.data &&
      !_.isEqual(cache.current.pagerVariables, { limit, offset, order, search })
    ) {
      setShouldRefetch(true);
    }
  }, [limit, offset, order, search]);

  useEffect(() => {
    if (
      cache.current.data &&
      !_.isEqual(cache.current.queryVariables, queryVariables)
    ) {
      setShouldRefetch(true);
    }
  }, [queryVariables]);

  useEffect(() => {
    if (shouldRefetch) {
      const controller = new AbortController();
      fetchData({ signal: controller.signal });
      return () => controller.abort();
    }
  }, [fetchData, shouldRefetch]);

  return {
    data: cache.current.data,
    isLoading,
    refetchQuery,
    refetched: true,
    setData,
    pagerVariables: { limit, offset, order, search },
    queryVariables,
  };
};

export default useQuery;
