import React from "react";
import { Heading, Body, Currency } from "../UI/Text";
import { Flex } from "reflexbox";
import Table from "../UI/Table";
import Button from "../UI/Button";
import styled, { useTheme } from "styled-components";
import { useMemo } from "react";
import { useQuery } from "../Hooks";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../requests/productManagementRequests";
import { PageWrapper } from "../UI/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const LineImg = styled.img`
  max-width: 75px;
  max-height: 75px;
  width: auto;
  height: auto;
`;

const ProductTable = ({ toggle, ...props }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryID: "productspage",
    request: getProducts,
  });
  const { rows = [], count } = data || {};

  const gridData = rows.map((row) => {
    const { ID, ImageUrl, Description, Name } = row || {};
    return { id: ID, image: ImageUrl, subtitle: Description, title: Name };
  });

  const columns = useMemo(
    () => [
      {
        id: "imgUrl",
        data: (row) => (
          <Body center>
            {row?.ImageUrl ? (
              <LineImg src={row?.ImageUrl} alt={row?.id || "imgUrl"} />
            ) : (
              <FontAwesomeIcon
                size="2x"
                style={{ color: theme.danger }}
                icon={faFileCircleExclamation}
              />
            )}
          </Body>
        ),
        header: "Preview",
        size: { md: -1, sm: -1, xs: -1 },
      },
      {
        id: "ProductNumber",
        data: "ProductNumber",
        header: "Part #",
        size: { md: 1, sm: 2, xs: -1 },
      },
      {
        id: "Name",
        data: "Name",
        size: { lg: 2, md: -1, sm: -1, xs: -1 },
      },
      {
        id: "Description",
        data: "Description",
        sort: false,
        size: { md: 3, sm: 0, xs: -1 },
      },
      {
        id: "defaultPrice",
        header: "Price",
        data: (row) => {
          return row?.defaultPrice ? (
            <Currency value={row.defaultPrice} />
          ) : (
            <Body right>—</Body>
          );
        },
        sort: false,
        size: { md: 2, sm: 0, xs: -1 },
      },
      {
        id: "quantityInStock",
        data: (row) => <Body center>{row?.quantityInStock || "—"}</Body>,
        header: "In Stock (#)",
        size: { md: 1, sm: 2, xs: 0 },
      },

      {
        id: "Mobile-Icons",
        data: "ImageUrl",
        display: ({ getValue, row }) => {
          let Name = row.getValue("Name");
          let Description = row.getValue("Description");
          let ID = row.getValue("ProductNumber");
          let ImageUrl = getValue();
          return (
            <Flex flexDirection="column" alignItems="center">
              <Flex
                sx={{ width: "100%" }}
                flexDirection="row"
                alignItems="center"
              >
                <Heading
                  style={{
                    flexGrow: 1,
                    width: "calc(100% - 50px)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Name}
                </Heading>
                <Button
                  reverse
                  to={`/Products/edit/${ID}`}
                  color={theme.secondary}
                  iconName="faPencil"
                />
              </Flex>
              <img alt={Name} src={ImageUrl} />
              <Body
                style={{
                  padding: "0.5rem",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  maxHeight: "5rem",
                }}
              >
                {Description}
              </Body>
            </Flex>
          );
        },
        header: "",
        sort: false,
        size: { sm: -1, xs: 1 },
      },
    ],
    [theme.danger, theme.secondary]
  );

  const click = (row) =>
    navigate(`/Products/edit/${row?.original?.ID || row?.ID || row?.id}`);

  return (
    <PageWrapper>
      <Table
        title="Products"
        createLink="/Products/create"
        backButtonLink="/Administration"
        createButtonIcon="faDolly"
        createButtonText="Create Product"
        onCardClick={click}
        onRowClick={click}
        data={rows}
        gridData={gridData}
        columns={columns}
        totalCount={count}
        isLoading={isLoading}
        {...props}
      />
    </PageWrapper>
  );
};

export default ProductTable;
