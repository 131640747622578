import React from "react";
import { useDropzone } from "react-dropzone";
import styled, { useTheme } from "styled-components";
import { Heading } from "../../Text";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Flex } from "reflexbox";

const DropTarget = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  border: dashed 2px ${({ theme }) => theme.secondary};
  border-radius: ${({ theme }) => theme.borderRadius};
  position: relative;
  div {
    margin: 0;
  }
  svg {
    margin-left: 1rem;
    width: 70px;
    height: auto;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const processUpload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const uploadedFile = await axios.post("/api/uploadfile", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return uploadedFile;
};

// const checkFileType = (filetype) => {
//   if (filetype === "video") {
//     return "video/mp4,video/x-m4v,video/*";
//   }
//   if (filetype === "officedoc") {
//     return `application/msword,  application/vnd.ms-excel,
//       application/vnd.ms-powerpoint,
//       application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
//       application/vnd.openxmlformats-officedocument.presentationml.presentation,
//       application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
//   }
//   if (filetype === "pdf") {
//     return "application/pdf";
//   }
//   return "image/*";
// };

const FileInput = (props) => {
  const theme = useTheme();
  const { multiple = false, onSubmit } = props || {};

  // const accept = checkFileType(filetype);
  const headerText = multiple ? "Files" : "a File";

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      files.forEach(async (file) => {
        await processUpload(file);
        onSubmit(file);
      });
    },
    multiple,
  });

  return (
    <Flex width={1} {...getRootProps()}>
      <input {...getInputProps()} />
      <DropTarget>
        <Heading>Upload {headerText}</Heading>
        <FontAwesomeIcon color={theme.secondary} icon={faCloudArrowUp} />
      </DropTarget>
    </Flex>
  );
};

export default FileInput;
