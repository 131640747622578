import React, { useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import * as yup from "yup";
import HeadingSection from "../UI/HeadingSection";
import { Form, Formik } from "formik";
import { Field, FormButtonCluster } from "../UI/forms";
import * as r from "../../requests/addressManagementRequests";
import AlertContext from "../../context/alert/AlertContext";
import states from "../../modules/states";

const addressTypes = [
  { name: "Billing", value: 1 },
  { name: "Shipping", value: 2 },
];

const validationSchema = yup.object().shape({
  Address: yup.string(),
  Address2: yup.string(),
  City: yup.string(),
  State: yup.string(),
  Zip: yup.number("Incorrect format for Zip"),
  AddressType: yup.number(),
});

const AddressForm = ({
  data,
  count,
  addressId,
  setAddressId,
  currentAddress,
  setShow,
  hasForm = true,
  setData,
}) => {
  const { showSuccessAlert } = useContext(AlertContext);

  const { Address, Address2, City, State, Zip, AddressType } =
    currentAddress || {};

  const initialValues = {
    Address: Address || "",
    Address2: Address2 || "",
    City: City || "",
    State: State || "",
    Zip: Zip || "",
    AddressType: AddressType || 1,
  };

  const onSubmit = useCallback(
    async (_values, { resetForm }) => {
      try {
        const { Zip: _Zip } = _values;
        const values = { ..._values, Zip: _Zip === "default" ? null : _Zip };

        const { rows } = data;

        if (addressId === "create") {
          const result = await r.createAddress({ input: values });
          const newObj = {
            ...result,
            sameAsShipping: "0",
          };

          const newRows = _.sortBy([...rows, newObj], ["displayOrder"]);

          const newData = { rows: newRows, count: count + 1 };
          setAddressId(newObj.id);

          showSuccessAlert(`Successfully created Address: ${newObj.id}`);
          setData(newData);
          setShow(false);
        } else {
          const updatedRecord = await r.updateAddress({
            id: addressId,
            input: values,
          });

          showSuccessAlert(`Successfully updated Address: ${addressId}`);

          const newRows = _.sortBy(
            rows.map((row) => {
              const { id: rowId } = row;
              if (rowId === addressId) {
                return updatedRecord;
              }
              return row;
            }),
            ["displayOrder"]
          );

          const newData = { ...data, rows: newRows };
          setData(newData);
          resetForm();
          setShow(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [data, addressId, count, setAddressId, showSuccessAlert, setData, setShow]
  );

  const stateOptions = useMemo(
    () =>
      states.getStates().map(({ name, abbreviation: a }) => (
        <option value={a} key={a}>
          {name}
        </option>
      )),
    []
  );

  const onDelete = useCallback(() => {
    r.deleteAddress({ id: addressId });
    const { rows } = data;
    const newRows = [...rows];
    _.remove(newRows, (row) => row.id === addressId);
    setData({ rows: newRows, count: count - 1 });
    setShow(false);
  }, [addressId, count, data, setData, setShow]);

  return hasForm ? (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formProps) => {
        const { values } = formProps;
        return (
          <>
            <HeadingSection
              my="1rem"
              hideCreate
              hideBack
              backButtonLink={() => setShow(false)}
              title={`${addressId === "create" ? "Create" : "Edit"} Address`}
            />
            <Form>
              <Field
                autoFocus={addressId === "create"}
                labelText="Address Line 1"
                name="Address"
              />
              <Field labelText="Address Line 2" name="Address2" />

              <Field as="select" name="State" labelText="State">
                <option value="" key="default">
                  Select a State
                </option>
                {stateOptions}
              </Field>
              <Field name="City" labelText="City" />
              <Field name="Zip" labelText="Zip Code" />

              <Field
                name="AddressType"
                as="select"
                labelText="Address Type"
                description="Setting this value will assign this Address to a platform, allowing only users assigned to that platform to view it."
              >
                {addressTypes.map((type) => (
                  <option
                    key={type.value}
                    label={type.name}
                    value={type.value}
                  />
                ))}
              </Field>
              <FormButtonCluster
                onDelete={onDelete}
                onCancel={() => setShow(false)}
                hideDelete={addressId === "create"}
                recordAction={addressId === "create" ? "create" : "edit"}
                deleteMessage={`Are you sure you want to delete ${values.name}?`}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  ) : (
    <>
      <Field
        autoFocus={addressId === "create"}
        labelText="Address Line 1"
        name="Address"
      />
      <Field labelText="Address Line 2" name="Address2" />

      <Field as="select" name="State" labelText="State">
        <option value="" key="default">
          Select a State
        </option>
        {stateOptions}
      </Field>
      <Field name="City" labelText="City" />
      <Field name="Zip" labelText="Zip Code" />
    </>
  );
};

export default AddressForm;
