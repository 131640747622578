import axios from "axios";

export const getRoles = async ({ signal, roleLevel }) => {
  try {
    const res = await axios.post("/api/v1/roleManagement/getRoles", {
      signal,
      roleLevel,
    });

    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createRole = async ({ input }) => {
  try {
    const res = await axios.post("/api/v1/roleManagement/createRole", {
      input,
    });

    if (res?.status === 200) return res.data.result;

    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateRole = async ({ input, id }) => {
  try {
    const res = await axios.post("/api/v1/roleManagement/updateRole", {
      input,
      id,
    });

    if (res?.status === 200) return res.data.result;

    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteRole = async ({ id }) => {
  try {
    const res = await axios.post("/api/v1/roleManagement/deleteRole", {
      id,
    });

    if (res?.status === 200) return res.data.result;

    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
