import React, { useMemo, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import styled, { useTheme } from "styled-components";
import Button from "../UI/Button";
import { Body, Heading } from "../UI/Text";
import { Flex } from "reflexbox";
import AlertContext from "../../context/alert/AlertContext";

const LineImg = styled.img`
  max-width: 75px;
  max-height: 75px;
  width: auto;
  height: auto;
`;

const Quantity = ({ row, onProductClick }) => {
  const theme = useTheme();
  const [quantity, setQuantity] = useState(1);
  const { showFailureAlert } = useContext(AlertContext);
  return (
    <>
      {row?.original?.isNonConfigurable ? (
        <Flex
          width="auto !important"
          height="100%"
          justifyContent="center"
          alignItems="center"
          key={row?.id + "|quantity"}
        >
          <Button
            iconName="faMinus"
            reverse
            size="tiny"
            color={theme.secondary}
            onClick={() =>
              quantity > 1
                ? setQuantity(quantity - 1)
                : showFailureAlert(
                    "Cannot set quantity to a number less than 1."
                  )
            }
          />

          <Body mx="0.5rem" bold center>
            {quantity}
          </Body>

          <Button
            iconName="faPlus"
            reverse
            size="tiny"
            color={theme.secondary}
            onClick={() => setQuantity(quantity + 1)}
          />
        </Flex>
      ) : null}

      <Button
        onClick={() =>
          row?.original?.isNonConfigurable
            ? onProductClick(row?.original?.ID, undefined, quantity)
            : onProductClick(row?.original?.ID)
        }
        iconName="faPlus"
      />
    </>
  );
};

export const Columns = (onProductClick) => {
  const theme = useTheme();

  return useMemo(
    () => [
      {
        id: "imgUrl",
        data: (row) => (
          <Body center>
            {row?.ImageUrl ? (
              <LineImg src={row?.ImageUrl} alt={row?.id || "imgUrl"} />
            ) : (
              <FontAwesomeIcon
                size="2x"
                style={{ color: theme.danger }}
                icon={faFileCircleQuestion}
              />
            )}
          </Body>
        ),
        header: "Preview",
        size: { md: -1, sm: -1, xs: -1 },
      },
      {
        id: "ProductNumber",
        data: "ProductNumber",
        header: "Part #",
        size: { md: 1, sm: 2, xs: -1 },
      },
      {
        id: "Name",
        data: "Name",
        size: { lg: 2, md: -1, sm: -1, xs: -1 },
      },
      {
        id: "Description",
        data: "Description",
        sort: false,
        size: { md: 3, sm: 0, xs: -1 },
      },
      {
        id: "quantityInStock",
        data: (row) => <Body center>{row?.quantityInStock}</Body>,
        header: "In Stock (#)",
        size: { md: 1, sm: 2, xs: 0 },
      },
      {
        id: "Actions",
        display: (props) => (
          <Flex
            style={{ gap: "1rem" }}
            width={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Quantity row={props.row} onProductClick={onProductClick} />
          </Flex>
        ),
        header: "Add To Quote",
        sort: false,
        size: { lg: 2, md: 3, sm: 3, xs: -1 },
      },
      {
        id: "Mobile-Icons",
        data: "ImageUrl",
        display: ({ row }) => {
          let Name = row.getValue("Name");
          let Description = row.getValue("Description");
          let ID = row.getValue("ProductNumber");

          return (
            <Flex flexDirection="column" alignItems="center">
              <Flex
                sx={{ width: "100%" }}
                flexDirection="row"
                alignItems="center"
              >
                <Heading
                  style={{
                    flexGrow: 2,
                    width: "calc(100% - 50px)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Name}
                </Heading>
                <Button
                  reverse
                  to={`/Products/edit/${ID}`}
                  color={theme.secondary}
                  iconName="faPencil"
                />
              </Flex>
              <img alt={Name} src={row?.ImageUrl} />
              <Body
                style={{
                  padding: "0.5rem",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  maxHeight: "5rem",
                }}
              >
                {Description}
              </Body>
            </Flex>
          );
        },
        header: "",
        sort: false,
        size: { sm: -1, xs: 1 },
      },
    ],
    [onProductClick, theme.danger, theme.secondary]
  );
};
