import React, { useEffect, useRef, useContext } from "react";
import { Input } from "../forms";
import Button from "../Button";
import { Flex } from "reflexbox";
import { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PagerContext } from "../Pager/PagerContext";

const Wrap = styled(Flex)`
  [type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }
`;

const SearchButton = styled(Button)`
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  width: 50px;
  border-radius: 0px 15px 15px 0px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  padding-right: 0.25rem;
`;

const DeleteButton = styled("span")`
  position: absolute;
  display: block;
  right: 4.5rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font: 13px monospace;
  text-align: center;
  span {
    padding: 1rem;
    background-color: ${({ theme }) => theme.backgroundColor};
  }

  line-height: 1em;
  cursor: pointer;
  padding-top: 14px;
`;

const SearchBar = ({ onSubmit, onThrottledChange, placeholder, ...props }) => {
  const theme = useTheme();
  const fieldRef = useRef();
  const throttle = useRef();

  const { setSearch, search } = useContext(PagerContext);

  useEffect(
    () => () => {
      if (throttle.current) clearTimeout(throttle.current);
    },
    []
  );

  const onChange = (e) => {
    e.stopPropagation();
    if (throttle.current) clearTimeout(throttle.current);
    throttle.current = setTimeout(() => {
      setSearch(fieldRef.current.value || "");
    }, 500);
  };

  const keyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      if (throttle.current) clearTimeout(throttle.current);
      setSearch(fieldRef.current.value || "");
    }
  };

  const onClear = (e) => {
    e.stopPropagation();
    fieldRef.current.value = "";
    if (throttle.current) clearTimeout(throttle.current);
    setSearch("");
  };

  return (
    <Wrap style={{ position: "relative", height: "46px" }} width={1} {...props}>
      <Input
        className="search"
        as="input"
        type="search"
        id="outlined-basic"
        variant="outlined"
        fullWidth
        label="Search"
        placeholder={placeholder || "Search"}
        style={{
          flexGrow: 1,
          borderRadius: "15px 0px 0px 15px",
          borderRight: "none",
          borderColor: theme.borderColor,
          appearance: "none",
        }}
        ref={fieldRef}
        onKeyDown={keyDown}
        onChange={onChange}
      />

      {search ? (
        <DeleteButton onClick={onClear} color={theme.darkerOffset}>
          <FontAwesomeIcon
            width="32px"
            height="32px"
            icon={faCircleXmark}
            color="#ccc"
          />
        </DeleteButton>
      ) : null}

      <SearchButton
        reverse
        color={theme.secondary}
        onClick={(e) => {
          e.stopPropagation();
          setSearch(fieldRef.current.value || "");
        }}
      >
        <SearchIcon width="32px" height="32px" icon={faMagnifyingGlass} />
      </SearchButton>
    </Wrap>
  );
};
SearchBar.propTypes = {
  onSubmit: PropTypes.func,
  onThrottledChange: PropTypes.func,
};

export default SearchBar;
