import axios from "axios";

export const getCustomers = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/customerManagement/getCustomers",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCustomer = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/customerManagement/getCustomer",
      body
    );

    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createCustomer = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/customerManagement/createCustomer",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const updateCustomer = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/customerManagement/updateCustomer",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const deleteCustomer = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/customerManagement/deleteCustomer",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
