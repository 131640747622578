import React, { useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import formatter from "../../modules/formatter";
import { useQuery } from "../Hooks";
import { getQuotes } from "../../requests/quoteManagementRequests";
import Table from "../UI/Table";
import { PageWrapper } from "../UI/Grid";
import { Body } from "../UI/Text";
import { Flex } from "reflexbox";
import { getStatusTypes } from "../../requests/statusTypesMgmtReqs";
import moment from "moment";

const OrderStatus = ({ csiStatus: _status, quoteId, statusTypes }) => {
  // const statusTypes = {
  //   P: "Planned",
  //   S: "Stopped",
  //   O: "Ordered",
  //   H: "History",
  // };

  let csiStatus = _status;

  const { platform, activeOrderStatus, queries } = useContext(UserContext);
  const { csiOrderCompleteString } = platform || {};

  if (activeOrderStatus?.length > 0) {
    activeOrderStatus.forEach((status) => {
      const { swiftsellQuoteId, csiStatus: csi } = status;
      if (swiftsellQuoteId === quoteId) csiStatus = csi;
      return queries?.orders?.rows?.forEach((row, i) => {
        if (row?.id === swiftsellQuoteId)
          queries.orders.rows[i].csiStatus = csi;
      });
    });
  }

  return (
    <Flex width={1}>
      <Body center>
        {statusTypes?.[csiStatus]?.name || csiOrderCompleteString || "Ordered"}
      </Body>
    </Flex>
  );
};

const OrdersPage = () => {
  const navigate = useNavigate();
  const {
    username,
    loggedIn,
    // activeOrderStatus,
    // setActiveOrderStatus,
    // setNumOrdersUpdated,
    numOrdersUpdated,
  } = useContext(UserContext);
  const { setQuote } = useContext(ConfiguratorContext);

  const ordersRes = useQuery({
    queryID: "orders",
    request: getQuotes,
    queryVariables: { type: 2, username: username },
    skip: !loggedIn || !username,
    attributes: [
      "id",
      "sfQuoteNumber",
      "statusTypeId",
      "createdByEmail",
      "createdDate",
      "totalCost",
      "name",
      "dateOrderPlaced",
    ],
  });

  const { data: stData } = useQuery({
    queryID: "statusTypes",
    request: getStatusTypes,
    forcedFilters: {},
    attributes: ["id", "name"],
    skip: !loggedIn || !username,
  });

  const { rows: statusTypes } = stData || {};

  const { data, isLoading, refetchQuery } = ordersRes || {};

  useEffect(() => {
    if (numOrdersUpdated > 0) refetchQuery("orders");
  }, [numOrdersUpdated, refetchQuery]);

  const { rows: quotes, count: totalCount } = data || {};

  let subtitle =
    "Here you can view previously placed orders for all of your registered users and administrators.";

  subtitle +=
    " No need to refresh this page, any updates to the order status will be updated automatically.";

  const columns = useMemo(
    () => [
      {
        id: "sfQuoteNumber",
        data: (row) => (
          <Body center ellipsis>
            {row.sfQuoteNumber}
          </Body>
        ),
        header: "#",
        size: { md: 2, sm: 2, xs: -1 },
      },

      {
        id: "createdByEmail",
        data: (row) => {
          const { lastName, firstName, createdByEmail } = row;

          return (
            <Body center ellipsis>
              {firstName || lastName
                ? `${firstName || ""}${lastName && firstName ? " " : ""}${
                    lastName || " "
                  }`
                : createdByEmail}
            </Body>
          );
        },
        header: "Customer",
        size: { md: 3, sm: 3, xs: -1 },
      },

      {
        id: "name",
        data: (row) => (
          <Body ellipsis center>
            {row?.name}
          </Body>
        ),
        header: "Project",
        size: { md: 3, sm: 3, xs: -1 },
      },
      {
        id: "dateOrderPlaced",
        data: (row) => (
          <Body center>{`${moment(row.dateOrderPlaced).format(
            "MM/DD/YYYY"
          )}`}</Body>
        ),
        header: "Ordered On",
        size: { md: 2, sm: 2, xs: 4 },
      },
      {
        id: "csiStatus",
        data: ({ statusTypeId, id }) => (
          <OrderStatus
            quoteId={id}
            csiStatus={statusTypeId}
            statusTypes={statusTypes}
          />
        ),
        header: "Status",
        sort: false,
        size: { md: 2, sm: 2, xs: 4 },
      },
      {
        id: "totalCost",
        data: (row) => (
          <Body right>{`$${formatter.currency(row.totalCost)}`}</Body>
        ),
        // WM MOD changed label
        header: "Quote Value",
        size: { md: 2, sm: 2, xs: 3 },
      },
    ],
    [statusTypes]
  );

  const onRowClick = (row) => {
    const { original: quote } = row || {};
    setQuote({ ...quote });

    navigate(`/Quote/${quote?.id}`);
  };

  return (
    <PageWrapper>
      <Table
        title="Orders"
        backButtonLink="/Administration"
        hideCreate
        hideRefetch={false}
        refetchData={refetchQuery}
        isLoading={isLoading}
        subtitle={subtitle}
        columns={columns}
        data={quotes}
        dropdown={false}
        hideToggle
        totalCount={totalCount}
        onRowClick={onRowClick}
      />
    </PageWrapper>
  );
};

export default OrdersPage;
