import { color } from "styled-system";
import styled from "styled-components";
import { Box } from "reflexbox/styled-components";

export const PageWrapper = styled(Box)`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 1rem;

  ${({ narrow }) =>
    narrow
      ? {
          maxWidth: "600px",
        }
      : ""}
  ${color};
`;
