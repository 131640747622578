import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import PaymentForm from "./PaymentForm";

const PUBLIC_KEY =
  "pk_test_51LNRuoGsa7Vz9DKLuiWB6js7HEjGj6wvfVzgd4BN7XRuelrJO5JCVSm8PbbWjZxh40YkR9y5IHQAODiJwJE2YAu300pTqU5bPL";

const StripeContainer = ({ quote }) => {
  /* Importing loadStripe from "@stripe/stripe-js/pure" to prevent stripe from
   * loading until it is actually needed. This will help prevent them from
   * tracking our users until they actually need to. {insert grumpy face here}
   */
  const stripeTestPromise = loadStripe(PUBLIC_KEY);
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm quote={quote} />
    </Elements>
  );
};

export default StripeContainer;
