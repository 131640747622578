import React, { useState } from "react";
import * as constants from "../../modules/constants";
import ConfiguratorContext from "./ConfiguratorContext";

const ConfiguratorContextProvider = ({ children }) => {
  const [activeProduct, setActiveProduct] = useState(null);
  const [quote, setQuote] = useState(null);
  const [isReconfigure, setIsReconfigure] = useState(false);
  const [api, setApi] = useState({ d: { Pages: [] } });
  const [sessionId, setSessionId] = useState(false);
  const [headerId, setHeaderId] = useState(false);
  const [autoContinueMode, setACMode] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [continueClicked, setContinueClicked] = useState(0);
  const [optionToFocus, setOptionToFocus] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [configuratorMode, setConfiguratorMode] = useState(
    constants.CONFIGURATOR_MODES.Default
  );

  return (
    <ConfiguratorContext.Provider
      value={{
        activeProduct,
        setActiveProduct,
        quote,
        setQuote,
        isReconfigure,
        setIsReconfigure,
        api,
        setApi,
        sessionId,
        setSessionId,
        headerId,
        setHeaderId,
        autoContinueMode,
        setACMode,
        currentPage,
        setCurrentPage,
        continueClicked,
        setContinueClicked,
        optionToFocus,
        setOptionToFocus,
        isLocked,
        setIsLocked,
        reviewMode,
        setReviewMode,
        configuratorMode,
        setConfiguratorMode,
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  );
};

export default ConfiguratorContextProvider;
