import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import _ from "lodash";
import { useTheme } from "styled-components";
import UserContext from "../../context/user/UserContext";
import { Formik, Form } from "formik";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "../Hooks";
import { Box, Flex } from "reflexbox/styled-components";
import * as userManagementRequests from "../../requests/userManagementRequests";
import { getRoles } from "../../requests/roleManagementRequests";
import { getSecret } from "../../requests/authRequests";
import { getPlatforms } from "../../requests/platformManagementRequests";
import StyledFormikField from "../UI/forms/Field";
import Button from "../UI/Button";
import Checkbox from "../UI/forms/Checkbox";
import FormButtonCluster from "../UI/forms/ButtonCluster";
import { PageWrapper } from "../UI/Grid";
import { Body } from "../UI/Text";
import * as yup from "yup";
import { QRCodeSVG } from "qrcode.react";
import AlertContext from "../../context/alert/AlertContext";
import Loading from "../UI/Loading";
import HeadingSection from "../UI/HeadingSection";

const validationSchema = yup.object().shape({
  username: yup.string().email().label("EmailAddress: "),
  firstName: yup.string().label("First Name: "),
  lastName: yup.string().label("Last Name: "),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-^&*]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    ),
  confirmPassword: yup
    .string()
    .label("Confirm Password: ")
    .when("password", {
      is: (val) => val && val.length > 8,
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords do not match."),
    }),
  roleId: yup.string().label("User Role: "),
  checkoutType: yup.string().required(),
  pid: yup.string().required(),
  enableMFA: yup.string(),
});

const UserDetails = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { showFailureAlert, showSuccessAlert } = useContext(AlertContext);
  const { activeUser, setActiveUser } = useContext(UserContext);
  const { roleLevel: activeUserRole, username: activeUsername } = activeUser;
  const { action, username } = useParams();
  const { getUser, createUser, updateUser, deleteUser } =
    userManagementRequests;

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [qrSecret, setQRsecret] = useState(null);
  const [secretKey, setSecretKey] = useState(null);

  const { pathname = "" } = location || {};
  const { 1: path } = pathname?.split("/");
  const isMyProfile = useMemo(() => {
    if (path === "MyProfile") return true;
    else if (username === activeUsername) return true;
    else return false;
  }, [activeUsername, path, username]);

  const _user = useQuery({
    queryID: `${username}`,
    request: getUser,
    queryVariables: { username },
    skip: isMyProfile || !username,
  });

  const { data: userData, isLoading: userLoading } = _user || {};

  const _roles = useQuery({
    queryID: "roles",
    request: getRoles,
    forcedFilters: {},
    queryVariables: { roleLevel: activeUserRole },
    skip:
      userLoading ||
      !_user?.data ||
      ((isMyProfile || !activeUserRole) && activeUserRole < 9),
  });

  const { data: roleData, isLoading: rolesLoading } = _roles || {};

  const _platform = useQuery({
    queryID: "platforms",
    request: getPlatforms,
    forcedFilters: {},
    skip:
      userLoading ||
      !_user?.data ||
      rolesLoading ||
      !_roles.data ||
      activeUserRole < 9,
  });

  const { data: platformsData } = _platform || {};

  const { rows: platforms = [] } = platformsData || {};

  const { result: userDataResult } = userData || {};
  const user =
    action === "create" ? {} : !isMyProfile ? userDataResult : activeUser;

  const { rows: roles = [] } = roleData || {};
  _.remove(roles, (role) => role.roleLevel > activeUserRole);

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      const input = {
        ...values,
      };
      delete input.enableMFA;

      if (values.enableMFA) input.mfaSecret = secretKey;
      if (values.enableMFA === false) input.mfaSecret = null;
      if (values.password) input.password = values.password;
      if (action === "create") {
        await createUser({ input });
        return navigate("/Users");
      }
      if (isMyProfile) setActiveUser({ ...user, ...input });
      await updateUser({ id: user.id, input });
      resetForm({ values });
      showSuccessAlert("User saved successfully!");
    },
    [
      action,
      createUser,
      isMyProfile,
      navigate,
      secretKey,
      setActiveUser,
      showSuccessAlert,
      updateUser,
      user,
    ]
  );

  const onDelete = async () => {
    await deleteUser({ id: user.id });
    return navigate("/Users");
  };

  const onCancel = () => navigate("/Users");

  const {
    username: _username,
    firstName: _firstName,
    lastName: _lastName,
    roleId: _roleId,
    checkoutType: _checkoutType,
    pid: _pid,
    mfaSecret: _mfaSecret,
    enableMFA: _enableMFA,
  } = user || {};

  const platformMFA = useMemo(() => {
    const currentPlatform = _.find(platforms, ({ id }) => id === _pid);
    const { requireMFA } = currentPlatform || {};
    return requireMFA === true;
  }, [_pid, platforms]);

  const runGetSecret = useCallback(
    async (secret) => {
      const secretKey = secret ? secret : await getSecret();
      const issuer = "SwiftSell";
      const algorithm = "SHA1";
      const digits = "6";
      const period = "30";
      const otpType = "totp";
      const configUri = `otpauth://${otpType}/${issuer}:${username}?algorithm=${algorithm}&digits=${digits}&period=${period}&issuer=${issuer}&secret=${secretKey}`;
      setQRsecret(configUri);
      setSecretKey(secretKey);
    },
    [username]
  );

  useEffect(() => {
    runGetSecret(_mfaSecret);
  }, [_mfaSecret, runGetSecret]);

  const { id: publicUserRoleId } =
    _.find(roles, (role) => role.roleLevel === 0) || {};

  const initialValues = {
    username: _username || "",
    firstName: _firstName || "",
    lastName: _lastName || "",
    roleId: _roleId || publicUserRoleId,
    checkoutType: _checkoutType || "order",
    pid: _pid || "default",
    enableMFA:
      platformMFA === true
        ? platformMFA
        : _enableMFA === false
        ? false
        : !!_mfaSecret,
  };

  const checkoutTypes = [
    { label: "Place Order", value: "order" },
    // WM Mod Removed Stripe Checkout Option
    // { label: "Self Checkout (Stripe)", value: "stripe" },
  ];

  return userLoading || rolesLoading ? (
    <Loading />
  ) : (
    <PageWrapper>
      <HeadingSection
        my="1rem"
        hideCreate
        title={
          action === "create"
            ? "Create User"
            : `Editing User: ${user?.username}`
        }
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ dirty, isSubmitting, errors, values, submitForm }) => {
          const fieldProps = {
            errors,
            type: "text",
          };

          return (
            <Form>
              <StyledFormikField
                disabled={action !== "create"}
                labelText="Email Address: "
                name="username"
                {...fieldProps}
              />
              <StyledFormikField
                labelText="First Name: "
                name="firstName"
                {...fieldProps}
              />
              <StyledFormikField
                labelText="Last Name: "
                name="lastName"
                {...fieldProps}
              />
              {roles && roles.length > 0 && activeUserRole >= 5 && (
                <StyledFormikField
                  labelText="User Role: "
                  name="roleId"
                  as="select"
                  errors={errors}
                  disabled={
                    (isMyProfile || activeUsername === username) &&
                    activeUserRole < 5
                  }
                >
                  {isMyProfile && activeUserRole < 5 ? (
                    <option
                      key={`${user?.roleId}|roleId`}
                      value={user?.roleId}
                      label={user?.roleName}
                    />
                  ) : (
                    roles.map((r) => (
                      <option
                        key={`${r.id}|roleId`}
                        value={r.id}
                        label={r.name}
                      />
                    ))
                  )}
                </StyledFormikField>
              )}

              {activeUserRole >= 5 && (
                <StyledFormikField
                  labelText="Checkout Type: "
                  name="checkoutType"
                  component="select"
                  errors={errors}
                >
                  {(checkoutTypes || []).map((type) => (
                    <option
                      key={`${type.value}|checkoutType`}
                      value={type.value}
                      label={type.label}
                    />
                  ))}
                </StyledFormikField>
              )}

              {activeUserRole === 9 && (
                <StyledFormikField
                  labelText="Platform: "
                  name="pid"
                  component="select"
                  errors={errors}
                >
                  <option value="default" label="Select a Value" />
                  {platforms.map(({ name, id }) => (
                    <option key={`${id}|platform`} value={id} label={name} />
                  ))}
                </StyledFormikField>
              )}

              {action !== "create" && (
                <Button
                  mb="1rem"
                  reverse={showPasswordFields}
                  color={showPasswordFields ? theme.gray : undefined}
                  onClick={() => setShowPasswordFields(!showPasswordFields)}
                  iconName={showPasswordFields ? "faXmark" : "faPencil"}
                >
                  {showPasswordFields ? "Cancel" : "Update Password"}
                </Button>
              )}

              {((showPasswordFields && (activeUserRole >= 5 || !action)) ||
                action === "create") && (
                <>
                  <StyledFormikField
                    labelText={
                      action === "create" ? "Password" : "New Password"
                    }
                    {...fieldProps}
                    name="password"
                    type="password"
                  />
                  <StyledFormikField
                    labelText="Confirm Password: "
                    name="confirmPassword"
                    {...fieldProps}
                    type="password"
                  />
                </>
              )}

              <Flex
                mt={showPasswordFields ? "1rem" : "0.5rem"}
                alignItems="center"
              >
                <Flex mr="1rem" flexDirection="column">
                  <Box
                    mt={showPasswordFields ? undefined : "-0.5rem"}
                    mb="0.5rem"
                    onClick={
                      platformMFA
                        ? () => showFailureAlert("MFA Required by platform.")
                        : undefined
                    }
                  >
                    <Checkbox
                      style={{
                        pointerEvents: platformMFA ? "none" : undefined,
                        cursor: platformMFA ? "not-allowed" : undefined,
                      }}
                      disabled={platformMFA}
                      name="enableMFA"
                      labelText="Enable Multi-Factor Authentication for this user."
                    />
                  </Box>
                  {values.enableMFA ? (
                    <Box maxWidth={400}>
                      <Body mb="0.5rem">
                        Scan the code with your authenticator application, you
                        can then use the code generated by the application when
                        prompted at login.
                      </Body>
                      <Body mb="1rem">
                        You can download the Google Authenticator app using the
                        buttons below.
                      </Body>
                      <Flex>
                        <Button
                          color={theme.secondary}
                          target="_blank"
                          to="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        >
                          App Store
                        </Button>
                        <Button
                          color={theme.warning}
                          target="_blank"
                          to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                        >
                          Google Play
                        </Button>
                      </Flex>
                    </Box>
                  ) : null}
                </Flex>
                {values.enableMFA && isMyProfile ? (
                  <QRCodeSVG value={qrSecret} />
                ) : null}
              </Flex>

              <FormButtonCluster
                onCancel={onCancel}
                onDelete={onDelete}
                recordAction={action}
                saving={isSubmitting}
                dirty={dirty}
                hideDelete={action !== "edit"}
                deleteMessage={`Are you sure you want to delete ${values.username}?`}
                values={values}
                onSubmit={submitForm}
              />
            </Form>
          );
        }}
      </Formik>
    </PageWrapper>
  );
};

export default UserDetails;
