import React, { useState, forwardRef, useCallback } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  getDefaultKeyBinding,
} from "draft-js";
import styled from "styled-components";
import "draft-js/dist/Draft.css";
import { Flex } from "reflexbox";

const EditorContainer = styled.div`
  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
  border: 1px solid black;
  border-radius: 0.25rem;
  border: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  & select {
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  }
  transition: all ease-in-out 200ms;
  &:focus,
  &:hover,
  &:has(*:focus) {
    border-color: ${({ theme }) => theme.secondary};
  }
`;

const EditorDivider = styled.div`
  margin-bottom: 0px;
  height: 2px;
  background: ${({ theme }) => theme?.borderColor || "gray"};
  width: 100%;
`;

const StyleButton = styled.span`
  padding: 0.125rem;
  margin: 0.125rem;
  border: 2px solid ${({ theme }) => theme?.borderColor || "gray"};
  width: 1rem;
  text-align: center;
  cursor: pointer;
  transition: background 0.1s;
  background: ${({ theme, active }) =>
    active ? theme.currentHighlight : theme?.backgroundColor || "#FFF"};
  ${({ styleType }) =>
    styleType === "BOLD"
      ? "font-weight: bold"
      : styleType === "ITALIC"
      ? "font-style: italic"
      : styleType === "UNDERLINE"
      ? "text-decoration: underline"
      : styleType === "STRIKETHROUGH"
      ? "text-decoration: line-through"
      : ""};
`;

const TextAreaContainer = styled.div`
  padding: 0.5rem;
  min-height: 38px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};
`;

const BLOCK_TYPES = [
  { label: "Paragraph", style: "unstyled" },
  { label: "Blockquote", style: "blockquote" },
  { label: "Bullet List", style: "unordered-list-item" },
  { label: "Number List", style: "ordered-list-item" },
  { label: "Heading 1", style: "header-one" },
  { label: "Heading 2", style: "header-two" },
  { label: "Heading 3", style: "header-three" },
  { label: "Heading 4", style: "header-four" },
];

const INLINE_STYLES = [
  { label: "B", style: "BOLD" },
  { label: "I", style: "ITALIC" },
  { label: "U", style: "UNDERLINE" },
  { label: "S", style: "STRIKETHROUGH" },
];

const MAX_TAB_HEGIHT = 4; // It gets a little funky after 4

const RichTextEditor = forwardRef(
  (
    {
      content,
      noStyles = false,
      noBlockTypes = false,
      maxLines = -1,
      disabled = false,
      onChange,
      runSubmit,
      ...props
    },
    ref
  ) => {
    const {
      editorState,
      setEditorState: _setEditorState,
      exportString,
    } = content;
    const [tabKeys, setTabKeys] = useState([]);
    const setEditorState = (v) => {
      if (tabKeys.length !== 0) {
        setTabKeys([]);
      }
      const lastExport = exportString.current;
      _setEditorState(v);
      if (lastExport !== exportString.current && onChange) {
        onChange(exportString.current);
      }
    };
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const startBlock = currentContent.getBlockForKey(selection.getStartKey());
    const blockType = startBlock.getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    const toggleBlockType = (e) => {
      if (disabled) {
        return;
      }
      setEditorState(RichUtils.toggleBlockType(editorState, e.target.value));
    };

    const toggleInlineStyle = (e, style) => {
      if (disabled) {
        return;
      }
      setEditorState(RichUtils.toggleInlineStyle(editorState, style));
      e.preventDefault();
    };

    const processKeyDown = useCallback(
      (e) => {
        if (disabled) {
          return;
        }
        //console.log(e.key);
        switch (e.key) {
          case "Escape":
            ref.current.blur();
            return;

          case "b":
            if (e.ctrlKey && !noStyles) {
              setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
              return;
            }
            break;

          case "i":
            if (e.ctrlKey && !noStyles) {
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, "ITALIC")
              );
              return;
            }
            break;
          case "u":
            if (e.ctrlKey && !noStyles) {
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
              );
              return;
            }
            break;
          case "5":
            if (e.ctrlKey && !noStyles) {
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH")
              );
              return;
            }
            break;
          case "Tab":
            //e.preventDefault();

            runSubmit({
              value: exportString.current,
            });
            break;
          // if (noBlockTypes) {
          //   setEditorState(
          //     EditorState.push(
          //       editorState,
          //       Modifier.replaceText(currentContent, selection, "\t"),
          //       "insert-characters"
          //     )
          //   );
          //   e.preventDefault();
          // } else if (tabKeys.length !== 0) {
          //   const keepTabKeys = tabKeys;
          //   let blockMap = currentContent.getBlockMap();
          //   tabKeys.forEach((key) => {
          //     const currentBlock = currentContent.getBlockForKey(key);
          //     let newBlock;
          //     if (e.shiftKey) {
          //       newBlock = currentBlock.set(
          //         "depth",
          //         Math.max(currentBlock.getDepth() - 1, 0)
          //       );
          //     } else {
          //       newBlock = currentBlock.set(
          //         "depth",
          //         Math.min(currentBlock.getDepth() + 1, MAX_TAB_HEGIHT)
          //       );
          //     }
          //     blockMap = blockMap.set(key, newBlock);
          //   });
          //   setEditorState(
          //     EditorState.push(
          //       editorState,
          //       currentContent.set("blockMap", blockMap),
          //       "adjust-depth"
          //     )
          //   );
          //   setTabKeys(keepTabKeys);
          //   e.preventDefault();
          // } else if (
          //   (blockType === "unordered-list-item" ||
          //     blockType === "ordered-list-item") &&
          //   selection.getStartOffset() === 0 &&
          //   selection.getStartKey() === selection.getEndKey()
          // ) {
          //   const newEditorState = RichUtils.onTab(
          //     e,
          //     editorState,
          //     MAX_TAB_HEGIHT
          //   );
          //   if (newEditorState !== editorState) {
          //     setEditorState(newEditorState);
          //   }
          // } else if (
          //   selection.getStartKey() !== selection.getEndKey() ||
          //   selection.getStartOffset() !== selection.getEndOffset()
          // ) {
          //   let blockMap = currentContent.getBlockMap();
          //   let indentedLine = false;
          //   const newTabKeys = [];
          //   const endKey = currentContent.getKeyAfter(selection.getEndKey());
          //   let currentKey = selection.getStartKey();
          //   for (
          //     ;
          //     currentKey !== endKey;
          //     currentKey = currentContent.getKeyAfter(currentKey)
          //   ) {
          //     const currentBlock = currentContent.getBlockForKey(currentKey);
          //     const currentType = currentBlock.getType();
          //     if (
          //       currentType === "unordered-list-item" ||
          //       currentType === "ordered-list-item"
          //     ) {
          //       indentedLine = true;
          //       newTabKeys.push(currentKey);
          //       let newBlock;
          //       if (e.shiftKey) {
          //         newBlock = currentBlock.set(
          //           "depth",
          //           Math.max(currentBlock.getDepth() - 1, 0)
          //         );
          //       } else {
          //         newBlock = currentBlock.set(
          //           "depth",
          //           Math.min(currentBlock.getDepth() + 1, MAX_TAB_HEGIHT)
          //         );
          //       }
          //       blockMap = blockMap.set(currentKey, newBlock);
          //     }
          //   }
          //   if (indentedLine) {
          //     setEditorState(
          //       EditorState.push(
          //         editorState,
          //         currentContent.set("blockMap", blockMap),
          //         "adjust-depth"
          //       )
          //     );
          //     setTabKeys(newTabKeys);
          //   } else {
          //     setEditorState(
          //       EditorState.push(
          //         editorState,
          //         Modifier.replaceText(currentContent, selection, "\t"),
          //         "insert-characters"
          //       )
          //     );
          //   }
          //   e.preventDefault();
          // } else {
          //   setEditorState(
          //     EditorState.push(
          //       editorState,
          //       Modifier.replaceText(currentContent, selection, "\t"),
          //       "insert-characters"
          //     )
          //   );
          //   e.preventDefault();
          // }
          // return;
          case "Backspace":
            if (
              !noBlockTypes &&
              (blockType === "unordered-list-item" ||
                blockType === "ordered-list-item") &&
              selection.getStartOffset() === 0 &&
              selection.isCollapsed()
            ) {
              if (e.ctrlKey || startBlock.getDepth() === 0) {
                setEditorState(
                  RichUtils.toggleBlockType(editorState, "unstyled")
                );
              } else {
                const newBlock = startBlock.set(
                  "depth",
                  Math.max(startBlock.getDepth() - 1, 0)
                );
                setEditorState(
                  EditorState.push(
                    editorState,
                    currentContent.set(
                      "blockMap",
                      currentContent
                        .getBlockMap()
                        .set(selection.getStartKey(), newBlock)
                    ),
                    "adjust-depth"
                  )
                );
              }
              return;
            }
            break;
          case "Enter":
            if (maxLines > 0 && currentContent.getBlockMap().size >= maxLines) {
              return;
            }
            break;
          default:
            break;
        }
        return getDefaultKeyBinding(e);
      },
      [
        blockType,
        currentContent,
        disabled,
        editorState,
        exportString,
        maxLines,
        noBlockTypes,
        noStyles,
        ref,
        runSubmit,
        selection,
        setEditorState,
        startBlock,
      ]
    );

    return (
      <EditorContainer disabled={disabled}>
        {(!noStyles || !noBlockTypes) && (
          <>
            <Flex>
              {!noBlockTypes && (
                <select
                  disabled={disabled}
                  value={blockType}
                  onChange={toggleBlockType}
                >
                  {BLOCK_TYPES.map((t) => (
                    <option key={t.style} value={t.style}>
                      {t.label}
                    </option>
                  ))}
                </select>
              )}
              {!noStyles &&
                INLINE_STYLES.map((s) => (
                  <StyleButton
                    disabled={disabled}
                    key={s.style}
                    styleType={s.style}
                    active={currentStyle.includes(s.style)}
                    onClick={(e) => toggleInlineStyle(e, s.style)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {s.label}
                  </StyleButton>
                ))}
            </Flex>
            <EditorDivider />
          </>
        )}
        <TextAreaContainer
          disabled={disabled}
          onClick={(e) => {
            ref.current.focus();
            e.stopPropagation();
          }}
        >
          <Editor
            readOnly={disabled}
            keyBindingFn={processKeyDown}
            editorState={editorState}
            onChange={setEditorState}
            ref={ref}
            {...props}
          />
        </TextAreaContainer>
      </EditorContainer>
    );
  }
);
export default RichTextEditor;
