import { Field } from "formik";
import styled from "styled-components";

const Input = styled(Field).attrs((props) => {
  return { ...props };
})`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 2px solid ${({ theme }) => theme?.borderColor};
  transition: all ease-in-out 200ms;
  ${({ theme }) => `
  background-color: ${theme?.backgroundColor};
  color: ${theme?.backgroundOpposite};
  `}
  margin: 0;
  &:hover,
  &:focus {
    border: 2px solid ${({ theme }) => theme?.activeStageColor};
  }
`;

export default Input;
