import React, { useContext } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box } from "reflexbox";
import * as yup from "yup";
import Loading from "../UI/Loading";
import FormButtonCluster from "../UI/forms/ButtonCluster";
import FieldArray from "../UI/forms/FieldArray";
import Field from "../UI/forms/Field";
import Checkbox from "../UI/forms/Checkbox";
import { PageWrapper } from "../UI/Grid";
import { useQuery } from "../Hooks";
import * as platforms from "../../requests/platformManagementRequests";
import UserContext from "../../context/user/UserContext";
import SocketContext from "../../context/socket/SocketContext";
import HeadingSection from "../UI/HeadingSection";

const { getPlatform, updatePlatform, createPlatform } = platforms;

const validationSchema = yup.object().shape({
  name: yup.string(),
  emailAddresses: yup.array(yup.string().email()).default([]),
  integration: yup.string(),
  requireMFA: yup.boolean(),
  requireHttps: yup.boolean(),
  csiOrderCompleteString: yup.string(),
});

const PlatformSettings = () => {
  const { pid, action } = useParams();
  const navigate = useNavigate();

  const { client } = useContext(SocketContext);
  const { activeUser } = useContext(UserContext);

  const { data, isLoading, refetchQuery } = useQuery({
    queryID: pid,
    request: getPlatform,
    queryVariables: { id: pid },
    forcedFilters: {},
    skip: !pid,
  });

  const {
    name,
    emailAddresses,
    integration,
    requireMFA,
    requireHttps,
    csiOrderCompleteString,
  } = data || {};

  const initialValues = {
    emailAddresses:
      emailAddresses && emailAddresses.length > 0
        ? emailAddresses.split(",")
        : [""],
    name: name || "",
    integration: integration || "default",
    requireMFA: requireMFA || false,
    requireHttps: requireHttps || false,
    csiOrderCompleteString: csiOrderCompleteString || "Ordered",
  };

  const onSubmit = async ({
    emailAddresses = [],
    name,
    integration,
    requireMFA: _requireMFA,
    requireHttps: _requireHttps,
    csiOrderCompleteString,
  }) => {
    const emailsString = _.toString(emailAddresses);
    const requireMFA = _requireMFA === true ? "1" : "0";
    const requireHttps = _requireHttps === true ? "1" : "0";
    if (action === "create") {
      await createPlatform({
        input: {
          emailAddresses: emailsString,
          name,
          integration,
          requireMFA,
          requireHttps,
        },
      });
      navigate("/Platforms");
    } else {
      await updatePlatform({
        id: pid,
        input: {
          emailAddresses: emailsString,
          name,
          integration,
          requireMFA,
          requireHttps,
          csiOrderCompleteString,
        },
      });
      client.send(
        JSON.stringify({
          type: "contentchange",
          username: activeUser?.username,
          content: emailAddresses,
        })
      );
    }
    refetchQuery(pid);
  };
  const onCancel = () => navigate("/Administration");

  return (
    <PageWrapper>
      <HeadingSection
        my="1rem"
        hideCreate
        title={action === "create" ? "Create Platform" : "Edit System Settings"}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <Formik
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
        >
          {(formData) => {
            const {
              dirty,
              isSubmitting,
              isValidating,
              errors,
              values,
              submitForm,
            } = formData;
            return (
              <Form>
                <Field
                  labelText="Platform Name"
                  name="name"
                  type="text"
                  errors={errors}
                />
                <FieldArray
                  description={`Email addresses entered here will be notified whenever a user places an order. Use the "+" or "-" buttons to add or remove an email address.`}
                  label="Order Confirmation Notifications"
                  name="emailAddresses"
                  errors={errors}
                />
                <Field
                  labelText="Integration Type"
                  name="integration"
                  component="select"
                >
                  <option value="default">Select a Value</option>
                  <option value="visual">Visual</option>
                  <option value="CSI">CSI</option>
                </Field>

                {values.integration === "CSI" && (
                  <Field
                    name="csiOrderCompleteString"
                    labelText="CSI Order Complete Identifier"
                    description={`Please enter the label that corresponds with the "Completed" status that you have set within CSI.`}
                  />
                )}

                <Box mt="0.5rem">
                  <Checkbox
                    name="requireMFA"
                    labelText="Require Multi-Factor Authentication for all users."
                  />
                </Box>
                <Box mt="0.5rem">
                  <Checkbox
                    name="requireHttps"
                    labelText="Require HTTPS"
                    description="Require configurations to use HTTPS protocol to make requests to configuration servers."
                  />
                </Box>

                <FormButtonCluster
                  saving={isSubmitting || isValidating}
                  dirty={dirty}
                  values={values}
                  onSubmit={submitForm}
                  onCancel={onCancel}
                  hideDelete
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </PageWrapper>
  );
};

export default PlatformSettings;
