import React, { useContext, useMemo, useState, useCallback } from "react";
import _ from "lodash";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import * as quoteReqs from "../../requests/quoteManagementRequests";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import AlertContext from "../../context/alert/AlertContext";
import guid, { GenerateGUID } from "../../modules/guid";
import formatter from "../../modules/formatter";
import { Flex } from "reflexbox";
import { Body } from "../UI/Text";
import Table from "../UI/Table";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import UserContext from "../../context/user/UserContext";
import ProductCatalog from "../ProductCatalog";
import { useQuery } from "../Hooks";
import { getProducts } from "../../requests/productManagementRequests";

import * as constants from "../../modules/constants";
import PagerContextProvider from "../UI/Pager/PagerContext";

const { createLine, updateLine, deleteLine } = quoteReqs;

const ButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-out;
  height: 100%;
`;

const LineImg = styled.img`
  max-width: 75px;
  max-height: 75px;
  width: auto !important;
  height: auto;
`;

const Lines = ({
  lineCount,
  refetchQuote,
  quoteType,
  quoteLoading,
  isLoading,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { quoteId } = useParams();
  const { showFailureAlert, showSuccessAlert } = useContext(AlertContext);
  const { activeUser } = useContext(UserContext);
  const { username } = activeUser || {};
  const configuratorContext = useContext(ConfiguratorContext);

  const { data, isLoading: productsLoading } = useQuery({
    queryID: "products",
    request: getProducts,
    forcedFilters: {},
    skip: isLoading,
  });

  const { rows: products = [] } = data || {};

  const {
    setActiveProduct,
    setIsReconfigure,
    setSessionId,
    activeProduct = {},
    setConfiguratorMode,
    quote,
    setQuote,
  } = configuratorContext;
  const { lines = [] } = quote || {};

  console.log({ lines });
  const [show, setShow] = useState(false);

  const onReconfigure = useCallback(
    async (row) => {
      const { productId, configurationId, headerId, id: LineId } = row;
      setIsReconfigure(true);
      setActiveProduct(productId);
      setSessionId(guid.GenerateGUID());
      const lineNumber = _.findIndex(lines, (line) => line.id === LineId) + 1;
      refetchQuote("quotes");

      navigate({
        pathname: `/Configurator/${productId}/${quoteId}/${configurationId}`,
        search: createSearchParams({
          lineNumber,
          headerId: headerId ? headerId : activeProduct?.HeaderId,
          configurationId,
          LineId,
        }).toString(),
      });
    },
    [
      activeProduct,
      lines,
      navigate,
      quoteId,
      refetchQuote,
      setActiveProduct,
      setIsReconfigure,
      setSessionId,
    ]
  );

  // const onCopyLine = useCallback(
  //   async (row, _lines) => {
  //     const oldLine = _.find(_lines, ({ id }) => id === row.id);
  //     const {
  //       configurationId: sourceConfigurationId,
  //       headerId: sourceHeaderId,
  //       price,
  //       quantity,
  //       productId,
  //     } = oldLine;
  //     const newLine = {
  //       ...oldLine,
  //       // Creating a GUID here to allow for the deleting and updating of rows
  //       // before the query has refetched.
  //       _id: guid.GenerateGUID(),
  //       sourceConfigurationId,
  //       sourceHeaderId,
  //       headerId: sourceHeaderId,
  //     };
  //     const newLines = [..._lines, newLine];
  //     const newPrice = quote?.totalCost + price * quantity;
  //     setQuote({ ...quote, totalCost: newPrice, lines: newLines });

  //     await createLine({
  //       input: newLine,
  //     });

  //     refetchQuery("quotes");
  //     const parentProduct = _.find(products, ({ ID }) => ID === productId);

  //     copyLineConfiguration({
  //       sourceHeaderId,
  //       sourceDetailId: sourceConfigurationId,
  //       headerId: sourceHeaderId,
  //       detailId: sourceConfigurationId,
  //       instanceName: parentProduct?.ApplicationInstance,
  //       applicationName: parentProduct?.ApplicationName,
  //     });
  //   },
  //   [products, quote, refetchQuery, setQuote]
  // );
  //     refetchQuote("quotes");
  //     const parentProduct = _.find(products, ({ ID }) => ID === productId);

  //     copyLineConfiguration({
  //       sourceHeaderId,
  //       sourceDetailId: sourceConfigurationId,
  //       headerId: sourceHeaderId,
  //       detailId: sourceConfigurationId,
  //       instanceName: parentProduct?.ApplicationInstance,
  //       applicationName: parentProduct?.ApplicationName,
  //     });
  //   },
  //   [products, quote, refetchQuote, setQuote]
  // );

  const onDeleteLine = useCallback(
    async (row) => {
      const { lines } = quote || {};
      const { _id, price, quantity, id } = row;
      console.log(row);

      const newLines = [...lines];
      _.remove(newLines, (line) => {
        if (line._id && line._id === _id) return true;
        if (line?.id === id) return true;
        return false;
      });

      const newPrice = quote?.totalCost - price * quantity;
      setQuote({ ...quote, totalCost: newPrice, lines: newLines });

      await deleteLine({ _id, id, quoteId });
      refetchQuote("quotes");
    },
    [quote, setQuote, quoteId, refetchQuote]
  );

  const onUpdateQuantity = useCallback(
    async (values, row) => {
      const { id, _id, quantity, price } = row;
      const newLines = lines.map((line) =>
        line._id === _id ? { ...line, quantity: values.quantity } : line
      );
      setQuote({
        ...quote,
        totalCost: quote.totalCost - quantity * price + values.quantity * price,
        lines: newLines,
      });
      await updateLine({
        id,
        _id,
        input: { quantity: values.quantity },
      });
      refetchQuote("quotes");
    },
    [lines, quote, refetchQuote, setQuote]
  );

  const onAddLine = useCallback(
    async (productId, quantity = 1) => {
      const { lines } = quote || {};
      setIsReconfigure(false);
      setConfiguratorMode(constants.CONFIGURATOR_MODES.Default);
      const selectedProduct = _.find(
        products,
        (product) => product.ID === productId
      );
      const { isNonConfigurable, isQuickConfig, defaultPrice } =
        selectedProduct || {};
      if (isNonConfigurable && defaultPrice) {
        const newLine = {
          _id: GenerateGUID(),
          headerId: selectedProduct?.HeaderId,
          // imgUrl: selectedProduct?.ImageUrl,
          price: selectedProduct?.defaultPrice,
          username: username,
          partNumber: selectedProduct?.ProductNumber,
          description: selectedProduct?.Description,
          productId,
          quoteId,
          quantity,
          companyId: 1,
        };

        const newLines = [...lines, newLine];
        const newPrice =
          quote?.totalCost + selectedProduct?.defaultPrice * quantity;
        setQuote({ ...quote, totalCost: newPrice, lines: newLines });
        showSuccessAlert(
          `Added ${selectedProduct?.ProductNumber} to Quote #${quoteId}`
        );
        await createLine({ input: newLine });

        refetchQuote(`lines|${quoteId}`);
        refetchQuote("quotes");
        return;
      }

      let lineNumber = 1;

      if (lines && lines.length > 0) {
        lineNumber = lines.length + 1;
      }

      const configurationId = guid.GenerateGUID();
      if (!isNonConfigurable || isQuickConfig || !defaultPrice)
        navigate({
          pathname: `/Configurator/${productId}/${quoteId}/${configurationId}`,
          search: createSearchParams({
            lineNumber,
          }).toString(),
        });
    },
    [
      setIsReconfigure,
      setConfiguratorMode,
      products,
      navigate,
      quoteId,
      username,
      quote,
      setQuote,
      showSuccessAlert,
      refetchQuote,
    ]
  );

  // useEffect(() => {
  //   // refetchQuery(`lines|${quoteId}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [quoteId]);

  const runCreateDoc = useCallback(
    async (row) => {
      const { id: LineId, configurationId } = row?.original || row || {};

      const docLink = await quoteReqs.getPricingDoc(
        quote?.quoteGenConfigId,
        configurationId,
        "PricingDocLink",
        `${LineId}`
      );

      console.log(docLink);
      if (!docLink) return showFailureAlert("No Document Available.");
      var filename = docLink.replace(/^.*[\\/]/, "");

      quoteReqs.downloadFile(docLink, filename, "application/pdf");
    },
    [quote, showFailureAlert]
  );

  const columns = useMemo(
    () => [
      {
        id: "imgUrl",
        data: (row) => {
          return (
            <Flex
              width={1}
              alignItems="center"
              height="100%"
              key={row?.id + "|imgUrl"}
              justifyContent="center"
            >
              {row?.imgUrl?.startsWith("http") ||
              row?.imgUrl?.startsWith("/") ||
              row?.imgUrl?.startsWith("C:") ? (
                <LineImg
                  src={(row?.imgUrl || "")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace("\\", "/")
                    .replace(String.fromCharCode(92), "/")
                    .replace(String.fromCharCode(92, 92), "//")
                    .replace(
                      "C:/inetpub/wwwroot/",
                      "https://configure.wmwmeyer.com/"
                    )}
                  alt={row?.id || "imgUrl"}
                />
              ) : null}
            </Flex>
          );
        },
        header: "Preview",
        sort: false,
        size: { md: 2, sm: -1, xs: -1 },
      },
      {
        id: "partNumber",
        data: (row) => (
          <Body
            key={row?.partNumber}
            color={row.id === "new" ? theme.secondary : undefined}
          >
            {row.partNumber}
          </Body>
        ),
        sort: false,
        header: "Part #",
        size: { md: 2, sm: 2, xs: 3 },
      },
      {
        id: "description",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            key={row?.id + "|description"}
          >
            <Body>{row.description}</Body>
          </Flex>
        ),
        header: "Description",
        size: { md: 4, sm: 3, xs: -1 },
      },
      {
        id: "headerId",
        data: (row) => (
          <Flex
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            key={row?.id + "|headerId"}
          >
            <Body>{row.headerId}</Body>
          </Flex>
        ),
        header: "Header ID",
        sort: false,
        size: { md: -1, sm: -1, xs: -1 },
      },
      {
        id: "quantity",
        data: (row) => {
          return quoteType === 2 ? (
            <Body center>{row.quantity}</Body>
          ) : (
            <Flex
              height="100%"
              justifyContent="center"
              alignItems="center"
              key={row?.id + "|quantity"}
            >
              <ButtonsWrapper id="actions" m="0 auto">
                <Button
                  iconName="faMinus"
                  reverse
                  size="tiny"
                  color={theme.secondary}
                  onClick={(e) => {
                    e.preventDefault();
                    return row.quantity > 1
                      ? onUpdateQuantity({ quantity: row.quantity - 1 }, row)
                      : showFailureAlert(
                          "Cannot set quantity to a number less than 1."
                        );
                  }}
                />
              </ButtonsWrapper>
              <Body mx="0" bold center>
                {row.quantity}
              </Body>
              <ButtonsWrapper id="actions" m="0 auto">
                <Button
                  iconName="faPlus"
                  reverse
                  size="tiny"
                  color={theme.secondary}
                  onClick={(e) => {
                    e.preventDefault();
                    onUpdateQuantity({ quantity: row.quantity + 1 }, row);
                  }}
                />
              </ButtonsWrapper>
            </Flex>
          );
        },
        header: "Quantity",
        sort: false,
        size: { md: 2, sm: 2, xs: 5 },
      },
      {
        id: "unitPrice",
        data: (row) => <Body right>${formatter.currency(row.price)}</Body>,
        header: "Unit Price",
        sort: false,
        size: { lg: 2, md: -1, sm: -1, xs: -1 },
      },
      {
        id: "totalPrice",
        data: (row) => (
          <Body right>${formatter.currency(row.price * row.quantity)}</Body>
        ),
        header: "Total Price",
        sort: false,
        size: { md: 2, sm: 2, xs: 4 },
      },
      {
        id: "salesPrice",
        data: (row) => (
          <Body right>
            ${formatter.currency(row.salesPrice * row.quantity)}
          </Body>
        ),
        header: "Sales Price",
        sort: false,
        size: { md: 2, sm: 2, xs: 4 },
      },
      {
        id: "actions",
        header: "Actions",
        sort: false,
        data: (row) => {
          return (
            <ButtonsWrapper
              id="actions"
              m="0 auto"
              height="100%"
              key={row?.id + "|actions"}
            >
              {!row.configurationId ? null : (
                <>
                  {quote?.quoteGenConfigId && (
                    <Button
                      size="small"
                      color={theme?.secondary}
                      reverse
                      iconName="faFileCirclePlus"
                      onClick={() => runCreateDoc(row)}
                    />
                  )}
                  <Button
                    size="small"
                    ml="1rem"
                    color={theme?.secondary}
                    reverse
                    iconName="faPencil"
                    onClick={() => onReconfigure(row)}
                  />
                  {/* <Button
                    size="small"
                    color={theme?.secondary}
                    reverse
                    iconName="faCopy"
                    onClick={() => onCopyLine(row, lines)}
                  /> */}
                </>
              )}
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faTrashCan"
                onClick={() => onDeleteLine(row)}
              />
            </ButtonsWrapper>
          );
        },
        size:
          quoteType === 2
            ? { md: -1, sm: -1, xs: -1 }
            : { lg: 4, md: 0, sm: 0, xs: 0 },
      },
    ],
    [
      onDeleteLine,
      onReconfigure,
      onUpdateQuantity,
      runCreateDoc,
      quoteType,
      showFailureAlert,
      theme.secondary,
    ]
  );

  return (
    <>
      <Modal show={show} setShow={setShow}>
        <PagerContextProvider>
          <ProductCatalog
            show={show}
            setShow={setShow}
            onProductClick={onAddLine}
            isLoading={isLoading || productsLoading}
          />
        </PagerContextProvider>
      </Modal>
      <PagerContextProvider>
        <Table
          title="Lines"
          additionalElements={[
            <Flex key="addLine" alignItems="center" justifyContent="flex-end">
              <ProductCatalog onProductClick={onAddLine} inline show />
            </Flex>,
          ]}
          createLink={() => setShow(true)}
          createButtonText="Catalog"
          createButtonIcon="faCartPlus"
          hideSearch
          hideBack
          hideProperties
          hidePager
          data={lines}
          columns={columns}
          totalCount={lineCount}
          isLoading={quoteLoading || productsLoading}
        />
      </PagerContextProvider>
    </>
  );
};

export default Lines;
