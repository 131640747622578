import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Button from "../Button";

export const StyledModal = styled(Flex)`
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100vw;

  height: 100vh;
  overflow: auto;
  background: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.25) 100%
  );
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @keyframes modal-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: modal-fade-in;
  animation-duration: 200ms;
`;

export const StyledModalBody = styled(Box)`
  display: ${(props) => (props.show ? "inline-flex" : "none")};
  margin: auto;
  position: relative;
  max-width: 1136px;
  @keyframes modal-slide-in {
    0% {
      opacity: 0;
      top: 10rem;
    }
    25% {
      opacity: 0;
      top: 10rem;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  animation-name: modal-slide-in;
  animation-duration: 250ms;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  z-index: 10;
  @media (max-width: 600px) {
    top: 0.5rem;
    right: 1.5rem;
  }
`;
