const sharedStyles = {
  breakpoints: ["320px", "360px", "600px", "800px", "1000px", "1200px"],
  borderRadius: "15px",
  gray: "#6c757d",
  grayDark: "#343a40",
  green: "#28a745",
  indigo: "#6610f2",
  info: "#17a2b8",
  light: "#e8e9ea",
  orange: "#fd7e14",
  pink: "#e83e8c",
  purple: "#6f42c1",
  danger: "#dc3545",
  success: "#28a745",
  teal: "#20c997",
  warning: "#ffc107",
  white: "#fff",
  yellow: "#ffc107",
  primary: "#b2062c", // Red
  secondary: "#ffc107", // Yellow
  borderColor: "#ced4da",
  buttonColor: "#EEEEEE",
  altButtonColor: "#6D6E71",
  buttonTextWhite: "#FFF",
  activeStageColor: "#13cdfd",
  inactiveStageForeColor: "#000",
  activeStageForeColor: "#FFF",
  completedStageColor: "#EEEEEE",
  incompleteStageColor: "#EEEEEE",
  accentColor: "#ffdd00",
  backgroundOffset: "#EEEEEE",
  boxShadowColor: "rgba(0, 0, 0, 0.2)",
  callToActionColor: "#b2062c", // Red
  headerColor: "#b2062c",
};

export const rentechTheme = {
  darkMode: false,
  gridLineHoverBackgroundColor: "#EEEEEE",
  gridLineHoverOpacity: 0.9,
  gridLineHoverColor: "#000",
  gridRowBackgroundColor: "#FFF", //offwhite
  gridRowAlternateBackgroundColor: "#f5f5f5",
  callToActionShadow: "0 0 0 1px #e12f6e !important",
  backgroundColor: "#FFF", //White
  backgroundOpposite: "#000", //black
  imageActionColorFilter:
    "invert(77%) sepia(67%) saturate(2280%) hue-rotate(349deg) brightness(105%) contrast(98%)",
  darkBackground: "#6D6E71",
  lightBackground: "#EEEEEE", //Light Grey
  darkerOffset: "#D3D3D3", //Grey
  currentHighlight: "#13cdfd", //Electric Blue
  currentHighlightShadow: "0 0 0 1px #13cdfd !important",
  darkerHighlight: "#4D4D4D", //dark dark grey
  textDark: "#000, contrast(1.75)", //Black
  textLight: "#FFF", //White
  textMedium: "#A9A9A9", //dark grey
  actionRequired: "#e2231a", //Red
  loadingIcon: "/images/loadingRipple.gif",
  companyLogo: "/images/HeaderLogo.png", //TrinityLogo
  companyLogoWhite: "/images/HeaderLogo.png", // White
  ...sharedStyles,
};

export const darkModeTheme = {
  darkMode: true,
  gridLineHoverBackgroundColor: "#606060",
  gridLineHoverOpacity: 0.7,
  gridLineHoverColor: "#000000 !important",
  gridRowBackgroundColor: "#0D0D0D", //black
  gridRowAlternateBackgroundColor: "#2b2a2a",
  callToActionColor: "#e12f6e", //Pink
  callToActionShadow: "0 0 0 1px #e12f6e !important",
  backgroundColor: "#0D0D0D", //White
  backgroundOpposite: "#FFF", //black
  imageActionColorFilter:
    "invert(77%) sepia(67%) saturate(2280%) hue-rotate(349deg) brightness(105%) contrast(98%)",
  darkBackground: "#000",
  lightBackground: "#2b2a2a", //Light Grey
  darkerOffset: "#6d6d6d", //Grey 6d6d6d 333333
  currentHighlight: "#13cdfd",
  currentHighlightShadow: "0 0 0 1px #13cdfd !important",
  darkerHighlight: "#5c5e5f", //dark dark grey 6f6f6f (9c85e6 - pruple)
  textDark: "#FFF", //Black
  textLight: "#000", //White
  textMedium: "#FFF", //Black
  actionRequired: "#e2231a", //Red
  boxShadowColor: "rgba(100, 100, 100, 0.2)",
  loadingIcon: "/images/loadingRippleDark.gif",
  companyLogo: "/images/HeaderLogo.png", //TrinityLogoDarkMode
  companyLogoWhite: "/images/HeaderLogo.png", // White
  ...sharedStyles,
};
