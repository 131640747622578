import React, { useMemo, useCallback, useContext } from "react";
import { Flex } from "reflexbox";
import Field from "../../UI/forms/Field";
import { Heading } from "../../UI/Text";
import AlertContext from "../../../context/alert/AlertContext";
import UserContext from "../../../context/user/UserContext";
import Link from "../../UI/Link";

const ContactForm = ({ isLoading, disableForm, onSubmit, setRefetch }) => {
  const { showFailureAlert } = useContext(AlertContext);
  const { activeUser } = useContext(UserContext);
  const { roleLevel } = activeUser || {};

  const onClick = useCallback(() => {
    if (roleLevel >= 5)
      showFailureAlert(
        `You must edit contact information on the customers page, ${(
          <Link to="/Customers" title="Go to Customers Page." />
        )}`
      );
    else
      showFailureAlert(`You cannot edit a customer that has been autofilled.`);
  }, [roleLevel, showFailureAlert]);

  const onBlur = useCallback(
    async (e) => {
      await onSubmit({ [e.target.name]: e.target.value });
      setRefetch(1);
    },
    [onSubmit, setRefetch]
  );

  const fieldProps = useMemo(
    () => ({
      disabled: disableForm,
      onClick: disableForm ? onClick : undefined,
      isLoading,
      onBlur,
    }),
    [disableForm, onClick, isLoading, onBlur]
  );

  return (
    <Flex flexDirection={["column", "column", "row"]}>
      <Flex mr={["0", "0", "0.5rem"]} flexDirection="column">
        <Heading mt="0">Contact Information</Heading>

        <Field
          {...fieldProps}
          name="contact.firstName"
          labelText="First Name"
        />
        <Field {...fieldProps} name="contact.lastName" labelText="Last Name" />
        <Field {...fieldProps} name="contact.email" labelText="Email" />
        <Field {...fieldProps} name="contact.phone" labelText="Phone" phone />
      </Flex>
    </Flex>
  );
};

export default ContactForm;
