import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { getUser, updateUser } from "../../requests/userManagementRequests";
import { getPlatform } from "../../requests/platformManagementRequests";
import authentication from "../../modules/authentication";
import UserContext from "./UserContext";
import { useQuery } from "../../components/Hooks";
import AlertContext from "../socket/SocketContext";
import SocketContext from "../socket/SocketContext";
import _ from "lodash";
import * as qmr from "../../requests/quoteManagementRequests";
import { login } from "../../requests/authRequests";

const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const { matches: isDarkOS } = window.matchMedia(
    "(prefers-color-scheme: dark)"
  );
  const dm = window.sessionStorage.getItem("darkMode");
  const { username } = authentication.verifyToken() || {};
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId") || "";

  // - SF URL Parameters
  const urlUsername = searchParams.get("username");
  const opportunityName = searchParams.get("opportunityName");
  const opportunityId = searchParams.get("opportunityId");
  const companyLocationId = searchParams.get("companyLocationId");
  const existingQuote = searchParams.get("existingQuote");
  const quoteId = searchParams.get("quoteId");
  const sfQuoteNumber = searchParams.get("sfQuoteNumber");
  const accountId = searchParams.get("accountId");
  const accountName = searchParams.get("accountName");
  const phone = searchParams.get("phone");
  const fax = searchParams.get("fax");
  const divisionCode = searchParams.get("divisionCode");
  const regionCode = searchParams.get("regionCode");
  const territoryCode = searchParams.get("territoryCode");
  const repCode = searchParams.get("repCode");
  const contactFirstName = searchParams.get("contactFirstName");
  const contactLastName = searchParams.get("contactLastName");
  const contactEmail = searchParams.get("contactEmail");
  const address = searchParams.get("address");
  const userId = searchParams.get("userId");
  const userAlias = searchParams.get("userAlias ");
  const customerBillingCity = searchParams.get("customerBillingCity");
  const customerBillingState = searchParams.get("customerBillingState");
  const customerBillingZip = searchParams.get("customerBillingZip");
  const customerBillingCountry = searchParams.get("customerBillingCountry");
  const customerBillingCountryCode = searchParams.get(
    "customerBillingCountryCode"
  );
  const customerBillingAddress = searchParams.get("customerBillingAddress");
  const isEmailBlank = searchParams.get("isEmailBlank");
  const sfUsername = isEmailBlank ? urlUsername : urlUsername;
  // - END SF URL Parameters

  const [loggedIn, setLoggedIn] = useState(!!username);
  const [activeUser, setActiveUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [queries, setQueries] = useState({});
  const [inFlight, setInFlight] = useState(false);
  const [loggedInUsers, setLoggedInUsers] = useState({});
  const [activeOrderStatus, setActiveOrderStatus] = useState([]);
  const [numOrdersUpdated, setNumOrdersUpdated] = useState(0);

  const alertContext = useContext(AlertContext);
  const { userLoggedIn } = useContext(SocketContext);

  const _setQueries = useCallback((data) => {
    if (typeof setQueries === "function") setQueries(data);
  }, []);

  const [darkMode, setDarkMode] = useState(
    productId ? false : dm === "true" ? true : dm === "false" ? false : isDarkOS
  );

  const updateLoggedInUsers = useCallback(
    (user) => {
      const { username, content } = user || {};
      const { isLoggedIn } = content || {};
      setLoggedInUsers({ ...loggedInUsers, [username]: isLoggedIn });
    },
    [loggedInUsers]
  );

  const { pid } = activeUser || {};

  const {
    data: platform,
    isLoading: platformLoading,
    refetchQuery,
  } = useQuery({
    queries,
    setQueries,
    queryID: "platform",
    request: getPlatform,
    queryVariables:
      !loggedIn || activeUser?.roleLevel === 0
        ? { defaultPlatform: true }
        : { id: pid },
    forcedFilters: {},
    skip:
      isLoading ||
      (loggedIn && !pid && activeUser?.roleLevel > 1) ||
      (loggedIn && !activeUser),
  });

  const updateOrderStatus = useCallback(
    (data) => {
      const { activeOrders = [], numItemsUpdated } = data;

      setActiveOrderStatus(activeOrders);

      if (activeUser?.roleLevel >= 5 && platform) {
        const { emailAddresses: _emails } = platform;
        const emails = JSON.parse(_emails).split(",");
        activeOrders.forEach((order) => {
          const subject = `New Update to Your ${
            activeOrders.length > 1 ? "Orders" : "Order"
          }.`;
          const htmlBody = (
            <div>
              {activeOrders.map((order) => {
                return Object.keys(order).map((val) => <p>{val}</p>);
              })}
            </div>
          );
          setNumOrdersUpdated(numItemsUpdated);
          if (_.isArray(emails)) {
            qmr.sendOrderStatusEmails({
              emails,
              subject,
              htmlBody,
            });
          }
        });
      }
    },
    [activeUser, platform]
  );

  const buildUserObject = useCallback(
    async (user, signal) => {
      if (!user) {
        setLoading(false);
        return;
      }

      if (!activeUser?.id) {
        setLoading(true);
        const userResponse = await getUser({
          username: user,
          signal,
        });

        const { result: currentUser } = userResponse || {};

        if (!currentUser) return console.log("No user");
        setActiveUser(currentUser);
        updateUser({ id: currentUser?.id, input: { isLoggedIn: true } });
        setLoggedIn(true);
        const userQueryObject = {
          data: currentUser,
          refetchQuery: buildUserObject,
          setData: setActiveUser,
          isLoading: false,
        };

        setQueries({ ...queries, [currentUser.username]: userQueryObject });

        userLoggedIn({ user, updateLoggedInUsers, updateOrderStatus });

        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUser, updateLoggedInUsers, updateOrderStatus, userLoggedIn]
  );

  const createQuotes = useCallback(
    async (signal) => {
      const { showSuccessAlert } = alertContext;

      const salesPortalQuoteDTO = {
        opportunityId,
        opportunityName,
        username: sfUsername,
        companyLocationId,
        salesforceQuoteId: null, // wm mod - we don't have this value yet until salesforce create quote is run. Removing default.
        sfQuoteNumber,
        accountId,
        accountName,
        phone,
        fax,
        divisionCode,
        regionCode,
        territoryCode,
        repCode,
        contactFirstName,
        contactLastName,
        contactEmail,
        address: address.split("\n")[0],
        userId,
        userAlias,
        customerBillingCity,
        customerBillingState,
        customerBillingZip: customerBillingZip.includes("-")
          ? customerBillingZip.split("-")[0]
          : customerBillingZip,
        customerBillingCountry,
        customerBillingAddress: customerBillingAddress.split("\n")[0],
        customerBillingCountryCode,
      };

      if (!existingQuote) {
        const { result: sfQuoteId } = await qmr.salesforceCreateQuote(
          opportunityId,
          opportunityName
        );
        const { result: solidfitQuoteId } =
          await qmr.createQuoteInitiatedFromSalesforce(salesPortalQuoteDTO);

        // update salesforce quote with the solidfit quote id
        await qmr.salesforceUpdateQuote(sfQuoteId, solidfitQuoteId);

        // get the salesforce quote number from the newly created salesforce quote
        // the salesforce quote number is NOT the same thing as the salesforce quote id
        const salesforceQuoteNumber = await qmr.salesforceGetQuoteNumber(
          sfQuoteId
        );

        await qmr.updateSalesforceQuoteNumber(
          salesforceQuoteNumber,
          solidfitQuoteId
        );

        setLoading(false);
        refetchQuery("quotes");

        if (showSuccessAlert) {
          showSuccessAlert(`Successfully created Quote #: ${sfQuoteId}`);
        }
        navigate(`/Quote/${solidfitQuoteId}`);
      } else {
        navigate(`/Quote/${quoteId}`);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accountId,
      accountName,
      address,
      companyLocationId,
      contactEmail,
      contactFirstName,
      contactLastName,
      customerBillingAddress,
      customerBillingCity,
      customerBillingCountry,
      customerBillingCountryCode,
      customerBillingState,
      customerBillingZip,
      divisionCode,
      existingQuote,
      fax,
      navigate,
      opportunityId,
      opportunityName,
      phone,
      quoteId,
      regionCode,
      repCode,
      sfQuoteNumber,
      sfUsername,
      territoryCode,
      userAlias,
      userId,
      refetchQuery,
    ]
  );

  const loginSalesforceUser = useCallback(
    async (signal) => {
      const { showSuccessAlert, showFailureAlert } = alertContext;

      if (showSuccessAlert)
        showSuccessAlert(`Creating a new Quote from SalesForce...`);

      let userResponse = await getUser({
        username: sfUsername,
        signal,
      });

      const { result: currentUser } = userResponse || {};

      setActiveUser(currentUser);

      if (!loggedIn) {
        const {
          result: loginRes,
          success,
          error,
        } = await login({
          username: currentUser?.username,
          fromSalesforce: true,
          stayLoggedIn: true,
        });
        if (error)
          return showFailureAlert(
            `Error creating a quote from salesforce: ${error}`
          );
        if (success) {
          window.localStorage.setItem("token", loginRes.token);
          setLoggedIn(true);

          userLoggedIn({
            user: sfUsername,
            updateLoggedInUsers,
            updateOrderStatus,
          });
          updateUser({ id: currentUser?.id, input: { isLoggedIn: true } });
          setLoggedIn(true);

          setLoading(false);

          if (showSuccessAlert) {
            showSuccessAlert(
              `Successfully logged in as ${currentUser?.username}`
            );
          }
        }
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedIn, sfUsername, updateLoggedInUsers, updateOrderStatus, userLoggedIn]
  );

  useEffect(() => {
    if (dm) return;
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", function (e) {
        const isDark = e.matches;
        const loc = window.sessionStorage.getItem("darkMode");
        setDarkMode(loc === "true" ? true : loc === "false" ? false : isDark);
      });
  }, [dm]);

  useEffect(() => {
    const controller = new AbortController();

    buildUserObject(username, controller.signal);

    return () => controller.abort();
  }, [buildUserObject, username]);

  useEffect(() => {
    const controller = new AbortController();

    if (sfUsername) loginSalesforceUser(controller.signal);

    if (sfUsername && loggedIn) {
      createQuotes(controller.signal);
    }

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <UserContext.Provider
      value={{
        activeUser,
        darkMode,
        setDarkMode,
        isLoading: isLoading || platformLoading,
        setActiveUser,
        loggedIn,
        setLoggedIn,
        buildUserObject,
        activeOrderStatus,
        setActiveOrderStatus,
        setNumOrdersUpdated,
        numOrdersUpdated,
        username,
        platform,
        queries,
        setQueries: _setQueries,
        inFlight,
        setInFlight,
        loggedInUsers,
        setLoggedInUsers,
        pid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
