import React, { useRef, useContext } from "react";
import PropTypes from "prop-types";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { TableWrapper, StyledTable } from "./styles-table.jsx";
import ShimmerRows from "./ShimmerRows";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow.jsx";
import { PagerContext } from "../Pager/PagerContext";
import { RowDisplayContext } from "./TableContext.jsx";

/**
 * See the README.md for detailed documentation
 */

const TableLayout = React.memo(
  ({ columns, data, setSorting, isLoading, open }) => {
    const { order } = useContext(PagerContext);

    const instance = useReactTable({
      data,
      columns,
      state: {
        sorting: order,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
    });

    const headers = instance.getHeaderGroups()[0].headers; // Should only have one header group
    const headersRef = useRef(headers);
    headersRef.current = headers;

    const { dropdown } = useContext(RowDisplayContext);

    return (
      <TableWrapper>
        <StyledTable hover style={{ tableLayout: "fixed" }}>
          <TableHeader
            columns={columns}
            headers={headers}
            hasDropdown={dropdown}
          />

          {!isLoading ? (
            instance
              .getRowModel()
              .rows.map((row, i) => (
                <TableRow
                  open={open}
                  count={data.length}
                  index={i}
                  key={row.id}
                  row={row}
                  headersRef={headersRef}
                />
              ))
          ) : (
            <ShimmerRows instance={instance} />
          )}
        </StyledTable>
      </TableWrapper>
    );
  }
);

TableLayout.propTypes = {
  /**
   * The dataset to be displayed on the table. This property should be an array of similar objects.
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Allows a specific click event to be triggered */
  onRowClick: PropTypes.func,
  /**
   * Definitions of each column in the table.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.oneOfType([
        // Used to associate a cell with information derived from the dataset
        PropTypes.string,
        PropTypes.func,
      ]),
      display: PropTypes.oneOfType([
        // Used when customizing the display of unique cells
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
      ]),
      header: PropTypes.oneOfType([
        // Determines the column's header
        PropTypes.string,
        PropTypes.func,
      ]),
      sort: PropTypes.oneOfType([
        // Specify whether you want the column to be sortable. Can also define a sorting function
        PropTypes.bool,
        PropTypes.func,
      ]),
      size: PropTypes.oneOfType([
        // Defines the widths for different device sizes
        PropTypes.number,
        PropTypes.shape({
          xs: PropTypes.number,
          sm: PropTypes.number,
          md: PropTypes.number,
          lg: PropTypes.number,
          xl: PropTypes.number,
        }),
      ]),
      id: PropTypes.string, // Holds a unique identifier of the column
    })
  ).isRequired,
  /**
   * Optional properties to be fed into the TanStack table.
   */
  tableOptions: PropTypes.object,
  /**
   * Optional properties to be fed into the TanStack table instance.
   */
  instanceOptions: PropTypes.object,
};

export default TableLayout;
