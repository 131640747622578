import { position, layout, flexbox, space, color } from "styled-system";
import styled, { css } from "styled-components";

export const margins = () => css`
  ${space};
  &:first-of-type {
    margin-left: 0;
    ${space};
  }
  &:last-of-type {
    margin-right: 0;
    ${space};
  }
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  align-content: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  margin: 0 0.5rem;
  padding: ${({ textOnly, size, icon }) => {
    if (textOnly) return "0";
    if (size === "tiny") return "0.5rem 0.75rem";
    if (icon) {
      if (size === "small") return "0.5rem 0.75rem";
      if (size === "large") return "0.95rem";
      return "0.65rem 1rem";
    }
    if (size === "small") return "0.35rem 0.75rem";
    if (size === "large") return "0.95rem 1.5rem";

    return "0.65rem 1rem";
  }};
  flex-wrap: nowrap;
  transform-origin: center;
  transition: 200ms all ease-in;
  border-radius: ${({ theme }) => theme?.borderRadius || "15px"};
  border: ${({ textOnly }) => (textOnly ? "none" : "2px solid")};
  border-color: ${({ color, theme }) => color || theme?.primary};

  ${margins};

  svg {
    margin: 0 0.35rem;
    font-weight: bold;
  }

  div,
  svg {
    white-space: nowrap;
    width: auto;
    font-weight: bold;
    line-height: ${({ size, icon, textOnly }) => {
      if (textOnly) return "1.5";
      if (size === "tiny") return "1.5";
      if (size === "small") return "1.5";
      if (size === "large" || icon) return "1.25";
      return "1.125";
    }};
    font-size: ${({ size, icon }) => {
      if (size === "tiny") return "0.75rem";
      if (size === "small") {
        if (icon) return "0.85rem";
        return "0.75rem";
      }
      if (size === "large" || icon) return "1.25rem";
      return "";
    }};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:only-child {
      margin: 0;
    }
    ${color};

    color: ${({ reverse, theme, color }) => {
      if (reverse) return color || theme?.primary;
      return theme?.backgroundColor;
    }};
    background-color: transparent;
  }

  opacity: ${({ disabled }) => (disabled ? "0.35" : "1")};

  ${color};

  background-color: ${({ textOnly, reverse, theme, color }) => {
    if (textOnly) return "transparent !important";
    if (reverse) return theme?.backgroundColor;
    return color || theme?.primary;
  }};

  &:hover {
    text-decoration: none;
    z-index: 2;
    transition: 100ms all ease-out;
    transform: scale(1.1);
    box-shadow: 0 5px 5px 2.5px ${({ theme }) => theme.boxShadowColor || ""};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    background-color: ${({ reverse, theme, color, bg, disabled }) => {
      if (disabled) return "";
      if (reverse) return color || bg || theme?.primary;
      return theme?.backgroundColor;
    }};

    svg,
    div {
      color: ${({ reverse, theme, color, bg, disabled }) => {
        if (disabled) return "";
        if (reverse) return theme?.backgroundColor;
        return bg || color || theme?.primary;
      }};
      background-color: transparent;
    }

    ${({ textOnly, disabled }) => {
      if (disabled) return "";
      if (textOnly)
        return css`
          box-shadow: none;
          transform: scale(1.02);
          color: #ffffffcc !important;
        `;
    }};
  }

  &.header-link {
    div,
    svg {
      color: white;
    }
    transform-origin: left;
    &:hover {
      transform: scale(1.05);
      div,
      svg {
        color: ${({ theme }) => (theme.darkMode ? theme.primary : "white")};
      }
    }
    &:active {
      transform: scale(1);
    }
  }
  &.header-dropdown {
    transform-origin: center;
  }

  &:active {
    ${({ textOnly, disabled }) =>
      !textOnly && !disabled
        ? {
            transform: "scale(1)",
            boxShadow: "none",
          }
        : { transform: "unset", boxShadow: "none" }};
  }

  ${position};
  ${layout};
  ${flexbox};
`;
