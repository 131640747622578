import React, { useContext, useState, useCallback, useMemo } from "react";
import * as yup from "yup";
import { Formik, Form, useFormikContext } from "formik";
import { Flex, Box } from "reflexbox/styled-components";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { login, getSecret, authorize } from "../../requests/authRequests";
import Loading from "../UI/Loading";
import UserContext from "../../context/user/UserContext";
import Field from "../UI/forms/Field";
import Checkbox from "../UI/forms/Checkbox";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import Card from "../UI/Card";
import { Helper, Body } from "../UI/Text";
import { PageWrapper } from "../UI/Grid";
import guid from "../../modules/guid";
import {
  createUser,
  forgotPassword,
} from "../../requests/userManagementRequests";
import { QRCodeSVG } from "qrcode.react";
import AlertContext from "../../context/alert/AlertContext";

const AutoSubmitToken = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    if (values.token.length === 7) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

const Login = ({ SUN = false }) => {
  const navigate = useNavigate();
  const { buildUserObject } = useContext(UserContext);
  const { showFailureAlert, showSuccessAlert } = useContext(AlertContext);
  const theme = useTheme();
  const { companyLogo, companyLogoWhite } = theme || {};

  const [showForgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [gatherToken, setGatherToken] = useState(false);
  const [secret, setSecret] = useState(null);
  const [secretURI, setSecretURI] = useState(null);
  const [jwt, setJWT] = useState(null);
  const [isAuthorizing, setAuthorizing] = useState(false);

  const runGetSecret = async (username, secret) => {
    setGatherToken(true);
    const secretKey = secret ? secret : await getSecret();
    const issuer = "SwiftSell";
    const algorithm = "SHA1";
    const digits = "6";
    const period = "30";
    const otpType = "totp";
    const configUri = `otpauth://${otpType}/${issuer}:${username}?algorithm=${algorithm}&digits=${digits}&period=${period}&issuer=${issuer}&secret=${secretKey}`;

    setSecret(secretKey);
    setSecretURI(configUri);
    return configUri;
  };

  const runAuthorize = useCallback(
    async (values, { resetForm }) => {
      const { token: _token, username } = values || {};
      const { 0: firstThree, 1: lastThree } = _token.split(" ");
      const token = `${firstThree}${lastThree}`;
      setAuthorizing(true);
      const { isVerified } = await authorize({ token, secret, username });

      if (!isVerified) {
        setAuthorizing(false);
        setLoading(false);
        showFailureAlert("Incorrect Passcode.");
        resetForm({ values });
        return false;
      } else {
        window.sessionStorage.setItem("token", jwt);
        if (values.stayLoggedIn === true)
          window.localStorage.setItem("token", jwt);
        await buildUserObject(username);

        return isVerified;
      }
    },
    [buildUserObject, jwt, secret, showFailureAlert]
  );

  const validationSchema = useMemo(
    () =>
      SUN
        ? yup.object().shape({
            name: yup.string().required("Name is required"),
            username: yup
              .string("Invalid email address.")
              .email("Invalid email address.")
              .required("Email address is required."),
          })
        : yup.object().shape({
            username: yup
              .string("Invalid email address.")
              .email("Invalid email address.")
              .required("Email address is required."),
            token: gatherToken
              ? yup
                  .string()
                  .required("No passcode provided.")
                  .min(7, "Passcode must be 6 numbers.")
                  .max(7, "Passcode must be 6 numbers.")
                  .matches(/[0-9] [0-9]/, "Passcode can only contain numbers.")
              : yup.string(),
            password: signUp
              ? yup
                  .string()
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-^&*]).{8,}$/,
                    "Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                  )
                  .required("Password is required.")
              : yup.string().required("Password is required."),
            confirmPassword: signUp
              ? yup
                  .string()
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-^&*]).{8,}$/,
                    "Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                  )
                  .when("password", {
                    is: (val) => val && val.length > 8,
                    then: yup
                      .string()
                      .oneOf([yup.ref("password")], "Passwords do not match."),
                  })
                  .required("Please confirm your password.")
              : undefined,
            stayLoggedIn: yup.boolean(),
          }),
    [SUN, gatherToken, signUp]
  );

  const onSubmit = useCallback(
    async (_values, { resetForm }) => {
      let values = { ..._values };
      setLoading(true);
      if (SUN) {
        try {
          const { 0: firstName, 1: lastName } =
            (values?.name || "").split(" ") || {};
          const pw = guid.GenerateGUID();
          const { result, success, error } = await createUser({
            input: {
              firstName: firstName && lastName ? firstName : values.name,
              lastName: firstName && lastName ? lastName : undefined,
              password: pw,
              username: values.username,
              _id: guid.GenerateGUID(),
              roleId: "public",
            },
          });
          if (!success || !result) {
            setLoading(false);
            resetForm({ values });
            return showFailureAlert(error);
          }
          values.password = pw;
        } catch (error) {
          setLoading(false);
          resetForm({ values });
          console.log(error);
          return showFailureAlert("Sign up failed!");
        }
      } else if (signUp) {
        try {
          const { result, success, error } = await createUser({
            input: {
              username: values.username,
              password: values.password,
              _id: guid.GenerateGUID(),
              roleId: "public",
            },
          });
          if (!success || !result) {
            setLoading(false);
            resetForm({ values });
            return showFailureAlert(error);
          }
          await runGetSecret(values.username);
          setGatherToken(true);
          return;
        } catch (error) {
          setLoading(false);
          resetForm({ values });
          console.error(error);
          return showFailureAlert("Sign up failed!");
        }
      }

      try {
        const {
          result: loginRes,
          success,
          error,
        } = await login({
          username: values.username,
          password: values.password,
          stayLoggedIn: values.stayLoggedIn,
        });

        if (!success || error) {
          setLoading(false);
          return showFailureAlert(error);
        }
        setJWT(loginRes.token);
        if (loginRes.platformRequiresMFA && !loginRes.secret) {
          await runGetSecret(values.username);
          setGatherToken(true);
        } else if (loginRes.secret) {
          setGatherToken(true);
        } else {
          if (values.stayLoggedIn === true)
            window.localStorage.setItem("token", loginRes.token);
          else window.sessionStorage.setItem("token", loginRes.token);
          await buildUserObject(values.username);
          navigate("/QuoteDashboard");
        }
        resetForm({ values });
      } catch (error) {
        setLoading(false);
        resetForm({ values });
        console.error(error);
        return showFailureAlert("Login failed!");
      }
    },
    [SUN, signUp, showFailureAlert, buildUserObject, navigate]
  );

  const onSubmitForgotPassword = useCallback(
    async (values) => {
      try {
        const response = await forgotPassword({
          username: values.username,
        });

        if (!response) return showFailureAlert("Password reset failed!");

        showSuccessAlert("Password reset email sent!");
      } catch (error) {
        console.error(error);
        showFailureAlert("Password reset failed!");
      }
    },
    [showFailureAlert, showSuccessAlert]
  );

  const changeToken = useCallback((e, setFieldValue, values) => {
    const lastDigit = e.target.value[e.target.value.length - 1] || "";
    if (e.target.value.length === 8) return;
    if (e.target.value.length === 4 && values.token.length === 3) {
      setFieldValue("token", `${values.token} ${lastDigit}`);
    } else if (e.target.value.length === 4 && values.token.length === 5) {
      const { 0: firstThree } = e.target.value.split(" ");
      setFieldValue("token", firstThree);
    } else {
      setFieldValue("token", e.target.value);
    }
  }, []);

  const submit = useMemo(() => {
    if (gatherToken) return runAuthorize;
    return onSubmit;
  }, [gatherToken, onSubmit, runAuthorize]);

  const getFields = useCallback(
    ({ values, setFieldValue }) => {
      if (gatherToken) {
        return (
          <>
            {secretURI ? (
              <>
                <QRCodeSVG value={secretURI} />
                <Box my="1rem" maxWidth={400}>
                  <Body>
                    Scan the code with your authenticator application, then
                    please enter the code generated in the field below.
                  </Body>
                </Box>
              </>
            ) : (
              <Box my="1rem" maxWidth={400}>
                <Body>
                  Please enter the code generated by your Multi-Factor
                  Authentication application.
                </Body>
              </Box>
            )}

            <AutoSubmitToken />

            <Field
              name="token"
              type="tel"
              autoFocus={gatherToken}
              onChange={(e) => changeToken(e, setFieldValue, values)}
              value={values.token}
              style={{ textAlign: "center", fontSize: "3rem" }}
            />
            <Button mt="0.5rem" type="submit">
              {isAuthorizing ? (
                <Loading
                  inline
                  color1={theme.secondary}
                  color2={theme.yellow}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </>
        );
      } else if (SUN) {
        return (
          <>
            <Field name="name" placeholder="Name" type="text" />
            <Field name="username" placeholder="Email" type="text" autoFocus />
            <Button type="submit">Try it out!</Button>
          </>
        );
      } else {
        return (
          <>
            <Field
              name="username"
              placeholder="Username"
              type="text"
              autoFocus
            />

            {!showForgotPassword ? (
              <>
                <Field name="password" placeholder="Password" type="password" />

                {signUp ? (
                  <Field
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    type="password"
                  />
                ) : null}

                <Checkbox
                  name="stayLoggedIn"
                  labelText="Keep me logged in on this computer"
                />

                <Flex mt="0.5rem">
                  <Button
                    reverse={signUp}
                    color={theme.secondary}
                    onMouseDown={!signUp ? undefined : () => setSignUp(false)}
                    type={!signUp ? "submit" : "button"}
                  >
                    {isLoading && !signUp ? (
                      <Loading
                        inline
                        color1={theme.primary}
                        color2={theme.yellow}
                      />
                    ) : (
                      "Login"
                    )}
                  </Button>

                  <Button
                    reverse={!signUp}
                    onMouseDown={signUp ? undefined : () => setSignUp(true)}
                    type={signUp ? "submit" : "button"}
                  >
                    {isLoading && signUp ? (
                      <Loading
                        inline
                        color1={theme.secondary}
                        color2={theme.success}
                      />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </Flex>

                <Button
                  reverse
                  size="small"
                  color={theme?.gray}
                  mt="1rem"
                  onClick={() => setForgotPassword(true)}
                >
                  <Helper>Forgot Password?</Helper>
                </Button>
              </>
            ) : (
              <>
                <Box>
                  <Button
                    mt="0.5rem"
                    type="button"
                    onClick={() => onSubmitForgotPassword(values)}
                  >
                    Request Password Reset
                  </Button>
                </Box>
                <Button
                  reverse
                  size="small"
                  color={theme?.gray}
                  mt="1rem"
                  onClick={() => setForgotPassword(false)}
                >
                  <Helper>Back to login</Helper>
                </Button>
              </>
            )}
          </>
        );
      }
    },
    [
      SUN,
      changeToken,
      gatherToken,
      isAuthorizing,
      isLoading,
      onSubmitForgotPassword,
      secretURI,
      showForgotPassword,
      signUp,
      theme,
    ]
  );

  return (
    <PageWrapper p={0}>
      <Modal
        alignItems="center"
        justifyContent="center"
        pr={SUN ? "0 !important" : undefined}
        pl={SUN ? "0 !important" : undefined}
        show={true}
      >
        <Card
          backgroundColor={SUN ? theme.secondary : undefined}
          disableHover
          style={{ display: SUN ? "flex" : "block" }}
          width={SUN ? ["100vw", "100vw", "100vw", "400px"] : undefined}
          height={SUN ? ["100vh", "100vh", "100vh", "auto"] : undefined}
          alignItems="center"
        >
          <Formik
            initialValues={{
              username: "",
              password: "",
              stayLoggedIn: false,
              confirmPassword: "",
              token: "",
            }}
            validationSchema={validationSchema}
            validateOnMount={false}
            validateOnChange={false}
            validateOnBlur={showForgotPassword}
            onSubmit={submit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form
                  style={{ width: "100%", margin: "auto", maxWidth: "400px" }}
                >
                  <Flex
                    width={1}
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                  >
                    {!gatherToken && (
                      <img
                        style={{
                          width: "150px",
                          height: "auto",
                          marginBottom: "1rem",
                        }}
                        src={SUN ? companyLogoWhite : companyLogo}
                        alt="SwiftSell"
                      />
                    )}

                    {getFields({ values, setFieldValue })}
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Modal>
    </PageWrapper>
  );
};

export default Login;
