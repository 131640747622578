import axios from "axios";

export const getStatusTypes = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/statusTypesMgmt/getStatusTypes",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getStatusType = async (body) => {
  try {
    const res = await axios.post("/api/v1/statusTypesMgmt/getStatusType", body);
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createStatusType = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/statusTypesMgmt/createStatusType",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const updateStatusType = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/statusTypesMgmt/updateStatusType",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const deleteStatusType = async (body) => {
  try {
    const res = await axios.post(
      "/api/v1/statusTypesMgmt/deleteStatusType",
      body
    );
    if (res?.status === 200) return res.data.result;
    throw new Error(res);
  } catch (error) {
    console.error(error);
    return null;
  }
};
