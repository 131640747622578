import React, { useMemo, useContext, useState, useEffect, useRef } from "react";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import Card from "../../UI/Card";
import ContactForm from "./ContactForm";
import AddressForm from "./AddressForm";
// import Field from "../../UI/forms/Field";
// import { useQuery } from "../../Hooks";
import * as QMR from "../../../requests/quoteManagementRequests";
import * as CMR from "../../../requests/customerManagementRequests";
import ConfiguratorContext from "../../../context/configurator/ConfiguratorContext";
import { updateAddress } from "../../../requests/addressManagementRequests";

// import UserContext from "../../../context/user/UserContext";

const { updateCustomer } = CMR;
const { updateQuote } = QMR;

export const FieldWrapper = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  form,
  div {
    width: 100%;
  }
`;

const validationSchema = yup.object().shape({
  customerId: yup.string(),
  shippingAddressId: yup.string(),
  sameAsBilling: yup.boolean().nullable(),
  contact: yup.object().shape({
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    email: yup.string().email().nullable(),
    phone: yup.string().nullable(),
    companyName: yup.string().nullable(),
  }),

  billing: yup.object().shape({
    Address: yup.string().nullable(),
    Address2: yup.string().nullable(),
    City: yup.string().nullable(),
    State: yup.string().nullable(),
    Zip: yup.number().nullable(),
  }),

  shipping: yup.object().shape({
    Address: yup.string().nullable(),
    Address2: yup.string().nullable(),
    City: yup.string().nullable(),
    State: yup.string().nullable(),
    Zip: yup.number().nullable(),
  }),
});

const Customer = ({
  contactLoading,
  isLoading: quoteLoading,
  shippingAddresses,
  quoteId,
  refetchQuote,
}) => {
  const { quote, setQuote } = useContext(ConfiguratorContext);
  // const { activeUser } = useContext(UserContext);
  // const { pid } = activeUser || {};

  const [shouldRefetch, setRefetch] = useState(null);

  const throttle = useRef(null);

  useEffect(() => {
    switch (shouldRefetch) {
      case 0:
        // throttle.current = timeout;
        break;
      case 1:
        if (!throttle.current) {
          const timeout = setTimeout(() => {
            clearTimeout(throttle);
            setRefetch(2);
          }, 5000);
          throttle.current = timeout;
        }
        break;
      case 2:
        refetchQuote();
        clearTimeout(throttle.current);
        setRefetch(null);
        break;
      default:
        throttle.current = null;
        break;
    }
  }, [refetchQuote, shouldRefetch]);

  const isLoading = useMemo(
    () => (quoteLoading || contactLoading) && !quote,
    [contactLoading, quote, quoteLoading]
  );

  // const { data } = useQuery({
  //   request: getCustomers,
  //   forcedFilters: {},
  //   skip: isLoading || !pid,
  //   queryVariables: { pid, all: false },
  // });

  // const { rows: customers = [] } = data || {};

  const { shippingAddress: quoteShipping, billingAddress: quoteBilling } =
    quote || {};

  // const onOptionClick = useCallback(
  //   async (id) => {
  //     if (id === "new") {
  //       setQuote({
  //         ...quote,
  //         contactId: null,
  //         sameAsBilling: false,
  //         shippingAddressId: null,
  //         contact: {
  //           id: quote?.contact?.id,
  //           firstName: null,
  //           lastName: null,
  //           erpId: null,
  //           email: null,
  //           phone: null,
  //           companyName: null,
  //         },
  //         shippingAddress: {
  //           id: quote?.shippingAddress?.id,
  //           Address: "",
  //           Address2: "",
  //           City: "",
  //           State: "",
  //           Zip: null,
  //           sameAsShipping: false,
  //         },
  //         billingAddress: {
  //           id: quote?.billingAddress?.id,
  //           Address: "",
  //           Address2: "",
  //           City: "",
  //           State: "",
  //           Zip: null,
  //           sameAsShipping: false,
  //         },
  //       });
  //       await updateQuote({
  //         id: quoteId,
  //         input: { sameAsBilling: "0", shippingAddressId: null, erpId: null },
  //       });
  //       await updateCustomer({
  //         id: quote?.contact?.id,
  //         input: {
  //           erpId: null,
  //           companyName: null,
  //           firstName: null,
  //           lastName: null,
  //           email: null,
  //           phone: null,
  //         },
  //       });
  //       [quote?.billingAddress?.id, quote?.shippingAddress?.id].forEach(
  //         async (id) => {
  //           if (id)
  //             await updateAddress({
  //               id,
  //               input: {
  //                 Address: "",
  //                 Address2: "",
  //                 City: "",
  //                 State: "",
  //                 Zip: null,
  //                 sameAsShipping: "0",
  //               },
  //             });
  //         }
  //       );

  //       await updateQuote({ id: quoteId, input: { contactId: null } });
  //       refetchQuote();
  //     } else {
  //       const selectedContact = _.find(customers, (c) => {
  //         return +c.id === +id;
  //       });
  //       const {
  //         billingAddress,
  //         shippingAddress,
  //         companyName,
  //         firstName,
  //         lastName,
  //         erpId,
  //         email,
  //         phone,
  //       } = selectedContact || {};
  //       setQuote({
  //         ...quote,
  //         contactId: id,
  //         contact: selectedContact,
  //         shippingAddress,
  //         billingAddress,
  //         erpId,
  //       });

  //       await updateCustomer({
  //         id: quote?.contact?.id,
  //         input: {
  //           companyName,
  //           firstName,
  //           lastName,
  //           email,
  //           phone,
  //           erpId: null,
  //         },
  //       });
  //       [billingAddress, shippingAddress].forEach(async (address, i) => {
  //         if (!address) return;
  //         const AddressType = i === 0 ? "billingAddress" : "shippingAddress";
  //         const { Address, Address2, City, State, Zip, sameAsShipping } =
  //           address;
  //         await updateAddress({
  //           id: quote?.[AddressType]?.id,
  //           input: {
  //             Address,
  //             Address2,
  //             City,
  //             State,
  //             Zip: !!Zip ? Zip : null,
  //             sameAsShipping: sameAsShipping ? "1" : "0",
  //           },
  //         });
  //       });

  //       await updateQuote({
  //         id: +quoteId,
  //         input: { contactId: id === "new" ? "new" : +id, erpId },
  //       });
  //       refetchQuote();
  //     }
  //   },
  //   [customers, refetchQuote, quote, quoteId, setQuote]
  // );

  const initialValues = useMemo(() => {
    const { firstName, lastName, email, phone, companyName, altPhone } =
      quote?.contact || {};
    console.log(quote?.contact);

    return {
      contact: {
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        phone: phone || "",
        altPhone: altPhone || "",
        companyName: companyName || "",
      },
      sameAsBilling: !!quote?.sameAsBilling ? true : false,
      shippingAddressId: quote?.shippingAddressId || "default",
      customerId: quote?.contactId || "new",
      billing: quoteBilling,
      shipping: quoteShipping,
    };
  }, [quote, quoteBilling, quoteShipping]);

  // const customerOptions = useMemo(() => {
  //   return !customers
  //     ? []
  //     : customers.map((row) => {
  //         const { id, erpId, billingAddress, companyName } = row;

  //         const { Address, Address2, City, State, Zip } = billingAddress || {};

  //         let label = `${companyName || erpId || "—"}`;
  //         if (Address) {
  //           label += `, ${Address}`;
  //           if (Address2) {
  //             label += ` ${Address2}`;
  //           }
  //           if (City) {
  //             label += `, ${City}`;
  //             if (State) {
  //               label += ` ${State}`;
  //               if (Zip) {
  //                 label += `, ${Zip}`;
  //               }
  //             }
  //           }
  //         }

  //         return { label, id };
  //       });
  // }, [customers]);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values }) => {
          const onSubmit = async (formValue) => {
            const [key, value] = Object.entries(formValue)[0] || {};
            const { 0: recordType, 1: recordKey } = key?.split(".");

            switch (recordType) {
              case "contact":
                try {
                  setQuote({
                    ...quote,
                    contact: { ...quote?.contact, [recordKey]: value },
                  });
                  await updateCustomer({
                    id: quote?.contact?.id,
                    input: { [recordKey]: value },
                  });
                } catch (error) {
                  console.error(error);
                }
                break;
              case "billing":
                try {
                  setQuote({
                    ...quote,
                    billingAddress: {
                      ...quote?.billingAddress,
                      [recordKey]: value,
                    },
                  });
                  await updateAddress({
                    id: quote?.billingAddress?.id,
                    input: { [recordKey]: value },
                  });
                } catch (error) {
                  console.error(error);
                }
                break;
              case "shipping":
                try {
                  setQuote({
                    ...quote,
                    shippingAddress: {
                      ...quote?.shippingAddress,
                      [recordKey]: value,
                    },
                  });
                  await updateAddress({
                    id: quote?.shippingAddress?.id,
                    input: { [recordKey]: value },
                  });
                } catch (error) {
                  console.error(error);
                }
                break;
              default:
                let val = value;

                if (`${key}` === "sameAsBilling") {
                  if (value === "true") val = "0";
                  if (value === true) val = "0";
                  if (value === "True") val = "0";
                  if (value === "false") val = "1";
                  if (value === "False") val = "1";
                  if (value === false) val = "1";
                }
                await updateQuote({
                  id: quoteId,
                  input: {
                    [key]: val,
                  },
                });
                await updateAddress({
                  id: quote?.billingAddress?.id,
                  input: { sameAsShipping: "0" },
                });
                await updateAddress({
                  id: quote?.shippingAddress?.id,
                  input: { sameAsShipping: "0" },
                });
                if (`${key}` === "sameAsBilling") {
                  refetchQuote();
                }
                break;
            }
          };
          return (
            <Box mt="2.5rem" mb="1rem">
              <Card>
                <Form style={{ width: "100%" }}>
                  {/* <Box width={1} flex={1}>
                    <Field
                      name="customerId"
                      as="select"
                      isLoading={customersLoading || isLoading}
                      onChange={(e) => {
                        setFieldValue("customerId", e.target.value);
                        onOptionClick(e.target.value);
                      }}
                      value={values["customerId"]}
                    >
                      <option key="new" value="new">
                        [New Customer]
                      </option>
                      {customerOptions &&
                        customerOptions.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>
                              {c.label}
                            </option>
                          );
                        })}
                    </Field>
                  </Box> */}
                  <FieldWrapper
                    width={1}
                    flexDirection={["column", "column", "row"]}
                  >
                    <Box flex={1}>
                      <ContactForm
                        disableForm={
                          quote?.erpId && values["customerId"] !== "new"
                        }
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        setRefetch={setRefetch}
                      />
                    </Box>

                    <AddressForm
                      disableForm={
                        quote?.erpId && values["customerId"] !== "new"
                      }
                      shippingAddresses={shippingAddresses}
                      setRefetch={setRefetch}
                      isLoading={isLoading}
                      setQuote={setQuote}
                      onSubmit={onSubmit}
                      refetchQuote={refetchQuote}
                    />
                  </FieldWrapper>
                </Form>
              </Card>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default Customer;
