import React from "react";
import { FieldArray } from "formik";
import _ from "lodash";
import { useTheme } from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import Label from "../Label";
import Button from "../../Button";
import StyledFormikField from "../Field";
import { Helper } from "../../Text";

const EnhancedFieldArray = (props) => {
  const { primary, secondary } = useTheme();
  const {
    errors,
    label,
    description,
    name,
    form,
    push,
    remove,
    faProps,
    ...rest
  } = props || {};
  const { values, setFieldValue } = form || {};

  const fields = values[name] || [];
  const filteredRest = _.omit(rest, faProps);

  return (
    <>
      <Label>{label}</Label>
      <Helper mb="0.5rem">{description}</Helper>
      {fields?.map((value, i) => {
        return (
          <Flex mb="1rem" key={`${name}.${i}`} alignItems="center">
            <Box width={1} mb="-1rem" mr="1rem">
              <StyledFormikField
                id={`${name}.${i}`}
                noLabel
                key={`${name}.${i}`}
                type="text"
                onChange={(e) => setFieldValue(`${name}.${i}`, e.target.value)}
                name={`${name}.${i}`}
                errors={errors[name]}
                {...filteredRest}
              />
            </Box>
            {i === fields.length - 1 && (
              <Button
                color={primary}
                iconName="faPlus"
                onClick={() => push("")}
              />
            )}
            {fields.length !== 1 && (
              <Button
                color={secondary}
                iconName="faMinus"
                onClick={() => remove(i)}
              />
            )}
          </Flex>
        );
      })}
    </>
  );
};

const FieldArrayHOC = (props) => (
  <FieldArray
    {...props}
    render={(faProps) => (
      <EnhancedFieldArray {...props} {...faProps} faProps={_.keys(faProps)} />
    )}
  />
);

export default FieldArrayHOC;
