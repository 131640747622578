import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { StyledModal, StyledModalBody, CloseButton } from "./styles";

const Modal = ({
  children,
  show,
  setShow = () => {},
  showClose = false,
  ...rest
}) => {
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target) && show) {
        setShow(false);
      }
    },
    [setShow, show]
  );
  const handleBrowserBack = useCallback(
    (e) => {
      e.preventDefault();
      if (show) setShow(false);
    },
    [show, setShow]
  );

  useEffect(() => {
    if (show) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handleBrowserBack);
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener("popstate", handleBrowserBack);
      };
    }
  }, [handleBrowserBack, handleClickOutside, show]);

  return show ? (
    <StyledModal>
      <StyledModalBody show={show} px={["0.5rem", "1rem"]} ref={ref} {...rest}>
        {showClose ? (
          <CloseButton
            reverse
            iconName="faXmark"
            onClick={() => setShow(false)}
          />
        ) : null}
        {children}
      </StyledModalBody>
    </StyledModal>
  ) : null;
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
