import React, { useContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import { createQuote } from "../../requests/quoteManagementRequests";
import { useTheme } from "styled-components";
import QuoteTable from "../Quote/QuoteDashboard/QuoteTable";
import guid from "../../modules/guid";
import { PageWrapper } from "../UI/Grid";
import Loading from "../UI/Loading";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";

const AllQuotes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setQuote } = useContext(ConfiguratorContext);
  const { activeUser } = useContext(UserContext);
  const [isCreating, setIsCreating] = useState(false);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setQuote({ ...row?.original });
    navigate(`/Quote/${row.original.id}`);
  };

  const onCreateClick = useCallback(async () => {
    setIsCreating(true);
    const newId = guid.GenerateGUID();
    const { result: newQuoteId } = await createQuote({
      input: { username: activeUser?.username, _id: newId },
    });
    setIsCreating(false);

    navigate(`/Quote/${newQuoteId}`);
  }, [activeUser.username, navigate]);

  return (
    <PageWrapper>
      <QuoteTable
        createButtonText={
          isCreating ? (
            <Loading inline color1={theme.secondary} color2={theme.success} />
          ) : (
            "New Quote"
          )
        }
        allQuotes
        onRowClick={onRowClick}
        createLink={onCreateClick}
      />
    </PageWrapper>
  );
};

export default AllQuotes;
