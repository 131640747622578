import React, { useCallback, useContext } from "react";
import _ from "lodash";
import * as yup from "yup";
import { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import HeadingSection from "../UI/HeadingSection";
import { Form, Formik } from "formik";
import { Field, FormButtonCluster, Checkbox, Radio, Label } from "../UI/forms";
import * as r from "../../requests/statusTypesMgmtReqs";
import Card from "../UI/Card";
import { Helper, Body } from "../UI/Text";
import AlertContext from "../../context/alert/AlertContext";

const validationSchema = yup.object().shape({
  name: yup.string(),
  visible: yup.boolean(),
  roleLevel: yup
    .number()
    .min(0, "Number must be greater than or equal to zero.")
    .max(
      9,
      "Nine is the highest access level built into the system. Please select a number from zero through nine."
    ),
  displayOrder: yup.number().min(0, "The lowest value for this field is zero."),
});

const StatusTypeForm = ({
  data,
  count,
  currentType,
  roles = [],
  roleCount = 0,
  setShow,
  getIcon,
  setData,
  typeId,
}) => {
  const theme = useTheme();
  const { showSuccessAlert } = useContext(AlertContext);

  const { id, name, roleLevel, displayOrder, visible } = currentType || {};

  const initialValues = {
    name: name || "",
    roleLevel: roleLevel || 0,
    visible: visible || false,
    displayOrder: displayOrder || 0,
  };

  const onSubmit = useCallback(
    async (_values, { resetForm }) => {
      try {
        const { id } = currentType || {};
        const values = { ..._values };
        values.visible = _values.visible ? "1" : "0";
        values.roleLevel = +_values.roleLevel;

        const { rows } = data;

        if (typeId === "create") {
          const result = await r.createStatusType({ input: values });
          const newObj = {
            ...result,
            visible: _values.visible,
          };

          const newRows = _.sortBy([...rows, newObj], ["displayOrder"]);

          const newData = { rows: newRows, count: count + 1 };

          showSuccessAlert(
            `Successfully updated Status Type: ${values.name || id}`
          );
          setData(newData);
          setShow(false);
        } else {
          const updatedRecord = await r.updateStatusType({ id, input: values });

          const newObj = {
            ...updatedRecord,
            visible: _values.visible,
          };

          showSuccessAlert(
            `Successfully updated Status Type: ${values.name || id}`
          );
          const newRows = _.sortBy(
            rows.map((row) => {
              const { id: rowId } = row;
              if (rowId === id) {
                return newObj;
              }
              return row;
            }),
            ["displayOrder"]
          );

          const newData = { ...data, rows: newRows };
          setData(newData);
          setShow(false);
        }
        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    [count, currentType, data, setData, setShow, showSuccessAlert, typeId]
  );

  const onDelete = useCallback(() => {
    r.deleteStatusType({ id });
    const { rows } = data;
    const newRows = [...rows];
    _.remove(newRows, (row) => row.id === id);
    setData({ rows: newRows, count: count - 1 });
    setShow(false);
  }, [count, data, id, setData, setShow]);

  return (
    <Card>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formProps) => {
          const { values, setFieldValue } = formProps;
          return (
            <>
              <HeadingSection
                my="1rem"
                hideCreate
                hideBack
                backButtonLink={() => setShow(false)}
                title={`${typeId === "create" ? "Create" : "Edit"} Status Type`}
              />
              <Form>
                <Field
                  autoFocus={typeId === "create"}
                  labelText="Name"
                  name="name"
                />
                <Field
                  labelText="Display Order"
                  name="displayOrder"
                  type="number"
                />

                <Flex flexDirection="column" width={1}>
                  <Label htmlFor="roleLevel" bold>
                    Minimum Role Level
                  </Label>
                  <Helper mb="-1rem">
                    Choose the mimimum role level required to be able to assign
                    this status.
                  </Helper>
                  <Radio
                    name="roleLevel"
                    optionWidth={100 / roleCount}
                    options={
                      roles.map((role) => ({
                        value: role.roleLevel,
                        label: (
                          <Card
                            width={1}
                            key={role.roleLevel}
                            isRadio
                            active={values.roleLevel === role.roleLevel}
                            interactive
                            iconName={getIcon(role.roleLevel)}
                            onClick={() =>
                              setFieldValue("roleLevel", role.roleLevel)
                            }
                          >
                            <Body
                              style={{ transition: "200ms ease-in-out all" }}
                              color={
                                values.roleLevel === role.roleLevel
                                  ? theme.secondary
                                  : undefined
                              }
                              mt="0.5rem"
                              center
                              bold
                            >
                              {role.name}
                            </Body>
                          </Card>
                        ),
                      })) || []
                    }
                  />
                </Flex>
                <Checkbox labelText="Is Visible" name="visible" />
                <FormButtonCluster
                  onDelete={onDelete}
                  onCancel={() => setShow(false)}
                  hideDelete={currentType === "create"}
                  recordAction={currentType === "create" ? "create" : "edit"}
                  deleteMessage={`Are you sure you want to delete ${values.name}?`}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </Card>
  );
};

export default StatusTypeForm;
