import React, { useContext, useCallback, useState, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import Button from "../UI/Button";
import { Heading } from "../UI/Text";
import UserContext from "../../context/user/UserContext";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import { configure } from "../../requests/apiRequests";
import formatter from "../../modules/formatter";
import * as constants from "../../modules/constants";
import Loading from "../UI/Loading";
import _ from "lodash";
const { SOWriteup, Review } = constants.CONFIGURATOR_MODES;

const PagesWrapper = styled(Flex)`
  flex: 3;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    .hide {
      display: none;
    }
  }
`;

const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme?.backgroundColor}47;
  backdrop-filter: blur(10px);

  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding: 0 1rem 1rem;

  @media (min-width: 1200px) {
    width: calc(100% + 3rem);
    margin-left: -3rem;
    padding: 0 0 1rem 3rem;
  }
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
  max-width: 1296px;
`;

const TopBar = (props) => {
  const theme = useTheme();
  const { activeUser, platform } = useContext(UserContext);
  const { requireHttps } = platform || {};
  const {
    api,
    setApi,
    currentPage,
    setCurrentPage,
    autoContinueMode,
    setACMode,
    activeProduct,
    configuratorMode,
  } = useContext(ConfiguratorContext);
  const { Server, ApplicationInstance } = activeProduct || {};
  const { ConfiguredPrice, Pages = [] } = api?.d || {};
  const price = `$${formatter.currency(ConfiguredPrice)}`;
  const {
    setShowCancel,
    finishConfiguration,
    sessionId,
    setShowDetails,
    setShowCreateUser,
    disableFinish,
    setDisableFinish,
  } = props || {};

  const [running, setRunning] = useState(false);

  const commitLastOption = useCallback(
    async (id, value) => {
      switch (autoContinueMode) {
        case constants.AUTO_CONTINUE_MODE.StayOnScreenRule:
          // Do nothing
          // OR
          // Test if this will move to the next question
          // If true, save locally then submit on tab change

          // Scroll to next line
          const __res = await configure({
            sessionId,
            id,
            value,
            Server,
            requireHttps,
            ApplicationInstance,
          });
          setApi(__res.data);
          break;
        case constants.AUTO_CONTINUE_MODE.StayOnTab:
          const res = await configure({
            sessionId,
            id,
            value,
            Server,
            requireHttps,
            ApplicationInstance,
          });
          setApi(res.data);

          if (id === res?.data?.d?.OptionToFocus) {
            const res = await configure({
              sessionId,
              id,
              value,
              Server,
              requireHttps,
              ApplicationInstance,
            });
            if (res?.data?.d?.Pages?.length > Pages?.length)
              setCurrentPage(res?.data?.d?.Pages?.length);
          }

          break;
        case constants.AUTO_CONTINUE_MODE.MoveTabAndScreenRule:
          // Check if the last screenOption on the next page
          // is the one that has focus
          const _res = await configure({
            sessionId,
            id,
            value,
            Server,
            requireHttps,
            ApplicationInstance,
          });

          setApi(_res.data);
          // if (id === _res?.data?.d?.OptionToFocus) {
          setCurrentPage(_res?.data?.d?.Pages?.length);
          // }

          break;
        default:
          break;
      }
      setDisableFinish(false);
    },
    [
      Pages,
      Server,
      autoContinueMode,
      requireHttps,
      sessionId,
      setApi,
      setCurrentPage,
      ApplicationInstance,
      setDisableFinish,
    ]
  );

  const continueClick = useCallback(async () => {
    const lastIndex = Pages.length - 1;

    const screens = [...Pages[lastIndex].Screens];
    _.reverse(screens);

    const lastScreen = _.find(
      screens,
      (screen) =>
        _.isArray(screen?.ScreenOptions) && screen?.ScreenOptions?.length > 0
    );

    const screenOptions = [...lastScreen.ScreenOptions];
    _.reverse(screenOptions);

    const lastScreenOption = screenOptions[0];

    const autoContinueMode = lastScreenOption?.AutoContinueMode;

    const id = lastScreenOption?.ID;
    const value = lastScreenOption?.Value;

    setACMode(autoContinueMode);
    setRunning(true);
    await commitLastOption(id, value);
    setRunning(false);

    setCurrentPage(lastIndex);
  }, [Pages, commitLastOption, setACMode, setCurrentPage]);

  const [isFinishing, setFinishing] = useState(false);

  const setPage = useCallback(
    (i) => {
      setCurrentPage(i);
    },
    [setCurrentPage]
  );

  const onArrowClick = useCallback(
    (action) => {
      if (action === "next" && currentPage + 1 >= Pages?.length) return;
      if (action === "previous" && currentPage === 0) return;
      setCurrentPage(currentPage + (action === "next" ? 1 : -1));
    },
    [Pages, currentPage, setCurrentPage]
  );

  const thePrice = useMemo(() => {
    return Review === configuratorMode ? (
      <Heading center>Create Quote</Heading>
    ) : SOWriteup === configuratorMode ? (
      <Heading center>Create Order</Heading>
    ) : (
      <Heading center>Total Price: {price}</Heading>
    );
  }, [configuratorMode, price]);

  const finishClick = useCallback(async () => {
    if (!activeUser) setShowCreateUser(true);
    else {
      setFinishing(true);
      await finishConfiguration({ sessionId });
    }
  }, [activeUser, finishConfiguration, sessionId, setShowCreateUser]);

  return (
    <Wrapper>
      {thePrice}
      <Flex
        mt={[0, 0, "-3.5rem"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flex={1} justifyContent="flex-start" alignItems="center">
          <Button
            iconName="faBan"
            onClick={() => setShowCancel(true)}
            color={theme.gray}
          >
            Cancel
          </Button>
        </Flex>

        <Flex flex={1} justifyContent="flex-end" alignItems="center">
          <Button
            color={theme.gray}
            iconName="faListUl"
            onClick={() => setShowDetails(true)}
          />

          {!api?.d?.IsExecutionComplete && (
            <Button
              // disabled={autoContinueMode === 0 || running}
              disabled={disableFinish || running}
              onClick={continueClick}
              color={theme.primary}
              iconName="faArrowRight"
            >
              {running || disableFinish ? (
                <Loading
                  inline
                  color1={theme.secondary}
                  color2={theme.success}
                />
              ) : (
                "Continue"
              )}
            </Button>
          )}

          {api?.d?.IsExecutionComplete ? (
            <Button
              autoFocus={api?.d?.IsExecutionComplete}
              disabled={isFinishing || disableFinish || running}
              onClick={finishClick}
              iconName="faFlagCheckered"
              type="submit"
            >
              {isFinishing || disableFinish || running ? (
                <Loading
                  inline
                  color1={theme.secondary}
                  color2={theme.success}
                />
              ) : (
                "Finish"
              )}
            </Button>
          ) : null}
        </Flex>
      </Flex>
      {constants.CONFIGURATOR_MODES.Default === configuratorMode && (
        <PagesWrapper mt="1rem">
          <Button
            reverse
            color={theme.secondary}
            style={{ opacity: !currentPage ? 0 : 1 }}
            iconName="faAngleLeft"
            onClick={() => onArrowClick("previous")}
          />
          {Pages?.map((page, i) => {
            const { Caption, IsVisible } = page || {};
            return IsVisible ? (
              <Button
                className={currentPage !== i ? "hide" : undefined}
                reverse={currentPage !== i}
                key={`Page|${i}`}
                color={theme.secondary}
                onClick={() => setPage(i)}
              >
                {Caption}
              </Button>
            ) : null;
          })}
          <Button
            reverse
            style={{
              opacity:
                currentPage === Pages?.length - 1 || !Pages?.length ? 0 : 1,
            }}
            disabled={autoContinueMode === 0}
            iconName="faAngleRight"
            color={theme.secondary}
            onClick={() => onArrowClick("next")}
          />
        </PagesWrapper>
      )}
    </Wrapper>
  );
};

export default TopBar;
