import React, { useRef, useEffect, useState } from "react";
import { useContext, useCallback, useMemo } from "react";
import { useField, useFormikContext } from "formik";
import Field from "../UI/forms/Field";
import Checkbox from "../UI/forms/Checkbox";
import { Box } from "reflexbox";
import Card from "../UI/Card";
import { Body } from "../UI/Text";
import Radio from "../UI/forms/Radio";
import Range from "../UI/forms/Range";
import * as constants from "../../modules/constants";
import { configure } from "../../requests/apiRequests";
import Button from "../UI/Button";
import ConfiguratorContext from "../../context/configurator/ConfiguratorContext";
import UserContext from "../../context/user/UserContext";

import { useTheme } from "styled-components";
import { decimalToFraction } from "../../modules/utilities";
import RichText from "../UI/RichText";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import useIsComponentMounted from "../Hooks/useIsComponentMounted";

const FormField = ({
  ScreenOption,
  animateIndex,
  lastOptionIndex,
  disableFinish,
  setDisableFinish,
}) => {
  const theme = useTheme();

  const {
    IsRequired,
    ID,
    IsEnabled,
    Name: name,
    SelectableValues,
    Ranges,
    DisplayFormat,
    DisplayType,
    Caption,
    Tooltip,
  } = ScreenOption || {};
  const isComponentMounted = useIsComponentMounted();
  const Name = name.replace("[", "").replace("]", "") || [name];

  const { Increment, Min, Max } = Ranges?.[0] || {};

  const type = DisplayType?.toLowerCase();
  const caption = Caption?.replace(/<\/?[^>]+(>|$)/g, "");

  const [field] = useField(Name);

  const { platform } = useContext(UserContext);
  // const { autoContinueMode } = useContext(ConfiguratorContext);
  const { requireHttps } = platform || {};

  const {
    optionToFocus,
    setApi,
    api,
    setOptionToFocus,
    sessionId,
    activeProduct,
  } = useContext(ConfiguratorContext);
  const { Server, ApplicationInstance } = activeProduct || {};

  useEffect(() => {
    if (optionToFocus === ID && !field.value) {
      if (ref.current?.focus) ref.current.focus();
    }
  }, [ID, optionToFocus, field.value]);

  // const formatValue = useCallback(() => {
  //   if (
  //     type === constants.NUMERIC_CONTROL ||
  //     type === constants.NUMERIC_TEXTBOX
  //   ) {
  //     if (typeof field.value === "string") {
  //       // if (field.value === "0") return 0;
  //       // const newVal = field.value.replace(",", "");
  //       return field.value;
  //     } else {
  //       return field.value;
  //     }
  //   } else {
  //     return field.value;
  //   }
  // }, [type, field.value]);

  const runSubmit = useCallback(
    async ({ value }) => {
      if (disableFinish) return;

      const type = DisplayType.toLowerCase();

      if (type === constants.CHECKBOX) value = value ? "True" : "False";
      if (
        type === constants.NUMERIC_CONTROL ||
        type === constants.NUMERIC_TEXTBOX
      ) {
        value = +value;
      }

      setDisableFinish(true);

      const _res = await configure({
        sessionId,
        id: ID || sessionId,
        value: value,
        requireHttps,
        Server,
        ApplicationInstance,
      });

      setApi(_res?.data);
      setDisableFinish(false);
      setOptionToFocus(_res?.data?.d?.OptionToFocus);
    },
    [
      ApplicationInstance,
      DisplayType,
      ID,
      Server,
      requireHttps,
      sessionId,
      setApi,
      setOptionToFocus,
      setDisableFinish,
      disableFinish,
    ]
  );

  const onKeyDown = useCallback(
    async (e, value) => {
      if (e.key === "Tab") {
        e.preventDefault();
        setDisableFinish(true);

        if (value === null || value === undefined || value === "") {
          console.log("no value on keydown");
          setDisableFinish(false);
          return;
        }
        await runSubmit({
          value: value,
        });
        setDisableFinish(false);
      }
    },
    [runSubmit, setDisableFinish]
  );

  const ref = useRef(null);
  const { setFieldValue } = useFormikContext();

  const onChange = useCallback(
    async (e) => {
      setDisableFinish(true);
      console.log("in on change");
      if (!e?.target?.value) {
        console.log("no value");
        setDisableFinish(false);
        return;
      }

      setFieldValue(Name, e?.target?.value);

      if (type === constants.NUMERIC_CONTROL) {
        console.log("is numeric");

        await runSubmit({ value: decimalToFraction(field?.value) });
      }
      await runSubmit({ value: field?.value });
    },
    [Name, setFieldValue, field.value, runSubmit, type, setDisableFinish]
  );

  const fieldProps = useMemo(() => {
    let tempName = Name;
    const name = tempName.replace("[", "").replace("]", "") || tempName;
    return {
      required: IsRequired,
      disabled: !!!IsEnabled,
      autoFocus:
        optionToFocus === ID && !api?.d?.IsExecutionComplete && !field.value,
      name,
      labelText: caption,
      id: ID,
      ref,
      onBlur: onChange,
      value: field.value,
      tooltip: Tooltip,
      onKeyDown: (e) => onKeyDown(e, field.value),
    };
  }, [
    Name,
    IsRequired,
    IsEnabled,
    optionToFocus,
    ID,
    api,
    field.value,
    caption,
    onChange,
    Tooltip,
    onKeyDown,
  ]);

  const radioProps = useMemo(
    () => ({
      innerRef: ref,
      animateIndex,
      lastOptionIndex,
    }),
    [animateIndex, lastOptionIndex]
  );

  switch (type) {
    case constants.DROPDOWN:
      // const props = { ...fieldProps };

      // delete props.onBlur;

      return (
        <Field as="select" {...fieldProps}>
          <option value="" key="default">
            Select a Value
          </option>
          {SelectableValues.map((val) => (
            <option
              disabled={!val.IsEnabled}
              style={{ display: val.IsVisible ? undefined : "none" }}
              key={val.Value}
              value={val.Value}
            >
              {val.Caption}
            </option>
          ))}
        </Field>
      );

    case constants.RADIO_HORIZTONAL:
      return (
        <div key={Name}>
          <Radio
            {...radioProps}
            {...fieldProps}
            options={SelectableValues.map((val) => ({
              value: val.Value,
              label: (
                <Button
                  reverse
                  onClick={() =>
                    runSubmit({
                      value: val.Value,
                    })
                  }
                  disabled={!val.IsEnabled}
                  style={{ display: val.IsVisible ? undefined : "none" }}
                  color={
                    val.Value !== field.value ? theme.gray : theme.secondary
                  }
                >
                  {val.Caption}
                </Button>
              ),
            }))}
          />
        </div>
      );

    case constants.NUMERIC_CONTROL:
      return (
        <Range
          unit={`"`}
          isFraction={DisplayFormat === "Fraction"}
          step={Increment}
          min={Min}
          max={Max}
          {...fieldProps}
        />
      );
    case constants.NUMERIC_TEXTBOX:
      let _fv = type === "number" ? field.value.replace(",", "") : field.value;
      return (
        <Field
          readOnly={_fv === "Display_Caption"}
          type="number"
          {...fieldProps}
        />
      );

    case constants.TEXTBOX:
      let formattedValue =
        type === "number" ? field.value.replace(",", "") : field.value;

      return (
        <Field
          readOnly={formattedValue === "Display_Caption"}
          {...fieldProps}
        />
      );
    case constants.TEXTAREA:
      let fv = type === "number" ? field.value.replace(",", "") : field.value;

      return (
        <RichText
          disabled={fv === "Display_Caption"}
          {...fieldProps}
          //onBlur={onChange}
          onChange={undefined}
          runSubmit={runSubmit}
        />
      );

    case constants.CHECKBOX:
      return (
        <Box my="0.5rem">
          <Checkbox
            {...fieldProps}
            value={field.value}
            onChange={null}
            onClick={() =>
              runSubmit({
                value: !field.value,
              })
            }
          />
        </Box>
      );

    case constants.IMAGE_TEXT_HORIZONTAL:
      return (
        <Radio
          {...radioProps}
          optionWidth={100 / SelectableValues.length}
          {...fieldProps}
          options={SelectableValues.map((val) => ({
            value: val.Value,
            label: (
              <Card
                style={{ display: val.IsVisible ? undefined : "none" }}
                width={1}
                key={val.Value}
                isRadio
                active={field.value === val.Value}
                interactive
                onClick={() =>
                  runSubmit({
                    value: val.Value,
                  })
                }
              >
                <img src={val.ImageLink} alt={val.Caption} />
                <Body center mt="1rem">
                  {val.Caption}
                </Body>
              </Card>
            ),
          }))}
        />
      );

    case constants.TYPEABLE_DROPDOWN:
      return (
        <Field {...fieldProps} as="select">
          <option value="" key="default">
            Select a Value
          </option>
          {SelectableValues.map((val) =>
            val.IsVisible ? (
              <option
                disabled={!val.IsEnabled}
                key={val.Value}
                value={val.Value}
              >
                {val.Caption}
              </option>
            ) : null
          )}
        </Field>
      );

    case constants.IMAGE_DROPDOWN:
      return (
        <Radio
          {...radioProps}
          {...fieldProps}
          optionWidth={100 / SelectableValues.length}
          fieldValue={field.value}
          options={SelectableValues.map((val) => ({
            value: val.Value,
            label: (
              <Card
                style={{ display: val.IsVisible ? undefined : "none" }}
                width={1}
                key={val.Value}
                isRadio
                active={field.value === val.Value}
                interactive
                onClick={() =>
                  runSubmit({
                    value: val.Value,
                  })
                }
              >
                <img src={val.ImageLink} alt={val.Caption} />
                <Body center mt="1rem">
                  {val.Caption}
                </Body>
              </Card>
            ),
          }))}
        />
      );
    default:
      return null;
  }
};

export default FormField;
