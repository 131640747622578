import { Flex } from "reflexbox";
import styled from "styled-components";

export const RadioWrap = styled(Flex)`
  flex-wrap: wrap;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  flex-grow: 0;
  flex-shrink: 1;

  img {
    max-height: 14rem;
  }

  > label {
    flex-grow: 1;
    flex-shrink: 0;
  }

  opacity: 1;
  position: relative;

  ${({ itemNumber, aF, numItems }) => {
    if (aF)
      return `
      @keyframes slide-left${itemNumber} {
        0% {
          left: 10rem;
          opacity: 0;
        }
        ${(itemNumber / numItems) * 75}% {
          left: ${(itemNumber / numItems) * 10}rem;
          opacity: 0;
        }

        100% {
          opacity: 1;
          left: 0;
        }
      }


      &:nth-of-type(${itemNumber}) {
        animation-name: slide-left${itemNumber};
        animation-duration: ${(itemNumber / 2) * 125}ms;
      }
    `;
  }}
`;

export const Radio = styled(Flex)`
  position: relative;
  justify-content: flex-start;
  flex-shrink: 0;
  transition: 200ms all ease-out;
  gap: 1rem;
  margin-top: 0.5rem;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  input {
    position: absolute;
    top: 60%;
    pointer-events: none;
    left: 0;
    opacity: 0;
  }

  &:after {
    content: "";
    display: none;
    width: 6px;
    height: 6px;
    border-width: 0;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${RadioWrap}:hover & {
    border-color: ${({ theme }) => theme.secondary};
  }

  .checked {
    border-color: ${({ theme }) => theme.secondary};
  }
  input:disabled ~ & {
    background-color: #dedede;
    cursor: not-allowed;
  }
`;

export const RadioContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
