import React, { useState, useCallback } from "react";
import SocketContext from "./SocketContext";

const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
const wsHost = window.location.hostname;
const wsPort = window.location.protocol === "https:" ? ":443" : ":80";
const wsPath = "/socket";
const wsURL = `${wsProtocol}//${wsHost}${wsPort}${wsPath}`;

const SocketContextProvider = ({ children }) => {
  const [client, setClient] = useState();

  const connectSocket = useCallback(async () => {
    const ws = new WebSocket(wsURL);
    return new Promise((resolve) => {
      const timer = setInterval(() => {
        if (ws.readyState === 1) {
          clearInterval(timer);
          setClient(ws);
          resolve(ws);
        }
      }, 10);
    });
  }, []);

  const initializeSocket = useCallback(
    async ({ updateLoggedInUsers, updateOrderStatus }) => {
      const ws = await connectSocket();

      ws.onmessage = (message) => {
        const dataFromServer = JSON.parse(message.data);
        const { data, type } = dataFromServer || {};
        if (type === "userevent") {
          const { users } = data || {};
          Object.entries(users).forEach((userKeyVal) => {
            const { 1: user } = userKeyVal;
            updateLoggedInUsers(user);
          });
        }
        if (type === "contentchange") {
          updateOrderStatus(data);
        }
      };

      setClient(ws);
      return ws;
    },
    [connectSocket]
  );

  const userLoggedIn = useCallback(
    async ({ user, updateLoggedInUsers, updateOrderStatus }) => {
      const _client = await initializeSocket({
        updateLoggedInUsers,
        updateOrderStatus,
      });
      _client.send(
        JSON.stringify({
          username: user,
          type: "userevent",
          content: { isLoggedIn: true },
        })
      );
    },
    [initializeSocket]
  );

  return (
    <SocketContext.Provider
      value={{
        client,
        setClient,
        userLoggedIn,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
