import React from "react";
import styled, { css } from "styled-components";
import { space, typography, color, flexbox, margin } from "styled-system";
import formatter from "../../../modules/formatter";
// import { Flex, Box } from "reflexbox/styled-components";

const BaseText = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  flex: 1;
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  ${margin};
  text-align: ${(props) => {
    if (props.center) return "center;";
    if (props.right) return "right;";

    return "left";
  }};
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  line-height: 1.5;
  ${({ ellipsis }) => {
    if (ellipsis) {
      return css`
        min-width: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
      `;
    }
  }}
`;

export const Heading = styled(BaseText)`
  font-size: 2rem;
  margin: 1rem 0;
  flex: unset;
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  ${margin};
`;

export const Body = styled(BaseText)`
  font-size: 1rem;
`;

export const Helper = styled(BaseText)`
  font-size: 0.75rem;
`;
export const Date = styled(BaseText)`
  justify-content: center;
  text-align: center;
`;
export const Currency = ({ value, children }) => {
  return (
    <BaseText right>
      ${formatter.currency(value ? value : +children?.[0])}
    </BaseText>
  );
};
