export const HEADER_ID = "ICE";
export const DEFAULT_SELECT_OPTION = "Select an option";
export const DROPDOWN = "dropdown";
export const RADIO_HORIZTONAL = "radiobuttonhorizontal";
export const NUMERIC_TEXTBOX = "numerictextbox";
export const TEXTBOX = "textbox";
export const TEXTAREA = "textarea";
export const CHECKBOX = "checkbox";
export const IMAGE_TEXT_HORIZONTAL = "imageandtexthorizontal";
export const NUMERIC_CONTROL = "numericcontrol";
export const TYPEABLE_DROPDOWN = "typeabledropdown";
export const IMAGE_DROPDOWN = "imagedropdown";
export const HOME_DELIVERY = "Home Delivery";
export const CPQ_HOST_SERVICES_SERVER = "13.92.153.226"; //  SALES SERVER
export const CPQ_SERVICE_URL =
  "https://13.92.153.226/PCM/ConfiguratorService/v2/ProductConfigurator.svc";

export const THEME_MODES = {
  Light: 1,
  Dark: 2,
};

export const PLACEHOLDER = "placeholder";

// Access this value with roleLevel 0-9
export const DEFAULT_HOMEPAGE = {
  0: "/",
  1: "/",
  2: "/",
  3: "/",
  4: "/",
  5: "/",
  6: "/",
  7: "/",
  8: "/",
  9: "/",
};

export const ICE_DEFAULT_HOMEPAGE = "/MyConfigurations";

export const QUOTE_TYPES = {
  Quote: "1",
  Order: "2",
  Request: "3",
};

export const ACCESS = {
  ICE: 1,
  Ordering: 2,
};
export const CONFIGURATOR_MODES = {
  Default: 0,
  Quote: 1,
  Order: 2,
  Ice: 3,
  Review: 4,
  SOWriteup: 5,
};

export const AUTO_CONTINUE_MODE = {
  StayOnScreenRule: 0,
  StayOnTab: 1,
  MoveTabAndScreenRule: 2,
};

// this name and id must have matching values in the WorkFlowEvents table
export const WORKFLOW_EVENTS = {
  onQuoteStatusChange: 1,
  onQuoteSubmit: 2,
  onQuoteCancellationRequest: 3,
  onQuoteCancellationAccept: 4,
  onQuoteCancellationReject: 5,
};

// this name and id must have matching values in the WorkFlowActionTypes table
export const WORKFLOW_ACTION_TYPES = {
  sendEmail: 1,
  updateStatus: 2,
  validateRequiredAttachments: 3,
  SendQuoteCancelRequestEmail: 5,
  UpdateStatusAfterQuoteCancelRequest: 6,
  SendQuoteCancelAcceptEmail: 7,
  UpdateStatusAfterQuoteCancelAccept: 8,
  SendQuoteCancelRejectEmail: 9,
  UpdateStatusAfterQuoteCancelReject: 10,
};

export const QUOTE_COMMUNICATION_TYPES = {
  user: 1,
  system: 2,
};

export const ROLE = {
  User: "User",
  SystemAdmin: "SystemAdmin",
};

export const ROLE_ID = {
  Fabricator: 1,
  User: 3,
  SystemAdmin: 5,
};

export const MESSAGE_RULE_TYPES = {
  Critical: 1,
  Warning: 2,
  Information: 3,
};

export const TABLES = {
  Quote: "Quote",
  Order: "Order",
  Address: "Address",
  Contact: "Contact",
};

export const ORDER_SQL_COLUMNS = {
  Status: "statusTypeId",
  EstimatedShipDate: "estimatedShipDate",
  ActualShipDate: "actualShipDate",
  ShipToStore: "shipToTypeId",
  IsLocked: "isLocked",
  Invoiced: "invoiced",
};

export const UI_MODES = {
  light: 1,
  dark: 2,
};

export const ADDRESS_TYPES = {
  Billing: 1,
  Shipping: 2,
};

export const EMAIL_TYPES = {
  QuoteOrder: "QuoteOrder",
  StatusChange: "StatusChange",
  QuoteCancel: "QuoteCancel",
};

export const ATTACHMENT_TYPES = {
  Drawings: 1,
  PurchaseOrders: 2,
  Invoices: 3,
};

export const ACCESS_LEVELS = {
  InterestCapture: 1,
  Quoting: 2,
};

export const STATUS_TYPES = {
  Active: 2,
  Pending: 3,
  Cancelled: 6,
  InReview: 8,
  Shipped: 15,
  Ordered: 16,
  Request: 19,
  Deleted: 99,
};

// Details: 2,
export const QUOTE_STAGES = {
  Line: 1,
  Customer: 2,
  //Address: 3,
  Attachments: 3,
  Submit: 4,
  // PoNumber: 6,
  // AdditionalAttachments: 7,
  // ConvertToOrder: 8,
  //Checkout: 5,
};

export const STAGES_BUTTON_LABELS = {
  Next: "Continue",
  Submit: "Submit",
  Convert: "Convert",
  Confirm: "Confirm",
  Accept: "Accept",
  Shipped: "Shipped",
  Locked: "Locked",
  Checkout: "Checkout",
};

export const SHIP_TO_TYPES = {
  Store: 1,
  Customer: 2,
  Installer: 3,
};

export const VENDOR_NUMBERS = {
  SOS: 26544,
  SOS_INSTALL: 53900,
};

export const REPORT_URLS = {
  OrderCost: `_HKC_OrderSummary`,
  OrderPrice: `_HKC_OrderSummary`,
  OrderFax: `_HKC_OrderFax`,

  //The new report is name _HKC_OrderFax

  //Could we get the links on the Quote and Order Attachments pages updated to point to the new report.
  //We will also have to pass the parameters along with order number, Type (O or Q) and CostPrice (which I think just P would suffice for both links)
};

export const HK_INSTALL_TYPES = [
  { Value: "SOS", Caption: "SOS" },
  { Value: "SOS_INSTALL", Caption: "SOS INSTALL" },
];
export const HK_INSTALL_TYPES_SOS_ONLY = [{ Value: "SOS", Caption: "SOS" }];

export const GRID_ACTIONS = [
  { Value: "quoteCost", Caption: "Quote Cost Summary" },
  { Value: "quotePrice", Caption: "Quote Price Summary" },
  { Value: "delete", Caption: "Delete" },
  { Value: "downloadAttachments", Caption: "Download Attachments" },
];

export const ORDER_GRID_ACTIONS = [
  { Value: "orderCost", Caption: "Order Cost Summary" },
  { Value: "orderPrice", Caption: "Order Price Summary" },
  { Value: "delete", Caption: "Delete" },
  { Value: "downloadAttachments", Caption: "Download Attachments" },
];

//export const GRID_ACTIONS = [{Value: "copy", Caption: "Copy"}, {Value: "delete", Caption: "Delete"}, {Value: "costReport", Caption: "View Cost Report"} ];
//export const GRID_ACTION_OPTIONS = ["copy", "delete", "costReport"];

export const GRID_ACTION_OPTIONS = {
  Copy: "copy",
  Delete: "delete",
  QuoteCost: "quoteCost",
  QuotePrice: "quotePrice",
  DownloadAttachments: "downloadAttachments",
};

export const ORDER_GRID_ACTION_OPTIONS = {
  Copy: "copy",
  Delete: "delete",
  OrderCost: "orderCost",
  OrderPrice: "orderPrice",
  DownloadAttachments: "downloadAttachments",
};

export const rentechTheme = {
  buttonColor: "#EEEEEE",
  altButtonColor: "#6D6E71",
  buttonTextWhite: "#FFF",
  activeStageColor: "#13cdfd",
  inactiveStageForeColor: "#000",
  activeStageForeColor: "#FFF",
  completedStageColor: "#EEEEEE",
  incompleteStageColor: "#EEEEEE",
  accentColor: "#ffdd00",
  darkBackground: "#6D6E71",
  lightBackground: "#EEEEEE",
  gridLineHoverBackgroundColor: "#4d4d4d",
  gridLineHoverOpacity: 0.7,
  gridLineHoverColor: "#FFF",
  gridRowBackgroundColor: "#f5f5f5", //offwhite
  gridRowAlternateBackgroundColor: "#FFF",

  callToActionColor: "#FDB813", //Trinity Orange
  backgroundColor: "#FFF", //White
  backgroundOffset: "#EEEEEE", //Light Grey
  darkerOffset: "#D3D3D3", //Grey
  currentHighlight: "#13cdfd", //Electric Blue
  textDark: "#000", //Black
  textLight: "#FFF", //White
};
