import axios from "axios";

export const payment = async ({ amount, id }) => {
  try {
    const result = await axios.post("/api/v1/paymentManagement/payment", {
      amount,
      id,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createCheckoutSession = async () => {
  try {
    const result = await axios.post(
      "/api/v1/paymentManagement/createCheckoutSession"
    );

    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
