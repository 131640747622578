import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useRef,
} from "react";
import TableLayout from "./TableLayout";
import { PagerContext } from "../Pager/PagerContext";
import Searchbar from "../SearchBar";
import { Flex } from "reflexbox";
import Checkbox from "../forms/Checkbox";
import { CheckboxContainer, CheckboxWrapper } from "./styles-table";
import { Body, Helper } from "../Text";
import HeadingSection from "../HeadingSection";
import Button from "../Button";
import Loading from "../Loading";
import Card from "../Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import Pager from "../Pager";
import {
  ColumnDisplayContext,
  RowDisplayContext,
  useTableContext,
} from "./TableContext";
import { TableResetContainer } from "./styles-table";
import styled, { useTheme } from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import { Formik, Form } from "formik";
import AlertContext from "../../../context/alert/AlertContext";

const RotateWrap = styled(Flex)`
  flex-shrink: 0;
  @keyframes full-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  &.rotate {
    svg {
      animation: full-rotate;
      animation-duration: 1s;
    }
  }
`;

const CheckboxTernary = ({ labelText, checked, name, ...rest }) => {
  const checkboxRef = useRef();
  return (
    <CheckboxWrapper>
      <Checkbox
        {...rest}
        labelText={labelText}
        className={["", "one", "two"][checked]}
        name={name}
        id={name}
        checked={checked}
        ref={checkboxRef}
      />
    </CheckboxWrapper>
  );
};

const withDevice = (Component) => {
  return (props) => {
    const sm = useMediaQuery(`(min-width: 576px)`);
    const md = useMediaQuery(`(min-width: 768px)`);
    const lg = useMediaQuery(`(min-width: 992px)`);
    const xl = useMediaQuery(`(min-width: 1200px)`);
    const biggest = xl ? "xl" : lg ? "lg" : md ? "md" : sm ? "sm" : "xs";
    return (
      <Component device={{ xl, lg, md, sm, xs: true, biggest }} {...props} />
    );
  };
};

export const Table = ({
  device,
  data = [],
  title,
  subtitle,
  gridData = [],
  columns = [],
  dropdown = true,
  gridSize = "normal",
  onRowClick,
  onCardClick,
  forceGrid = false,
  hideSearch = false,
  hideProperties = false,
  hidePager = false,
  hideToggle = false,
  selections = null,
  popout = true,
  totalCount = null,
  isLoading = false,
  customGrid = false,
  createButtonText = "Create",
  createButtonIcon = "faPlus",
  hideCreate = false,
  hideBack = false,
  backButtonIcon = "faArrowLeft",
  createLink,
  backButtonLink,
  additionalElements = [],
  relativeLoading,
  open,
  hideRefetch = true,
  refetchData = () => {},
}) => {
  const { showFailureAlert } = useContext(AlertContext);
  const theme = useTheme();
  const { order, setOrder, setSearch: _setSearch } = useContext(PagerContext);
  const setSorting = useCallback(
    (a) => {
      const { 0: newSort } = a();

      const newOrder = [
        `${newSort.id}`,
        `${order[1] === "DESC" ? "ASC" : "DESC"}`,
      ];

      if (setOrder) setOrder(newOrder);
    },
    [order, setOrder]
  );
  const setSearch = useCallback(
    (newSearch) => {
      if (newSearch) _setSearch(newSearch || "");
    },
    [_setSearch]
  );

  const processedColumns = useMemo(() => processColumns(columns), [columns]);

  const [
    rowContext,
    columnContext,
    {
      initialVisibility,
      configuredVisibility,
      setConfiguredVisibility,
      configuredSizing,
    },
  ] = useTableContext(
    processedColumns,
    device,
    dropdown && selections === null,
    onRowClick,
    selections,
    popout
  );

  const [propOpen, setPropOpen] = useState(false);
  const [grid, setGrid] = useState(forceGrid);

  const onResetTable = () => {
    columnContext.arbitrarySizeReducer(["", -2]);
    setConfiguredVisibility(["", -2]);
  };

  const gridSpacing = () => {
    switch (gridSize) {
      case "tiny":
        return [
          "calc(33.3333% - 2rem)",
          "calc(33.3333% - 2rem)",
          "calc(25% - 2rem)",
          "calc(20% - 2rem)",
          "calc(20% - 2rem)",
          "calc(16.6666% - 2rem)",
        ];

      case "small":
        return [
          "calc(50% - 2rem)",
          "calc(50% - 2rem)",
          "calc(33.3333% - 2rem)",
          "calc(25% - 2rem)",
          "calc(20% - 2rem)",
          "calc(16.6666% - 2rem)",
        ];
      case "medium":
        return [
          "calc(100% - 2rem)",
          "calc(100% - 2rem)",
          "calc(50% - 2rem)",
          "calc(33.3333% - 2rem)",
          "calc(25% - 2rem)",
          "calc(20% - 2rem)",
        ];
      case "large":
        return [
          "100%",
          "100%",
          "calc(50% - 2rem)",
          "calc(50% - 2rem)",
          "calc(33.3333% - 2rem)",
        ];
      default:
        return [
          "100%",
          "100%",
          "calc(50% - 2rem)",
          "calc(33.3333% - 2rem)",
          "calc(25% - 2rem)",
        ];
    }
  };

  const spinRef = useRef(null);

  const spin = () => {
    spinRef.current.classList.add("rotate");
    setTimeout(() => {
      spinRef.current.classList.remove("rotate");
    }, 1000);
  };

  return (
    <Flex width={1} flexDirection="column" style={{ gap: "1rem" }} my="1rem">
      <HeadingSection
        hideBack={hideBack}
        hideCreate={hideCreate}
        backButtonIcon={backButtonIcon}
        backButtonLink={backButtonLink}
        title={title}
        subtitle={subtitle}
        createButtonText={createButtonText}
        createLink={createLink}
        createButtonIcon={createButtonIcon}
        additionalElements={additionalElements}
      />

      <Flex
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-start"
        width={1}
        style={{ gap: "1rem" }}
      >
        {!hideSearch || !hideProperties ? (
          <Flex
            width={1}
            style={{ gap: "1rem" }}
            flexDirection={["column", "column", "row"]}
            alignItems="flex-start"
          >
            {hideProperties || grid ? null : (
              <Formik
                initialValues={() => {
                  const vals = {};
                  processedColumns.forEach((entry) => {
                    let configured = entry.id in configuredVisibility;
                    let [name, visibility] = configured
                      ? configuredVisibility[entry.id]
                      : initialVisibility[entry.id];
                    vals[name] = visibility;
                  });
                  return vals;
                }}
              >
                {() => (
                  <CheckboxContainer
                    open={propOpen}
                    width={["100%", "100%", "calc(50% - 0.5rem)"]}
                  >
                    <Form>
                      <Flex
                        onClick={() => setPropOpen(!propOpen)}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        p="0.5rem 1rem"
                        style={{ cursor: "pointer" }}
                      >
                        <Body bold>Properties</Body>

                        <FontAwesomeIcon
                          icon={propOpen ? fa.faChevronUp : fa.faChevronDown}
                          style={{ padding: "0.25rem" }}
                          size="sm"
                        />
                      </Flex>

                      <Flex className="table-properties" flexWrap="wrap">
                        {processedColumns.map((entry) => {
                          let configured = entry.id in configuredVisibility;
                          let [name, visibility] = configured
                            ? configuredVisibility[entry.id]
                            : initialVisibility[entry.id];
                          return (
                            <Flex key={name}>
                              <CheckboxTernary
                                name={name}
                                checked={visibility}
                                onClick={() => {
                                  let ret = 0;

                                  Object.keys(configuredVisibility).forEach(
                                    (k, v) => {
                                      if (v !== 0 && k !== entry.id) ret += 1;
                                    }
                                  );
                                  if (
                                    ret >=
                                    Object.entries(initialVisibility).length - 2
                                  )
                                    return showFailureAlert(
                                      "Must have at least 1 column header visible."
                                    );

                                  return setConfiguredVisibility([
                                    entry.id,
                                    [
                                      name,
                                      dropdown
                                        ? (visibility + 1) % 3
                                        : (visibility + 1) % 2,
                                    ],
                                  ]);
                                }}
                              />
                              {configured && (
                                <Flex
                                  size={20}
                                  onClick={() =>
                                    setConfiguredVisibility([entry.id, -1])
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={fa.faArrowRotateLeft}
                                  />
                                </Flex>
                              )}
                              <Body>{name}</Body>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Form>
                  </CheckboxContainer>
                )}
              </Formik>
            )}

            {hideSearch ? null : (
              <Flex width={1}>
                <Searchbar onThrottledChange={setSearch} onSubmit={setSearch} />
              </Flex>
            )}

            {hideRefetch ? null : (
              <RotateWrap ref={spinRef} onClick={spin} flexShrink={0}>
                <Button
                  color={theme.secondary}
                  iconName="faRotate"
                  onClick={refetchData}
                />
              </RotateWrap>
            )}

            {hideToggle ? null : (
              <Flex flexShrink={0} style={{ gap: "1rem" }}>
                <Button
                  m={0}
                  reverse={!grid}
                  color={!grid ? theme.textMedium : theme.secondary}
                  onClick={() => setGrid(true)}
                  iconName="faTableCellsLarge"
                />
                <Button
                  m={0}
                  reverse={grid}
                  color={grid ? theme.textMedium : theme.secondary}
                  onClick={() => setGrid(false)}
                  iconName="faListUl"
                />
              </Flex>
            )}
          </Flex>
        ) : null}

        {grid ? (
          <Flex mx="-1rem" width="calc(100% + 2rem)" flexWrap="wrap">
            {!isLoading ? (
              gridData?.map((item, i) => {
                if (customGrid) return item;
                const {
                  id,
                  iconName,
                  title,
                  subtitle,
                  image,
                  elements = [],
                } = item || {};
                return (
                  <Card
                    justifyContent="center !important"
                    alignItems="center"
                    key={`${id}|card${i}`}
                    isLoading={isLoading}
                    m="1rem"
                    p={gridSize === "tiny" ? "0 !important" : "a"}
                    interactive
                    iconName={iconName}
                    onClick={() => onCardClick(item)}
                    width={gridSpacing()}
                  >
                    {image && <img src={image} alt={title || id} />}
                    {title && (
                      <Body center bold mt="1rem">
                        {title}
                      </Body>
                    )}
                    {subtitle && (
                      <Helper center my="1rem">
                        {subtitle}
                      </Helper>
                    )}
                    {elements}
                  </Card>
                );
              })
            ) : (
              <Loading relative={relativeLoading} />
            )}
          </Flex>
        ) : (
          <>
            {data && (
              <div style={{ position: "relative", width: "100%" }}>
                <RowDisplayContext.Provider value={rowContext}>
                  <ColumnDisplayContext.Provider value={columnContext}>
                    <TableLayout
                      data={data}
                      open={open}
                      isLoading={isLoading}
                      columns={processedColumns}
                      setSorting={setSorting}
                    />
                  </ColumnDisplayContext.Provider>
                </RowDisplayContext.Provider>
                {(Object.keys(configuredSizing).length > 0 ||
                  Object.keys(configuredVisibility).length > 0) && (
                  <TableResetContainer>
                    <Flex size={24} onClick={onResetTable}>
                      <FontAwesomeIcon icon={fa.faArrowRotateLeft} />
                    </Flex>
                  </TableResetContainer>
                )}
              </div>
            )}
          </>
        )}
        {!hidePager && <Pager totalCount={totalCount} />}
      </Flex>
    </Flex>
  );
};

function processColumns(columns) {
  let index = 0;
  return columns.map((col) => {
    index += 1;
    let { data, header, display, sort, id, ...rest } = col;

    if (sort === undefined) sort = true;

    if (typeof sort === "function") {
      rest = { sortingFn: sort, ...rest };
      sort = true;
    }
    if (data) {
      if (typeof data === "string") {
        rest = { accessorKey: data, ...rest };
      }
      if (typeof data === "function") {
        rest = { accessorFn: data, ...rest };
      }
    }
    return {
      id: id || index.toString(),
      header: header !== undefined ? header : id,
      sort: sort,
      cell: display !== undefined ? display : ({ getValue }) => getValue(),
      ...rest,
    };
  });
}

// equality is a data property or an equality function (rowA, rowB) => bool
export const useTableSelections = (equality) => {
  const [rows, setRows] = useState([]);
  const clear = useCallback(() => {
    setRows([]);
  }, [setRows]);
  const isEqual = useMemo(() => {
    if (typeof equality === "string") {
      return (rowA, rowB) => {
        return rowA[equality] === rowB[equality];
      };
    } else if (typeof equality === "function") {
      return equality;
    }
  }, [equality]);
  const getIndex = useCallback(
    (row) => {
      for (let i = 0; i < rows.length; ++i) {
        const compareRow = rows[i];
        if (isEqual(row, compareRow)) return i;
      }
      return -1;
    },
    [isEqual, rows]
  );
  const select = useCallback(
    (row) => {
      setRows((s) => {
        const newTable = s.slice(0);
        newTable.push(row);
        return newTable;
      });
    },
    [setRows]
  );
  const deselect = useCallback(
    (row) => {
      setRows((s) => {
        const index = getIndex(row);
        if (index === -1) {
          return s;
        }
        const newTable = s.slice(0);
        newTable.splice(index, 1);
        return newTable;
      });
    },
    [setRows, getIndex]
  );
  return { rows, actions: { select, deselect, getIndex, clear } };
};

export default withDevice(Table);
