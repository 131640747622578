import React, { useContext, useMemo, useState, useCallback } from "react";
import _ from "lodash";
import styled, { useTheme } from "styled-components";
import { Flex } from "reflexbox";
import { useQuery } from "../Hooks";
import { PageWrapper } from "../UI/Grid";
import { Body } from "../UI/Text";
import Table from "../UI/Table";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import Card from "../UI/Card";
import AlertContext from "../../context/alert/AlertContext";
import AddressForm from "./AddressForm";
import * as addressRequests from "../../requests/addressManagementRequests";

const { getAddresses, deleteAddress } = addressRequests;

const ButtonsWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  transition: 200ms all ease-out;
`;

const AddressesPage = () => {
  const theme = useTheme();
  const { showSuccessAlert } = useContext(AlertContext);

  const [show, setShow] = useState(false);
  const [addressId, setAddressId] = useState("create");

  const { data, isLoading, refetchQuery, setData } = useQuery({
    queryID: "addressesPage",
    request: getAddresses,
  });

  const { count = 0, rows: addresses = [] } = data || {};

  const currentAddress = useMemo(
    () => _.find(addresses, (__) => __.id === addressId),
    [addresses, addressId]
  );
  const onDelete = useCallback(
    async (e, id) => {
      e.stopPropagation();
      const { rows } = data;
      const newRows = [...rows];
      _.remove(newRows, (row) => row.id === id);
      setData({ rows: newRows, count: count - 1 });
      setShow(false);
      await deleteAddress({ id });

      showSuccessAlert(`Successfully deleted Address with the ID of ${id}`);
    },
    [count, data, setData, showSuccessAlert]
  );

  const onRowClick = (row) => {
    setAddressId(row.original.id);
    setShow(true);
  };

  const columns = useMemo(() => {
    return [
      {
        id: "id",
        data: (d) => (
          <Body center ellipsis>
            {d?.id || "—"}
          </Body>
        ),
        header: "ID",
        size: { md: 1, sm: 1, xs: 1 },
      },
      {
        id: "Address",
        data: (d) => (
          <Body ellipsis>
            {d?.Address || d?.Address2
              ? `${d.Address || ""}${d?.Address2 && d?.Address ? " " : ""}${
                  d?.Address2 || " "
                }`
              : "—"}
          </Body>
        ),
        header: "Address",
        size: { md: 4, sm: 3, xs: 2 },
      },

      {
        id: "City",
        data: (d) => (
          <Body center ellipsis>
            {d?.City || "—"}
          </Body>
        ),
        header: "City",
        size: { md: 2, sm: 1, xs: 1 },
      },
      {
        id: "State",
        data: (d) => (
          <Body center ellipsis>
            {d?.State || "—"}
          </Body>
        ),
        header: "State",
        size: { md: 1, sm: 1, xs: 1 },
      },
      {
        id: "Zip",
        data: (d) => (
          <Body center ellipsis>
            {d?.Zip || "—"}
          </Body>
        ),
        header: "Zip",
        size: { md: 1, sm: 1, xs: 1 },
      },
      {
        display: (props) => {
          let address = props.cell.row.original;

          return (
            <ButtonsWrapper id="actions">
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faPencil"
                onClick={(e) => onRowClick(props.cell.row, e)}
              />
              <Button
                size="small"
                color={theme?.secondary}
                reverse
                iconName="faTrashCan"
                onClick={(e) => onDelete(e, address.id)}
              />
            </ButtonsWrapper>
          );
        },
        header: "Actions",
        sort: false,
        size: { md: 3, sm: 0, xs: 0 },
      },
    ];
  }, [onDelete, theme.secondary]);

  return (
    <PageWrapper>
      <Modal show={show} setShow={setShow}>
        <Card>
          <AddressForm
            data={data}
            count={count}
            addressId={addressId}
            setAddressId={setAddressId}
            currentAddress={currentAddress}
            setShow={setShow}
            setData={setData}
          />
        </Card>
      </Modal>
      <Table
        title="Addresses"
        data={addresses}
        createButtonText="Create Address"
        createLink={() => {
          setAddressId("create");
          setShow(true);
        }}
        createButtonIcon="faAddressCard"
        hideToggle
        hideRefetch={false}
        refetchData={refetchQuery}
        onRowClick={onRowClick}
        columns={columns}
        isLoading={isLoading}
        totalCount={count}
        backButtonLink="/Administration"
      />
    </PageWrapper>
  );
};

export default AddressesPage;
