import React, { useState, useContext, useCallback } from "react";
import { Flex, Box } from "reflexbox";
import styled, { useTheme } from "styled-components";
import { getProducts } from "../../requests/productManagementRequests";
import { useQuery } from "../Hooks";
import formatter from "../../modules/formatter";
import { Columns } from "./columns";
import { Body } from "../UI/Text";
import Table from "../UI/Table";
import Card from "../UI/Card";
import Button from "../UI/Button";
import UserContext from "../../context/user/UserContext";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

const ImgContainer = styled(Box)`
  max-height: 170px;
  width: 100%;
  overflow: hidden;
  flex: 4;
  text-align: center;
  svg {
    width: 60px;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    color: ${({ theme }) => theme?.primary};
  }
  img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const ProductCard = ({ product, inline, onProductClick }) => {
  const [quantity, setQuantity] = useState(1);
  const theme = useTheme();

  const onSetQuantity = useCallback(
    (positive) => {
      if (positive) {
        setQuantity(quantity + 1);
        return;
      }
      if (quantity === 1) return;
      setQuantity(quantity - 1);
    },
    [quantity]
  );

  const {
    ID: id,
    ImageUrl: image,
    Description: subtitle,
    Name: title,
    isQuickConfig,
    defaultPrice,
    quantityInStock,
    isNonConfigurable,
  } = product || {};

  console.log(image);

  return useMemo(() => {
    if (inline)
      return (
        <Card
          onClick={() => onProductClick(id)}
          p="0.5rem !important"
          interactive
          key={id}
          width={[
            "calc(33.3333% - 2rem)",
            "calc(33.3333% - 2rem)",
            "calc(25% - 2rem)",
            "calc(20% - 2rem)",
            "calc(20% - 2rem)",
          ]}
          mx="0.5rem"
        >
          {!!image ? (
            <img style={{ maxHeight: "unset" }} src={image} alt={title} />
          ) : (
            <FontAwesomeIcon icon={faBook} />
          )}
        </Card>
      );
    return (
      <Card
        key={id}
        width={[
          "calc(100% - 2rem)",
          "calc(100% - 2rem)",
          "calc(50% - 2rem)",
          "calc(33.3333% - 2rem)",
          "calc(25% - 2rem)",
          "calc(20% - 2rem)",
          "calc(20% - 2rem)",
        ]}
        minWidth="calc(20% - 2rem)"
        m="1rem"
      >
        <ImgContainer>
          {!!image ? (
            <img src={image} alt={title} />
          ) : (
            <FontAwesomeIcon icon={faBook} />
          )}
        </ImgContainer>
        <Flex
          flex={3}
          width={1}
          alignItems="center"
          flexDirection="column"
          my="1rem"
        >
          <Body bold center>
            {title}
          </Body>
          <Body center>{subtitle}</Body>
          {defaultPrice && !isQuickConfig ? (
            <>
              <Flex
                mt="1rem"
                justifyContent="space-between"
                alignItems="center"
                width={1}
              >
                <Body center>
                  <span style={{ fontWeight: "bold", marginRight: "0.65rem" }}>
                    Price:
                  </span>
                  ${formatter.currency(defaultPrice)}
                </Body>
              </Flex>
              <Body center>
                <span style={{ fontWeight: "bold", marginRight: "0.65rem" }}>
                  # In Stock:{" "}
                </span>{" "}
                {quantityInStock}
              </Body>
              <Flex mt="0.5rem" alignItems="center">
                <Button
                  reverse
                  onClick={() => onSetQuantity(false)}
                  color={theme.secondary}
                  size="tiny"
                  iconName="faMinus"
                />
                <Body width="auto">{quantity}</Body>
                <Button
                  reverse
                  onClick={() => onSetQuantity(true)}
                  color={theme.secondary}
                  size="tiny"
                  iconName="faPlus"
                />
              </Flex>
            </>
          ) : null}
        </Flex>
        <Flex flex={0}>
          <Button
            color={isNonConfigurable ? theme.secondary : theme.primary}
            onClick={() => onProductClick(id, undefined, quantity)}
            iconName={!isNonConfigurable ? "faPenRuler" : "faPlus"}
          >
            {!isNonConfigurable ? "Configure" : "Add To Quote"}
          </Button>
        </Flex>
      </Card>
    );
  }, [
    defaultPrice,
    id,
    image,
    inline,
    isNonConfigurable,
    isQuickConfig,
    onProductClick,
    onSetQuantity,
    quantity,
    quantityInStock,
    subtitle,
    theme.primary,
    theme.secondary,
    title,
  ]);
};

const ProductCatalog = (props) => {
  const {
    inline = false,
    onProductClick,
    setShow,
    show = false,
    isLoading: _isLoading,
  } = props || {};

  const { loggedIn } = useContext(UserContext);

  const productsRes = useQuery({
    queryID: "products",
    request: getProducts,
    queryVariables: { showInCatalog: true },
    skip: !loggedIn || !show || inline || _isLoading,
  });

  const quickConfigRes = useQuery({
    queryID: "quickConfig",
    request: getProducts,
    forcedFilters: {},
    queryVariables: {
      isQuickConfig: true,
      isNonConfigurable: false,
      showInCatalog: true,
    },
    attributes: ["ID", "ImageUrl"],
    skip: !loggedIn || !inline || _isLoading,
  });

  const { data, isLoading } = inline ? quickConfigRes : productsRes;
  const { rows: products = [] } = data || {};

  const gridData = products.map((product) => (
    <ProductCard key={`${product.ID}|catalog`} {...props} product={product} />
  ));

  const createLink = useCallback(() => setShow(false), [setShow]);

  const retObj = useMemo(() => {
    if (inline) return gridData;
    return (
      <Flex
        width={
          inline
            ? "auto"
            : ["100vw", "100vw", "100vw", "100vw", "100vw", "100vw", "1136px"]
        }
        height="80vh"
        p={0}
        style={{ zIndex: "9", position: "relative" }}
      >
        <Card width={1} overflowY="scroll">
          <Box mt="-1rem" mb="-2rem" width={1}>
            <Table
              hideBack
              createButtonIcon="faXmark"
              createLink={createLink}
              createButtonText={null}
              isLoading={isLoading}
              title="Product Catalog"
              columns={Columns(onProductClick)}
              gridSize="medium"
              gridData={gridData}
              data={products}
              forceGrid
              customGrid
              hideSearch={inline}
              hideProperties={inline}
              onCardClick={(row) => onProductClick(row.id)}
            />
          </Box>
        </Card>
      </Flex>
    );
  }, [gridData, inline, isLoading, onProductClick, products, createLink]);
  return retObj;
};

export default ProductCatalog;
