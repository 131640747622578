import { Flex } from "reflexbox/styled-components";
import styled from "styled-components";

export const HeaderWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.headerColor};
  padding: 1rem;
  max-height: 5rem;
  .header-link {
    &:hover {
      div,
      svg {
        color: ${({ theme }) => theme.secondary} !important;
      }
    }
  }
  .hideable-header {
    transform-origin: center !important;
  }
  img {
    max-height: 2.5rem;
    height: 100%;
    width: auto;
  }
  .username {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .hideable-header {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .hideable-dropdown {
    @media (min-width: 601px) {
      display: none;
    }
  }
`;

export const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  display: inline-flex;
  padding: 1rem;
  margin-left: 1rem;
  position: relative;

  z-index: 1000;
  .username {
    max-width: 250px;
  }
  .header-dropdown {
    transform-origin: center;
    z-index: 1001;
    @media (max-width: 600px) {
      min-width: 180px;
    }
    justify-content: flex-end;
  }
  @media (max-width: 600px) {
    .header-dropdown,
    .header-link {
      justify-content: flex-end;
      transform-origin: right;
    }
  }

  .open {
    display: ${({ toggleOpen }) => (!toggleOpen ? "none" : "")};
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    padding: 3rem 1rem 1rem 1rem;
    background-color: ${({ theme }) => theme.headerColor};
    border-radius: ${({ theme }) => theme?.borderRadius};
    overflow: hidden;
    box-shadow: ${({ toggleOpen, theme }) =>
      toggleOpen ? "0 5px 5px 5px " + theme.boxShadowColor : "none"};
  }

  .hideable {
    @media (min-width: 600px) {
      display: none;
    }
  }
`;

export const DropdownItems = styled(Flex)`
  margin-top: 1rem !important;
  flex-direction: column;
  min-width: 180px;
  > button,
  a {
    margin-top: 0.25rem;
    margin-button: 0.25rem;
    margin-left: 0 !important;
    margin-right: 0 !important;

    div {
      flex: unset;
    }
    @media (max-width: 600px) {
      margin: 0.4rem 0;
    }
  }
`;
