import styled, { css } from "styled-components";

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.35rem;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: "*";
        padding-left: 2px;
        color: ${({ theme }) => theme.danger};
      }
    `};
`;

export default Label;
