import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import { useTheme } from "styled-components";
import UserContext from "../../context/user/UserContext";
import Field from "../UI/forms/Field";
import FormButtonCluster from "../UI/forms/ButtonCluster";
import { PageWrapper } from "../UI/Grid";
import * as yup from "yup";
import * as productManagementRequests from "../../requests/productManagementRequests";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Label, File, Radio } from "../UI/forms";
import { Flex, Box } from "reflexbox";
import { useQuery } from "../Hooks";
import Card from "../UI/Card";
import HeadingSection from "../UI/HeadingSection";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import { useCallback } from "react";

const validationSchema = yup.object().shape({
  Name: yup.string(),
  Description: yup.string().max(250),
  ProductNumber: yup.string(),
  ApplicationInstance: yup.string(),
  ApplicationName: yup.string(),
  HeaderId: yup.string(),
  Profile: yup.string(),
  ImageUrl: yup.string(),
  Ruleset: yup.string(),
  Namespace: yup.string(),
  Server: yup.string(),
  isNonConfigurable: yup.boolean().default(false),
  defaultPrice: yup.number(),
  isQuickConfig: yup.boolean().default(true),
  showInCatalog: yup.boolean().default(true),
  quantityInStock: yup.number(),
});

const ProductForm = () => {
  const theme = useTheme();

  const { action, id } = useParams();
  const navigate = useNavigate();
  const { activeUser } = useContext(UserContext);
  const { roleLevel } = activeUser || {};

  const {
    getProduct,
    getProducts,
    updateProduct,
    deleteProduct,
    createProduct,
  } = productManagementRequests;

  const [canRefetch, setCanRefetch] = useState(false);

  const { refetchQuery } = useQuery({
    queryID: "productspage",
    request: getProducts,
    skip: !canRefetch,
  });

  const {
    data,
    isLoading,
    refetchQuery: refetchProduct,
  } = useQuery({
    queryID: id,
    request: getProduct,
    queryVariables: { id },
    forcedFilters: {},
    skip: action === "create",
  });
  const { result: initialProduct } = data || {};

  const initialValues = {
    Name: initialProduct?.Name || "",
    Description: initialProduct?.Description || "",
    ProductNumber: initialProduct?.ProductNumber || "",
    ApplicationInstance: initialProduct?.ApplicationInstance || "",
    ApplicationName: initialProduct?.ApplicationName || "",
    HeaderId: initialProduct?.HeaderId || "",
    Profile: initialProduct?.Profile || "",
    ImageUrl: initialProduct?.ImageUrl || "",
    Ruleset: initialProduct?.Ruleset || "",
    Namespace: initialProduct?.Namespace || "",
    Server: initialProduct?.Server || "",
    isNonConfigurable: initialProduct?.isNonConfigurable || false,
    defaultPrice: initialProduct?.defaultPrice || 0,
    isQuickConfig: initialProduct?.isQuickConfig || false,
    quantityInStock: initialProduct?.quantityInStock || 0,
    showInCatalog: initialProduct?.showInCatalog,
  };

  const [image, setImage] = useState(initialProduct?.ImageUrl || "");

  const onSubmit = async (values, { resetForm }) => {
    const { username } = activeUser;
    setCanRefetch(true);
    const input = {
      ...values,
      createdByEmail: username,
      ImageUrl: values.ImageUrl || image || initialProduct?.ImageUrl || "",
      showInCatalog: values.showInCatalog || false,
      ...(values.isNonConfigurable
        ? {
            isQuickConfig: false,
          }
        : {
            quantityInStock: null,
            defaultPrice: null,
          }),
    };
    if (action === "create") {
      const data = await createProduct({ input });
      refetchQuery();
      if (data.success) {
        navigate(`/Products/edit/${data?.error?.createdProduct?.ID}`);
      }
    } else if (action === "edit") {
      await updateProduct({ id, input });
      refetchProduct();
      refetchQuery();
    }
    return;
  };

  const onDelete = useCallback(async () => {
    setCanRefetch(true);
    if (initialProduct.ID) {
      let data = await deleteProduct(id);
      refetchQuery();
      if (data.success) {
        navigate("/Products");
      }
    }
  }, [deleteProduct, id, initialProduct, navigate, refetchQuery]);

  const onTextareaInput = (e) => {
    e.target.value = e.target.value.replace(/\r?\n/, ""); // Filter out newlines
  };

  const onFileUpload = useCallback(
    async (file) => {
      setCanRefetch(true);
      const { name } = file;
      if (action === "create") {
        setImage(`/uploads/${name}`);
      } else {
        await updateProduct({ id, input: { ImageUrl: `/uploads/${name}` } });
      }
    },
    [action, id, updateProduct]
  );

  return (
    <PageWrapper>
      <HeadingSection
        my="1rem"
        hideCreate
        title={
          action === "create"
            ? "Create Product"
            : `Editing Product: ${initialProduct?.Name || ""}`
        }
      />

      {isLoading ? (
        <Loading />
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          enableReinitialize
        >
          {(formProps) => {
            const {
              dirty,
              isSubmitting,
              isValidating,
              errors,
              values,
              submitForm,
              setFieldValue,
            } = formProps;

            return (
              <Form>
                <Flex width={1} justifyContent="center">
                  <Box mt="-1.5rem">
                    <Radio
                      id="isNonConfigurable"
                      name="isNonConfigurable"
                      options={[
                        {
                          value: true,
                          label: (
                            <Button
                              onClick={() =>
                                setFieldValue("isNonConfigurable", true)
                              }
                              color={
                                !values.isNonConfigurable
                                  ? theme.gray
                                  : theme.secondary
                              }
                            >
                              Fixed Price
                            </Button>
                          ),
                        },
                        {
                          value: false,
                          label: (
                            <Button
                              onClick={() =>
                                setFieldValue("isNonConfigurable", false)
                              }
                              color={
                                values.isNonConfigurable
                                  ? theme.gray
                                  : theme.secondary
                              }
                            >
                              Configurable
                            </Button>
                          ),
                        },
                      ]}
                    />
                  </Box>
                </Flex>

                <Flex
                  alignItems="flex-start"
                  width={1}
                  style={{ gap: "1rem" }}
                  flexDirection={["column", "column", "row"]}
                >
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    width={[1, 1, 0.5]}
                  >
                    <Field labelText="Name: " name="Name" type="text" />
                    <Field
                      labelText="Part Number: "
                      name="ProductNumber"
                      type="text"
                    />
                    <Field
                      labelText="Description: "
                      name="Description"
                      type="text"
                      component="textarea"
                      style={{ resize: "none" }}
                      onInput={onTextareaInput}
                    />
                    <Flex width={1} flexDirection="column" my="0.5rem">
                      <Label htmlFor="ImageUrl">Product Image:</Label>
                      <Flex mt="-1rem" width={1} alignItems="flex-start">
                        {isLoading ? (
                          <Card mt="1rem" width="348px" height="348px">
                            <Loading relative />
                          </Card>
                        ) : (
                          <Card mt="1rem" mr="1rem" width="348px" height="100%">
                            {values?.ImageUrl && (
                              <img
                                src={values?.ImageUrl}
                                alt={values?.Name || ""}
                              />
                            )}
                          </Card>
                        )}
                        <File
                          mufltiple
                          onSubmit={onFileUpload}
                          name="ImageUrl"
                        />
                      </Flex>
                      <Box color={theme.danger}>{errors["ImageUrl"]}</Box>
                    </Flex>
                  </Flex>

                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    width={[1, 1, 0.5]}
                  >
                    {values.isNonConfigurable ? (
                      <>
                        <Field
                          labelText="Quantity In Stock"
                          name="quantityInStock"
                          type="number"
                        />
                        <Field
                          labelText="Fixed Price: "
                          name="defaultPrice"
                          type="number"
                          disabled={!values?.isNonConfigurable || false}
                        />
                      </>
                    ) : (
                      <>
                        <Field
                          labelText="Header ID: "
                          name="HeaderId"
                          type="text"
                        />
                        <Field
                          labelText="Application Instance: "
                          name="ApplicationInstance"
                          type="text"
                        />
                        <Field
                          labelText="Application Name: "
                          name="ApplicationName"
                          type="text"
                        />
                        <Field
                          labelText="Profile: "
                          name="Profile"
                          type="text"
                        />

                        <Field
                          labelText="Ruleset: "
                          name="Ruleset"
                          type="text"
                        />
                        <Field
                          labelText="Namespace: "
                          name="Namespace"
                          type="text"
                        />
                        <Field labelText="Server: " name="Server" type="text" />
                        <Checkbox
                          labelText="Show this product in Quick Configure."
                          name="isQuickConfig"
                        />
                        <Checkbox
                          labelText="Show this product in the Product Catalog."
                          name="showInCatalog"
                        />
                      </>
                    )}
                  </Flex>
                </Flex>

                <FormButtonCluster
                  onCancel={() => navigate("/Products")}
                  onDelete={onDelete}
                  recordAction={action}
                  saving={isSubmitting || isValidating}
                  dirty={dirty}
                  hideDelete={roleLevel !== 9 || action === "create"}
                  deleteMessage={`Are you sure you want to delete ${values.Name}?`}
                  values={values}
                  onSubmit={submitForm}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </PageWrapper>
  );
};

export default ProductForm;
