import React, { useContext, useState, useCallback, useMemo } from "react";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import { Flex } from "reflexbox/styled-components";
import { Body } from "../UI/Text";
import Link from "../UI/Link";
import { DropdownWrapper, HeaderWrapper, DropdownItems } from "./styles";
import { useTheme } from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { updateUser } from "../../requests/userManagementRequests";
import * as fa from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../context/user/UserContext";
import * as constants from "../../modules/constants";
import SocketContext from "../../context/socket/SocketContext";
// import styled from "styled-components";

// const SolidFit = styled(Flex)`
//   align-items: center;
//   position: relative;
//   color: ${({ theme }) => theme.primary};
//   background-color: white;

//   svg {
//     height: 2rem;
//     margin: 0.25rem;
//   }
//   .fit {
//     margin: -0.25rem;
//     margin-left: 0;
//     background-color: black;
//     color: white;
//     svg:first-of-type {
//       margin-left: 0.5rem;
//     }
//     svg:last-of-type {
//       margin-right: 0.5rem;
//     }
//   }
// `;

// const SolidFitLogo = () => {
//   return (
//     <SolidFit>
//       <FA icon={fa["faS"]} />
//       <FA icon={fa["faO"]} />
//       <FA icon={fa["faL"]} />
//       <FA icon={fa["faI"]} />
//       <FA icon={fa["faD"]} />
//       <Flex className="fit">
//         <FA icon={fa["faF"]} />
//         <FA icon={fa["faI"]} />
//         <FA icon={fa["faT"]} />
//       </Flex>
//     </SolidFit>
//   );
// };

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    activeUser,
    darkMode,
    setDarkMode,
    username,
    setActiveUser,
    loggedIn,
    setLoggedIn,
  } = useContext(UserContext);
  const { client } = useContext(SocketContext);

  const [toggleOpen, setToggleOpen] = useState(false);

  const darkModeToggle = useCallback(() => {
    window.sessionStorage.setItem("darkMode", !darkMode);
    setDarkMode(!darkMode);
  }, [darkMode, setDarkMode]);

  const signOut = useCallback(async () => {
    const { username, id } = activeUser || {};
    if (client)
      client.send(
        JSON.stringify({
          type: "userevent",
          username,
          content: { isLoggedIn: false },
        })
      );

    await updateUser({ id, input: { isLoggedIn: false } });
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("token");
    setActiveUser({});
    setToggleOpen(false);
    setLoggedIn(false);
    navigate("/Login");
  }, [activeUser, client, setActiveUser, setLoggedIn, navigate]);

  return useMemo(
    () => (
      <HeaderWrapper className="no-print" darkMode={darkMode}>
        <Link
          to={
            !activeUser
              ? "/"
              : constants.DEFAULT_HOMEPAGE[activeUser?.roleLevel || 0]
          }
        >
          {/* <SolidFitLogo /> */}
          <Flex alignItems="center">
            <img src={theme?.companyLogoWhite} alt="Wm. W. Meyer™" />
            <Body bold color="white">
              MEYER
              <span style={{ alignSelf: "flex-start", fontSize: "0.5rem" }}>
                ®
              </span>
            </Body>
          </Flex>
        </Link>
        <Flex alignItems="center">
          {!activeUser || activeUser?.roleLevel === 0 ? null : (
            <>
              <Button
                className="header-link hideable-header"
                iconPosition="left"
                iconName="faFileContract"
                textOnly
                to="/QuoteDashboard"
              >
                Quotes
              </Button>
              <Button
                className="header-link hideable-header"
                iconPosition="left"
                iconName="faTruckFast"
                textOnly
                to={`/Orders/${activeUser?.username}`}
              >
                Orders
              </Button>
            </>
          )}

          <DropdownWrapper
            onMouseLeave={() => setToggleOpen(false)}
            toggleOpen={toggleOpen}
            darkMode={darkMode}
          >
            {!loggedIn ? (
              <Button
                className="header-dropdown header-link"
                iconPosition="left"
                iconName="faUser"
                textOnly
                to="/Login"
              >
                Login
              </Button>
            ) : (
              <>
                <Button
                  className="header-dropdown header-link"
                  iconPosition="left"
                  iconName="faUser"
                  textOnly
                  onClick={() => setToggleOpen(!toggleOpen)}
                >
                  <Body ellipsis className="username">
                    {username || activeUser?.username}
                  </Body>
                  <FA icon={toggleOpen ? fa.faAngleUp : fa.faAngleDown} />
                </Button>
              </>
            )}

            <DropdownItems className="open">
              {!activeUser || activeUser?.roleLevel === 0 ? null : (
                <>
                  <Button
                    className="header-link hideable-dropdown"
                    iconPosition="left"
                    iconName="faBorderAll"
                    textOnly
                    to="/QuoteDashboard"
                  >
                    Quotes
                  </Button>
                  <Button
                    className="header-link hideable-dropdown"
                    iconPosition="left"
                    iconName="faTags"
                    textOnly
                    to={`/Orders/${activeUser?.username}`}
                  >
                    Orders
                  </Button>
                </>
              )}
              {activeUser?.roleLevel >= 5 && (
                <Button
                  className="header-link"
                  textOnly
                  to="/Administration"
                  iconName="faUserGear"
                  iconPosition="left"
                >
                  Administration
                </Button>
              )}

              <Button
                className="header-link"
                textOnly
                iconPosition="left"
                iconName="faUserPen"
                to={`/MyProfile/${activeUser?.username}`}
              >
                My Profile
              </Button>
              {/* WM Mod Removed */}
              {/* <Button
                className="header-link"
                textOnly
                to="/MyConfigurations"
                iconPosition="left"
                iconName="faPenRuler"
              >
                My Configurations
              </Button> */}
              <Button
                iconPosition="left"
                className="header-link"
                textOnly
                iconName="faArrowRightFromBracket"
                onClick={signOut}
              >
                Sign Out
              </Button>
              <Button className="header-link" textOnly onClick={darkModeToggle}>
                <FA icon={darkMode ? fa.faMoon : fa.faSun} />
                <Body>Dark Mode</Body>
                <FA
                  icon={darkMode ? fa.faToggleOn : fa.faToggleOff}
                  size="lg"
                />
              </Button>
            </DropdownItems>
          </DropdownWrapper>
        </Flex>
      </HeaderWrapper>
    ),
    [
      activeUser,
      darkMode,
      darkModeToggle,
      loggedIn,
      signOut,
      theme.companyLogoWhite,
      toggleOpen,
      username,
    ]
  );
};

export default Header;
