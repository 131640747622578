import React, { useContext, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "../Hooks";
import { Box, Flex } from "reflexbox/styled-components";
import * as userManagementRequests from "../../requests/userManagementRequests";
import StyledFormikField from "../UI/forms/Field";
import Button from "../UI/Button";
import FormButtonCluster from "../UI/forms/ButtonCluster";
import { PageWrapper } from "../UI/Grid";
import { Heading } from "../UI/Text";
import * as yup from "yup";
import moment from "moment";
import AlertContext from "../../context/alert/AlertContext";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-^&*]).{8,}$/,
      "Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .required("Password is required."),
  confirmPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-^&*]).{8,}$/,
      "Must contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .when("password", {
      is: (val) => val && val.length > 8,
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords do not match."),
    })
    .required("Please confirm your password."),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const passwordResetRequestId = searchParams.get("request") || "";
  const { showFailureAlert } = useContext(AlertContext);
  const { getPasswordResetRequest, updateUser } = userManagementRequests;

  const { data: userData, isLoading } = useQuery({
    queryID: "passwordReset",
    request: getPasswordResetRequest,
    queryVariables: { passwordResetRequestId },
    forcedFilters: {},
    skip: !passwordResetRequestId,
  });

  const { result: resetRequest } = userData || {};

  const { userId, expirationDate } = resetRequest || {};
  const now = new Date();
  const expired = moment(expirationDate).isBefore(now); // true

  useEffect(() => {
    if (!passwordResetRequestId) navigate("/Login");
  }, [isLoading, navigate, passwordResetRequestId, userId]);

  useEffect(() => {
    console.error(expired);
    showFailureAlert("Your login token has expired.");
    if (expired) navigate("/Login");
  }, [expired, navigate, showFailureAlert]);

  const onSubmit = useCallback(
    async (values) => {
      const input = {
        password: values.password,
        resetId: null,
        mfaSecret: null,
      };

      if (!userId) return showFailureAlert("Invalid password reset request.");

      await updateUser({ id: userId, input });

      navigate("/Login");
    },
    [navigate, showFailureAlert, updateUser, userId]
  );

  const onCancel = () => navigate("/Login");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <PageWrapper>
      <Box>
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            iconPosition="left"
            reverse
            mr="1rem"
            iconName="faArrowLeft"
            onClick={() => navigate(-1)}
          />
          <Heading ellipsis>Reset Password</Heading>
        </Flex>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            dirty,
            isSubmitting,
            isValidating,
            errors,
            values,
            submitForm,
            submitCount,
          }) => {
            const fieldProps = {
              errors,
              type: "text",
            };
            return (
              <Form>
                <>
                  <StyledFormikField
                    labelText="New Password"
                    {...fieldProps}
                    name="password"
                    type="password"
                  />
                  <StyledFormikField
                    labelText="Confirm Password: "
                    name="confirmPassword"
                    {...fieldProps}
                    type="password"
                  />
                </>

                <FormButtonCluster
                  onCancel={onCancel}
                  recordAction={"edit"}
                  saving={isSubmitting || isValidating}
                  dirty={dirty && submitCount < 1}
                  hideDelete
                  deleteMessage={`Are you sure you want to delete ${values.username}?`}
                  values={values}
                  onSubmit={submitForm}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </PageWrapper>
  );
};

export default ResetPassword;
