import React, { useContext, useMemo } from "react";
import * as yup from "yup";
import { useQuery } from "../Hooks";
import { Formik, Form } from "formik";
import { Flex } from "reflexbox";
import Card from "../UI/Card";
import { Field } from "../UI/forms";
import { Heading } from "../UI/Text";
import { updateQuote } from "../../requests/quoteManagementRequests";
import { getStatusTypes } from "../../requests/statusTypesMgmtReqs";
import UserContext from "../../context/user/UserContext";

const Details = ({ quote, isLoading: loading, setQuote }) => {
  const { _id, name, id, sfCustomer, notes, createdByEmail, statusTypeId } =
    quote || {};

  const { activeUser } = useContext(UserContext);
  const { roleLevel } = activeUser || {};

  const validationSchema = yup.object().shape({
    quoteName: yup.string().nullable(),
    customer: yup.string().nullable(),
    status: yup.string().nullable(),
    notes: yup.string().nullable(),
    createdByEmail: yup.string().nullable(),
    statusTypeId: yup.number().nullable(),
  });

  const statusTypesRes = useQuery({
    queryID: "quoteStatusTypes",
    request: getStatusTypes,
    queryVariables: { roleLevel },
    skip: !quote || loading || !roleLevel,
  });

  const { data, isLoading } = statusTypesRes || {};

  const { rows = [] } = data || {};

  const statusTypes = useMemo(() => {
    return rows.map((statusType) => {
      const { id, name } = statusType;
      return (
        <option key={id} value={id}>
          {name}
        </option>
      );
    });
  }, [rows]);

  const onSubmit = async (values) => {
    const input = {
      ...values,
    };
    setQuote({ ...quote, ...input });

    await updateQuote({ _id, input, id });
  };

  const initialValues = {
    quoteName: name || "",
    sfCustomer: sfCustomer || "",
    status: "",
    notes: notes || "",
    createdByEmail: createdByEmail || "",
    statusTypeId: statusTypeId || "",
  };

  return (
    <Card mt="2rem">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formProps) => {
          const { values } = formProps;
          return (
            <Form style={{ width: "100%" }}>
              <Heading mt="0">Details</Heading>
              <Flex
                alignItems="flex-start"
                flexWrap={["wrap", "wrap", "nowrap"]}
                style={{ gap: "1rem" }}
              >
                <Flex
                  flexWrap="wrap"
                  width={[1, 1, 0.5]}
                  style={{ gap: "1rem" }}
                >
                  <Field
                    onBlur={(e) => onSubmit({ name: e.target.value })}
                    labelText="Project Name"
                    name="quoteName"
                  />
                  <Field
                    onBlur={() => onSubmit(values)}
                    labelText="Customer"
                    name="sfCustomer"
                    disabled={true}
                  />
                  <Field
                    onBlur={() => onSubmit(values)}
                    labelText="Status"
                    name="statusTypeId"
                    component="select"
                    isLoading={isLoading}
                  >
                    <option>Select a Value</option>
                    {statusTypes}
                  </Field>
                </Flex>
                <Flex
                  flexWrap="wrap"
                  width={[1, 1, 0.5]}
                  style={{ gap: "1rem" }}
                >
                  {/* WM MOD Added Created By Email Field */}
                  <Field
                    disabled
                    onBlur={() => onSubmit(values)}
                    labelText="Created By"
                    name="createdByEmail"
                  />
                  {/* WM MOD Added Notes Field */}
                  <Field
                    onBlur={() => onSubmit(values)}
                    labelText="Notes"
                    name="notes"
                  />
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default Details;
